import React from "react";
import { YellowTextBold } from "../../../styles/GlobalStyles";
import {
  NewsSection,
  SingleNews,
  NewsImage,
  NewsDate,
  NewsInfo,
  NewsCategoryName,
  NewsTitle,
  NewsDesc,
  NewsHeader,
  NewsButton,
  NewsBox,
} from "./style";
import MerProgram from "../../../assets/news/MER-Program.png";
import Robotcode from "../../../assets/news/Robotcode.png";
import Kotor from "../../../assets/news/Kotor.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const News = () => {
  const { t } = useTranslation();
  return (
    <>
      <NewsSection id="news">
        <NewsHeader>{t("News")}</NewsHeader>
        <NewsBox>
          <Link to="/mojbiznews" style={{ textDecoration: "none" }}>
            <SingleNews>
              <NewsImage src={MerProgram} alt="" />
              <NewsInfo>
                <NewsDate>03. avgust 2023.</NewsDate>
                <NewsCategoryName>Projekti</NewsCategoryName>
                <NewsTitle>
                  Mojbiz.me podržan od strane Ministarstva ekonomskog razvoja
                  Crne Gore
                </NewsTitle>
                <NewsDesc>
                  Preduzeće Asenvirocon d.o.o. Kotor je danas potpisalo Ugovor o
                  sufinansiranju za realizaciju digitalne platforme mojbiz.me sa
                  Ministarstvom ekonomskog razvoja Crne Gore.Sredstva za
                  sufinansiranje su obezbjeđena iz Programske linije za
                  podršku...
                  <YellowTextBold>{t("ReadMore")}</YellowTextBold>
                </NewsDesc>
              </NewsInfo>
            </SingleNews>
          </Link>
          <Link to="/robot_code_news" style={{ textDecoration: "none" }}>
            <SingleNews>
              <NewsImage src={Robotcode} alt="" />
              <NewsInfo>
                <NewsDate>4. avgust 2023.</NewsDate>
                <NewsCategoryName>Projekti</NewsCategoryName>
                <NewsTitle>
                  Robot Code će razvijati platformu mojbiz.me
                </NewsTitle>
                <NewsDesc>
                  Pereduzeće Robot code d.o.o. iz Podgorice je danas potpisalo
                  Ugovor o pružanju usluga iz oblasti informatike sa preduzećem
                  Asenvirocon d.o.o. Kotor. Predmet ovog Ugovora su usluge
                  razvoja platforme za on-line kupovinu koja je osmišljena kao
                  digitalni trgovački centar. Preduzeće Asenvirocon d.o.o. Kotor
                  je sredstva za realizaciju Ugovora...
                  <YellowTextBold>{t("ReadMore")}</YellowTextBold>
                </NewsDesc>
              </NewsInfo>
            </SingleNews>
          </Link>
          <Link to="/miro&sons_news" style={{ textDecoration: "none" }}>
            <SingleNews>
              <NewsImage src={Kotor} alt="" />
              <NewsInfo>
                <NewsDate>4. avgust 2023.</NewsDate>
                <NewsCategoryName>Prodavnice</NewsCategoryName>
                <NewsTitle>
                  Miro & Sons iz Kotora je prvi korisnik platforme mojbiz.me
                </NewsTitle>
                <NewsDesc>
                  Preduzeće Miro & Sons d.o.o. iz Kotora će biti prvi korisnik
                  platforme mojbiz.me. Nakon prihvatanja ponude preduzeća
                  Asenvicon d.o.o. iz Kotora za razvoj platforme za on-line
                  kupovinu preduzeće Miro & Sons će razviti svoju digitalnu
                  prodavnicu – prodajni izlog...
                  <YellowTextBold>{t("ReadMore")}</YellowTextBold>
                </NewsDesc>
              </NewsInfo>
            </SingleNews>
          </Link>
        </NewsBox>
        <Link to={"/morenews"}>
          <NewsButton>{t("MoreNews")}</NewsButton>
        </Link>
      </NewsSection>
    </>
  );
};

export default News;
