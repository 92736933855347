import React from "react";
import { useEffect } from "react";
import {
  AvatarBlank,
  Diagram,
  Note,
  OrdersArrow,
} from "../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../context/context";
import {
  BlueTextBold,
  FlexBetween,
  FlexColumn,
  GrayTextMedium,
} from "../../styles/GlobalStyles";
import moment from "moment/moment";
import {
  Activity,
  BlueTextBigger,
  BlueTextMini,
  BlueTextSmaller,
  GrayText,
  Header,
  HomeSection,
  OrderPlaced,
  StatImage,
  StatImageBox,
  StatLeft,
  StatRight,
  StatText,
  ContentBox,
  AvatarText,
  AvatarImg,
  Avatar,
  FlexBetweenDemoShop,
} from "./styles";
import "../DemoShop copy/stylesAllPages.scss";
import { useTranslation } from "react-i18next";

const demoShopHomeData = {
  total_sum: 2526.0,
  order_count: 9,
  user_orders: [
    {
      order_date: "2023-01-06 13:30:35.568394",
      order_total: "600.0",
      status: "rejected",
      buyer_email: "adgdsg@nazyno.com",
    },
    {
      order_date: "2023-01-06 13:30:12.512935",
      order_total: "600.0",
      status: "pending",
      buyer_email: "markemail@nazyno.com",
    },
    {
      order_date: "2023-01-06 13:29:56.666523",
      order_total: "126.0",
      status: "pending",
      buyer_email: "markemail@nazyno.com",
    },
    {
      order_date: "2023-12-24 11:53:32.615074",
      order_total: "200.0",
      status: "accepted",
      buyer_email: "pipave8839@nazyno.com",
    },
    {
      order_date: "2023-12-24 11:46:13.607098",
      order_total: "200.0",
      status: "rejected",
      buyer_email: "pipave8839@nazyno.com",
    },
  ],
  top_products: [
    {
      name: "First product",
      out_of_stock: "False",
      price: "100.0",
    },
    {
      name: "Second product",
      out_of_stock: "False",
      price: "200.0",
    },
    {
      name: "Third productt",
      out_of_stock: "False",
      price: "1256.0",
    },
  ],
};

const DemoShopHome = ({ sections, setSections }) => {
  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };
  const { authKey, logUserOut, getDemoShopHome, home } = useGlobalContext();
  useEffect(() => {
    console.log(authKey);
    getDemoShopHome();
  }, [authKey]);
  console.log(authKey);

  const dateHandler = (dateString) => {
    const time = moment(dateString).fromNow();
    return time;
  };

  const { t } = useTranslation();

  return (
    <>
      <HomeSection>
        <Header>
          <div className="hello-there">
            <BlueTextSmaller style={{ display: "block" }}>
              {t("Hellothere")}!
            </BlueTextSmaller>
            <GrayTextMedium style={{ lineHeight: "30px", height: "60px" }}>
              {t("HereIsSomeInformation")}
            </GrayTextMedium>
          </div>
          <div className="hello-there-small">
            <h2 className="hello">Hello there</h2>
            <GrayTextMedium style={{ lineHeight: "30px", height: "60px" }}>
              {t("HereIsSomeInformation")}
            </GrayTextMedium>
          </div>
        </Header>
        <ContentBox>
          <div className="stats-box-and-products">
            <FlexBetweenDemoShop>
              <StatLeft id="stat">
                <FlexBetween
                  style={{
                    padding: "15px 15px 15px 20px",
                    alignItems: "start",
                  }}
                >
                  <StatText>
                    <div
                      style={{
                        height: "90px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <BlueTextSmaller style={{ marginLeft: -3 }}>
                        {t("Sales")}
                      </BlueTextSmaller>
                      <GrayText>{t("Today")}</GrayText>
                    </div>
                    <div
                      style={{
                        lineHeight: "30px",
                        marginTop: "-8px",
                      }}
                    >
                      <BlueTextMini>EUR</BlueTextMini>&nbsp;
                      <BlueTextSmaller>
                        {demoShopHomeData.total_sum}.00
                      </BlueTextSmaller>
                    </div>
                  </StatText>
                  <StatImageBox>
                    <StatImage src={Diagram} alt="" />
                  </StatImageBox>
                </FlexBetween>
              </StatLeft>
              <StatRight>
                <FlexBetween
                  style={{
                    padding: "15px 15px 15px 20px",
                    alignItems: "start",
                  }}
                >
                  <StatText>
                    <div
                      style={{
                        height: "90px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <BlueTextSmaller style={{ marginLeft: -3 }}>
                        {t("Orders")}
                      </BlueTextSmaller>
                      <GrayText>{t("Today")}</GrayText>
                    </div>
                    <div
                      style={{
                        lineHeight: "30px",
                        marginTop: "-8px",
                      }}
                    >
                      <BlueTextSmaller>
                        {demoShopHomeData.order_count}
                      </BlueTextSmaller>
                    </div>
                  </StatText>
                  <StatImageBox>
                    <StatImage src={Note} alt="" />
                  </StatImageBox>
                </FlexBetween>
              </StatRight>
            </FlexBetweenDemoShop>
            {/* orders */}
            <div className="home-top-products">
              {/* <div className="home-products-content"> */}
              <h4 className="home-top-products-header">
                {t("Bestsellingproducts")}
              </h4>
              <table className="home-products-table">
                <tbody>
                  {/* slicer(demoShopHomeData.top_products) */}
                  {demoShopHomeData.top_products.map((item) => {
                    return (
                      <tr>
                        <td>
                          <GrayTextMedium>{item?.name}</GrayTextMedium>
                        </td>
                        <td>
                          <GrayTextMedium>EUR {item?.price}</GrayTextMedium>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="home-single-product"></div>
              {/* </div> */}
            </div>
          </div>
          <Activity id="activity">
            <div style={{ height: "60px" }}>
              <BlueTextSmaller
                style={{ textAlign: "left", display: "inline-block" }}
              >
                {t("Activity")}
              </BlueTextSmaller>
            </div>
            <FlexColumn style={{ alignItems: "start", padding: "0 10px" }}>
              {demoShopHomeData.user_orders.map((item) => {
                return (
                  <OrderPlaced>
                    <BlueTextMini>#11864 {t("Orderwasplaced")}</BlueTextMini>
                    <div className="gray-text">{t("KadaNaruceno")}</div>
                  </OrderPlaced>
                );
              })}
            </FlexColumn>
          </Activity>
        </ContentBox>
      </HomeSection>
    </>
  );
};

export default DemoShopHome;
