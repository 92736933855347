import React, { useState } from "react";
import {
  ScrollUp,
  Logo,
  YT,
  FB,
  IG,
} from "../../../assets/landingPage/landingPageAssets";

import {
  Logo_NLB_Banka_sponzorski_10mm_RGB,
  mc_idcheck_vrt_rev,
  mc_vrt_opt_rev_63_3x1,
  ms_vrt_opt_rev_63_3x,
  visa_pos_fc,
  visa_sec,
} from "../../../assets/footer/footerAssets";

import {
  FooterContainer,
  Icon,
  Info,
  InfoClasses,
  InfoClassHeader,
  InfoClassLink,
  InfoContainer,
  RightsReserved,
  Scroll,
  ScrollArrow,
  Social,
  SocialIcons,
  ScrollBox,
} from "./style";
import { AlignItems } from "../../../styles/GlobalStyles";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";
import { useTranslation } from "react-i18next";
import "../../../styles/app.css";

const openPdf = (name) => {
  // Replace 'your-pdf-file.pdf' with the actual path or URL of your PDF file
  const pdfUrl = `assets/pdfs/${name}.pdf`;
  
  // Open the PDF in a new window
  window.open(pdfUrl, "_blank");
};

const Footer = () => {
  const [scrollHover, setScrollHover] = useState(false);
  const { t } = useTranslation();
  
  

  const scrollHandler = () => {
    window.scrollTo({ top: "0", left: "0", behavior: "smooth" });
  };

  return (
    <>
      <FooterContainer id="container">
        <InfoContainer id="info-container">
          <AlignItems id="">
                
            <div id="fLogoInfoBig" style={{ display: "flex", flexDirection: "column", alignItems: "center", color: "#ffffff", fontFamily: "Jost", fontStyle: "normal", fontWeight: "300", lineHeight: "1.5rem" }}>
            <img src={Logo} id="logo1" alt="logo" />
            <p>Asenvirocon d.o.o. Kotor</p>
            <p> Šebeljska bb, 85317 Lastva Grbaljska</p>
              <p>Kotor, Montenegro</p>
              {t("Vat")}: 03354946; {t("Pin")}: 50964681
            </div>

            <Info id="info">
              <InfoClasses id="info-classes">
                <InfoClassHeader>{t("About")}</InfoClassHeader>
                <HashLink
                  style={{ textDecoration: "none" }}
                  to={"/contactus/#contact"}
                  scroll={(el) =>
                    el.scrollIntoView({ behavior: "auto", block: "end" })
                  }
                >
                  <InfoClassLink>{t("Contact")}</InfoClassLink>
                </HashLink>
                <button
                  style={{ textDecoration: "none", backgroundColor: 'transparent', border: '0' }}
                  onClick={() => openPdf('usloviilicenca')}
                >
                  <InfoClassLink>{t("LicenseAgreement")}</InfoClassLink>
                </button>
                <button
                  style={{ textDecoration: "none", backgroundColor: 'transparent', border: '0' }}
                  onClick={() => openPdf('politikaprivatnosti')}
                >
                  <InfoClassLink>{t("PrivacyPolicy")}</InfoClassLink>
                </button>
                <button
                  style={{ textDecoration: "none", backgroundColor: 'transparent', border: '0' }}
                  onClick={() => openPdf('uputstvozakupovinu')}
                >
                  <InfoClassLink>{t("PurchaseInstructions")}</InfoClassLink>
                </button>
              </InfoClasses>
              <InfoClasses>
                <InfoClassHeader>{t("Shops")}</InfoClassHeader>
                <HashLink
                  style={{ textDecoration: "none" }}
                  to={"/demoshop"}
                  scroll={(el) =>
                    el.scrollIntoView({ behavior: "auto", block: "center" })
                  }
                >
                  <InfoClassLink>{t("Demoshop")}</InfoClassLink>
                </HashLink>
                <HashLink
                  style={{ textDecoration: "none" }}
                  to={"/offer"}
                  scroll={(el) =>
                    el.scrollIntoView({ behavior: "auto", block: "center" })
                  }
                >
                  <InfoClassLink>{t("Offer")}</InfoClassLink>
                </HashLink>
              </InfoClasses>
              {!sessionStorage.getItem("authKey") && (
                <>
                  <InfoClasses>
                    <InfoClassHeader>{t("Users")}</InfoClassHeader>
                    <HashLink
                      style={{ textDecoration: "none" }}
                      to={"/login"}
                      scroll={(el) =>
                        el.scrollIntoView({ behavior: "auto", block: "center" })
                      }
                    >
                      <InfoClassLink>{t("Login")}</InfoClassLink>
                    </HashLink>
                  </InfoClasses>
                </>
              )}
            </Info>
          </AlignItems>
          <AlignItems id='scroll_arrow'>
            <Scroll scrollHover={scrollHover}>{t("ScrollUp")}</Scroll>
            <ScrollBox scrollHover={scrollHover}>
              <ScrollArrow
                scrollHover={scrollHover}
                src={ScrollUp}
                alt="scroll-arrow"
                onClick={scrollHandler}
                onMouseOver={() => setScrollHover(true)}
                onMouseLeave={() => setScrollHover(false)}
              />
            </ScrollBox>
          </AlignItems>
        </InfoContainer>
        <div id="fLogoInfoSmall" style={{ display: "flex", flexDirection: "column", alignItems: "center", color: "#ffffff", fontFamily: "Jost", fontStyle: "normal", fontWeight: "300", lineHeight: "1.5rem" }}>
            <img src={Logo} id="logo2" alt="logo" />
            <p>Asenvirocon d.o.o. Kotor</p>
            <p> Šebeljska bb, 85317 Lastva Grbaljska</p>
              <p>Kotor, Montenegro</p>
            {t("Vat")}: 03354946; {t("Pin")}: 50964681
            </div>
            <div id="sup">
              {t("SupportedBy")}:
            <div id="bankWrapper">
              <div>
            <img src={visa_pos_fc} id="bank" />
            <img src={mc_vrt_opt_rev_63_3x1} id="bank" />
            <img src={ms_vrt_opt_rev_63_3x} id="bank" />
            </div>
            <div>
            <img src={mc_idcheck_vrt_rev} id="bank" />
            <img src={visa_sec} id="bank" />
            <img src={Logo_NLB_Banka_sponzorski_10mm_RGB} id="bank" />
            </div>
            </div>
            </div>
        <Social id="social">
          <RightsReserved>
            © {new Date().getFullYear()}{" "}
            <Link
              to={"/"}
              style={{ color: "#FF9900", textDecoration: "none" }}
              onClick={scrollHandler}
            >
              MojBiz.me
            </Link>{" "}
            {t("AllRightsReserved")}
          </RightsReserved>
          {/* <SocialIcons>
            <Link to={"/"}>
              <Icon src={IG} alt="instagram-icon" />
            </Link>
            <Link to={"/"}>
              <Icon src={FB} alt="facebook-icon" />
            </Link>
            <Link to={"/"}>
              <Icon src={YT} alt="youtube" style={{ marginRight: "0" }} />
            </Link>
          </SocialIcons> */}
        </Social>

      </FooterContainer>
    </>
  );
};

export default Footer;
