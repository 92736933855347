import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DashboardEdit,
  DashboardTrash,
  OrangeTrash,
} from "../../../assets/landingPage/landingPageAssets";

const SingleCategory = ({ item, editCategoryHandler, removeHandler }) => {
  const [trashHovered, setTrashHovered] = useState(false);
  const [editHovered, setEditHovered] = useState(false);
  const { t } = useTranslation();
  return (
    <tr className="categories-tr">
      <td width="60%" className="gray-text categories-td">
        <div className="categories-td-box">{item.name}</div>
      </td>
      <td className="gray-text ">{item?.number_of_occurrence}</td>
      <td>
        <div
          className="trash-btn-categories"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {item?.number_of_occurrence > 0 ? (
            <button className="trash-btn-disabled">
              <div className="trash-btn-content">
                <img src={DashboardTrash} alt="" />
                <h4 className="trash-text">{t("Remove")}</h4>
              </div>
            </button>
          ) : (
            <div className="trash-btn-box">
              {/* <button
                className="trash-btn"
                onClick={() => editCategoryHandler(item?.id)}
                onMouseOver={() => setEditHovered(true)}
                onMouseOut={() => setEditHovered(false)}
              >
                <div className="trash-btn-content">
                  <img
                    src={DashboardEdit}
                    alt=""
                    // style={editHovered ? { opacity: 0 } : { opacity: 1 }}
                  />
                  <img
                    // src={OrangeEdit}
                    className="orange-edit"
                    alt=""
                    // style={editHovered ? { opacity: 1 } : { opacity: 0 }}
                  />
                  <h4 className="trash-text">{t("Edit")}</h4>
                </div>
              </button> */}

              <button
                className="trash-btn"
                onClick={() => removeHandler("category", item?.id)}
                onMouseOver={() => setTrashHovered(true)}
                onMouseOut={() => setTrashHovered(false)}
              >
                <div className="trash-btn-content">
                  <img
                    src={DashboardTrash}
                    alt=""
                    style={trashHovered ? { opacity: 0 } : { opacity: 1 }}
                  />
                  <img
                    src={OrangeTrash}
                    alt=""
                    className="orange-trash"
                    style={trashHovered ? { opacity: 1 } : { opacity: 0 }}
                  />
                  <h4 className="trash-text">{t("Remove")}</h4>
                </div>
              </button>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default SingleCategory;
