import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  BlueCross,
  CustomerArrowUp,
  CustomerRealArrow,
  GrayArrow,
  GrayArrowRight,
  PaginationArrow,
  PaginationArrowLeft,
} from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import {
  BlueTextBold,
  BlueTextMedium,
  GrayTextMedium,
} from "../../../styles/GlobalStyles";
import {
  AllCustomersBox,
  BlueTextSmaller,
  CustomerArrow,
  CustomerBoxHeader,
  CustomerContainer,
  CustomerCross,
  CustomerFilterButton,
  CustomerFiltersBox,
  CustomerHeader,
  CustomerInput,
  CustomerInputWrap,
  CustomersBoxFlex,
  CustomerSection,
  PaginationArrows,
  PaginationBox,
} from "../styles";
import "../../DemoShop copy/stylesAllPages.scss";
import { useTranslation } from "react-i18next";

const customersData = [
  {
    customer_name: "Phillip",
    customer_last_name: "Phlein",
    customer_email: "pplein@gmail.com",
    customer_phone: "06343434333",
  },
  {
    customer_name: "John",
    customer_last_name: "Addison",
    customer_email: "mm@gmail.com",
    customer_phone: "0634341133",
  },
  {
    customer_name: "Nicole",
    customer_last_name: "Fomm",
    customer_email: "dfdfdf@gmail.com",
    customer_phone: "06111434333",
  },
  {
    customer_name: "Mike",
    customer_last_name: "Zooker",
    customer_email: "1122dsaf@gmail.com",
    customer_phone: "06343434344",
  },
  {
    customer_name: "Don",
    customer_last_name: "Davidson",
    customer_email: "dav@gmail.com",
    customer_phone: "06343434555",
  },
  {
    customer_name: "Seil",
    customer_last_name: "Boom",
    customer_email: "gggBoom@gmail.com",
    customer_phone: "06243434222",
  },
  {
    customer_name: "Deem",
    customer_last_name: "Finch",
    customer_email: "finchfinchfinch@gmail.com",
    customer_phone: "06143434311",
  },
  {
    customer_name: "Michael",
    customer_last_name: "Dave",
    customer_email: "michaeldavedave@gmail.com",
    customer_phone: "06343434355",
  },
  {
    customer_name: "Joe",
    customer_last_name: "Mikelson",
    customer_email: "333mikelson@gmail.com",
    customer_phone: "06743434399",
  },
];

const CustomersPage = ({ sections, setSections }) => {
  const [ascending, setAscending] = useState({
    name: false,
    lastName: false,
    email: false,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [customerFocus, setCustomerFocus] = useState(false);
  const [customersFilter, setCustomersFilter] = useState("");
  const customerRef = useRef(null);
  const customerCrossHandler = () => {
    setCustomerFocus(false);
    setCustomersFilter("");
    customerRef.current.value = "";
  };
  const { t } = useTranslation();
  const { customers, logUserOut, getDemoShopCustomers } = useGlobalContext();
  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };
  useEffect(() => {
    console.log(customersFilter);
    getDemoShopCustomers();
    if (customersFilter) {
      setCurrentPage(0);
    }
  }, [customersFilter]);
  const customersListMaker = () => {
    let list = [];
    customersData.filter((item) => {
      if (
        item.customer_email
          .toLowerCase()
          .includes(customersFilter.toLowerCase())
      ) {
        list.push(item);
      }
      return list;
    });
    return list;
  };
  let itemsPerPage = 7;
  let pageCount = Math.ceil(customersListMaker().length / itemsPerPage);
  let itemsVisited = currentPage * itemsPerPage;
  const pageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const ascendingHandler = (asc) => {
    let data = customersData;
    if (asc === "firstName") {
      setAscending({ ...ascending, name: !ascending.name });
      if (ascending.name) {
        data.sort((a, b) => a.customer_name.localeCompare(b.customer_name));
      } else {
        data.sort((a, b) => b.customer_name.localeCompare(a.customer_name));
      }
    } else if (asc === "lastName") {
      setAscending({ ...ascending, lastName: !ascending.lastName });
      if (ascending.lastName) {
        data.sort((a, b) =>
          a.customer_last_name.localeCompare(b.customer_last_name)
        );
      } else {
        data.sort((a, b) =>
          b.customer_last_name.localeCompare(a.customer_last_name)
        );
      }
    } else if (asc === "email") {
      setAscending({ ...ascending, email: !ascending.email });
      if (ascending.email) {
        data.sort((a, b) => a.customer_email.localeCompare(b.customer_email));
      } else {
        data.sort((a, b) => b.customer_email.localeCompare(a.customer_email));
      }
    }
  };

  const nextPage = () => {
    if (currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <>
      <CustomerSection>
        {/* <Avatar id="avatar" className="avatar">
          <AvatarText className="avatar-text">
            <BlueTextBold style={{ fontSize: "18px" }}>
              admin@example.com
            </BlueTextBold>
            <div className="flex">
              <GrayTextMedium className="settings" onClick={settingsHandler}>
                Profile settings
              </GrayTextMedium>
              <GrayTextMedium className="log-out">Log Out</GrayTextMedium>
            </div>
          </AvatarText>
          <AvatarImg src={AvatarBlank} alt="" />
        </Avatar> */}
        <CustomerContainer id="container">
          <CustomerHeader>
            <BlueTextSmaller>{t("Customers")}</BlueTextSmaller>
          </CustomerHeader>
          <AllCustomersBox>
            <CustomersBoxFlex id="customersBox">
              <CustomerBoxHeader
                style={{
                  width: "100%",
                  borderBottom: "1px solid rgba(126, 148, 171, 0.1)",
                }}
              >
                {t("AllCustomers")}
              </CustomerBoxHeader>
              <CustomerFiltersBox>
                <CustomerInputWrap customerFocus={customerFocus}>
                  <CustomerCross
                    onClick={customerCrossHandler}
                    customerFocus={customerFocus}
                    src={BlueCross}
                    alt=""
                  />
                  <CustomerInput
                    onClick={() => setCustomerFocus(true)}
                    ref={customerRef}
                    placeholder={t("SearchCustomerbyemail")}
                    onChange={(e) => setCustomersFilter(e.target.value)}
                    customerFocus={customerFocus}
                  />
                </CustomerInputWrap>
              </CustomerFiltersBox>
              <div className="table_wrapper">
                <table className="orders-table-customers">
                  <tbody>
                    <tr>
                      <td width="20%">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <h4
                            className="gray-text"
                            style={{ marginRight: "10px" }}
                          >
                            {t("Firstname")}
                          </h4>
                          <div
                            className="arrowBox"
                            onClick={() => ascendingHandler("firstName")}
                          >
                            <CustomerArrow src={CustomerArrowUp} alt="" />
                            <img
                              src={CustomerRealArrow}
                              alt=""
                              className={
                                ascending.name ? "arrow-drop" : "arrow"
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td width="20%">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <h4
                            className="gray-text"
                            style={{ marginRight: "10px" }}
                          >
                            {t("LastName")}
                          </h4>
                          <div
                            className="arrowBox"
                            onClick={() => ascendingHandler("lastName")}
                          >
                            <CustomerArrow src={CustomerArrowUp} alt="" />
                            <img
                              src={CustomerRealArrow}
                              alt=""
                              className={
                                ascending.lastName ? "arrow-drop" : "arrow"
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td width="40%">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <h4
                            className="gray-text"
                            style={{ marginRight: "10px" }}
                          >
                            {t("Customeremail")}
                          </h4>
                          <div
                            className="arrowBox"
                            onClick={() => ascendingHandler("email")}
                          >
                            <CustomerArrow src={CustomerArrowUp} alt="" />
                            <img
                              src={CustomerRealArrow}
                              alt=""
                              className={
                                ascending.email ? "arrow-drop" : "arrow"
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td width="20%">
                        <div
                          className="orders-td-box-center"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div className="gray-text ">{t("Phonenumber")}</div>
                        </div>
                      </td>
                    </tr>

                    {/* table */}
                    {customersListMaker().length ? (
                      <>
                        {customersListMaker()
                          .slice(itemsVisited, itemsVisited + itemsPerPage)
                          .map((item) => {
                            return (
                              <tr>
                                <td width="20%">
                                  <div className="image-flex">
                                    <h4 className="gray-text orders-td-box">
                                      {item.customer_name}
                                    </h4>
                                  </div>
                                </td>
                                <td width="20%" className="gray-text">
                                  <div className="orders-td-box">
                                    {item.customer_last_name}
                                  </div>
                                </td>
                                <td width="40%">
                                  <div
                                    className="orders-td-box gray-text"
                                    style={{ textAlign: "left" }}
                                  >
                                    {item.customer_email}
                                  </div>
                                </td>
                                <td width="20%" className="gray-text">
                                  <div className="orders-td-box-center">
                                    {item.customer_phone}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
              <PaginationBox>
                <div className="pagination-elements">
                  <div className="pagination-arrow">
                    <img
                      alt=""
                      src={currentPage === 0 ? GrayArrow : PaginationArrowLeft}
                      onClick={previousPage}
                    />
                  </div>
                  <BlueTextMedium className="current-page">
                    {currentPage}
                  </BlueTextMedium>
                  <div className="pagination-arrow">
                    <img
                      alt=""
                      src={
                        customersListMaker().length - itemsVisited <= 7
                          ? GrayArrowRight
                          : PaginationArrow
                      }
                      onClick={nextPage}
                    />
                  </div>
                </div>
              </PaginationBox>
            </CustomersBoxFlex>
          </AllCustomersBox>
        </CustomerContainer>
      </CustomerSection>
    </>
  );
};

export default CustomersPage;
