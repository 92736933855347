import React from 'react'
import { Footer } from '../../imports/landingPageContent'
import { BlueTextBold, ButtonBlue, GrayTextBold, YellowTextBold } from '../../styles/GlobalStyles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentSuccess = () => {
    const { t } = useTranslation();
    
    return (
      <>
        <div className="order-placed-section">
          <div className="order-container">
            <GrayTextBold className="header">{t("Hooray")}</GrayTextBold>
            <div className="order-placed">
              <BlueTextBold className="order-number">
                {t("PaymentSuccess")}
              </BlueTextBold>
            </div>
            <Link to={`/shop/${localStorage.getItem("shopId")}`}>
              <ButtonBlue className="proceed">{t("BackToShop")}</ButtonBlue>
            </Link>
          </div>
        </div>
        <Footer />
      </>
    );
  };

export default PaymentSuccess