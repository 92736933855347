import React, { useEffect } from "react";
import { useState } from "react";
import {
  BlueCross,
  PremiumStar,
} from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import {
  ButtonBlue,
  ButtonYellow,
  GrayTextMedium,
  GrayTextMediumBigger,
} from "../../../styles/GlobalStyles";
import { CustomerBoxHeaderNoBorder } from "../styles";
import "./shopStyles.scss";
import FileBase from "react-file-base64";
import {
  InputWrap,
  PhoneNumberCross,
  PhoneNumberPlaceholder,
  PhoneNumberPlaceholderBOx,
} from "../../../pages/CheckoutPage/CheckoutInputs";
import PhoneInput from "react-phone-number-input";
import { useTranslation } from "react-i18next";

const EditShop = ({ setCreateNewShop, edit, setEdit, open }) => {
  const {
    getDemoShopShop,
    postPatchedShop,
    yourShop,
    patchedShop,
    setPatchedShop,
    setYourShop,
    setRemoveString,
    editShopResponse,
    setEditShopResponse,
    setReload,
    reload,
    setLoadPatch,
    loadPatch,
  } = useGlobalContext();
  const [shopNameFocused, setShopNameFocused] = useState(false);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [descriptionFocused, setDescriptionFocused] = useState(false);
  const [edited, setEdited] = useState();
  const [descriptionCount, setDescriptionCount] = React.useState(0);

  const [image, setImage] = useState();
  useEffect(() => {
    getDemoShopShop();
  }, [reload]);

  useEffect(() => {
    // setPatchedShop({
    //   name: yourShop?.name,
    //   description: yourShop?.description,
    //   phone: yourShop?.phone_number,
    //   is_premium: false,
    // });
    // if (image) {
    //   setPatchedShop({
    //     ...patchedShop,
    //     image: image?.split("base64,")[1],
    //   });
    // }
  }, [yourShop, image]);
  const SubmitShophandler = (e) => {
    e.preventDefault();
    if (
      !patchedShop.name.length &&
      !patchedShop.phone_number.length &&
      !patchedShop.description.length &&
      !patchedShop.image.length
    ) {
      setEditShopResponse("You haven't made any changes.");
      console.log(123);
    } else {
      setLoadPatch(true);
      setEditShopResponse("");
      postPatchedShop();
    }
    setPatchedShop({
      name: "",
      description: "",
      image: "",
      is_premium: false,
      phone_number: "",
    });
    setShopNameFocused(false);
    setDescriptionFocused(false);
    setEdited(true);
    setTimeout(() => {
      setEdited(false);
    }, 7000);
    setReload(true);
  };
  // cross handlers
  const shopNameCrossHandler = () => {
    setShopNameFocused(false);
  };
  const phoneCrossHandler = () => {
    setPhoneFocused(false);
  };
  const descriptionCrossHandler = () => {
    setDescriptionFocused(false);
  };
  const premiumHandler = (string) => {
    open();
    setRemoveString(string);
  };
  const shopImageHandler = (file) => {
    setPatchedShop({ ...patchedShop, image: file.base64.split("base64,")[1] });
    setYourShop({ ...yourShop, image: file.base64 });
  };
  const changeHandler = (e, string) => {
    if (string === "name") {
      setYourShop({ ...yourShop, name: e.target.value });
      setPatchedShop({ ...patchedShop, name: e.target.value });
      console.log(yourShop, patchedShop, e);
    } else {
      setYourShop({ ...yourShop, description: e.target.value });
      setPatchedShop({ ...patchedShop, description: e.target.value });
      console.log(e);
    }
  };
  const cancelHandler = () => {
    if (!loadPatch) {
      setEdit(false);
    }
    setPatchedShop({
      name: "",
      description: "",
      phone_number: "",
      image: "",
      is_premium: false,
    });
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="content">
        <CustomerBoxHeaderNoBorder className="header">
          {t("Editshop")}
        </CustomerBoxHeaderNoBorder>
        <div className="edit-box">
          {loadPatch ? (
            <>
              <div className="shop-image-box-loading">
                <img src={image ? image : yourShop?.image} alt="" />
                <div className="text">
                  <label>
                    <div className="loading-spinner-smaller"></div>
                  </label>
                </div>
                {yourShop?.is_premium && (
                  <img src={PremiumStar} alt="" className="star" />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="shop-image-box">
                <img src={image ? image : yourShop?.image} alt="" />
                <div className="text">
                  <label>
                    <FileBase
                      type="file"
                      onDone={shopImageHandler.bind(this)}
                    />
                    {t("ChangeImage")}
                  </label>
                    <label className="">280x280px</label>
                </div>
                {yourShop?.is_premium && (
                  <img src={PremiumStar} alt="" className="star" />
                )}
              </div>
            </>
          )}

          <h3 className="edit-box-response">
            {edited ? (
              <GrayTextMediumBigger>{editShopResponse}</GrayTextMediumBigger>
            ) : (
              <></>
            )}
          </h3>
          <form onSubmit={SubmitShophandler}>
            <div className="no-shop-content">
              <div className="input-shop">
                <input
                  style={
                    shopNameFocused
                      ? { background: "rgba(114, 139, 166, .4)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onClick={() => setShopNameFocused(true)}
                  onChange={(e) => changeHandler(e, "name")}
                  value={yourShop?.name}
                />
                <img
                  style={shopNameFocused ? { opacity: "1" } : { opacity: "0" }}
                  src={BlueCross}
                  onClick={shopNameCrossHandler}
                  alt=""
                />
                <div style={{ top: "-33px" }} className="placeholder-box">
                  <div className="placeholder" style={{ fontSize: "11px" }}>
                    {t("Shopname")}
                  </div>
                </div>
              </div>
              <InputWrap
                className="phone-input-box"
                style={
                  phoneFocused
                    ? {
                        backgroundColor: "rgba(114, 139, 166, .4)",
                        width: "100%",
                        borderRadius: "20px",
                      }
                    : { width: "100%", borderRadius: "20px" }
                }
              >
                <PhoneNumberCross
                  phoneFocused={phoneFocused}
                  src={BlueCross}
                  style={{ width: "24px", height: "24px" }}
                  alt=""
                  onClick={phoneCrossHandler}
                />
                <PhoneInput
                  onFocus={() => setPhoneFocused(true)}
                  value={yourShop?.phone_number}
                  onChange={(number) => {
                    setYourShop({ ...yourShop, phone_number: number });
                    setPatchedShop({ ...patchedShop, phone_number: number });
                  }}
                />
                <PhoneNumberPlaceholderBOx
                  style={{ top: "-33px", left: "22px" }}
                >
                  <PhoneNumberPlaceholder style={{ fontSize: "11px" }}>
                    {t("Phonenumber")}
                  </PhoneNumberPlaceholder>
                </PhoneNumberPlaceholderBOx>
              </InputWrap>
              <div className="input-shop relative" style={{ height: "130px" }}>
                <textarea
                  maxLength="2000"
                  className="edit-shop-textarea"
                  style={
                    descriptionFocused
                      ? { background: "rgba(114, 139, 166, .4)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onClick={() => setDescriptionFocused(true)}
                  onChange={(e) => {
                    changeHandler(e, "description");
                    setDescriptionCount(e.target.value.length);
                  }}
                  value={yourShop?.description}
                />
                <div
                  style={{
                    top: "30%",
                    left: "-8%",
                    position: "relative",
                    zIndex: "1",
                  }}
                  className="placeholder-box"
                >
                  <p>{descriptionCount}</p>
                </div>
                <img
                  style={
                    descriptionFocused ? { opacity: "1" } : { opacity: "0" }
                  }
                  src={BlueCross}
                  onClick={descriptionCrossHandler}
                  alt=""
                />
                <div
                  style={{ top: "-70px", position: "reltive", zIndex: "1" }}
                  className="placeholder-box"
                >
                  <div className="placeholder" style={{ fontSize: "11px" }}>
                    {t("Description")}
                  </div>
                </div>
              </div>
              <div className="button-box">
                <div>
                  <ButtonBlue
                    className="create-shop-btn"
                    onSubmit={SubmitShophandler}
                  >
                    {t("Editshop")}
                  </ButtonBlue>
                  <ButtonBlue
                    className="create-shop-btn"
                    type="submit"
                    onClick={cancelHandler}
                  >
                    {t("Close")}
                  </ButtonBlue>
                </div>
                {!yourShop?.is_premium && (
                  <ButtonYellow
                    className="premium-plan-btn"
                    type="button"
                    onClick={() => premiumHandler("premium")}
                  >
                    {t("Changetopremiumplan")}
                  </ButtonYellow>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditShop;
