import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DashboardEdit,
  DashboardTrash,
  OrangeEdit,
  OrangeTrash,
} from "../../../assets/landingPage/landingPageAssets";

const SingleProduct = ({ item, editProductHandler }) => {
  const [trashHovered, setTrashHovered] = useState(false);
  const [editHovered, setEditHovered] = useState(false);
  const { t } = useTranslation();
  return (
    <tr className="products-tr" key={item?.id}>
      <td className="products-td" width="20%">
        <div className="image-flex">
          <img
            className="product-image"
            alt=""
            src={item?.images[0].image_url}
          />
          <h4 className="gray-text">{item?.name}</h4>
        </div>
      </td>
      <th className="gray-text">{item?.category.name}</th>
      <th>
        {item?.out_of_stock ? (
          <button className="availability red">{t("NotAvailable")}</button>
        ) : (
          <button className="availability green">{t("Available")}</button>
        )}
      </th>
      <th className="gray-text">{item?.price === 0.01 ? `${t("PriceRequest")}` : `${item?.price.toLocaleString()}€`}</th>
      <th>
        <div className="trash-btn-box">
          <button
            className="trash-btn"
            onClick={() => editProductHandler(item)}
            onMouseOver={() => setEditHovered(true)}
            onMouseOut={() => setEditHovered(false)}
          >
            <div className="trash-btn-content">
              <img
                src={DashboardEdit}
                alt=""
                style={editHovered ? { opacity: 0 } : { opacity: 1 }}
              />
              <img
                src={OrangeEdit}
                className="orange-edit"
                alt=""
                style={editHovered ? { opacity: 1 } : { opacity: 0 }}
              />
              <h4 className="trash-text">{t("Edit")}</h4>
            </div>
          </button>
          <button
            className="trash-btn"
            onMouseOver={() => setTrashHovered(true)}
            onMouseOut={() => setTrashHovered(false)}
          >
            <div className="trash-btn-content">
              <img
                src={DashboardTrash}
                alt=""
                style={trashHovered ? { opacity: 0 } : { opacity: 1 }}
              />
              <img
                src={OrangeTrash}
                alt=""
                className="orange-trash"
                style={trashHovered ? { opacity: 1 } : { opacity: 0 }}
              />
              <h4 className="trash-text">{t("Remove")}</h4>
            </div>
          </button>
        </div>
      </th>
    </tr>
  );
};

export default SingleProduct;
