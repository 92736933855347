import styled from "styled-components";
import { BlueTextBold, FlexColumn, GrayTextMedium, YellowTextBold } from "../../styles/GlobalStyles";


const NewsSection = styled.section`
height: 100%;
margin-bottom: 2rem;
`
const BackgroundImage = styled.img`
    width:100%;
    @media only screen and (max-width: 1024px) {
        height: 300px;
      }
`
const NewsText = styled.div`
    width:1200px;
    margin:auto;
    margin-top:42px;

    @media only screen and (max-width: 1400px) {
        width: 80%;
      }
`
const Date = styled(GrayTextMedium)`
    font-size: 24px;
    line-height: 35px;
    @media only screen and (max-width: 768px) {
        font-size: 18px;
      }
`
const CategoryName = styled(YellowTextBold)`
    font-size: 24px;
    line-height: 35px;
    margin-bottom:14px;
`
const Title = styled(BlueTextBold)`
    font-size: 48px;
    line-height: 50px;
    margin-bottom:80px;

    @media only screen and (max-width: 768px) {
    font-size: 2rem;
    line-height: 50px;
    margin-bottom: 50px;
  }
`
const Paragraph = styled(GrayTextMedium)`
    font-size: 25px;
    line-height: 30px;
    &:last-child {
        margin-bottom:74px;
    }
    @media only screen and (max-width: 768px) {
        font-size: 18px;
      }
`

const NewsColumn = styled(FlexColumn)`
    align-items:start;
    width: 100%;
    
`



export {
    NewsColumn,
    NewsSection,
    BackgroundImage,
    NewsText,
    Date,
    CategoryName,
    Title,
    Paragraph,
}