import React, { useState, useEffect } from "react";
import {
  BlueTextBold,
  ButtonBlue,
  GrayTextMediumBigger,
} from "../../../styles/GlobalStyles";
import {
  BlueCross,
  OutOfStockTick,
  PasswordCross,
  UploadImage,
  WhiteTick,
} from "../../../assets/landingPage/landingPageAssets";
import "./Productstyles.scss";
import { useGlobalContext } from "../../../context/context";
import Select from "react-select";
import axios from "axios";
import FileBase from "react-file-base64";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const EditProduct = ({ openEdit, setOpenEdit }) => {
  const [formText, setFormText] = useState({
    name: "",
    description: "",
    price: "",
    out_of_stock: false,
    category_id: null,
    attribute_ids: [],
    images: [],
  });
  const [nameFocused, setNameFocused] = useState(false);
  const [descriptionFocused, setDescriptionFocused] = useState(false);
  const [priceFocused, setPriceFocused] = useState(false);
  const [outOfStockClicked, setOutOfStockClicked] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState();
  const [selectedCategories, setSelectedCategories] = useState();
  const [stock, setStock] = useState();
  const [imageAll, setImageAll] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [editMessage, setEditMessage] = useState();
  const [content, setContent] = useState();
  const [editProduct, setEditProduct] = useState();
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [image4, setImage4] = useState();
  const [image5, setImage5] = useState();
  const [editImage1, setEditImage1] = useState();
  const [editImage2, setEditImage2] = useState();
  const [editImage3, setEditImage3] = useState();
  const [editImage4, setEditImage4] = useState();
  const [editImage5, setEditImage5] = useState();
  const ref = useRef();
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [priceRequest, setPriceRequest] = useState();
  console.log(priceRequest, formText.price);

  const {
    productId,
    getDemoShopCategories,
    getAllAttributes,
    attribute,
    categories,
    baseUrl,
    config,
    setClosed,
    setProductLoaded,
    productLoaded,
    setRemoveString,
  } = useGlobalContext();

  const formSubmit = (e) => {
    e.preventDefault();
    setClosed(true);
    setClicked(true);

    let editedImages = editProduct?.images?.filter(
      (item) =>
        item !== undefined &&
        item !== null &&
        !item.image_url?.includes("cloudinary")
    );

    const submiter = () => {
      try {
        const response = axios
          .patch(`${baseUrl}/product/${productId}`, editProduct, config)
          .then((response) => {
            if (response.status === 200) {
              setProductLoaded(false);
              setEditMessage("You have successfully edited the product.");
            }
          });
      } catch (error) {
        setEditMessage(error.response.message);
        setProductLoaded(false);
      }
    };
    if (!editProduct) {
      setEditMessage("Please make changes before saving.");
      setTimeout(() => {
        setClicked(false);
      }, 5000);
    } else {
      setProductLoaded(true);
      submiter();
      setTimeout(() => {
        setClicked(false);
      }, 5000);
    }
  };
  const nameCrossHandler = () => {
    setNameFocused(false);
  };
  const DescriptionCrossHandler = () => {
    setDescriptionFocused(false);
  };
  const PriceCrossHandler = () => {
    setPriceFocused(false);
  };
  const cancelHandler = () => {
    setOpenEdit(false);
    setEditProduct({});
  };
  useEffect(() => {
    getDemoShopCategories();
    getAllAttributes();
    const demoShopSingleProduct = () => {
      try {
        const response = axios
          .get(`${baseUrl}/product/${productId}`, config)
          .then((response) => {
            if (response.status === 200) {
              setContent(response.data);
              setStock(response.data.out_of_stock);
              setSelectedCategories([
                {
                  value: response.data.category.id,
                  label: response.data.category.name,
                },
              ]);

              let arr = [];
              response.data.attributes.map((item) =>
                arr.push({ value: item.id, label: item.name })
              );
              setSelectedAttributes(arr);

              console.log(response.data);

              const imagesArr = [];
              response.data.images.map((item) =>
                imagesArr.push({ id: item.id, image_url: item.image_url })
              );
              setFormText({
                name: response.data.name,
                description: response.data.description,
                price: response.data.price,
                out_of_stock: response.data.out_of_stock,
                images: imagesArr,
                attribute_ids: response.data.attributes,
                category_id: response.data.category,
              });

              if (response.data.price == 0.01) {
                setPriceRequest(true);
              } else {
                setPriceRequest(false);
              }

              if (response.data.images[0]) {
                setImage1(response.data.images[0].image_url);
              } else if (response.data.images[1]) {
                setImage2(response.data.images[1].image_url);
              } else if (response.data.images[2]) {
                setImage3(response.data.images[2].image_url);
              } else if (response.data.images[3]) {
                setImage4(response.data.images[3].image_url);
              } else if (response.data.images[4]) {
                setImage5(response.data.images[4].image_url);
              }
            }
          });
      } catch (error) {}
    };
    demoShopSingleProduct();
  }, []);
  useEffect(() => {
    let arr = [editImage1, editImage2, editImage3, editImage4, editImage5];
    const newArr = arr?.filter((item) => {
      if (item !== undefined || item !== null) {
        return item;
      }
    });
    if (editImage1 || editImage2 || editImage3 || editImage4 || editImage5) {
      setEditProduct({ ...editProduct, images: newArr });
    }
  }, [formText, image1, image2, image3, image4, image5]);
  //   categories dropdown data
  let categoriesArr = [];
  categories?.data.forEach((item) => {
    let itemobj = { value: item.id, label: item.name };
    categoriesArr.push(itemobj);
  });
  //   attributes dropdown data
  let attributesArr = [];
  attribute?.data.forEach((item) => {
    let itemobj = { value: item.id, label: item.name };
    attributesArr.push(itemobj);
  });

  //   select component functionality
  const handleAttributesChange = (selectedAttributes) => {
    setSelectedAttributes(selectedAttributes);
    let arr = [];
    selectedAttributes.forEach((item) => arr.push(parseInt(item.value)));
    setEditProduct({ ...editProduct, attribute_ids: arr });
    setFormText({ ...formText, attribute_ids: arr });
    console.log(arr, editProduct);
  };
  const handleCategoriesChange = (selectedCategories) => {
    setSelectedCategories(selectedCategories);
    setEditProduct({ ...editProduct, category_id: selectedCategories.value });
    setFormText({ ...formText, category_id: selectedCategories.value });
  };

  const selectStylesCategory = {
    control: (provided, state) => ({
      ...provided,
      height: "54px",
      width: "100%",
      // border: '2px solid rgba(114, 139, 166, 0.5)',
      borderRadius: "20px",
      border: state.isSelected
        ? "2px solid rgba(114, 139, 166, 0.5)"
        : "2px solid transparent",
      fontFamily: "Jost",
      fontWeight: "400",
      color: "#0e1425",
      background: state.isFocused
        ? "rgba(114, 139, 166, 0.4)"
        : "rgba(114, 139, 166, 0.2)",
      padding: "0 0 0 10px",
      "&:hover": {
        borderColor: "rgba(114, 139, 166, 0.5)",
      },
    }),
    placeholder: (provider) => ({
      ...provider,
      color: "#3853a3",
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "5px",
      background: "#fff",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#0e1425",
    }),
  };

  const selectStyles = {
    control: (provided, state) => {
      const height = selectedAttributes?.length > 2 ? "100px" : "54px";
      return {
        ...provided,
        height: height,
        width: "100%",
        // border: '2px solid rgba(114, 139, 166, 0.5)',
        borderRadius: "20px",
        border: state.isSelected
          ? "2px solid rgba(114, 139, 166, 0.5)"
          : "2px solid transparent",
        fontFamily: "Jost",
        fontWeight: "400",
        color: "#0e1425",
        background: state.isFocused
          ? "rgba(114, 139, 166, 0.4)"
          : "rgba(114, 139, 166, 0.2)",
        padding: "0 0 0 10px",
        "&:hover": {
          borderColor: "rgba(114, 139, 166, 0.5)",
        },
      };
    },
    placeholder: (provider) => ({
      ...provider,
      color: "#3853a3",
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "5px",
      background: "#fff",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#0e1425",
    }),
  };
  const deleteImage1 = () => {
    setImage1(null);
    if (
      formText.images[0]?.image_url?.includes("cloudinary") ||
      editImage1?.id ||
      editImage1 === undefined ||
      editImage1 === null
    ) {
      const newImage = {
        id: formText?.images[0]?.id,
        image_url: "",
      };
      setEditImage1(newImage);
    } else if (!editImage1.id) {
      setEditImage1(null);
    }
  };
  const deleteImage2 = () => {
    setImage2(null);
    if (
      formText.images[1]?.image_url?.includes("cloudinary") ||
      editImage2?.id ||
      editImage2 === undefined ||
      editImage2 === null
    ) {
      const newImage = {
        id: formText?.images[1]?.id,
        image_url: "",
      };
      setEditImage2(newImage);
    } else if (!editImage2.id) {
      setEditImage2(null);
    }
  };
  const deleteImage3 = () => {
    setImage3(null);
    if (
      formText.images[2]?.image_url?.includes("cloudinary") ||
      editImage3?.id ||
      editImage3 === undefined ||
      editImage3 === null
    ) {
      const newImage = {
        id: formText?.images[2]?.id,
        image_url: "",
      };
      setEditImage3(newImage);
    } else if (!editImage3.id) {
      setEditImage3(null);
    }
  };
  const deleteImage4 = () => {
    setImage4(null);
    if (
      formText.images[3]?.image_url?.includes("cloudinary") ||
      editImage4?.id ||
      editImage4 === undefined ||
      editImage4 === null
    ) {
      const newImage = {
        id: formText?.images[3]?.id,
        image_url: "",
      };
      setEditImage4(newImage);
    } else if (!editImage4.id) {
      setEditImage4(null);
    }
  };
  const deleteImage5 = () => {
    setImage5(null);
    if (
      formText.images[4]?.image_url?.includes("cloudinary") ||
      editImage5?.id ||
      editImage5 === undefined ||
      editImage5 === null
    ) {
      const newImage = {
        id: formText?.images[4]?.id,
        image_url: "",
      };
      setEditImage5(newImage);
    } else if (!editImage5.id) {
      setEditImage5(null);
    }
  };

  const stockHandler = (e) => {
    let out = formText.out_of_stock;
    setFormText({ ...formText, out_of_stock: !out });
    setEditProduct({ ...editProduct, out_of_stock: !out });
  };

  const changeHandler = (e, string) => {
    if (string === "name") {
      setFormText({ ...formText, name: e.target.value });
      setEditProduct({ ...editProduct, name: e.target.value });
    } else if (string === "description") {
      setFormText({ ...formText, description: e.target.value });
      setEditProduct({ ...editProduct, description: e.target.value });
    } else if (string === "price") {
      setFormText({ ...formText, price: parseFloat(e.target.value) });
      setEditProduct({ ...editProduct, price: parseFloat(e.target.value) });
    }
  };

  const imageHandler = (base64, string) => {
    // one
    if (string === "one") {
      setImage1(base64);
      if (
        formText.images[0]?.image_url?.includes("cloudinary") ||
        editImage1?.id
      ) {
        const newImage = {
          id: formText?.images[0]?.id,
          image_url: base64.split("base64,")[1],
        };
        setEditImage1(newImage);
      } else if (
        !editImage1?.id ||
        editImage1 === undefined ||
        editImage1 === null
      ) {
        setEditImage1({ image_url: base64.split("base64,")[1] });
      }

      // two
    } else if (string === "two") {
      setImage2(base64);
      if (
        formText.images[1]?.image_url?.includes("cloudinary") ||
        editImage2?.id
      ) {
        const newImage = {
          id: formText?.images[1]?.id,
          image_url: base64.split("base64,")[1],
        };
        setEditImage2(newImage);
      } else if (
        !editImage2?.id ||
        editImage1 === undefined ||
        editImage1 === null
      ) {
        setEditImage2({ image_url: base64.split("base64,")[1] });
      }

      // three
    } else if (string === "three") {
      setImage3(base64);
      if (
        formText.images[2]?.image_url?.includes("cloudinary") ||
        editImage3?.id
      ) {
        const newImage = {
          id: formText?.images[2]?.id,
          image_url: base64.split("base64,")[1],
        };
        setEditImage3(newImage);
      } else if (
        !editImage3?.id ||
        editImage1 === undefined ||
        editImage1 === null
      ) {
        setEditImage3({ image_url: base64.split("base64,")[1] });
      }

      // four
    } else if (string === "four") {
      setImage4(base64);
      if (
        formText.images[3]?.image_url?.includes("cloudinary") ||
        editImage4?.id
      ) {
        const newImage = {
          id: formText?.images[3]?.id,
          image_url: base64.split("base64,")[1],
        };
        setEditImage4(newImage);
      } else if (
        !editImage4?.id ||
        editImage1 === undefined ||
        editImage1 === null
      ) {
        setEditImage4({ image_url: base64.split("base64,")[1] });
      }

      // five
    } else if (string === "five") {
      setImage5(base64);
      if (
        formText.images[4]?.image_url?.includes("cloudinary") ||
        editImage5?.id
      ) {
        const newImage = {
          id: formText?.images[4]?.id,
          image_url: base64.split("base64,")[1],
        };
        setEditImage5(newImage);
      } else if (
        !editImage5?.id ||
        editImage1 === undefined ||
        editImage1 === null
      ) {
        setEditImage5({ image_url: base64.split("base64,")[1] });
      }
    }
  };

  const stockHandlerReq = (e) => {
    if (priceRequest === true) {
    setFormText({ ...formText, price: parseFloat(0.00) });
      setEditProduct({ ...editProduct, price: parseFloat(0.00) });
      setPriceRequest(false);
    } else {
      setFormText({ ...formText, price: parseFloat(0.01) });
      setEditProduct({ ...editProduct, price: parseFloat(0.01) });
      setPriceRequest(true);
    }
    console.log(priceRequest)

  };

  const { t } = useTranslation();
  return (
    <>
      <div className="create-new-product">
        <form>
          <div className="input-box">
            <div className="left-side">
              <div className="input-wrap">
                <input
                  style={
                    nameFocused
                      ? { background: "rgba(114, 139, 166, .4)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onClick={() => setNameFocused(true)}
                  onChange={(e) => changeHandler(e, "name")}
                  value={formText?.name}
                />
                <img
                  style={nameFocused ? { opacity: "1" } : { opacity: "0" }}
                  src={BlueCross}
                  onClick={nameCrossHandler}
                  alt=""
                />
                <div style={{ top: "-33px" }} className="placeholder-box">
                  <div className="placeholder" style={{ fontSize: "11px" }}>
                    {t("ProductName")}
                  </div>
                </div>
              </div>
              {/* description */}
              <div className="input-wrap" style={{ height: "130px" }}>
                <textarea
                  className="create-shop-textarea"
                  style={
                    descriptionFocused
                      ? { background: "rgba(114, 139, 166, .4)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onClick={() => setDescriptionFocused(true)}
                  onChange={(e) => {
                    changeHandler(e, "description");
                    setDescriptionCount(e.target.value.length);
                  }}
                  value={formText?.description}
                />
                <div
                  style={{
                    top: "30%",
                    left: "-8%",
                    position: "relative",
                    zIndex: "1",
                  }}
                  className="placeholder-box"
                >
                  <p>{descriptionCount}</p>
                </div>
                <img
                  style={
                    descriptionFocused ? { opacity: "1" } : { opacity: "0" }
                  }
                  src={BlueCross}
                  onClick={DescriptionCrossHandler}
                  alt=""
                />
                <div style={{ top: "-70px" }} className="placeholder-box">
                  <div className="placeholder" style={{ fontSize: "11px" }}>
                    {t("Description")}
                  </div>
                </div>
              </div>
              {/* price */}
              <div className="input-wrap">
                <input
                  style={
                    priceFocused
                      ? { background: "rgba(114, 139, 166, .4)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  type="number"
                  onClick={() => setPriceFocused(true)}
                  onChange={(e) => changeHandler(e, "price")}
                  value={formText?.price}
                />
                <img
                  style={priceFocused ? { opacity: "1" } : { opacity: "0" }}
                  src={BlueCross}
                  onClick={PriceCrossHandler}
                  alt=""
                />
                <div style={{ top: "-33px" }} className="placeholder-box">
                  <div className="placeholder" style={{ fontSize: "11px" }}>
                    {t("Price")}
                  </div>
                </div>
              </div>
            </div>
            {/* category */}
            <div className="right-side">
              <div className="select-box">
                <Select
                  styles={selectStylesCategory}
                  name="colors"
                  options={categoriesArr}
                  className="basic-single react-select-styling"
                  classNamePrefix="select"
                  onChange={handleCategoriesChange}
                  isClearable
                  value={selectedCategories}
                  placeholder={t("SelectCategories")}
                />
                <div style={{ top: "-33px" }} className="placeholder-box">
                  <div className="placeholder" style={{ fontSize: "11px" }}>
                    {t("Category")}
                  </div>
                </div>
              </div>
              <div className="select-box">
                <Select
                  styles={selectStyles}
                  isMulti
                  name="colors"
                  options={attributesArr}
                  className="basic-multi-select react-select-styling"
                  placeholder={t("SelectAttributes")}
                  onChange={handleAttributesChange}
                  value={selectedAttributes}
                />
                <div style={{ top: "-33px" }} className="placeholder-box">
                  <div className="placeholder" style={{ fontSize: "11px" }}>
                    {t("Attributes")}
                  </div>
                </div>
              </div>
              <div
                className="out-of-stock"
                style={
                  selectedAttributes?.length > 2 ? { paddingTop: "50px" } : {}
                }
              >
                <h3 className="out-of-stock-header">{t("OutOfStock")}:</h3>
                {/* <input
                  type="checkbox"
                  name="stock"
                  id="stock"
                  onClick={stockHandler}
                /> */}
                <div
                  className="stock-box"
                  style={formText.out_of_stock ? { background: "#249924" } : {}}
                  onClick={stockHandler}
                >
                  <img className="stock-tick" src={OutOfStockTick} alt="" />
                </div>
              </div>

              <div className="out-of-stock">
                <h3 className="out-of-stock-header">{t("PriceRequest")}:</h3>
                {/* <input
                  type="checkbox"
                  name="stock"
                  id="stock"
                  onClick={stockHandler}
                /> */}
                <div
                  className="stock-box"
                  style={priceRequest ? { background: "#249924" } : {}}
                  onClick={stockHandlerReq}
                >
                  <img className="stock-tick" src={OutOfStockTick} alt="" />
                </div>
              </div>

              <BlueTextBold className="upload-header">
                {t("UploadImagesOfYourProduct(max.5)")}
              </BlueTextBold>
              <div className="create-new-product-upload-image">
                <div className="create-new-product-image-box">
                  <img
                    src={
                      formText?.images[0]?.image_url &&
                      !image1 &&
                      image1 !== null
                        ? formText?.images[0]?.image_url
                        : image1
                        ? image1
                        : UploadImage
                    }
                    alt=""
                  />
                  {formText?.images[0]?.image_url !== undefined ? (
                    <>
                      {image1 !== null ? (
                        <img
                          className="upload-cross"
                          src={PasswordCross}
                          alt=""
                          onClick={deleteImage1}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : formText?.images[0]?.image_url === undefined ? (
                    <>
                      {image1 ? (
                        <img
                          className="upload-cross"
                          src={PasswordCross}
                          alt=""
                          onClick={deleteImage1}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="text">
                    <label className="custom-file-upload">
                      <FileBase
                        type="file"
                        onDone={({ base64 }) => imageHandler(base64, "one")}
                      />
                      {t("UploadImage")}
                    </label>
                  </div>
                </div>
                <div className="create-new-product-image-box">
                  <img
                    src={
                      formText?.images[1]?.image_url &&
                      !image2 &&
                      image2 !== null
                        ? formText?.images[1]?.image_url
                        : image2
                        ? image2
                        : UploadImage
                    }
                    alt=""
                  />
                  {formText?.images[1]?.image_url !== undefined ? (
                    <>
                      {image2 !== null ? (
                        <img
                          className="upload-cross"
                          src={PasswordCross}
                          alt=""
                          onClick={deleteImage2}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : formText?.images[1]?.image_url === undefined ? (
                    <>
                      {image2 ? (
                        <img
                          className="upload-cross"
                          src={PasswordCross}
                          alt=""
                          onClick={deleteImage2}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="text">
                    <label className="custom-file-upload">
                      <FileBase
                        type="file"
                        onDone={({ base64 }) => imageHandler(base64, "two")}
                      />
                      {t("UploadImage")}
                    </label>
                  </div>
                </div>
                <div className="create-new-product-image-box">
                  <img
                    src={
                      formText?.images[2]?.image_url &&
                      !image3 &&
                      image3 !== null
                        ? formText?.images[2]?.image_url
                        : image3
                        ? image3
                        : UploadImage
                    }
                    alt=""
                  />
                  {formText?.images[2]?.image_url !== undefined ? (
                    <>
                      {image3 !== null ? (
                        <img
                          className="upload-cross"
                          src={PasswordCross}
                          alt=""
                          onClick={deleteImage3}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : formText?.images[2]?.image_url === undefined ? (
                    <>
                      {image3 ? (
                        <img
                          className="upload-cross"
                          src={PasswordCross}
                          alt=""
                          onClick={deleteImage3}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="text">
                    <label className="custom-file-upload">
                      <FileBase
                        type="file"
                        onDone={({ base64 }) => imageHandler(base64, "three")}
                      />
                      {t("UploadImage")}
                    </label>
                  </div>
                </div>
                <div className="create-new-product-image-box">
                  <img
                    src={
                      formText?.images[3]?.image_url &&
                      !image4 &&
                      image4 !== null
                        ? formText?.images[3]?.image_url
                        : image4
                        ? image4
                        : UploadImage
                    }
                    alt=""
                  />
                  {formText?.images[3]?.image_url !== undefined ? (
                    <>
                      {image4 !== null ? (
                        <img
                          className="upload-cross"
                          src={PasswordCross}
                          alt=""
                          onClick={deleteImage4}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : formText?.images[3]?.image_url === undefined ? (
                    <>
                      {image4 ? (
                        <img
                          className="upload-cross"
                          src={PasswordCross}
                          alt=""
                          onClick={deleteImage4}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="text">
                    <label className="custom-file-upload">
                      <FileBase
                        type="file"
                        onDone={({ base64 }) => imageHandler(base64, "four")}
                      />
                      {t("UploadImage")}
                    </label>
                  </div>
                </div>
                <div className="create-new-product-image-box">
                  <img
                    src={
                      formText?.images[4]?.image_url &&
                      !image5 &&
                      image5 !== null
                        ? formText?.images[4]?.image_url
                        : image5
                        ? image5
                        : UploadImage
                    }
                    alt=""
                  />
                  {formText?.images[4]?.image_url !== undefined ? (
                    <>
                      {image5 !== null ? (
                        <img
                          className="upload-cross"
                          src={PasswordCross}
                          alt=""
                          onClick={deleteImage5}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : formText?.images[4]?.image_url === undefined ? (
                    <>
                      {image5 ? (
                        <img
                          className="upload-cross"
                          src={PasswordCross}
                          alt=""
                          onClick={deleteImage5}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="text">
                    <label className="custom-file-upload">
                      <FileBase
                        type="file"
                        onDone={({ base64 }) => imageHandler(base64, "five")}
                      />
                      {t("UploadImage")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="created-product" style={{ height: "10px" }}>
            {clicked && (
              <>
                {productLoaded ? (
                  <>
                    <div className="loading-spinner-gray"></div>
                  </>
                ) : (
                  <>
                    <GrayTextMediumBigger>{editMessage}</GrayTextMediumBigger>
                  </>
                )}
              </>
            )}
          </div>
          <div className="button-box-edit-product">
            <ButtonBlue type="submit" onClick={formSubmit}>
              {t("Save")}
            </ButtonBlue>
            <ButtonBlue onClick={cancelHandler}>{t("Close")}</ButtonBlue>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditProduct;
