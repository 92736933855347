import styled from "styled-components";
import Pattern2 from "../../../assets/landingPage/lightblueWeb_Business1.png";
import { ButtonBlue } from "../../../styles/GlobalStyles";
import { SearchBar, SearchInput } from "../Home/styles";

const NewsLetterContainer = styled.div`
  width: 100%;
  height: 231px;
  // background-image: url(${Pattern2});
  background-color: #ffa800;
  background-size: 40%;
`;

const NewsLetterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const NewsLetterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
  }
`

const Subscribe = styled.h3`
  margin-right: 112px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  color: #fff;
  @media only screen and (max-width: 1024px) {
    margin-right: 0;
    font-size: 25px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1250px) {
    font-size: 32px;
    margin-right: 80px;
  }
`;
const SubcribeButton = styled(ButtonBlue)`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding: 11px 40px;
  transition: 0.5s all ease;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid #fff;
    color: #fff;
    transition: 0.5s all ease;
    background: transparent;
  }
  @media only screen and (max-width: 1024px) {
    padding: 6px 20px;
    font-size: 16px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1250px) {
    padding: 9px 30px;
    font-size: 16px;
  }
`;

const Button = styled.button`
  background: #3853a3;
  border-radius: 100px;
  width: 156px;
  height: 48px;
  color: #fff;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  border: none;
  cursor: pointer;
`;

const NewsletterSearch = styled(SearchBar)`
  border: ${(props) =>
    props.focused ? "2px solid transparent" : "2px solid #fff"};
  display: flex;
  width: 456px;
  margin: 0 19px 0 0;
  background: ${(props) => (props.focused ? "#fff" : "transparent")};
  position: relative;
  filter: ${(props) =>
    props.focused ? "drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.25))" : "none"};
    @media only screen and (max-width: 1024px) {
      margin-right: 0;
      margin-block: 20px;
      width: 300px;
      height: 45px;
    }
    @media only screen and (min-width: 1025px) and (max-width: 1250px) {
      margin-block: 20px;
      width: 300px;
      height: 45px;
    }
    
`;
const NewsletterInput = styled(SearchInput)`
  background: transparent;
  margin-left: 22px;
  color: ${(props) => (props.focused ? "#3853a3" : "#fff")};
  width: 380px;
  z-index: 5;
`;

const NewsLetterPlaceholderBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 22px;
  transition: 0.5s all ease;
  z-index: 1;
  top: ${(props) => (props.focused ? "-33px" : "2px")};
`;

const NewsLetterPlaceholder = styled.div`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: #3853a3;
  transition: 0.5s all ease;
  height: 25px;
  font-size: ${(props) => (props.focused ? "12px" : "15px")};
  line-height: ${(props) => (props.focused ? "16px" : "22px")};
  color: #fff;
`;

export {
  NewsLetterPlaceholderBox,
  NewsLetterPlaceholder,
  NewsletterSearch,
  NewsletterInput,
  SubcribeButton,
  NewsLetterContent,
  Subscribe,
  Button,
  NewsLetterContainer,
  NewsLetterWrapper
};
