import React, { useState, useEffect } from "react";
import { BlueTextBold, ButtonBlue, Cross } from "../../../styles/GlobalStyles";
import {
  BlueCross,
  UploadImage,
  DropdownArrow,
} from "../../../assets/landingPage/landingPageAssets";
import { CustomerBoxHeader } from "../styles";
import { useGlobalContext } from "../../../context/context";
import Select from "react-select";
import "../../DemoShop copy/ProductPage/Productstyles.scss";
import "../../DemoShop copy/stylesAllPages.scss";
import { useTranslation } from "react-i18next";

const CreateNewProduct = ({ createNew, setCreateNew }) => {
  const [formText, setFormText] = useState({
    name: "",
    description: "",
    price: "",
    out_of_stock: false,
    category_id: null,
    attribute_ids: [],
    images: [],
  });
  const [nameFocused, setNameFocused] = useState(false);
  const [descriptionFocused, setDescriptionFocused] = useState(false);
  const [priceFocused, setPriceFocused] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [image, setImage] = useState();

  const formSubmit = (e) => {
    e.preventDefault();
  };
  const nameCrossHandler = () => {
    setNameFocused(false);
    setFormText({ ...formText, name: "" });
  };
  const DescriptionCrossHandler = () => {
    setDescriptionFocused(false);
    setFormText({ ...formText, description: "" });
  };
  const PriceCrossHandler = () => {
    setPriceFocused(false);
    setFormText({ ...formText, price: "" });
  };
  const cancelHandler = () => {
    setCreateNew(false);
  };

  let categoriesArr = [
    { value: "Lure Kit", label: "Lure Kit" },
    { value: "Fishing Rod", label: "Fishing Rod" },
    { value: "Fishing Reel", label: "Fishing Reel" },
    { value: "Rod", label: "Rod" },
    { value: "Spinning Reel", label: "Spinning Reel" },
  ];
  let attributesArr = [
    { value: "blue", label: "Blue" },
    { value: "green", label: "Green" },
    { value: "strong", label: "strong" },
    { value: "smooth", label: "smooth" },
    { value: "sharp", label: "sharp" },
    { value: "yellow", label: "yellow" },
    { value: "big", label: "big" },
    { value: "premium", label: "premium" },
  ];

  const handleAttributesChange = (selectedAttributes) => {
    setSelectedAttributes(selectedAttributes);
  };
  const handleCategoriesChange = (selectedCategories) => {
    setSelectedCategories(selectedCategories);
  };

  const selectStyles = {
    control: (provided, state) => {
      const height = selectedAttributes.length > 2 ? "100px" : "54px";
      return {
        ...provided,
        height: height,
        whiteSpace: "nowrap",
        overflow: "auto",
        borderRadius: "20px",
        border: state.isSelected
          ? "2px solid rgba(114, 139, 166, 0.5)"
          : "2px solid transparent",
        fontFamily: "Jost",
        fontWeight: "400",
        color: "#3853a3",
        background: "rgba(114, 139, 166, 0.2)",
        padding: "0 0 0 10px",
        "&:hover": {
          borderColor: "rgba(114, 139, 166, 0.5)",
        },
      };
    },
    placeholder: (provider) => ({
      ...provider,
      color: "#3853a3",
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "5px",
      background: "#fff",
    }),
  };
  const fileHandler = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="create-new-product">
        <form>
          <div className="input-box">
            <div className="left-side">
              <div className="product-input-wrap">
                <input
                  style={
                    nameFocused
                      ? { background: "rgba(114, 139, 166, .6)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onClick={() => setNameFocused(true)}
                  onChange={(e) =>
                    setFormText({ ...formText, name: e.target.value })
                  }
                  value={formText.name}
                />
                <img
                  style={nameFocused ? { opacity: "1" } : { opacity: "0" }}
                  src={BlueCross}
                  onClick={nameCrossHandler}
                  alt=""
                />
                <div
                  style={nameFocused ? { top: "-33px" } : { top: "3px" }}
                  className="placeholder-box"
                >
                  <div
                    className="placeholder"
                    style={
                      nameFocused ? { fontSize: "11px" } : { fontSize: "16px" }
                    }
                  >
                    {t("ProductName")}
                  </div>
                </div>
              </div>
              {/* description */}
              <div className="input-wrap" style={{ height: "130px" }}>
                <textarea
                  className="create-shop-textarea"
                  style={
                    descriptionFocused
                      ? { background: "rgba(114, 139, 166, .6)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onClick={() => setDescriptionFocused(true)}
                  onChange={(e) =>
                    setFormText({ ...formText, description: e.target.value })
                  }
                  value={formText.description}
                />
                <img
                  style={
                    descriptionFocused ? { opacity: "1" } : { opacity: "0" }
                  }
                  src={BlueCross}
                  onClick={DescriptionCrossHandler}
                  alt=""
                />
                <div
                  style={
                    descriptionFocused ? { top: "-70px" } : { top: "-35px" }
                  }
                  className="placeholder-box"
                >
                  <div
                    className="placeholder"
                    style={
                      descriptionFocused
                        ? { fontSize: "11px" }
                        : { fontSize: "16px" }
                    }
                  >
                    {t("Description")}
                  </div>
                </div>
              </div>
              {/* price */}
              <div className="product-input-wrap">
                <input
                  style={
                    priceFocused
                      ? { background: "rgba(114, 139, 166, .6)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onClick={() => setPriceFocused(true)}
                  onChange={(e) =>
                    setFormText({ ...formText, price: e.target.value })
                  }
                  value={formText.price}
                />
                <img
                  style={priceFocused ? { opacity: "1" } : { opacity: "0" }}
                  src={BlueCross}
                  onClick={PriceCrossHandler}
                  alt=""
                />
                <div
                  style={priceFocused ? { top: "-33px" } : { top: "3px" }}
                  className="placeholder-box"
                >
                  <div
                    className="placeholder"
                    style={
                      priceFocused ? { fontSize: "11px" } : { fontSize: "16px" }
                    }
                  >
                    Price (€)
                  </div>
                </div>
              </div>
            </div>
            {/* category */}
            <div className="right-side">
              <Select
                styles={selectStyles}
                defaultValue={"Rose"}
                name="colors"
                options={categoriesArr}
                className="basic-single react-select-styling"
                classNamePrefix="select"
                placeholder={t("SelectCategories")}
                onChange={handleCategoriesChange}
                isClearable
              />
              <Select
                styles={selectStyles}
                isMulti
                name="colors"
                options={attributesArr}
                className="basic-multi-select react-select-styling"
                placeholder={t("SelectAttributes")}
                onChange={handleAttributesChange}
              />
              <BlueTextBold className="upload-header">
                {t("UploadImagesOfYourProduct(max.5)")}
              </BlueTextBold>
              <div className="create-new-product-upload-image">
                <div className="create-new-product-image-box">
                  <img src={UploadImage} alt="" />
                  <div className="text">
                    <label className="custom-file-upload">
                      {t("UploadImage")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button-box">
            <ButtonBlue type="submit" onClick={formSubmit}>
              {t("Save")}
            </ButtonBlue>
            <ButtonBlue onClick={cancelHandler}>{t("Close")}</ButtonBlue>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateNewProduct;
