import React, { useState, useRef } from "react";
import { CrossBtn } from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import { AlignItems, FlexCenter } from "../../../styles/GlobalStyles";
import { Cross } from "../Home/styles";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  NewsLetterContainer,
  Subscribe,
  NewsLetterContent,
  NewsletterSearch,
  NewsletterInput,
  SubcribeButton,
  NewsLetterPlaceholder,
  NewsLetterPlaceholderBox,
  NewsLetterWrapper,
} from "./style";
import "./styles.scss";
import { useEffect } from "react";

const NewsLetter = () => {
  const [focused, setFocused] = useState(false);
  const [formText, setFormText] = useState("");
  const location = useLocation();
  const {
    newsLetter,
    setNewsLetterEmail,
    newsLetterEmail,
    newsLetterResponse,
    setNewsLetterResponse,
  } = useGlobalContext();
  const [created, setCreated] = useState(false);
  const { t } = useTranslation();

  const crossHandler = () => {
    setFocused(false);
    setNewsLetterEmail("");
  };

  useEffect(() => {
    crossHandler();
  }, [location.pathname]);

  const handleSubscribe = () => {
    setCreated(true);
    newsLetter();
    setTimeout(() => {
      setNewsLetterResponse(false);
      setCreated(false);
    }, 5000);
  };
  console.log(newsLetterResponse);

  return (
    <>
      <NewsLetterContainer>
        <NewsLetterContent id="asfadsfadsf">
          <NewsLetterWrapper>
            <Subscribe>{t("SubscribeToOurNewsLetter")}</Subscribe>
            <NewsletterSearch focused={focused} style={{ boxShadow: "none" }}>
              <NewsletterInput
                focused={focused}
                onChange={(e) => setNewsLetterEmail(e.target.value)}
                value={newsLetterEmail}
                onClick={() => setFocused(true)}
              />
              <Cross
                focused={focused}
                src={CrossBtn}
                alt=""
                onClick={crossHandler}
              />
              <NewsLetterPlaceholderBox focused={focused}>
                <NewsLetterPlaceholder focused={focused}>
                  {t("EmailAddress")}
                </NewsLetterPlaceholder>
              </NewsLetterPlaceholderBox>
              <div className={created ? "subscribed" : "appear"}>
                {newsLetterResponse}
              </div>
            </NewsletterSearch>
            <SubcribeButton onClick={handleSubscribe}>
              {t("Subscribe")}
            </SubcribeButton>
          </NewsLetterWrapper>
        </NewsLetterContent>
      </NewsLetterContainer>
    </>
  );
};

export default NewsLetter;
