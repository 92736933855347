import styled from "styled-components";
import { Cross } from "../../../styles/GlobalStyles";

const InputWrap = styled.div`
  width: 600px;
  position: relative;
  display: flex;
  margin-bottom: 24px;
  height: 54px;
  outline: none;
  border: none;
  @media only screen and (max-width: 1200px) {
    width: 80%;
  }
`;

const Input = styled.input`
  transition: 0.5s all ease;
  width: 100%;
  border-radius: 50px;
  height: 55px;
  border: none;
  outline: none;
  padding: 0 0 0 20px;
  margin-bottom: 27px;
  color: #374366;
  position: relative;
`;
const PlaceHolderBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 22px;
  transition: 0.5s all ease;
  z-index: 1;
`;
const Placeholder = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: #3853a3;
  transition: 0.5s all ease;
  height: 30px;
`;

const NameInput = styled(Input)`
  background: ${(props) =>
    props.categoryFocused
      ? "rgba(114, 139, 166, .6)"
      : "rgba(114, 139, 166, 0.2)"};
  z-index: 3;
`;

const NameCross = styled(Cross)`
  opacity: ${(props) => (props.categoryFocused ? "1" : "0")};
  z-index: 6;
`;
const NamePlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.categoryFocused ? "-33px" : "2px")};
  z-index: 1;
`;
const NamePlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.categoryFocused ? "11px" : "16px")};
`;

const Textarea = styled.textarea`
  background: ${(props) =>
    props.descriptionFocused
      ? "rgba(114, 139, 166, .6)"
      : "rgba(114, 139, 166, 0.2)"};
  z-index: 3;
  font-family: "Jost";
  font-style: normal;
`;
const TextareaCross = styled(Cross)`
  opacity: ${(props) => (props.descriptionFocused ? "1" : "0")};
  z-index: 6;
`;
const TextareaPlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.descriptionFocused ? "-120px" : "-90px")};
  z-index: 1;
`;
const TextareaPlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.descriptionFocused ? "11px" : "16px")};
`;

export {
  Textarea,
  TextareaCross,
  TextareaPlaceholderBox,
  TextareaPlaceholder,
  InputWrap,
  NameInput,
  NameCross,
  NamePlaceholderBox,
  NamePlaceholder,
};
