import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CrossBtn } from "../../../assets/landingPage/landingPageAssets";
import { FlexCenter, FlexColumn, Cross } from "../../../styles/GlobalStyles";
import {
  ContactButton,
  ContactContainer,
  ContactHeader,
  ContactTextArea,
  Input,
  InputWrap,
  NamePlaceholderBox,
  Placeholder,
  EmailPlaceholderBox,
  NamePlaceholder,
  EmailPlaceholder,
  MessageCross,
  NameCross,
  EmailCross,
  NameInput,
  EmailInput,
  MessageBox,
  MessagePlaceholder,
  MessagePlaceholderBox,
} from "./styles";

const Contact = () => {
  const [formInfo, setFormInfo] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [yourEmailFocused, setYourEmailFocused] = useState(false);
  const [nameFocused, setNameFocused] = useState(false);
  const [textFocused, setTextFocused] = useState(false);
  // refs
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const textRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formInfo);
  };

  const nameCrossHandler = () => {
    setNameFocused(false);
    nameRef.current.value = "";
  };

  const emailCrossHandler = () => {
    setYourEmailFocused(false);
    emailRef.current.value = "";
  };

  const textCrossHandler = () => {
    setTextFocused(false);
    textRef.current.value = "";
  };
  const { t } = useTranslation();
  return (
    <ContactContainer id="contact">
      <ContactHeader>{t("Contact")}</ContactHeader>
      <FlexCenter>
        <form onSubmit={handleSubmit}>
          <FlexColumn>
            <InputWrap nameFocused={nameFocused}>
              <NameCross
                nameFocused={nameFocused}
                src={CrossBtn}
                alt=""
                onClick={nameCrossHandler}
              />
              <NameInput
                ref={nameRef}
                nameFocused={nameFocused}
                type="name"
                name="name"
                onClick={() => setNameFocused(true)}
                onChange={(e) =>
                  setFormInfo({ ...formInfo, name: e.target.value })
                }
                placeholder={t("Name")}
              />
              {/* <NamePlaceholderBox
                onClick={() => setNameFocused(true)}
                nameFocused={nameFocused}
              >
                <NamePlaceholder nameFocused={nameFocused}>
                  Your Name
                </NamePlaceholder>
              </NamePlaceholderBox> */}
            </InputWrap>
            <InputWrap yourEmailFocused={yourEmailFocused}>
              <EmailCross
                yourEmailFocused={yourEmailFocused}
                src={CrossBtn}
                alt=""
                onClick={emailCrossHandler}
              />
              <EmailInput
                ref={emailRef}
                yourEmailFocused={yourEmailFocused}
                type="email"
                name="email"
                onClick={() => setYourEmailFocused(true)}
                placeholder={t("LastName")}
                onChange={(e) =>
                  setFormInfo({ ...formInfo, name: e.target.value })
                }
              />
              {/* <EmailPlaceholderBox
                onClick={() => setYourEmailFocused(true)}
                yourEmailFocused={yourEmailFocused}
              >
                <EmailPlaceholder yourEmailFocused={yourEmailFocused}>
                  Your Name
                </EmailPlaceholder>
              </EmailPlaceholderBox> */}
            </InputWrap>
            <MessageBox>
              <MessageCross
                textFocused={textFocused}
                src={CrossBtn}
                alt=""
                onClick={textCrossHandler}
              />
              <ContactTextArea
                ref={textRef}
                name="message"
                cols="30"
                rows="10"
                placeholder={t("Message")}
                textFocused={textFocused}
                onClick={() => setTextFocused(true)}
                onChange={(e) =>
                  setFormInfo({ ...formInfo, message: e.target.value })
                }
              ></ContactTextArea>
              {/* <MessagePlaceholderBox
                onClick={() => setTextFocused(true)}
                textFocused={textFocused}
              >
                <MessagePlaceholder textFocused={textFocused}>
                  Message
                </MessagePlaceholder>
              </MessagePlaceholderBox> */}
            </MessageBox>
            <ContactButton>{t("Message")}</ContactButton>
          </FlexColumn>
        </form>
      </FlexCenter>
    </ContactContainer>
  );
};

export default Contact;
