import React, { useState, useMemo, useRef } from "react";
import {
  BlueTextBold,
  ButtonBlue,
  GrayTextMedium,
  WhiteTextBold,
  YellowTextBold,
} from "../../styles/GlobalStyles";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import Footer from "../../components/LandingPage/Footer/Footer";
import {
  NamePlaceholderBox,
  LastNamePlaceholderBox,
  EmailPlaceholderBox,
  PhoneNumberPlaceholderBOx,
  NamePlaceholder,
  LastNamePlaceholder,
  EmailPlaceholder,
  PhoneNumberPlaceholder,
  NameCross,
  LastNameCross,
  EmailCross,
  PhoneNumberCross,
  InputWrap,
  NameInput,
  LastNameInput,
  EmailInput,
  AddressInput,
  CityInput,
  CountryInput,
  PostalInput,
  AddressCross,
  CityCross,
  CountryCross,
  PostalCross,
  AddressPlaceholderBox,
  CityPlaceholderBox,
  CountryPlaceholderBox,
  PostalPlaceholderBox,
  AddressPlaceholder,
  CityPlaceholder,
  CountryPlaceholder,
  PostalPlaceholder,
} from "./CheckoutInputs";
import { CrossBtn } from "../../assets/landingPage/landingPageAssets";
import "./styles.scss";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import countryList from "country-list";
import { useTranslation } from "react-i18next";

const CheckoutPage = () => {
  const [formInfo, setFormInfo] = useState({
    buyer_name: "",
    buyer_last_name: "",
    buyer_email: "",
    buyer_phone: "+382",
    buyer_address: "",
    buyer_city: "",
    buyer_country: "Montenegro",
    buyer_postal_code: "",
    order_total: "",
    status: "pending",
    products_and_qty: {},
  });

  const [nameFocused, setNameFocused] = useState(false);
  const [lastNameFocused, setLastNameFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [phoneFocused, setPhoneFocused] = useState(true);
  const [addressFocused, setAddressFocused] = useState(false);
  const [cityFocused, setCityFocused] = useState(false);
  const [countryFocused, setCountryFocused] = useState(true);
  const [postalFocused, setPostalFocused] = useState(false);
  const [orderSet, setOrderSet] = useState();
  const [orderMessage, setOrderMessage] = useState();
  const [selectedCountry, setSelectedCountry] = useState("ME");
  const [country, setCountry] = useState("Montenegro");
  const { baseUrl, authKey, total, cart, setCart, handleClearCart } =
    useGlobalContext();
  const navigate = useNavigate();
  // refs

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formInfo.buyer_name.length &&
      formInfo.buyer_last_name.length &&
      formInfo.buyer_email.length &&
      formInfo.buyer_phone.length &&
      formInfo.buyer_address.length &&
      formInfo.buyer_city.length &&
      formInfo.buyer_country.length &&
      formInfo.buyer_postal_code.length
    ) {
      setOrderSet("setOrder");
      try {
        const response = await axios
          .post(`${baseUrl}/order`, formInfo)
          .then((response) => {
            if (response.status === 201) {
              setFormInfo({
                buyer_name: "",
                buyer_last_name: "",
                buyer_email: "",
                buyer_phone: "",
                buyer_address: "",
                buyer_city: "",
                buyer_country: "",
                buyer_postal_code: "",
                shop_id: "",
                order_total: "",
                status: "pending",
                products_and_qty: {},
              });
              setOrderMessage("");
              navigate("/orderplaced");
              sessionStorage.removeItem("cart");
              handleClearCart();
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setOrderMessage(t("FillOut"));
      setTimeout(() => {
        setOrderMessage(false);
      }, 5000);
    }
  };
  const { t } = useTranslation();
  useEffect(() => {
    console.log(cart);
    let arr = cart.map((item) => {
      let newObj = {};
      for (const key in item) {
        if (key === "id") {
          newObj[item[key]] = item["quantity"];
        }
      }

      return newObj;
    });
    let obj = arr.reduce((acc, obj) => {
      return { ...acc, ...obj };
    }, {});

    setFormInfo({
      ...formInfo,
      buyer_country: country,
      order_total: parseInt(total),
      products_and_qty: obj,
    });
  }, [country]);

  const nameCrossHandler = () => {
    setNameFocused(false);
    setFormInfo({ ...formInfo, buyer_name: "" });
  };
  const lastNameCrossHandler = () => {
    setLastNameFocused(false);
    setFormInfo({ ...formInfo, buyer_last_name: "" });
  };

  const emailCrossHandler = () => {
    setEmailFocused(false);
    setFormInfo({ ...formInfo, buyer_email: "" });
  };

  const phoneCrossHandler = () => {
    setPhoneFocused(false);
    setFormInfo({ ...formInfo, buyer_phone: "" });
  };
  const AddressCrossHandler = () => {
    setAddressFocused(false);
    setFormInfo({ ...formInfo, buyer_address: "" });
  };

  const cityCrossHandler = () => {
    setCityFocused(false);
    setFormInfo({ ...formInfo, buyer_city: "" });
  };
  const countryCrossHandler = () => {
    setCountryFocused(false);
    setFormInfo({ ...formInfo, buyer_country: "" });
    setCountry("");
  };
  const PostalCrossHandler = () => {
    setPostalFocused(false);
    setFormInfo({ ...formInfo, buyer_postal_code: "" });
  };

  const handleCountryChange = (countryCode) => {
    setSelectedCountry(countryCode);
    setTimeout(() => {
      setCountry(countryList.getName(countryCode));
      console.log(countryCode);
    }, 300);
  };
  console.log(formInfo);
  return (
    <>
      <div className="checkout-page-section">
        <div className="order-container">
          <BlueTextBold className="header">
            {t("YourShoppingCart")}
          </BlueTextBold>
          <div className="order-form">
            <YellowTextBold className="order-header">
              {t("ForOrderCompletion")}
            </YellowTextBold>
            <div className="inputs-box">
              <form className="all-inputs">
                {/* name input */}
                <InputWrap nameFocused={nameFocused}>
                  <NameCross
                    nameFocused={nameFocused}
                    src={CrossBtn}
                    alt=""
                    onClick={nameCrossHandler}
                  />
                  <NameInput
                    maxLength="30"
                    nameFocused={nameFocused}
                    type="text"
                    name="name"
                    onFocus={() => setNameFocused(true)}
                    value={formInfo.buyer_name}
                    onChange={(e) =>
                      setFormInfo({ ...formInfo, buyer_name: e.target.value })
                    }
                  />
                  <NamePlaceholderBox nameFocused={nameFocused}>
                    <NamePlaceholder nameFocused={nameFocused}>
                      {t("Firstname")}
                    </NamePlaceholder>
                  </NamePlaceholderBox>
                </InputWrap>

                {/* last name input */}

                <InputWrap lastNameFocused={lastNameFocused}>
                  <LastNameCross
                    lastNameFocused={lastNameFocused}
                    src={CrossBtn}
                    alt=""
                    onClick={lastNameCrossHandler}
                  />
                  <LastNameInput
                    maxLength="30"
                    lastNameFocused={lastNameFocused}
                    type="text"
                    name="last name"
                    value={formInfo.buyer_last_name}
                    onFocus={() => setLastNameFocused(true)}
                    onChange={(e) =>
                      setFormInfo({
                        ...formInfo,
                        buyer_last_name: e.target.value,
                      })
                    }
                  />
                  <LastNamePlaceholderBox lastNameFocused={lastNameFocused}>
                    <LastNamePlaceholder lastNameFocused={lastNameFocused}>
                      {t("LastName")}
                    </LastNamePlaceholder>
                  </LastNamePlaceholderBox>
                </InputWrap>

                {/* email input */}

                <InputWrap emailFocused={emailFocused}>
                  <EmailCross
                    emailFocused={emailFocused}
                    src={CrossBtn}
                    alt=""
                    onClick={emailCrossHandler}
                  />
                  <EmailInput
                    maxLength="45"
                    emailFocused={emailFocused}
                    type="email"
                    name="email"
                    onFocus={() => setEmailFocused(true)}
                    value={formInfo.buyer_email}
                    onChange={(e) =>
                      setFormInfo({ ...formInfo, buyer_email: e.target.value })
                    }
                  />
                  <EmailPlaceholderBox emailFocused={emailFocused}>
                    <EmailPlaceholder emailFocused={emailFocused}>
                      {t("YourEmail")}
                    </EmailPlaceholder>
                  </EmailPlaceholderBox>
                </InputWrap>

                {/* phone number input */}
                <InputWrap
                  className="phone-input-box"
                  style={
                    phoneFocused
                      ? {
                          backgroundColor: "rgba(114, 139, 166, .4)",
                          borderRadius: "50px",
                        }
                      : { borderRadius: "50px" }
                  }
                >
                  <PhoneNumberCross
                    phoneFocused={phoneFocused}
                    src={CrossBtn}
                    style={{ width: "28px", height: "28px" }}
                    alt=""
                    onClick={phoneCrossHandler}
                  />
                  <PhoneInput
                    maxLength="25"
                    type="phone"
                    name="phone"
                    onFocus={() => setPhoneFocused(true)}
                    international
                    value={formInfo?.buyer_phone}
                    onChange={(number) =>
                      setFormInfo({ ...formInfo, buyer_phone: number })
                    }
                  />
                  <PhoneNumberPlaceholderBOx phoneFocused={phoneFocused}>
                    <PhoneNumberPlaceholder phoneFocused={phoneFocused}>
                      {t("YourPhone")}
                    </PhoneNumberPlaceholder>
                  </PhoneNumberPlaceholderBOx>
                </InputWrap>

                {/* address input */}

                <InputWrap addressFocused={addressFocused}>
                  <AddressCross
                    addressFocused={addressFocused}
                    src={CrossBtn}
                    alt=""
                    onClick={AddressCrossHandler}
                  />
                  <AddressInput
                    maxLength="50"
                    addressFocused={addressFocused}
                    type="text"
                    name="address"
                    onFocus={() => setAddressFocused(true)}
                    value={formInfo.buyer_address}
                    onChange={(e) =>
                      setFormInfo({
                        ...formInfo,
                        buyer_address: e.target.value,
                      })
                    }
                  />
                  <AddressPlaceholderBox addressFocused={addressFocused}>
                    <AddressPlaceholder addressFocused={addressFocused}>
                      {t("Address")}
                    </AddressPlaceholder>
                  </AddressPlaceholderBox>
                </InputWrap>

                {/* City input */}

                <InputWrap cityFocused={cityFocused}>
                  <CityCross
                    cityFocused={cityFocused}
                    src={CrossBtn}
                    alt=""
                    onClick={cityCrossHandler}
                  />
                  <CityInput
                    maxLength="30"
                    cityFocused={cityFocused}
                    type="text"
                    name="city"
                    onFocus={() => setCityFocused(true)}
                    value={formInfo.buyer_city}
                    onChange={(e) =>
                      setFormInfo({
                        ...formInfo,
                        buyer_city: e.target.value,
                      })
                    }
                  />
                  <CityPlaceholderBox cityFocused={cityFocused}>
                    <CityPlaceholder cityFocused={cityFocused}>
                      {t("City")}
                    </CityPlaceholder>
                  </CityPlaceholderBox>
                </InputWrap>

                {/* Country input */}

                <InputWrap
                  className="phone-input-box"
                  style={
                    countryFocused
                      ? {
                          backgroundColor: "rgba(114, 139, 166, .4)",
                          borderRadius: "50px",
                        }
                      : { borderRadius: "50px" }
                  }
                >
                  <CountryCross
                    countryFocused={countryFocused}
                    src={CrossBtn}
                    style={{ width: "28px", height: "28px" }}
                    alt=""
                    onClick={countryCrossHandler}
                  />
                  <PhoneInput
                    onFocus={() => setCountryFocused(true)}
                    style={{ cursor: "pointer" }}
                    readOnly
                    type="phone"
                    name="phone"
                    onChange={() => console.log(123)}
                    onCountryChange={handleCountryChange}
                    defaultCountry={selectedCountry}
                  />
                  <h3 className="country-select">{country}</h3>
                  <CountryPlaceholderBox countryFocused={countryFocused}>
                    <CountryPlaceholder countryFocused={countryFocused}>
                      {t("YourCountry")}
                    </CountryPlaceholder>
                  </CountryPlaceholderBox>
                </InputWrap>

                {/* Postal input */}

                <InputWrap postalFocused={postalFocused}>
                  <PostalCross
                    postalFocused={postalFocused}
                    src={CrossBtn}
                    alt=""
                    onClick={PostalCrossHandler}
                  />
                  <PostalInput
                    maxLength="30"
                    postalFocused={postalFocused}
                    type="number"
                    name="postal code"
                    onFocus={() => setPostalFocused(true)}
                    value={formInfo.buyer_postal_code}
                    onChange={(e) =>
                      setFormInfo({
                        ...formInfo,
                        buyer_postal_code: e.target.value,
                      })
                    }
                  />
                  <PostalPlaceholderBox postalFocused={postalFocused}>
                    <PostalPlaceholder postalFocused={postalFocused}>
                      {t("PostalCode")}
                    </PostalPlaceholder>
                  </PostalPlaceholderBox>
                </InputWrap>

                {orderMessage && orderMessage.length > 0 ? (
                  <div className="order-error-message-box">
                    <h3 className="order-error-message">{orderMessage}</h3>
                  </div>
                ) : (
                  <>
                    <div className="order-error-message-box">
                      <span className="order-error-message"></span>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
          <div className="total">
            <div className="checkout-content">
              {orderSet === "setOrder" ? (
                <>
                  <div className="total-button-box">
                    <Link to={`/shop/${localStorage.getItem("shopId")}`}>
                      <ButtonBlue className="checkout-proceed">
                        {t("BackToShop")}
                      </ButtonBlue>
                    </Link>
                    <div class="loading-spinner"></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="total-button-box">
                    <Link to={`/shop/${localStorage.getItem("shopId")}`}>
                      <ButtonBlue className="checkout-proceed">
                        {t("BackToShop")}
                      </ButtonBlue>
                    </Link>
                    <ButtonBlue
                      onClick={handleSubmit}
                      type="submit"
                      className="checkout-proceed"
                    >
                      {t("PlaceOrder")}
                    </ButtonBlue>
                  </div>
                </>
              )}
              <div className="price-box">
                <BlueTextBold className="price-header">
                  {t("TotalPrice")}
                </BlueTextBold>
                <WhiteTextBold className="total-price">
                  {total.toFixed(2)}€
                </WhiteTextBold>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckoutPage;
