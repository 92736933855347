import React, { useEffect } from "react";
import { useState } from "react";
import { useGlobalContext } from "../../../context/context";
import {
  DropdownArrow,
  WhiteCross,
  WhiteTick,
} from "../../../assets/landingPage/landingPageAssets";
import axios from "axios";
import { GrayTextMedium } from "../../../styles/GlobalStyles";
import { useTranslation } from "react-i18next";

const SingleOrderCheck = ({
  singleOrderContent,
  dropProducts,
  setDropProducts,
}) => {
  const [statusOpen, setStatusOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const [singleOrder, setSingleOrder] = useState();
  const [arrowUp, setArrowUp] = useState(false);
  const { orderId, baseUrl, config, updateSingleOrder, setLoadSingleOrder } =
    useGlobalContext();
  const { t } = useTranslation();

  useEffect(() => {}, [selected]);
  const handleSelect = (selecter) => {
    setStatusOpen(false);
    setSelected(selecter);
  };
  const handleDrop = () => {
    setArrowUp(!arrowUp);
    setDropProducts(!dropProducts);
  };

  return (
    <>
      <div className="single-order-box">
        <div className="left-column">
          <p className="order-info-type">{t("Idnumber")}</p>
          <p className="order-info-type">{t("Firstname")}</p>
          <p className="order-info-type">{t("Email")}</p>
          <p className="order-info-type">{t("Phonenumber")}</p>
          <p className="order-info-type">{t("Country")}</p>
          <p className="order-info-type">{t("Products")}</p>
          <p className="order-info-type">{t("Price")} (€)</p>
          <p className="order-info-type">{t("Status")}</p>
        </div>
        <div className="right-column">
          <p className="order-info">{singleOrderContent.id}</p>
          <p className="order-info">
            {singleOrderContent?.buyer_name}{" "}
            {singleOrderContent?.buyer_last_name}
          </p>
          <p className="order-info">{singleOrderContent?.buyer_email}</p>
          <p className="order-info">{singleOrderContent?.buyer_phone}</p>
          <p className="order-info">{singleOrderContent?.buyer_country}</p>
          <div className="order-info">
            <div className="single-order-all-products" onClick={handleDrop}>
              <button>
                <GrayTextMedium style={{ fontWeight: "700" }}>
                  All products
                </GrayTextMedium>
              </button>
              <img
                src={DropdownArrow}
                alt=""
                style={arrowUp ? { transform: "rotate(180deg)" } : {}}
              />

              <div
                className="single-order-all-products-box"
                style={
                  dropProducts ? { display: "block" } : { display: "none" }
                }
              >
                <ul className="single-order-all-products-ul">
                  {singleOrderContent?.products?.map((item, index) => (
                    <li key={index}>
                      <span>{item.name}</span>
                      <span>{item.price}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <p className="order-info">{singleOrderContent?.order_total}</p>
          <div className="order-info status-info">
            <div className="accept-reject-box">
              <button className="accept-reject-image-box image-box-accept">
                <img src={WhiteTick} alt="" />
              </button>
              <button className="accept-reject-image-box image-box-reject">
                <img src={WhiteCross} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleOrderCheck;
