import styled from "styled-components";
import { Cross } from "../../styles/GlobalStyles";

const InputWrap = styled.div`
  width: 450px;
  position: relative;
  display: flex;
  margin-bottom: 24px;
  height: 54px;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

const Input = styled.input`
  transition: 0.5s all ease;
  width: 100%;
  border-radius: 50px;
  height: 55px;
  border: none;
  outline: none;
  padding: 0 0 0 20px;
  margin-bottom: 27px;
  color: #374366;
  position: relative;
  z-index: 2;
`;
const PlaceHolderBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 22px;
  transition: 0.5s all ease;
  z-index: 1;
`;
const Placeholder = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: #3853a3;
  transition: 0.5s all ease;
  height: 30px;
`;

const NameInput = styled(Input)`
  background: ${(props) =>
    props.nameFocused ? "rgba(114, 139, 166, .6)" : "rgba(114, 139, 166, 0.2)"};
`;
const LastNameInput = styled(Input)`
  background: ${(props) =>
    props.lastNameFocused
      ? "rgba(114, 139, 166, .6)"
      : "rgba(114, 139, 166, 0.2)"};
`;
const PhoneNumberInput = styled(Input)`
  background: rgba(114, 139, 166, 0.4);
  ::placeholder {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    color: #3853a3;
    transition: 0.5s all ease;
    height: 30px;
  }
`;
const EmailInput = styled(Input)`
  background: rgba(114, 139, 166, 0.4);
  ::placeholder {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    color: #3853a3;
    transition: 0.5s all ease;
    height: 30px;
  }
`;

// crosses

const NameCross = styled(Cross)`
  opacity: ${(props) => (props.nameFocused ? "1" : "0")};
`;
const LastNameCross = styled(Cross)`
  opacity: ${(props) => (props.lastNameFocused ? "1" : "0")};
`;
const EmailCross = styled(Cross)`
  opacity: ${(props) => (props.emailFocused ? "1" : "0")};
`;
const PhoneNumberCross = styled(Cross)`
  opacity: ${(props) => (props.phoneFocused ? "1" : "0")};
`;

// placeholderBox

const NamePlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.nameFocused ? "-33px" : "2px")};
`;
const LastNamePlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.lastNameFocused ? "-33px" : "2px")};
`;
const EmailPlaceholderBox = styled(PlaceHolderBox)`
  top: -33px;
`;
const PhoneNumberPlaceholderBOx = styled(PlaceHolderBox)`
  top: -33px;
`;

// placeholders

const NamePlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.nameFocused ? "11px" : "16px")};
`;
const LastNamePlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.lastNameFocused ? "11px" : "16px")};
`;

const EmailPlaceholder = styled(Placeholder)`
  font-size: 11px;
`;
const PhoneNumberPlaceholder = styled(Placeholder)`
  font-size: 11px;
`;

export {
  NamePlaceholderBox,
  LastNamePlaceholderBox,
  EmailPlaceholderBox,
  PhoneNumberPlaceholderBOx,
  NamePlaceholder,
  LastNamePlaceholder,
  EmailPlaceholder,
  PhoneNumberPlaceholder,
  NameCross,
  LastNameCross,
  EmailCross,
  PhoneNumberCross,
  InputWrap,
  Input,
  NameInput,
  LastNameInput,
  PhoneNumberInput,
  EmailInput,
};
