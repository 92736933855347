import React, { useState, useRef } from "react";
import { useEffect } from "react";
import {
  AvatarBlank,
  BlueCross,
  CustomerArrowUp,
  CustomerRealArrow,
  GrayArrow,
  GrayArrowRight,
  PaginationArrow,
  PaginationArrowLeft,
} from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import { BlueTextMedium, GrayTextMedium } from "../../../styles/GlobalStyles";
import {
  AllCustomersBox,
  BlueTextSmaller,
  CreateCustomer,
  CustomerArrow,
  CustomerBoxHeader,
  CustomerContainer,
  CustomerCross,
  CustomerFilterButton,
  CustomerFiltersBox,
  CustomerHeader,
  CustomerInput,
  CustomerInputWrap,
  CustomersBoxFlex,
  CustomerSection,
  PaginationArrows,
  PaginationBox,
} from "../styles";
import ReactPaginate from "react-paginate";
import SingleOrderCheck from "./SingleOrderCheck";
import "../../DemoShop copy/stylesAllPages.scss";
import { useTranslation } from "react-i18next";

const ordersData = [
  {
    id: 1,
    order_date: "2023-12-24 11:39:00.319019",
    shop_id: 1,
    order_total: 200,
    status: "U toku",
    buyer_name: "John",
    buyer_last_name: "Doe",
    buyer_email: "jdoe@grgr.com",
    buyer_address: "Street 3/4",
    buyer_phone: "0543432243",
    buyer_postal_code: "11000",
    buyer_city: "Belgrade",
    buyer_country: "Serbia",
    seller_user_id: 1,
    products: [
      { name: "hookXL", id: 1 },
      { name: "Bass lure", id: 2 },
      { name: "Green water jacket", id: 3 },
      { name: "Fish Lure Prem", id: 4 },
      { name: "Lure Extra", id: 5 },
    ],
  },
  {
    id: 2,
    order_date: "2023-11-24 11:39:00.319018",
    shop_id: 2,
    order_total: 90,
    status: "Prihvaćeno",
    buyer_name: "Mike",
    buyer_last_name: "Mikelson",
    buyer_email: "mikelson@grgr.com",
    buyer_address: "Street 3/4",
    buyer_phone: "0541112243",
    buyer_postal_code: "11000",
    buyer_city: "Belgrade",
    buyer_country: "Serbia",
    seller_user_id: 1,
    products: [
      { name: "hookXL", id: 1 },
      { name: "Bass lure", id: 2 },
      { name: "Green water jacket", id: 3 },
      { name: "Fish Lure Prem", id: 4 },
      { name: "Lure Extra", id: 5 },
    ],
  },
  {
    id: 3,
    order_date: "2023-10-24 11:39:00.319017",
    shop_id: 3,
    order_total: 180,
    status: "Odbijeno",
    buyer_name: "Dave",
    buyer_last_name: "Daver",
    buyer_email: "davedaver@grgr.com",
    buyer_address: "Street 3/4",
    buyer_phone: "0543432243",
    buyer_postal_code: "11000",
    buyer_city: "Belgrade",
    buyer_country: "Serbia",
    seller_user_id: 1,
    products: [
      { name: "hookXL", id: 1 },
      { name: "Bass lure", id: 2 },
      { name: "Green water jacket", id: 3 },
      { name: "Fish Lure Prem", id: 4 },
      { name: "Lure Extra", id: 5 },
    ],
  },
  {
    id: 4,
    order_date: "2023-9-24 11:39:00.319016",
    shop_id: 4,
    order_total: 130,
    status: "U toku",
    buyer_name: "Phillip",
    buyer_last_name: "Doe",
    buyer_email: "doedoe@grgr.com",
    buyer_address: "Street 3/4",
    buyer_phone: "0543431111",
    buyer_postal_code: "11000",
    buyer_city: "Belgrade",
    buyer_country: "Serbia",
    seller_user_id: 1,
    products: [
      { name: "hookXL", id: 1 },
      { name: "Bass lure", id: 2 },
      { name: "Green water jacket", id: 3 },
      { name: "Fish Lure Prem", id: 4 },
      { name: "Lure Extra", id: 5 },
    ],
  },
  {
    id: 5,
    order_date: "2023-8-24 11:39:00.319015",
    shop_id: 5,
    order_total: 17,
    status: "Prihvaćeno",
    buyer_name: "David",
    buyer_last_name: "Picker",
    buyer_email: "davidpicker@grgr.com",
    buyer_address: "Street 3/4",
    buyer_phone: "0543432777",
    buyer_postal_code: "11000",
    buyer_city: "Belgrade",
    buyer_country: "Serbia",
    seller_user_id: 1,
    products: [
      { name: "hookXL", id: 1 },
      { name: "Bass lure", id: 2 },
      { name: "Green water jacket", id: 3 },
      { name: "Fish Lure Prem", id: 4 },
      { name: "Lure Extra", id: 5 },
    ],
  },
  {
    id: 6,
    order_date: "2023-7-24 11:39:00.319014",
    shop_id: 6,
    order_total: 110,
    status: "U toku",
    buyer_name: "Simon",
    buyer_last_name: "Wooder",
    buyer_email: "simonwooder@grgr.com",
    buyer_address: "Street 3/4",
    buyer_phone: "0543432778",
    buyer_postal_code: "11000",
    buyer_city: "Belgrade",
    buyer_country: "Serbia",
    seller_user_id: 1,
    products: [
      { name: "hookXL", id: 1 },
      { name: "Bass lure", id: 2 },
      { name: "Green water jacket", id: 3 },
      { name: "Fish Lure Prem", id: 4 },
      { name: "Lure Extra", id: 5 },
    ],
  },
];

const OrdersSection = ({ setSections, sections, modalOpen }) => {
  const [ascending, setAscending] = useState({
    id: false,
    date: false,
  });
  const { getDemoShopOrders, orders, setOrder, setOrderId } =
    useGlobalContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [checkOrder, setCheckOrder] = useState(false);
  const [ordersHeader, setOrdersHeader] = useState("allOrders");
  const [customerFocus, setCustomerFocus] = useState(false);
  const [dropProducts, setDropProducts] = useState(false);
  const [headersClicked, setHeadersClicked] = useState(false);
  const [ordersFilter, setOrdersFilter] = useState("");
  const [singleOrderContent, setSingleOrderContent] = useState();
  const customerRef = useRef(null);
  const customerCrossHandler = () => {
    setCustomerFocus(false);
    customerRef.current.value = "";
    setOrdersFilter("");
  };
  const { t } = useTranslation();

  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };

  useEffect(() => {
    if (ordersFilter) {
      setCurrentPage(0);
    }
  }, [ordersFilter]);
  const OrdersListMaker = () => {
    let list = [];
    ordersData?.filter((item) => {
      if (ordersFilter) {
        if (
          item.buyer_email.toLowerCase().includes(ordersFilter.toLowerCase())
        ) {
          list.push(item);
        }
        return list;
      } else {
        if (item.status === ordersHeader) {
          list.push(item);
        } else if (ordersHeader === "allOrders") {
          list.push(item);
        }
      }
    });
    return list;
  };
  const filterHandler = (selecter) => {
    setOrdersHeader(selecter);
    let data = ordersData;
    if (selecter === t("Accepted")) {
      let newArr = data.filter((item) => item.status === "accepted");
    } else if (selecter === t("Rejected")) {
      let newArr = data.filter((item) => item.status === "rejected");
    } else if (selecter === t("Pending")) {
      data.filter((item) => item.status === "pending");
    } else {
      return data;
    }
  };

  let itemsPerPage = 7;
  let pageCount = Math.ceil(OrdersListMaker().length / itemsPerPage);
  let itemsVisited = currentPage * itemsPerPage;
  const handleSingleOrder = (item) => {
    setCheckOrder(true);
    setSingleOrderContent(item);
  };

  const ascendingHandler = (acc) => {
    let data = ordersData;
    if (acc === "id") {
      setAscending({ ...ascending, id: !ascending.id });
      if (ascending.id) {
        data.sort((a, b) => a.id - b.id);
      } else {
        data.sort((a, b) => b.id - a.id);
      }
    } else if (acc === "date") {
      setAscending({ ...ascending, date: !ascending.date });
      if (ascending.date) {
        data.sort(
          (a, b) =>
            parseInt(b.order_date.split(" ")[1].split(":").join("")) -
            parseInt(a.order_date.split(" ")[1].split(":").join(""))
        );
      } else {
        data.sort(
          (a, b) =>
            parseInt(a.order_date.split(" ")[1].split(":").join("")) -
            parseInt(b.order_date.split(" ")[1].split(":").join(""))
        );
      }
    }
  };
  const clearFilterOnPagination = () => {
    setCustomerFocus(false);
    setOrdersFilter("");
  };
  const nextPage = () => {
    if (currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <CustomerSection>
      <CustomerContainer id="container">
        <CustomerHeader>
          <BlueTextSmaller>{t("Orders")}</BlueTextSmaller>
        </CustomerHeader>
        <AllCustomersBox>
          <CustomersBoxFlex id="customersBox">
            {checkOrder ? (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomerBoxHeader>{t("SingleOrder")}</CustomerBoxHeader>
                  <CreateCustomer onClick={() => setCheckOrder(false)}>
                    {t("Allorders")}
                  </CreateCustomer>
                </div>
                <SingleOrderCheck
                  singleOrderContent={singleOrderContent}
                  dropProducts={dropProducts}
                  setDropProducts={setDropProducts}
                />
              </>
            ) : (
              <>
                <div className="orders-accepted-box">
                  <h3
                    style={{ fontSize: "14px" }}
                    className={
                      ordersHeader === "allOrders"
                        ? "orders-accepted-active"
                        : "orders-accepted-header"
                    }
                    onClick={() => filterHandler("allOrders")}
                  >
                    {t("Allorders")}
                  </h3>
                  <h3
                    style={{ fontSize: "14px" }}
                    className={
                      ordersHeader === "accepted"
                        ? "orders-accepted-active"
                        : "orders-accepted-header"
                    }
                    onClick={() => filterHandler(t("Accepted"))}
                  >
                    {t("Accepted")}
                  </h3>
                  <h3
                    style={{ fontSize: "14px" }}
                    className={
                      ordersHeader === "rejected"
                        ? "orders-accepted-active"
                        : "orders-accepted-header"
                    }
                    onClick={() => filterHandler(t("Rejected"))}
                  >
                    {t("Declined")}
                  </h3>
                  <h3
                    style={{ fontSize: "14px" }}
                    className={
                      ordersHeader === "pending"
                        ? "orders-accepted-active"
                        : "orders-accepted-header"
                    }
                    onClick={() => filterHandler(t("Pending"))}
                  >
                    {t("Pending")}
                  </h3>
                </div>
                <CustomerFiltersBox>
                  <CustomerInputWrap customerFocus={customerFocus}>
                    <CustomerCross
                      onClick={customerCrossHandler}
                      customerFocus={customerFocus}
                      src={BlueCross}
                      alt=""
                    />
                    <CustomerInput
                      ref={customerRef}
                      placeholder={t("SearchOrderbyemail")}
                      onClick={() => setCustomerFocus(true)}
                      onChange={(e) => setOrdersFilter(e.target.value)}
                      customerFocus={customerFocus}
                    />
                  </CustomerInputWrap>
                </CustomerFiltersBox>
                <div
                  className="table_wrapper"
                  style={dropProducts ? { paddingBottom: "150px" } : {}}
                >
                  <table className="orders-table">
                    {/* header */}
                    <tbody>
                      <tr>
                        <td width="15%">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <h4
                              className="gray-text"
                              style={{ marginRight: "10px" }}
                            >
                              {t("Idnumber")}
                            </h4>
                            <div
                              className="arrowBox"
                              onClick={() => ascendingHandler("id")}
                            >
                              <CustomerArrow src={CustomerArrowUp} alt="" />
                              <img
                                src={CustomerRealArrow}
                                alt=""
                                className={
                                  ascending.id ? "arrow-drop" : "arrow"
                                }
                              />
                            </div>
                          </div>
                        </td>
                        <td width="10%">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <h4
                              className="gray-text"
                              style={{ marginRight: "10px" }}
                            >
                              {t("Date")}
                            </h4>
                            <div
                              className="arrowBox"
                              onClick={() => ascendingHandler("date")}
                            >
                              <CustomerArrow src={CustomerArrowUp} alt="" />
                              <img
                                src={CustomerRealArrow}
                                alt=""
                                className={
                                  ascending.date ? "arrow-drop" : "arrow"
                                }
                              />
                            </div>
                          </div>
                        </td>
                        <td width="20%">
                          <div className="orders-td-box gray-text">
                            {t("Email")}
                          </div>
                        </td>
                        <td width="20%">
                          <div className="orders-td-box gray-text">
                            {t("Customer")}
                          </div>
                        </td>
                        <td width="10%">
                          <div className="orders-td-box gray-text">
                            {t("Checkorderstatus")}
                          </div>
                        </td>
                        <td width="15%">
                          <div className="orders-td-box-center gray-text">
                            {t("Orderstatus")}
                          </div>
                        </td>
                        <td width="10%">
                          <div className="orders-td-box-center gray-text">
                            {t("Price")}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    {/* table */}

                    {OrdersListMaker()
                      .slice(itemsVisited, itemsVisited + itemsPerPage)
                      .map((item, index) => {
                        return (
                          <>
                            <tbody key={index}>
                              <tr>
                                <td width="15%">
                                  <div className="image-flex">
                                    <h4 className="gray-text orders-td-box">
                                      #{item.id}
                                    </h4>
                                  </div>
                                </td>
                                <td width="10%" className="gray-text">
                                  <div className="orders-td-box">
                                    {item.order_date.split(" ")[0]}
                                  </div>
                                </td>
                                <td width="20%">
                                  <div className="orders-td-box gray-text">
                                    {item.buyer_email}
                                  </div>
                                </td>
                                <td width="20%">
                                  <div className="orders-td-box">
                                    {ordersHeader ? <></> : <></>}
                                    <div className="gray-text">
                                      {item.buyer_name} {item.buyer_last_name}
                                    </div>
                                  </div>
                                </td>
                                <td width="10%">
                                  <div className="orders-td-box">
                                    <div className="gray-text">
                                      <button
                                        className="trash-btn"
                                        onClick={() => handleSingleOrder(item)}
                                      >
                                        <div className="trash-btn-content">
                                          <h4 className="check-text">
                                            {t("Check")}
                                          </h4>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </td>
                                <td width="15%" className="gray-text">
                                  <div className="orders-td-box-center">
                                    <button
                                      className={
                                        item.status === t("Accepted")
                                          ? "availability green"
                                          : item.status === t("Pending")
                                          ? "availability yellow"
                                          : "availability red"
                                      }
                                    >
                                      {item.status}
                                    </button>
                                  </div>
                                </td>
                                <td width="10%">
                                  <div
                                    className="orders-td-box"
                                    style={{ textAlign: "right" }}
                                  >
                                    <GrayTextMedium>
                                      <span style={{ fontSize: "10px" }}>
                                        EUR
                                      </span>{" "}
                                      {item.order_total}.00
                                    </GrayTextMedium>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        );
                      })}
                  </table>
                </div>
              </>
            )}

            {checkOrder ? (
              <></>
            ) : (
              <PaginationBox>
                <div className="pagination-elements">
                  <div className="pagination-arrow">
                    <img
                      alt=""
                      src={currentPage === 0 ? GrayArrow : PaginationArrowLeft}
                      onClick={previousPage}
                    />
                  </div>
                  <BlueTextMedium className="current-page">
                    {currentPage}
                  </BlueTextMedium>
                  <div className="pagination-arrow">
                    <img
                      alt=""
                      src={
                        OrdersListMaker().length - itemsVisited <= 7
                          ? GrayArrowRight
                          : PaginationArrow
                      }
                      onClick={nextPage}
                    />
                  </div>
                </div>
              </PaginationBox>
            )}
          </CustomersBoxFlex>
        </AllCustomersBox>
      </CustomerContainer>
    </CustomerSection>
  );
};

export default OrdersSection;
