import styled from "styled-components";
import {
  BlueTextMedium,
  ButtonYellow,
  FlexCenter,
  GrayTextMedium,
  Input,
  WhiteTextMedium,
  YellowTextBold,
} from "../../styles/GlobalStyles";
import  Pattern3  from "../../assets/landingPage/lightblueWeb_Business1.png";

const LogInSection = styled.div`
  background: #d9d9d9;
`;
const LogInContainer = styled.div`
  background-image: url(${Pattern3});
  background-size: 40%;
  @media only screen and (max-width: 730px) {
    background-size: 100%;}
  
`;
const LogInBox = styled.form`
  width: 543px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 450px) {
    width: 100%;
    margin: 0 auto;
  }
`;
const LogInHeader = styled(BlueTextMedium)`
  font-size: 30px;
  display: block;
  margin: 70px 0 20px 0;
`;

const Remember = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  border: 2px solid #d9d9d9;
  border-radius: 8px;
  transition: 0.5s all ease;
  position: relative;
  &::after {
    position: absolute;
    content: url("../../assets/landingPage/checked.png");
    top: 5px;
    left: 5px;
    color: #77a944;
    font-size: 10px;
    font-weight: 800;
    display: block;
  }
  &:checked {
    background: #ffffff;
    border: 1px solid #77a944;
    border-radius: 8px;
  }

  @media only screen and (max-width: 756px) {
  }
`;

const FlexRememberMe = styled.div`
  width: 380px;
  marginbottom: 15px;
  display: flex;
  justify-content: flex-start;

  @media only screen and (max-width: 756px) {
    width: 100%;
    justify-content: center;
  }
`;

const Label = styled(GrayTextMedium)`
  font-size: 13px;
  margin-left: 14px;
  cursor: pointer;
  &:hover {
    ${Remember} {
      background: #d9d9d9;
      transition: 0.3s all ease;
    }
  }

  @media only screen and (max-width: 756px) {
    white-space: nowrap;
  }
`;
const WelcomeBack = styled(GrayTextMedium)`
  font-size: 18px;
  line-height: 20px;
`;
const Forgot = styled(YellowTextBold)`
  font-size: 13px;
  cursor: pointer;
`;
const LogInButton = styled(ButtonYellow)`
  padding: 14.5px 166px;
  font-size: 16px;
  line-height: 23px;
  border: 2px solid transparent;
  &:hover {
    background: #fff;
    border: 2px solid #ffa800;
    color: #ffa800;
    transition: 0.5s all ease;
  }

  @media only screen and (max-width: 756px) {
    padding: 14.5px 70px;
  }
`;
const LogInButtonClicked = styled(ButtonYellow)`
  padding: 14.5px 166px;
  font-size: 16px;
  line-height: 23px;
  border: 2px solid transparent;
  width: 390.19px;
  height: 56px;
  &:hover {
    border: 2px solid transparent;
    transition: 0.5s all ease;
    background-color: #ffa800;
  }
`;
const GoogleText = styled(BlueTextMedium)`
  font-size: 16px;
  padding: 0 49px 0 0;

  @media only screen and (max-width: 756px) {
    padding: 0 20px 0 0;
  }
`;
const Google = styled.button`
  background: #ffffff;
  border: 1px solid #3853a3;
  border-radius: 26px;
  display: flex;
  align-items: center;
  margin: 24px 0 14px 0;
  cursor: pointer;
  transition: 0.5s all ease;
  &:hover {
    background: #000;
    transition: 0.5s all ease;
    color: #fff;
  }
  &:hover ${GoogleText} {
    color: #fff;
    transition: 0.5s all ease;
  }
`;

const FacebookText = styled.p`
  font-size: 16px;
  padding-right: 42px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  color: #ffffff;

  @media only screen and (max-width: 756px) {
    padding-right: 16px;
  }
`;

const PlaceHolderBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 22px;
  transition: 0.5s all ease;
  z-index: 1;
`;

const Placeholder = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: #3853a3;
  transition: 0.5s all ease;
  height: 30px;
`;

//PLACEHOLDER BOX
const RegisterPlaceholderBoxEmail = styled(PlaceHolderBox)`
  top: ${(props) => (props.registerEmailfocus ? "-33px" : "2px")};
`;
const RegisterPlaceholderBoxPassword = styled(PlaceHolderBox)`
  top: ${(props) => (props.registerPasswordfocus ? "-33px" : "2px")};
`;
const PlaceholderBoxConfirmPassword = styled(PlaceHolderBox)`
  top: ${(props) => (props.confirmPasswordFocus ? "-33px" : "2px")};
`;

const PlaceholderBoxConfirmEmail = styled(PlaceHolderBox)`
  top: ${(props) => (props.confirmEmailFocus ? "-33px" : "2px")};
`;
const PlaceholderBoxPassword = styled(PlaceHolderBox)`
  top: ${(props) => (props.passwordFocus ? "-33px" : "2px")};
`;
const PlaceholderBoxEmail = styled(PlaceHolderBox)`
  top: ${(props) => (props.emailFocus ? "-33px" : "2px")};
`;

const PlaceholderEmail = styled(Placeholder)`
  font-size: ${(props) => (props.emailFocus ? "11px" : "15px")};
  line-height: ${(props) => (props.emailFocus ? "16px" : "22px")};
  color: ${(props) => (props.emailFocus ? "#7E94AB" : "")};
`;
const RegisterPlaceholderEmail = styled(Placeholder)`
  font-size: ${(props) => (props.registerEmailfocus ? "11px" : "15px")};
  line-height: ${(props) => (props.registerEmailfocus ? "16px" : "22px")};
  color: ${(props) => (props.registerEmailfocus ? "#7E94AB" : "")};
`;
const RegisterPlaceholderPassword = styled(Placeholder)`
  font-size: ${(props) => (props.registerPasswordfocus ? "11px" : "15px")};
  line-height: ${(props) => (props.registerPasswordfocus ? "16px" : "22px")};
  color: ${(props) => (props.registerPasswordfocus ? "#7E94AB" : "")};
`;

//PLACEHOLDER

const PlaceholderPassword = styled(Placeholder)`
  font-size: ${(props) => (props.passwordFocus ? "11px" : "15px")};
  line-height: ${(props) => (props.passwordFocus ? "16px" : "22px")};
  color: ${(props) => (props.passwordFocus ? "#7E94AB" : "")};
`;

const PlaceholderConfirmEmail = styled(Placeholder)`
  font-size: ${(props) => (props.confirmEmailFocus ? "11px" : "15px")};
  line-height: ${(props) => (props.confirmEmailFocus ? "16px" : "22px")};
  color: ${(props) => (props.confirmEmailFocus ? "#7E94AB" : "")};
`;

const PlaceholderConfirmPassword = styled(Placeholder)`
  font-size: ${(props) => (props.confirmPasswordFocus ? "11px" : "15px")};
  line-height: ${(props) => (props.confirmPasswordFocus ? "16px" : "22px")};
  color: ${(props) => (props.confirmPasswordFocus ? "#7E94AB" : "")};
`;

const LogInInput = styled(Input)`
  width: 100%;
  background: transparent;
  border-radius: 100px;
  position: relative;
  z-index: 2;
`;

const LogInInputBox = styled.div`
  width: 385px;
  position: relative;
  margin-bottom: 24px;
  border-radius: 100px;
  color: #3853a3;
  transition: 0.5s all ease;
  height: 54px;

  @media only screen and (max-width: 756px) {
    width: 91%;
  }
`;

//LOGIN INPUT BOX

const LogInInputBoxPassword = styled(LogInInputBox)`
  border: ${(props) =>
    props.passwordFocus ? "1px solid transparent" : "1px solid #3853a3"};
  background: ${(props) => (props.passwordFocus ? "#EBEBEB" : "#fff")};
`;

const LogInInputBoxEmail = styled(LogInInputBox)`
  border: ${(props) =>
    props.emailFocus ? "1px solid transparent" : "1px solid #3853a3"};
  background: ${(props) => (props.emailFocus ? "#EBEBEB" : "#fff")};
`;

const LoginInputBoxRegisterEmail = styled(LogInInputBox)`
  border: ${(props) =>
    props.registerEmailfocus ? "1px solid transparent" : "1px solid #3853a3"};
  background: ${(props) => (props.registerEmailfocus ? "#EBEBEB" : "#fff")};
`;

const LogInInputBoxConfirmEmail = styled(LogInInputBox)`
  border: ${(props) =>
    props.confirmEmailFocus ? "1px solid transparent" : "1px solid #3853a3"};
  background: ${(props) => (props.confirmEmailFocus ? "#EBEBEB" : "#fff")};
`;

const LogInInputBoxRegisterPassword = styled(LogInInputBox)`
  border: ${(props) =>
    props.registerPasswordfocus
      ? "1px solid transparent"
      : "1px solid #3853a3"};
  background: ${(props) => (props.registerPasswordfocus ? "#EBEBEB" : "#fff")};
`;

const LogInInputBoxConfirmPassword = styled(LogInInputBox)`
  border: ${(props) =>
    props.confirmPasswordFocus ? "1px solid transparent" : "1px solid #3853a3"};
  background: ${(props) => (props.confirmPasswordFocus ? "#EBEBEB" : "#fff")};
`;

const LogInCross = styled.img`
  position: absolute;
  width: 28px;
  height: 28px;
  right: 10px;
  top: 13px;
  z-index: 3;
  cursor: pointer;
  transition: 0.5s all ease;
`;

// LOGIN CROSS

const LogInCrossPassword = styled(LogInCross)`
  opacity: ${(props) => (props.passwordFocus ? "1" : "0")};
`;

const LogInCrossEmail = styled(LogInCross)`
  opacity: ${(props) => (props.emailFocus ? "1" : "0")};
`;

const LogInCrossConfirmEmail = styled(LogInCross)`
  opacity: ${(props) => (props.confirmEmailFocus ? "1" : "0")};
`;
const LogInCrossConfirmPassword = styled(LogInCross)`
  opacity: ${(props) => (props.confirmPasswordFocus ? "1" : "0")};
`;

const LogInCrossRegisterEmail = styled(LogInCross)`
  opacity: ${(props) => (props.registerEmailfocus ? "1" : "0")};
`;

const LogInCrossRegisterPassword = styled(LogInCross)`
  opacity: ${(props) => (props.registerPasswordfocus ? "1" : "0")};
`;

const FacebookIMG = styled.img`
  width: 26.42px;
  height: 26.26px;
  margin: 13px 22px 12px 16px;
`;
const FacebookIMGHover = styled.img`
  width: 26.42px;
  height: 26.26px;
  position: absolute;
  top: 13px;
  left: 16px;
  display: none;
  transition: 0.5s all ease;
`;

const Facebook = styled.button`
  background: #1877f2;
  border-radius: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  border: 2px solid transparent;
  position: relative;
  transition: 0.5s all ease;
  &:hover {
    background: #fff;
    border: 2px solid #1877f2;
    transition: 0.5s all ease;
  }
  &:hover ${FacebookText} {
    color: #1877f2;
    transition: 0.5s all ease;
  }
  &:hover ${FacebookIMGHover} {
    display: block;
    transition: 0.5s all ease;
  }
`;

const NoAccount = styled(GrayTextMedium)`
  margin-top: 26px;
  margin-bottom: 70px;
`;
const Line = styled.div`
  border-top: 0.5px solid #7e94ab;
  width: 168px;
`;
const Break = styled(FlexCenter)`
  width: 383.5px;
  height: 25px;
  margin-top: 24px;

  @media only screen and (max-width: 756px) {
    width: 100%;
  }
`;

const GreenClick = styled.img`
  position: absolute;
  right: 4px;
  display: ${(props) => (props.checkbox ? "block" : "none")};
`;

export {
  LogInInputBoxRegisterPassword,
  LogInCrossRegisterPassword,
  LoginInputBoxRegisterEmail,
  LogInCrossRegisterEmail,
  GreenClick,
  PlaceholderBoxPassword,
  PlaceholderPassword,
  PlaceholderBoxConfirmEmail,
  PlaceholderConfirmEmail,
  PlaceholderBoxConfirmPassword,
  PlaceholderConfirmPassword,
  LogInInputBoxPassword,
  LogInInputBoxConfirmEmail,
  LogInInputBoxConfirmPassword,
  LogInCrossPassword,
  LogInCrossConfirmEmail,
  LogInCrossConfirmPassword,
  LogInCrossEmail,
  LogInInputBoxEmail,
  LogInInput,
  LogInInputBox,
  PlaceholderBoxEmail,
  PlaceholderEmail,
  LogInCross,
  FacebookIMGHover,
  FacebookIMG,
  FacebookText,
  Remember,
  GoogleText,
  Break,
  Line,
  Label,
  LogInSection,
  LogInBox,
  LogInHeader,
  WelcomeBack,
  Forgot,
  LogInButton,
  Google,
  Facebook,
  NoAccount,
  LogInContainer,
  RegisterPlaceholderBoxEmail,
  RegisterPlaceholderBoxPassword,
  RegisterPlaceholderEmail,
  RegisterPlaceholderPassword,
  LogInButtonClicked,
  FlexRememberMe,
};
