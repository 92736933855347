import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import theme from "./styles/Theme";
import "./styles/app.css";
import CreateShopPage from "./pages/CreateShopPage/CreateShopPage";
import LogIn from "./pages/LogInPage/LogInPage";
import MojBizNewsPage from "./pages/NewsPage/MojBizNewsPage";
import ContactUs from "./pages/ContactUs/ContactUs";
import Shop from "./pages/Shop/Shop";
import ProductPage from "./pages/ProductPage/ProductPage";
import Cart from "./pages/Cart/Cart";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import OrderPlacedPage from "./pages/OrderPlacedPage/OrderPlacedPage";
import RedirectPage from "./pages/RedirectPage/RedirectPage";
import MoreNewsPage from "./pages/MoreNewsPage.js/MoreNewsPage";
import ScrollToTop from "./ScrollToTop";
import ProfileDashboard from "./components/DemoShop copy/DemoShop";
import { useGlobalContext } from "./context/context";
import DemoShopOriginal from "./components/DemoShop copy 2/DemoShop";
import { Navbar } from "./imports/landingPageContent";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PaymentSuccess from "./pages/PaymentSuccess/PaymentSuccess";
import PaymentError from "./pages/PaymentError/PaymentError";
import PaymentCancel from "./pages/PaymentCancel/PaymentCancel";
import RobotCodeNewsPage from "./pages/NewsPage/RobotCodeNewsPage";
import MiroiSonsNewsPage from "./pages/NewsPage/MiroiSonsNewsPage";

function App() {
  const { authkey, setAuthKey } = useGlobalContext();
  const pathname = window.location.href;
  const { t } = useTranslation();

  useEffect(() => {}, [authkey]);
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{t("MojBizNexusShops")}</title>
      </Helmet>
      <ScrollToTop>
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/offer" element={<CreateShopPage />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/demoshop" element={<DemoShopOriginal />} />
          <Route path="/mojbiznews" element={<MojBizNewsPage />} />
          <Route path="/robot_code_news" element={<RobotCodeNewsPage />} />
          <Route path="/miro&sons_news" element={<MiroiSonsNewsPage />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/shop/:id" element={<Shop />} />
          <Route path="/product/:id" element={<ProductPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/orderplaced" element={<OrderPlacedPage />} />
          <Route path="/verifyemail" element={<RedirectPage />} />
          <Route path="/morenews" element={<MoreNewsPage />} />
          <Route path="/profile" element={<ProfileDashboard />} />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="/error" element={<PaymentError />} />
          <Route path="/cancel" element={<PaymentCancel />} />
        </Routes>
      </ScrollToTop>
    </ThemeProvider>
  );
}

export default App;
