import React from "react";
import {
  NewsSection,
  BackgroundImage,
  NewsText,
  Date,
  CategoryName,
  Title,
  Paragraph,
  NewsColumn,
} from "./styles";
import { FishingManBigger } from "../../assets/landingPage/landingPageAssets";
import MerProgram from "../../assets/news/MER-Program.png";
import Robotcode from "../../assets/news/Robotcode.png";
import Kotor from "../../assets/news/Kotor.png";
import { useTranslation } from "react-i18next";

const MojBizNews = () => {
  const { t } = useTranslation();
  

  return (
    <>
      <NewsSection>
        <BackgroundImage src={MerProgram} alt="news-image" />
        <NewsText>
          <NewsColumn>
            <div style={{ height: "41px" }}>
              <Date>03. avgust 2023.</Date>
            </div>
            <div style={{ height: "41px" }}>
              <CategoryName>Projekti</CategoryName>
            </div>
            <Title>Mojbiz.me podržan od strane Ministarstva ekonomskog razvoja
                  Crne Gore</Title>
            <Paragraph>
            Preduzeće Asenvirocon d.o.o. Kotor je danas potpisalo Ugovor o sufinansiranju za realizaciju digitalne platforme mojbiz.me sa Ministarstvom ekonomskog razvoja Crne Gore. Sredstva za sufinansiranje su obezbjeđena iz Programske linije za podršku digitalizaciji u okviru Programa za unapređenje konkurentnosti privrede za 2023.godinu. Obezbjeđena sredstva predstavljaju značajan doprinos za ostvarenje poslovnog plana Asenvirocon da postane jedan od lidera u oblasti digitalne podrške poslovanju mikro, malih i srednjih preduzeća u Crnog Gori sa fokusom na preduzeća u sektoru plavog rasta.
            </Paragraph>
            <p><br /></p>
            <p><br /></p>
            <Paragraph>
            Krajnji rok za realizaciju digitalne platforme mojbiz.me je 1. novembar 2023. godine. Platforma će biti razvijena prema ponudi koju je dostavilo preduzeće Robot code iz Podgorice.
            </Paragraph>
            <p><br /></p>
            <p><br /></p>
          </NewsColumn>
        </NewsText>
      </NewsSection>
    </>
  );
};

export default MojBizNews;
