import React from "react";
import {
  NewsSection,
  BackgroundImage,
  NewsText,
  Date,
  CategoryName,
  Title,
  Paragraph,
  NewsColumn,
} from "./styles";
import { FishingManBigger } from "../../assets/landingPage/landingPageAssets";
import MerProgram from "../../assets/news/MER-Program.png";
import Robotcode from "../../assets/news/Robotcode.png";
import Kotor from "../../assets/news/Kotor.png";
import { useTranslation } from "react-i18next";

const RobotCodeNews = () => {
  const { t } = useTranslation();
  

  return (
    <>
      <NewsSection>
        <BackgroundImage src={Robotcode} alt="news-image" />
        <NewsText>
          <NewsColumn>
            <div style={{ height: "41px" }}>
              <Date>4. avgust 2023.</Date>
            </div>
            <div style={{ height: "41px" }}>
              <CategoryName>Projekti</CategoryName>
            </div>
            <Title>Robot Code će razvijati platformu mojbiz.me</Title>
            <Paragraph>
            Pereduzeće Robot code d.o.o. iz Podgorice je danas potpisalo Ugovor o pružanju usluga iz oblasti informatike sa preduzećem Asenvirocon d.o.o. Kotor. Predmet ovog Ugovora su usluge  razvoja platforme za on-line kupovinu koja je osmišljena kao digitalni trgovački centar. Preduzeće Asenvirocon d.o.o. Kotor je sredstva za realizaciju Ugovora obezbijedilo kroz Ugovor o sufinansiranju sa Ministarstvom ekonomskog razvoja Crne Gore. Sredstva za sufinansiranje su obezbjeđena iz Programske linije za podršku digitalizaciji u okviru Programa za unapređenje konkurentnosti privrede za 2023.godinu.
            </Paragraph>
            <p><br /></p>
            <p><br /></p>
            <Paragraph>
            Platforma mojbiz.me će omogućiti korisnicima – mikro, malim i srednjim preduzećima da sami kreiraju svoju digitalnu prodavnicu. Korisnici će nakon dobijanja administratorskog naloga u administratorskom panelu kreirati liste proizvoda/ usluga, kategorisati svoje proizvode / usluge i opisivati proizvode / usluge setom definisanih (standardizovanih) atributa.
            </Paragraph>
            <p><br /></p>
            <p><br /></p>
            <Paragraph>
            Krajnji rok za realizaciju digitalne platforme mojbiz.me je 1. novembar 2023. godine. Platforma će biti razvijena prema ponudi koju je dostavilo preduzeće Robot code iz Podgorice.
            </Paragraph>
            <p><br /></p>
            <p><br /></p>
          </NewsColumn>
        </NewsText>
      </NewsSection>
    </>
  );
};

export default RobotCodeNews;
