import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {} from "../../../assets/landingPage/landingPageAssets";
import {} from "../../../styles/GlobalStyles";
import {
  AllCustomersBox,
  BlueTextSmaller,
  CustomerContainer,
  CustomerHeader,
  CustomersBoxFlex,
  CustomerSection,
} from "../styles";
import CreateNewShop from "./CreateNewShop";
import YourShop from "./YourShop";
import EditShop from "./EditShop";
import { useGlobalContext } from "../../../context/context";
import "../../DemoShop copy/stylesAllPages.scss";
import "../../DemoShop copy/ShopPage/shopStyles.scss";
import { useTranslation } from "react-i18next";

const ShopSection = ({ setSections, modalOpen }) => {
  const [isShop, setIsShop] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openCreateShop, setOpenCreateShop] = useState(false);
  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };

  const fileHandler = (e) => {
    console.log(123);
  };

  const { authKey, logUserOut, getDemoShopShop, yourShop } = useGlobalContext();

  useEffect(() => {
    getDemoShopShop();
  }, []);
  const { t } = useTranslation();

  return (
    <>
      <CustomerSection>
        <CustomerContainer id="container">
          <CustomerHeader>
            <BlueTextSmaller>{t("Shop")}</BlueTextSmaller>
            {/* ustvari ako je yourshop objekat prazan ... */}
            {/* {yourShop === undefined &&
              <>
                {openCreateShop ?
                  <>
                    <CreateCustomerGray>Create new</CreateCustomerGray>
                  </>
                  :
                  (
                    <>
                      <CreateCustomer onClick={() => setOpenCreateShop(true)}>Create new</CreateCustomer>
                    </>
                  )}
              </>
            } */}
          </CustomerHeader>

          <AllCustomersBox>
            <CustomersBoxFlex id="customersBox">
              {edit ? (
                <>
                  <EditShop edit={edit} setEdit={setEdit} />
                </>
              ) : (
                <>
                  <YourShop edit={edit} setEdit={setEdit} />
                </>
              )}
            </CustomersBoxFlex>
          </AllCustomersBox>
        </CustomerContainer>
      </CustomerSection>
    </>
  );
};

export default ShopSection;
