import styled, { keyframes } from "styled-components";
import Pattern1  from "../../../assets/landingPage/lightblueWeb_Business1.png";
import { FlexBetween, FlexCenter } from "../../../styles/GlobalStyles";

const FooterContainer = styled.div`
  background-color: #3853a3;
`;
const InfoContainer = styled(FlexBetween)`
  background-image: url(${Pattern1});
  padding: 54px 10% 54px 10%;
  background-size: 40%;

  @media only screen and (max-width: 730px) {
    background-size: 100%;
    padding-block: 0.25rem;
    padding-inline: 0;
    justify-content: space-around;
  }

  @media only screen and (min-width: 731px) and (max-width: 1024px) {
    padding: 0.25rem 2rem;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    padding: 0.25rem 2.75rem;
  }
`;
const BounceAnimation = keyframes`
    0% {transform:translateY(0);}
    15% {transform:translateY(-30px);}
    30% {transform:translateY(-30px);}
    80% {transform:translateY(-30px);}
    90% {transform:translateY(0);}
    97% {transform:translateY(-3px);}
    100% {transform:translateY(0);}
`;

const ScrollBox = styled.div`
  position: relative;
  border: 1px solid #ff9900;
  border-radius: 10px;
  width: 64px;
  height: 64px;
  transition: 1s all ease;
  background: ${(props) => (props.scrollHover ? "#FFA800" : "transparent")};

  @media only screen and (max-width: 480px) {
    width: 1.8rem;
    height: 1.8rem;
    margin-left: 0.25rem;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 2rem;
    height: 2rem;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    max-width: 2.25rem;
    max-height: 2.25rem;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    max-width: 2.5rem;
    max-height: 2.5rem;
  }
`;

const Scroll = styled.span`
    width: 104px;
    text-align-center;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-size: 1.35rem;
    line-height: 26px;
    color: #FFFFFF;
    animation-name:${(props) => (props.scrollHover ? BounceAnimation : "none")};
    animation-timing-function:ease-in;
    animation-duration:2s;
    animation-iteration-count:1;

    @media only screen and (max-width: 580px) {
      display: none;
    }

    @media only screen and (min-width: 581px) and (max-width: 785px) {
      font-size: .85rem;
      width: 70px;
    }

    @media only screen and (min-width: 786px) and (max-width: 1024px) {
      font-size: .85rem;
      width: 80px;
    }
    
    @media only screen and (min-width: 1025px) and (max-width: 1200px) {
      font-size: 1.25rem;
      padding-right: 10px;
      width: 90px;
    }
`;
const ScrollArrow = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 17px 9px;
  cursor: pointer;
  -webkit-filter: ${(props) =>
    props.scrollHover ? "brightness(0) invert(1)" : "none"};
  transition: 0.8s all ease;

  @media only screen and (max-width: 480px) {
    width: 95%;
    padding: 0;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 130%;
  }

  @media only screen and (min-width: 769px) and (max-width: 1200px) {
    width: 120%;
  }
`;
const Social = styled(FlexCenter)`
  border-top: 1px solid rgba(126, 148, 171, 0.2);
  padding: 19.5px 223px;

  @media only screen and (max-width: 480px) {
    padding: 1.25rem 1.75rem;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    padding: 1.25rem 1.75rem;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 1.5rem 2rem;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    padding: 1.5rem 2rem;
  }
`;

const Info = styled.div`
  display: flex;
  width: 100%;
  margin-left: 100px;
  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
  @media only screen and (min-width: 769px) and (max-width: 910px) {
    margin-left: 90px;
  }

`;
const InfoClasses = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 1.75rem;
  white-space: nowrap;

  @media only screen and (max-width: 414px) {
    width: 100%;
    white-space: nowrap;
    padding: 0.5rem;
  }

  @media only screen and (min-width: 415px) and (max-width: 480px) {
    width: 100%;
    white-space: nowrap;
    padding: 0.75rem;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 100%;
    white-space: nowrap;
    padding: 0.85rem;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 100%;
    white-space: nowrap;
    padding: 1.25rem;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 100%;
    white-space: nowrap;
    padding: 1.5rem;
  }
`;
const InfoClassHeader = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 26px;
  color: #ff9900;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 1.25rem;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    font-size: 1.5rem;
  }
`;
const InfoClassLink = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 46px;
  color: #ffffff;
  margin-bottom: 11px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    font-size: 0.75rem;
    line-height: 30px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 1.25rem;
    line-height: 36px;
  }
`;

const RightsReserved = styled.p`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #fff;

  @media only screen and (max-width: 480px) {
    font-size: 0.75rem;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    font-size: 0.85rem;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 1.15rem;
  }
`;
const SocialIcons = styled.div``;
const Icon = styled.img`
  margin-right: 10px;

  @media only screen and (max-width: 480px) {
    width: 18px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 22px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 24px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 26px;
  }
`;

export {
  RightsReserved,
  SocialIcons,
  Icon,
  FooterContainer,
  InfoContainer,
  Social,
  Scroll,
  ScrollArrow,
  Info,
  InfoClasses,
  InfoClassHeader,
  InfoClassLink,
  ScrollBox,
};
