import React, { useRef } from "react";
import { useState } from "react";
import {
  AvatarBlank,
  CustomerArrowUp,
  CustomerRealArrow,
  PaginationArrow,
  DashboardTrash,
  GrayArrow,
  PaginationArrowLeft,
  GrayArrowRight,
  BlueCross,
} from "../../../assets/landingPage/landingPageAssets";
import {
  BlueTextBold,
  BlueTextMedium,
  GrayTextMedium,
} from "../../../styles/GlobalStyles";
import {
  AllCustomersBox,
  Avatar,
  AvatarImg,
  AvatarText,
  BlueTextSmaller,
  CreateCustomer,
  CustomerArrow,
  CustomerBoxHeader,
  CustomerContainer,
  CustomerHeader,
  CustomersBoxFlex,
  CustomerSection,
  PaginationArrows,
  PaginationBox,
  CreateCustomerGray,
  CustomerFiltersBox,
  CustomerFilterButton,
  CustomerInputWrap,
  CustomerCross,
  CustomerInput,
} from "../styles";
import "../../DemoShop copy/stylesAllPages.scss";
import CreateNew from "./CreateNew";
import { useEffect } from "react";
import { useGlobalContext } from "../../../context/context";
import ReactPaginate from "react-paginate";
import SingleCategory from "./SingleCategory";
import { useTranslation } from "react-i18next";

const categoriesData = [
  { id: 1, number_of_occurrence: 1, name: "Riba", description: "" },
  { id: 2, number_of_occurrence: 3, name: "Školjke", description: "" },
  { id: 3, number_of_occurrence: 1, name: "Začini", description: "" },

];

const CustomersPage = ({
  sections,
  setSections,
  modalOpen,
  handleClose,
  open,
}) => {
  const [ascending, setAscending] = useState({
    name: false,
    products: false,
  });
  const [createNew, setCreateNew] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const customerRef = useRef(null);
  const [customerFocus, setCustomerFocus] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState("");
  const childrenNumberRef = useRef(null);

  const customerCrossHandler = () => {
    setCustomerFocus(false);
    setCategoryFilter("");
    customerRef.current.value = "";
  };

  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };

  useEffect(() => {
    if (categoryFilter) {
      setCurrentPage(0);
    }
    console.log(currentPage + "this is current page");
    console.log(pageCount + "this is page count");
  }, [createNew, categoryFilter]);
  const CategoriesListMaker = () => {
    let list = [];
    categoriesData.filter((item) => {
      if (item.name.toLowerCase().includes(categoryFilter.toLowerCase())) {
        list.push(item);
      }
      return list;
    });
    return list;
  };
  let itemsPerPage = 7;
  let pageCount = Math.ceil(CategoriesListMaker().length / itemsPerPage);
  let itemsVisited = currentPage * itemsPerPage;
  const pageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const ascendingHandler = (asc) => {
    let data = categoriesData;
    if (asc === "name") {
      setAscending({ ...ascending, name: !ascending.name });
      if (ascending.name) {
        data.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        data.sort((a, b) => b.name.localeCompare(a.name));
      }
    } else if (asc === "products") {
      setAscending({ ...ascending, products: !ascending.products });
      if (ascending.products) {
        data.sort((a, b) => a.number_of_occurrence - b.number_of_occurrence);
      } else {
        data.sort((a, b) => b.number_of_occurrence - a.number_of_occurrence);
      }
    }
  };

  const nextPage = () => {
    if (currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <CustomerSection>
        <CustomerContainer id="container">
          <CustomerHeader>
            <BlueTextSmaller>{t("Categories")}</BlueTextSmaller>
            {createNew ? (
              <></>
            ) : (
              <>
                <CreateCustomer onClick={() => setCreateNew(true)}>
                  {t("CreateNew")}{" "}
                  <div
                    style={{
                      fontFamily: "Jost",
                      fontSize: "20px",
                      display: "inline-block",
                      transform: "translate(2px, 1px)",
                    }}
                  >
                    +
                  </div>
                </CreateCustomer>
              </>
            )}
          </CustomerHeader>

          <AllCustomersBox>
            <CustomersBoxFlex id="customersBox">
              {createNew ? (
                <>
                  <CustomerBoxHeader>
                    {t("CreateNewCategory")}
                  </CustomerBoxHeader>
                  <CreateNew
                    setCreateNew={setCreateNew}
                    createNew={createNew}
                  />
                </>
              ) : (
                <>
                  <CustomerBoxHeader
                    style={{
                      width: "100%",
                      borderBottom: "1px solid rgba(126, 148, 171, 0.1)",
                    }}
                  >
                    {t("AllCategories")}
                  </CustomerBoxHeader>
                  <CustomerFiltersBox>
                    <CustomerInputWrap customerFocus={customerFocus}>
                      <CustomerCross
                        onClick={customerCrossHandler}
                        customerFocus={customerFocus}
                        src={BlueCross}
                        alt=""
                      />
                      <CustomerInput
                        onClick={() => setCustomerFocus(true)}
                        ref={customerRef}
                        placeholder={t("SearchCategoryByName")}
                        onChange={(e) => setCategoryFilter(e.target.value)}
                        customerFocus={customerFocus}
                      />
                    </CustomerInputWrap>
                  </CustomerFiltersBox>
                  <table id="table" className="table">
                    <tbody ref={childrenNumberRef}>
                      <tr className="categories-tr">
                        <td className="categories-td">
                          <div
                            className="categories-td-box"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h4
                              className="gray-text"
                              style={{ marginRight: "10px" }}
                            >
                              {t("CategoryName")}
                            </h4>
                            <div
                              className="arrowBox"
                              onClick={() => ascendingHandler("name")}
                            >
                              <CustomerArrow src={CustomerArrowUp} alt="" />
                              <img
                                src={CustomerRealArrow}
                                alt=""
                                className={
                                  ascending.name ? "arrow-drop" : "arrow"
                                }
                              />
                            </div>
                          </div>
                        </td>
                        <th>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h4
                              className="gray-text"
                              style={{ marginRight: "10px" }}
                            >
                              {t("NumberOfProducts")}
                            </h4>
                            <div
                              className="arrowBox"
                              onClick={() => ascendingHandler("products")}
                            >
                              <CustomerArrow src={CustomerArrowUp} alt="" />
                              <img
                                src={CustomerRealArrow}
                                alt=""
                                className={
                                  ascending.products ? "arrow-drop" : "arrow"
                                }
                              />
                            </div>
                          </div>
                        </th>
                        <th></th>
                      </tr>

                      {CategoriesListMaker()
                        .slice(itemsVisited, itemsVisited + itemsPerPage)
                        .map((item, index) => {
                          return <SingleCategory item={item} key={index} />;
                        })}
                    </tbody>
                  </table>
                  <PaginationBox>
                    <div className="pagination-elements">
                      <div className="pagination-arrow">
                        <img
                          alt=""
                          src={
                            currentPage === 0 ? GrayArrow : PaginationArrowLeft
                          }
                          onClick={previousPage}
                        />
                      </div>
                      <BlueTextMedium className="current-page">
                      {pageCount == 0 ? `${currentPage+1} / ${pageCount+1}` : `${currentPage+1} / ${pageCount}`}
                      </BlueTextMedium>
                      <div className="pagination-arrow">
                        <img
                          alt=""
                          src={
                            CategoriesListMaker().length - itemsVisited <= 7
                              ? GrayArrowRight
                              : PaginationArrow
                          }
                          onClick={nextPage}
                        />
                      </div>
                    </div>
                  </PaginationBox>
                </>
              )}
            </CustomersBoxFlex>
          </AllCustomersBox>
        </CustomerContainer>
      </CustomerSection>
    </>
  );
};

export default CustomersPage;
