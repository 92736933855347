import React, { useRef } from "react";
import { useState } from "react";
import {
  AvatarBlank,
  CustomerArrowUp,
  CustomerRealArrow,
  PaginationArrow,
  GrayArrow,
  PaginationArrowLeft,
  GrayArrowRight,
  BlueCross,
} from "../../../assets/landingPage/landingPageAssets";
import {
  BlueTextBold,
  BlueTextMedium,
  ButtonBlue,
  GrayTextMedium,
  GrayTextMediumBigger,
} from "../../../styles/GlobalStyles";
import {
  AllCustomersBox,
  Avatar,
  AvatarImg,
  AvatarText,
  BlueTextSmaller,
  CreateCustomer,
  CreateCustomerGray,
  CustomerArrow,
  CustomerBoxHeader,
  CustomerContainer,
  CustomerCross,
  CustomerFilterButton,
  CustomerFiltersBox,
  CustomerHeader,
  CustomerInput,
  CustomerInputWrap,
  CustomersBoxFlex,
  CustomerSection,
  PaginationArrows,
  PaginationBox,
} from "../styles";
import "./Productstyles.scss";
import "../stylesAllPages.scss";
import CreateNewProduct from "./CreateNewProduct";
import { useGlobalContext } from "../../../context/context";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import EditProduct from "./EditProduct";
import axios from "axios";
import SingleProduct from "./SingleProduct";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading";
import { useTranslation } from "react-i18next";

const ProductPage = ({ setSections, sections, open }) => {
  const [ascending, setAscending] = useState({
    title: false,
    category: false,
    availability: false,
    price: false,
  });

  const [customerFocus, setCustomerFocus] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [createNew, setCreateNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [productFilter, setProductFilter] = useState("");
  const [products, setProducts] = useState([]);
  const [loadProduct, setLoadProduct] = useState(true);
  const customerRef = useRef(null);
  const {
    closed,
    setClosed,
    setProductId,
    baseUrl,
    config,
    setModalRemoveId,
    removeString,
    setRemoveString,
    logUserOut,
    userDetails,
    getUserDetails,
    getDemoShopShop,
    getDemoShopCategories,
    categories,
    createProductMessage,
    yourShop,
    loadShop,
    productLoaded,
    setProductLoaded,
  } = useGlobalContext();
  const navigate = useNavigate();
  const customerCrossHandler = () => {
    setCustomerFocus(false);
    setProductFilter("");
    customerRef.current.value = "";
  };

  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };

  const getDemoShopProducts = async () => {
    try {
      const response = axios
        .get(`${baseUrl}/product`, config)
        .then((response) => {
          if (response.status === 200) {
            setProducts(response);
            setLoadProduct(false);
          }
        });
    } catch (error) {
      setLoadProduct(false);
      console.log(error);
    }
    setCurrentPage(0);
  };
  useEffect(() => {
    if (productFilter) {
      setCurrentPage(0);
    }
    setClosed(false);
    getUserDetails();
    getDemoShopShop();
    getDemoShopCategories();
    getDemoShopProducts();
  }, [
    closed,
    createNew,
    sections,
    createProductMessage,
    loadProduct,
    loadShop,
    productLoaded,
    productFilter,
    products.length,
  ]);
  const customersListMaker = () => {
    let list = [];
    
    products?.data?.data?.filter((item) => {
      if (item.name.toLowerCase().includes(productFilter.toLowerCase())) {
        list.push(item);
      }
      return list.sort((a,b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0);
    });
    return list;
  };
  let itemsPerPage = 7;
  let pageCount = Math.ceil(customersListMaker().length / itemsPerPage);
  let itemsVisited = currentPage * itemsPerPage;
  const pageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const editProductHandler = (id) => {
    setOpenEdit(true);
    setProductId(id);
  };

  const ascendingHandler = (asc) => {
    let data = products?.data.data;
    if (asc === "title") {
      setAscending({ ...ascending, title: !ascending.title });
    } else if (asc === "category") {
      setAscending({ ...ascending, category: !ascending.category });
    } else if (asc === "availability") {
      setAscending({ ...ascending, availability: !ascending.availability });
    } else if (asc === "price") {
      setAscending({ ...ascending, price: !ascending.price });
    }

    if (asc === "title") {
      if (ascending.title) {
        data.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        data.sort((a, b) => b.name.localeCompare(a.name));
      }
    } else if (asc === "price") {
      if (ascending.price) {
        data.sort((a, b) => a.price - b.price);
      } else {
        data.sort((a, b) => b.price - a.price);
      }
    } else if (asc === "category") {
      if (ascending.category) {
        data.sort((a, b) => a.category.name.localeCompare(b.category.name));
      } else {
        data.sort((a, b) => b.category.name.localeCompare(a.category.name));
      }
    } else if (asc === "availability") {
      if (ascending.availability) {
        data.sort((a, b) => a.out_of_stock - b.out_of_stock);
      } else {
        data.sort((a, b) => b.out_of_stock - a.out_of_stock);
      }
    }
  };

  const removeHandler = (string, id) => {
    open();
    setModalRemoveId(id);
    setRemoveString(string);
  };

  const LogOutHandler = () => {
    logUserOut();
    navigate("/");
  };
  const handleLimit = () => {
    setRemoveString("limit");
    open();
  };
  const nextPage = () => {
    if (currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const { t } = useTranslation();
  console.log(yourShop);
  return (
    <>
      <CustomerSection>
        <Avatar id="avatar" className="avatar">
          <AvatarText className="avatar-text">
            <BlueTextBold style={{ fontSize: "18px" }}>
              {userDetails?.email}
            </BlueTextBold>
            <div className="flex">
              <GrayTextMedium className="settings" onClick={settingsHandler}>
                {t("Profilesettings")}
              </GrayTextMedium>
              <GrayTextMedium className="log-out" onClick={LogOutHandler}>
                {t("LogOut")}
              </GrayTextMedium>
            </div>
          </AvatarText>
          {yourShop?.image && <AvatarImg src={yourShop?.image} alt="" />}{" "}
        </Avatar>
        <CustomerContainer id="container">
          <CustomerHeader>
            <BlueTextSmaller>{t("Products")}</BlueTextSmaller>
            {createNew ||
            openEdit ||
            categories?.data.length <= 0 ||
            yourShop?.id === null ? (
              <></>
            ) : (
              <>
                {!yourShop?.is_premium && products?.data?.data?.length == 4 ? (
                  <>
                    <CreateCustomer onClick={handleLimit}>
                      {t("CreateNew")}{" "}
                      <div
                        style={{
                          fontFamily: "Jost",
                          fontSize: "20px",
                          display: "inline-block",
                          transform: "translate(2px, 1px)",
                        }}
                      >
                        +
                      </div>
                    </CreateCustomer>
                  </>
                ) : (
                  <>
                    <CreateCustomer onClick={() => setCreateNew(true)}>
                      {t("CreateNew")}{" "}
                      <div
                        style={{
                          fontFamily: "Jost",
                          fontSize: "20px",
                          display: "inline-block",
                          transform: "translate(2px, 1px)",
                        }}
                      >
                        +
                      </div>
                    </CreateCustomer>
                  </>
                )}
              </>
            )}
          </CustomerHeader>

          <AllCustomersBox>
            <CustomersBoxFlex id="customersBox">
              {createNew ? (
                <>
                  <CustomerBoxHeader>{t("CreateProduct")}</CustomerBoxHeader>
                  <CreateNewProduct
                    setCreateNew={setCreateNew}
                    createNew={createNew}
                    open={open}
                    products={products}
                  />
                </>
              ) : openEdit ? (
                <>
                  <CustomerBoxHeader>{t("EditProduct")}</CustomerBoxHeader>
                  <EditProduct setOpenEdit={setOpenEdit} openEdit={openEdit} />
                </>
              ) : (
                <>
                  <CustomerBoxHeader>{t("AllProducts")}</CustomerBoxHeader>
                  {products?.data?.data?.length > 0 && (
                    <CustomerFiltersBox>
                      <CustomerInputWrap customerFocus={customerFocus}>
                        <CustomerCross
                          onClick={customerCrossHandler}
                          customerFocus={customerFocus}
                          src={BlueCross}
                          alt=""
                        />
                        <CustomerInput
                          ref={customerRef}
                          placeholder={t("SearchProductByName")}
                          onClick={() => setCustomerFocus(true)}
                          onChange={(e) => setProductFilter(e.target.value)}
                          customerFocus={customerFocus}
                        />
                      </CustomerInputWrap>
                    </CustomerFiltersBox>
                  )}
                  {products?.data?.data?.length > 0 && (
                    <div className="table_wrapper">
                      <table id="table" className="table">
                        {/* headers */}
                        <tbody>
                          <tr className="header">
                            <td width="25%">
                              <div className="td-box-border-left">
                                <h4
                                  className="gray-text"
                                  style={{ marginRight: "10px" }}
                                >
                                  {t("Title")}
                                </h4>
                                <div
                                  className="arrowBox"
                                  onClick={() => ascendingHandler("title")}
                                >
                                  <CustomerArrow src={CustomerArrowUp} alt="" />
                                  <img
                                    src={CustomerRealArrow}
                                    alt=""
                                    className={
                                      ascending.title ? "arrow-drop" : "arrow"
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <th width="15%" className="gray-text">
                              <div className="table-center">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <h4
                                    className="gray-text"
                                    style={{ marginRight: "10px" }}
                                  >
                                    {t("Category")}
                                  </h4>
                                  <div
                                    className="arrowBox"
                                    onClick={() => ascendingHandler("category")}
                                  >
                                    <CustomerArrow
                                      src={CustomerArrowUp}
                                      alt=""
                                    />
                                    <img
                                      src={CustomerRealArrow}
                                      alt=""
                                      className={
                                        ascending.category
                                          ? "arrow-drop"
                                          : "arrow"
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th width="15%" className="gray-text">
                              <div className="table-center">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <h4
                                    className="gray-text"
                                    style={{ marginRight: "10px" }}
                                  >
                                    {t("Availability")}
                                  </h4>
                                  <div
                                    className="arrowBox"
                                    onClick={() =>
                                      ascendingHandler("availability")
                                    }
                                  >
                                    <CustomerArrow
                                      src={CustomerArrowUp}
                                      alt=""
                                    />
                                    <img
                                      src={CustomerRealArrow}
                                      alt=""
                                      className={
                                        ascending.availability
                                          ? "arrow-drop"
                                          : "arrow"
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th width="15%" className="gray-text">
                              <div className="table-center">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <h4
                                    className="gray-text"
                                    style={{ marginRight: "10px" }}
                                  >
                                    {t("Price")}
                                  </h4>
                                  <div
                                    className="arrowBox"
                                    onClick={() => ascendingHandler("price")}
                                  >
                                    <CustomerArrow
                                      src={CustomerArrowUp}
                                      alt=""
                                    />
                                    <img
                                      src={CustomerRealArrow}
                                      alt=""
                                      className={
                                        ascending.price ? "arrow-drop" : "arrow"
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th width="30%"></th>
                          </tr>

                          {/* table */}
                          {customersListMaker()
                            .slice(itemsVisited, itemsVisited + itemsPerPage)
                            .map((item, index) => {
                              return (
                                <SingleProduct
                                  item={item}
                                  key={index}
                                  editProductHandler={editProductHandler}
                                  removeHandler={removeHandler}
                                />
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {loadProduct ? (
                    <div style={{ padding: "20px 0 0 30px" }}>
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {yourShop?.id === null ||
                      categories?.data?.length <= 0 ? (
                        <>
                          <div className="table-box-empty">
                            <GrayTextMediumBigger>
                              {t("ShopAndCategory")}
                            </GrayTextMediumBigger>
                          </div>
                        </>
                      ) : (
                        <>
                          {products?.data?.data?.length <= 0 && (
                            <div className="table-box-empty">
                              <GrayTextMediumBigger>
                                {t("NoProductsYet")}
                              </GrayTextMediumBigger>
                              <CreateCustomer
                                onClick={() => setCreateNew(true)}
                              >
                                {t("FirstProduct")}
                              </CreateCustomer>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <PaginationBox>
                    <div className="pagination-elements">
                      <div className="pagination-arrow">
                        <img
                          alt=""
                          src={
                            currentPage === 0 ? GrayArrow : PaginationArrowLeft
                          }
                          onClick={previousPage}
                        />
                      </div>
                      <BlueTextMedium className="current-page">
                      {pageCount == 0 ? `${currentPage+1} / ${pageCount+1}` : `${currentPage+1} / ${pageCount}`}
                      </BlueTextMedium>
                      <div className="pagination-arrow">
                        <img
                          alt=""
                          src={
                            customersListMaker().length - itemsVisited <= 7
                              ? GrayArrowRight
                              : PaginationArrow
                          }
                          onClick={nextPage}
                        />
                      </div>
                    </div>
                  </PaginationBox>
                </>
              )}
            </CustomersBoxFlex>
          </AllCustomersBox>
        </CustomerContainer>
      </CustomerSection>
    </>
  );
};

export default ProductPage;
