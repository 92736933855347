import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";

const Auth = () => {
  const [isSignUp, setisSignUp] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    checkbox: "",
  });
  const handleSignUp = (e) => {
    e.preventDefault();
    setisSignUp(!isSignUp);
  };
  const handleSubmit = (e) => {};
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className="auth-container mt-80 bg-gray-400">
        <h2 className="sign-header">{isSignUp ? "Sign up" : "Sign in"}</h2>
        <form
          className="border-2 border-black w-1/4"
          action=""
          onSubmit={handleSubmit}
        >
          {isSignUp && (
            <>
              <label htmlFor="firstName">Name</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                onChange={handleChange}
              />
              <br />
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                onChange={handleChange}
              />
              <br />
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="normal"
                  name="checkbox"
                  value="normal"
                  onChange={handleChange}
                />
                <label htmlFor="normal">Normal</label>
                <input
                  type="checkbox"
                  id="fisherman"
                  name="checkbox"
                  value="fisherman"
                  onChange={handleChange}
                />
                <label htmlFor="fisherman">Fisherman</label>
                <input
                  type="checkbox"
                  id="booking"
                  name="checkbox"
                  value="booking"
                  onChange={handleChange}
                />
                <label htmlFor="booking">Booking</label>
              </div>
            </>
          )}
          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="email" onChange={handleChange} />
          <br />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            onChange={handleChange}
          />
          <br />
          {isSignUp && (
            <>
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="text"
                name="confirmPassword"
                id="confirmPassword"
                onChange={handleChange}
              />
            </>
          )}
          <br />
          <button type="submit">{isSignUp ? "Sign up" : "Sign in"}</button>
          {/* <GoogleLogin 
                    render={(renderProps) => (
                        <><button onClick={renderProps.onClick}>Google Sign In</button></>
                    )}
                    onSuccess={googleSuccess}
                    onFailure={googleFailure}
                    cookiePolicy="single_host_origin"
                /> */}
          <br />
          <button onClick={handleSignUp}>
            {isSignUp
              ? "Already have an account? Sign in"
              : "Don't have an account? Sign in"}
          </button>
        </form>
      </div>
    </>
  );
};

export default Auth;
