import React, { useEffect } from "react";
import Footer from "../../components/LandingPage/Footer/Footer";
import Contact from "../../components/CreateShopPage/ContactUs/ContactUs";
import Faq from "../../components/CreateShopPage/Faq/Faq";
import { useGlobalContext } from "../../context/context";

const ContactUs = () => {
  const { shopId, setShopId } = useGlobalContext();
  useEffect(() => {
    setShopId(3);
  });
  return (
    <>
      <Faq />
      <Contact />
      <Footer style={{position: "absolute", bottom: 0}}/>
    </>
  );
};

export default ContactUs;
