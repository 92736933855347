import styled from "styled-components";
import { BlueTextMedium, GrayTextMedium } from "../../../styles/GlobalStyles";

const FaqSection = styled.section`
  // background:#EEF0F7;
  height: 834px;
`;
const FaqHeader = styled(BlueTextMedium)`
  font-size: 3rem;
  line-height: 80px;
  margin-top: 50px;
  text-align: center;
  display: block;
  padding-top: 23.98px;
`;
const FaqList = styled.ol`
  height: 570px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    position: absolute;
    background: #3853a3;
    border-radius: 30px;
    width: 17px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #ffa800;
    height: 50px;
  }
`;
const FaqText = styled.div`
  padding: 22px 48px 19px 70px;
  width: 100%;

  @media only screen and (max-width: 480px) {
    padding: 11px 24px 9px 34px;
  }
`;

const FaqBox = styled.div`
  margin-left: 20px;
  margin-bottom: 12px;
  
  width: 1264px;
  transition: 0.5s all ease;
  border-radius: 30px;
  &:hover {
    background: #ffffff;
    box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
    border-radius: 30px;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    min-height: 200px;
    margin-left: 0;
  }

  @media only screen and (min-width: 481px) and (max-width: 580px) {
    width: 100%;
    min-height: 250px;
  }

  @media only screen and (min-width: 581px) and (max-width: 768px) {
    width: 100%;
    min-height: 200px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1400px) {
    width: 100%;
    min-height: 200px;
  }
`;
const FaqBlueText = styled.li`
  max-width: 85%;
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  // line-height: 60px;
  color: #3853a3;
  padding-bottom: 10px;

  @media only screen and (max-width: 480px) {
    font-size: 16px;
    width: 100%;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1400px) {
    font-size: 16px;
  }
`;

const FaqLiParagraph = styled(GrayTextMedium)`
  max-width: 85%;
  font-size: 25px;
  line-height: 30px;
  margin-top: 10px;

  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1400px) {
    font-size: 16px;
  }
`;
const After = styled.div`
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 1264px;
    z-index: 2;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    &:hover {
      display: none;
    }

    @media only screen and (max-width: 1400px) {
      width: 100%;
    }
  }
`;
const FaqContainer = styled.div`
  margin: auto;
  width: 1367px;
  height: 650px;

  @media only screen and (max-width: 1400px) {
    width: 90%;
  }
`;

export {
  FaqText,
  FaqHeader,
  After,
  FaqBlueText,
  FaqSection,
  FaqList,
  FaqLiParagraph,
  FaqBox,
  FaqContainer,
};
