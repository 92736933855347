import styled from "styled-components";
import { ButtonTransparentYellow } from "../../../styles/GlobalStyles";

const RecommendedSection = styled.section`
  width: 100%;

  @media only screen and (max-width: 450px) {
    margin: 2rem 0;
  }
`;

const RecommendedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const RecommendedBox = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 46px;
  margin: auto;
  overflow: hidden;
`;
const ShopButton = styled(ButtonTransparentYellow)`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  background: #ffa80014;
  border: 1px solid #ffa800;
  &:hover {
    background: #ffa800;
    border-radius: 100px;
    color: #fff;
    cursor: pointer;
    outline: none;
    font-family: "Jost";
    font-style: normal;
    border: 1px solid #ffa800;
    font-weight: 400;
  }
  @media (max-width: 1024px) {
    padding: 5px 15px;
    font-size: 14px;
  }
`;

const ImageBox = styled.div`
  margin-bottom: 20px;
  height: 250px;
  width: 250px;
  border-radius: 24px;
  position: relative;
  mix-blend-mode: luminosity;
  &::after {
    content: "";
    left: 0;r
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background: #d9d9d9;
    border-radius: 24px;
    opacity: .25;
    transition: 0.5s all ease;
  }
  &:hover::after {
    opacity: 0;
    transition: 0.5s all ease;
  }

  @media (max-width: 1600px) {
    height: 210px;
    width: 200px;
  }

  @media only screen and (max-width:450px){
    &::after {
      content: "";
      left: 0;r
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background: #d9d9d9;
      border-radius: 24px;
      opacity: 0;
      transition: 0.5s all ease;
    }
  }
 `;

const ShopImage = styled.img`
  border-radius: 24px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: optimizeQuality;
`;

const ArrowLeft = styled.img`
  width: 52px;
  height: 39px;
  margin: 0 23px 0 125px;
  cursor: pointer;
`;
const ArrowRight = styled.img`
  width: 52px;
  height: 39px;
  margin: 0 125px 0 23px;
  cursor: pointer;
`;
const RecommendedHeader = styled.h1`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 180px;
  text-align: center;
  color: #3853a3;
  @media only screen and (max-width: 768px) {
    font-size: 3rem;
  }
  @media only screen and (max-width: 450px) {
    margin-bottom: 20px;
    line-height: normal;
  }
`;

const Header = styled.h2`
  margin-bottom: 10px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #ffa800;
`;
const Description = styled.p`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7e94ab;
  margin-bottom: 25px;
  text-align: center;
  width: 100%;
  word-wrap: break-word;
`;

const ArrowBox = styled.div`
  transform: translateY(-270%);
  position: relative;
  width: 72px;
  &:hover {
    transform: translateX(20px);
  }
`;

const ShopBox = styled.div`
  display: flex;
  width: 100%;
  z-index: 2;
  position: relative;
  justify-content: ${(props) => (props.length <= 5 ? "center" : "flex-start")};
  @media only screen and (max-width: 450px) {
    justify-content: flex-start;
  }
`;

const SingleShop = styled.div`
  cursor: pointer;
  padding: 0 25px;
  transition: 0.5s all ease;
  @media only screen and (max-width: 450px) {
    padding: 0 5px;
  }
  &:hover {
    // transform: scale(1.05);
    transition: 0.5s all ease;
    ${ShopButton} {
      background: #ffa800;
      border-radius: 100px;
      color: #fff;
      cursor: pointer;
      outline: none;
      font-family: "Jost";
      font-style: normal;
      border: 1px solid #ffa800;
      font-weight: 400;
    }
    ${ImageBox} {
      &:after {
        opacity: 0;
        transition: 0.5s all ease;
      }
    }
  }
`;

export {
  ShopBox,
  ArrowBox,
  RecommendedBox,
  ShopButton,
  SingleShop,
  ImageBox,
  ShopImage,
  Header,
  Description,
  RecommendedHeader,
  ArrowRight,
  ArrowLeft,
  RecommendedSection,
  RecommendedContainer,
};
