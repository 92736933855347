import React from "react";
import { useState } from "react";
import {
  DashboardEdit,
  DashboardTrash,
  OrangeTrash,
} from "../../../assets/landingPage/landingPageAssets";

const SingleCategory = ({ item }) => {
  const [trashHovered, setTrashHovered] = useState(false);
  return (
    <tr className="categories-tr">
      <td className="gray-text categories-td">
        <div className="categories-td-box">{item.name}</div>
      </td>
      <td className="gray-text td-category">{item?.number_of_occurrence}</td>
      <td>
        <div
          className="trash-btn-categories"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            
          }}
        >
          {item?.number_of_occurrence > 0 ? (
            <button className="trash-btn-disabled">
              <div className="trash-btn-content">
                <img src={DashboardTrash} alt="" />
                <h4 className="trash-text">Remove</h4>
              </div>
            </button>
          ) : (
            <button
              className="trash-btn"
              onMouseOver={() => setTrashHovered(true)}
              onMouseOut={() => setTrashHovered(false)}
            >
              <div className="trash-btn-content">
                <img
                  src={DashboardTrash}
                  alt=""
                  style={trashHovered ? { opacity: 0 } : { opacity: 1 }}
                />
                <img
                  src={OrangeTrash}
                  alt=""
                  className="orange-trash"
                  style={trashHovered ? { opacity: 1 } : { opacity: 0 }}
                />
                <h4 className="trash-text">Remove</h4>
              </div>
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default SingleCategory;
