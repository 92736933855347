import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  SingleShop,
  ImageBox,
  ShopImage,
  Header,
  Description,
  ShopButton,
} from "./style";
import { FlexColumn } from "../../../styles/GlobalStyles";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../../context/context";

const SingleShopComponent = ({ item, id }) => {
  const [hoverId, setHoverId] = useState("");

  const { shopId } = useGlobalContext();
  useEffect(() => {}, []);
  const { t } = useTranslation();

  const description =
    item.description.length > 250
      ? item.description.substring(0, 250) + "..."
      : item.description;

  return (
    <>
      <SingleShop className={`single-shop-box`}>
        <div className="column-single-box">
          <Link
            style={{ textDecoration: "none" }}
            to={`/shop/${item.id}`}
            onClick={() => localStorage.setItem("shopId", item.id)}
          >
            <ImageBox className="recom-image-box">
              <ShopImage src={item.image} />
            </ImageBox>
          </Link>
          <Header>{item.name}</Header>
          <Description>{description}</Description>
          <Link
            style={{ textDecoration: "none" }}
            to={`/shop/${item.id}`}
            onClick={() => localStorage.setItem("shopId", item.id)}
          >
            <ShopButton className="recom-shop-button">
              {t("Visitnow")}{" "}
            </ShopButton>
          </Link>
        </div>
      </SingleShop>
    </>
  );
};

export default SingleShopComponent;
