import styled, { keyframes } from "styled-components";
import { ButtonTransparentWhite } from "../../../styles/GlobalStyles";
import Pattern1 from "../../../assets/landingPage/lightblueWeb_Business1.png";
import { Link } from "react-router-dom";

const HomeDemoShopBtn = styled(ButtonTransparentWhite)`
  padding: 21.9px 39.5px;
  margin-top: 100px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 37px;
  // color:#D8D6DB
`;

const HomeContainer = styled.section`
  width: 100%;
  height: 900px;
  background-image: url(${Pattern1});
  background-color: #3853a3;
  background-size: 45%;
  
  @media only screen and (max-width: 480px) {
    height: 800px;
    background-size: 100%;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    height: 750px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    height: 850px;
  }
`;

const ScrollBounce = keyframes`
    0% {transform:translateY(0);}
    10% {transform:translateY(-10px);}
    12% {transform:translateY(40px);}
    14% {transform:translateY(26px);}
    16% {transform:translateY(32px);}
    18% {transform:translateY(30px);}
    70% {transform:translateY(30px);}
    75% {transform:translateY(-6);}
    80% {transform:translateY(0);}
    100% {transform:translateY(0);}
`;
const ScrollOpacity = keyframes`
    0% {opacity: 0;}
    5% {opacity: 0;}
    10% {opacity: 0;}
    18% {opacity: 1;}
    67% {opacity: 1;}
    73% {opacity: 0;}
    80% {opacity: 0}
    100% {opacity: 0}
`;
const ScrollBackIn = keyframes`
    0% {transform:translateY(0);}
    50% {transform:translateY(0);}
    100% {transform:translateY(0);}
`;

const DownArrow = styled.img`
  width: 46px;
  height: 29.2px;
  cursor: pointer;
  transition: 1s all ease;
  animation-name: ${(props) => (props.scroll ? ScrollBounce : ScrollBackIn)};
  animation-duration: 7s;
  animation-iteration-count: infinite;
`;
const ScrollDownText = styled.span`
  opacity: 0;
  top: -20px;
  position: absolute;
  display: block;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 27px;
  color: #fff;
  transition: 0.8s all ease;
  animation-name: ${(props) => (props.scroll ? ScrollOpacity : "none")};
  animation-duration: 7s;
  animation-iteration-count: infinite;
`;
const ScrollAnimate = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
`;

const HomeContent = styled.div`
  width: 100%;
`;
const HomeHeader = styled.h1`
  text-align: center;
  margin-bottom: 100px;
  margin-top: 100px;
  @media only screen and (max-width: 480px) {
    padding-top: 0px;
    margin-bottom: 120px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    padding-top: 0px;
    margin-bottom: 120px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1200px) {
    padding-top: 0px;
    margin-bottom: 120px;
  }
`;
const Fall = keyframes`
    100% {
        transform:translateY(0)
    }
`;
const Word = styled.span`
  font-family: "Jost";
  font-size: 100px;
  line-height: 140px;
  font-weight: 400;
  color: #fff;
  animation: fall 3.5s forwards;
  display: inline-block;
  margin-right: 1.5rem;
  &:first-child {
    transform: translateY(-205px);
    animation: ${Fall} 1.8s forwards;
  }
  &:nth-child(2) {
    transform: translateY(-135px);
    animation: ${Fall} 1.8s forwards;
  }
  &:nth-child(3) {
    transform: translateY(-205px);
    animation: ${Fall} 1.8s forwards;
  }
  &:nth-child(4) {
    transform: translateY(-205px);
    animation: ${Fall} 1.8s forwards;
  }
  &:nth-child(5) {
    transform: translateY(-135px);
    animation: ${Fall} 1.8s forwards;
  }
  &:nth-child(6) {
    transform: translateY(-90px);
    animation: ${Fall} 1.8s forwards;
    margin-right: 0rem;
  }
  @media (max-width: 480px) {
    font-size: 50px;
    line-height: 80px;
    margin-inline: 10px;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    font-size: 60px;
    line-height: 80px;
    margin-inline: 12px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 80px;
    line-height: 100px;
    margin-inline: 20px;
  }
`;

const SearchBar = styled.form`
  margin: 0 0 100px 0;
  width: 654px;
  height: 54px;
  background: #ffffff;
  border: 2px solid #3853a3;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s all ease;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 2px solid transparent;
  z-index: 4;
  filter: ${(props) =>
    props.focused ? "drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.25))" : "none"};
  @media only screen and (max-width: 480px) {
    width: 320px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 360px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 560px;
  }
`;

const SearchBarShop = styled.form`
  margin: 0 0 100px 0;
  width: 654px;
  height: 54px;
  background: #ffffff;
  border: 2px solid #3853a3;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s all ease;
  position: relative;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border: 2px solid transparent;
  z-index: 4;
  filter: ${(props) =>
    props.focused ? "drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.25))" : "none"};
  @media only screen and (max-width: 480px) {
    width: 320px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 360px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 560px;
  }
`;
const DropDownMenu = styled.div`
  position: absolute;
  top: 32px;
  right: 2px;
  width: 172px;
  background: #ffa800;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
  transition: 0.5s all ease;
  opacity: ${(props) => (props.openShops ? "1" : "0")};
  padding-bottom: 10px;
`;
const DropDownUl = styled.ul`
  text-decoration: none;
  list-style: none;
  padding-top: 35px;
`;
const DropDownLi = styled(Link)`
  width: 100%;
  &:hover {
    background: #fff;
  }
`;
const DropDownLink = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: #fff;
  text-decoration: none;
  display: block;
  margin-left: 19px;
  cursor: pointer;
  &:hover {
    color: #3853a3;
  }
`;

const SearchButton = styled.img`
  width: 19.8px;
  height: 19.8px;
  margin-left: 24.6px;
  margin-right: 14.5px;
  transform: translateY(2px);
  cursor: pointer;
`;
const InputWrap = styled.div`
  border-right: 1px solid rgba(126, 148, 171, 0.2);
  padding-right: 25px;
  width: 420px;
  position: relative;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 360px;
  }
`;
const Cross = styled.img`
  width: 28px;
  height: 28px;
  position: absolute;
  right: 11px;
  cursor: pointer;
  opacity: ${(props) => (props.focused ? "1" : "0")};
  transition: 0.5s all ease;
  z-index: 6;
`;
const SearchInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #3853a3;
  cursor: pointer;
  &::placeholder {
    transition: 0.5s all ease;
    color: ${(props) => (props.focused ? "#fff" : "#3853A3")};
  }
`;
const SearchBox = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  text-align: center;
  @media only screen and (max-width: 480px) {
    width: 33%;
    margin-right: 5px;
    // margin-top: 10px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 33%;
    margin-right: 5px;
    margin-top: 5px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 33%;
    margin-right: 5px;
    margin-top: 5px;
  }
`;
const OurShops = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #3853a3;
  margin: 0 18.7px 0 19.3px;
  @media only screen and (max-width: 480px) {
    margin: 5px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin: 0;
    line-height: 6px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    margin: 0;
    line-height: 6px;
  }
`;
const ShopsArrow = styled.img`
  margin-right: 29.8px;
  transform: ${(props) => (props.openShops ? "rotate(180deg)" : "none")};
  transition: 0.3s all ease;
  @media only screen and (max-width: 480px) {
    margin: 0;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-right: 0;
    margin-left: 6px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    margin: 0 0 2.4px 8px;
  }
`;

export {
  SearchBarShop,
  ScrollAnimate,
  ScrollDownText,
  DropDownMenu,
  DropDownUl,
  DropDownLi,
  DropDownLink,
  ShopsArrow,
  Cross,
  InputWrap,
  OurShops,
  SearchButton,
  SearchInput,
  SearchBox,
  SearchBar,
  HomeDemoShopBtn,
  HomeContainer,
  DownArrow,
  HomeContent,
  HomeHeader,
  Word,
};
