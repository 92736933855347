import React, { useState, useEffect } from "react";
import { RecommendedHeader, RecommendedSection, ShopBox } from "./style";
import { useTranslation } from "react-i18next";

import {
  SliderBox,
  SliderContainer,
  ArrowLeft,
  ArrowRight,
} from "../../../styles/GlobalStyles";
import {
  ArrowYellowLeft,
  ArrowYellowRight,
} from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import "./recommendedShops.scss";
import SingleShopComponent from "./SingleShopComponent";

const RecommendedShops = () => {
  let screenWidth = window.screen.width;
  const [slide, setSlide] = useState(0);
  const { shops, fetchAllShops } = useGlobalContext();
  let numberOfSlides = Math.floor((shops?.length - 1) / 5);
  let numberOfSlidesSmall = Math.floor((shops?.length - 1) / 1);

  const { t } = useTranslation();

  useEffect(() => {
    fetchAllShops();
  }, []);

  const handleClick = (direction) => {
    if (direction === "right") {
      if (slide === numberOfSlides) {
        setSlide(0);
      } else {
        setSlide(slide + 1);
      }
    } else if (direction === "left") {
      if (slide === 0) {
        setSlide(0);
      } else {
        setSlide(slide - 1);
      }
    } else if (direction === "right-small") {
      if (slide === numberOfSlidesSmall) {
        setSlide(numberOfSlides);
      } else {
        setSlide(slide + 1);
      }
    } else if (direction === "left-small") {
      if (slide === 0) {
        setSlide(0);
      } else {
        setSlide(slide - 1);
      }
    }
  };
  return (
    <RecommendedSection id="recommend">
      <RecommendedHeader id="container">{t("MOJBIZ Shops")}</RecommendedHeader>
      <SliderContainer>
        <div className="arrow-box-left">
          <ArrowLeft
            src={ArrowYellowLeft}
            id="left"
            onClick={() => handleClick("left")}
            style={
              slide === 0
                ? { opacity: "0", transition: ".7 all ease" }
                : { opacity: "1", transition: ".7 all ease" }
            }
          />
        </div>
        <div
          className="smaller-view-arrow left"
          onClick={() => handleClick("left-small")}
        >
          <img src={ArrowYellowLeft} alt="" />
        </div>
        <SliderBox className="opacity-left" id="recombox">
          <ShopBox
            length={shops?.length}
            style={
              screenWidth <= 450
                ? {
                    transform: `translateX(${slide * -260}px)`,
                    transition: "2s all ease",
                  }
                : {
                    transform: `translateX(${slide * -1500}px)`,
                    transition: "2s all ease",
                  }
            }
          >
            {shops ? (
              shops
                .slice()
                .reverse()
                .map((item) => {
                  return (
                    <>
                      <SingleShopComponent
                        item={item}
                        id={item.id}
                        key={item.id}
                      />
                    </>
                  );
                })
            ) : (
              <div className="loading-spinner-box">
                <div className="loading-spinner-gray-recom-shops"></div>
              </div>
            )}
          </ShopBox>
        </SliderBox>
        {shops && (
          <div className="arrow-box-right">
            <ArrowRight
              src={ArrowYellowRight}
              id="left"
              onClick={() => handleClick("right")}
              // style={
              //   slide === numberOfSlides && screenWidth > 500
              //     ? { opacity: "0", transition: ".7 all ease" }
              //     : { opacity: "1", transition: ".7 all ease" }
              // }
            />
          </div>
        )}
        <div
          className="smaller-view-arrow right"
          onClick={() => handleClick("right-small")}
        >
          <img src={ArrowYellowRight} alt="" />
        </div>
      </SliderContainer>
    </RecommendedSection>
  );
};

export default RecommendedShops;
