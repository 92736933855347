import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ArrowDown,
  Search,
  DropdownArrow,
  CrossBtn,
} from "../../../assets/landingPage/landingPageAssets";
import {
  SearchInput,
  SearchBox,
  OurShops,
  SearchBar,
  SearchButton,
  InputWrap,
  ShopsArrow,
  Word,
  DropDownMenu,
  DropDownUl,
  DropDownLi,
  DropDownLink,
} from "./styles";
import {
  HomeContainer,
  HomeHeader,
  DownArrow,
  HomeContent,
  Cross,
  ScrollDownText,
  ScrollAnimate,
} from "./styles";
import { FlexColumn, GrayTextMedium } from "../../../styles/GlobalStyles";
import axios from "axios";
import { useGlobalContext } from "../../../context/context";
import "./home.scss";
import { Link, useLocation } from "react-router-dom";

const Home = () => {
  const [focused, setFocused] = useState(false);
  const [openShops, setOpenShops] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [formText, setFormText] = useState("");
  const [searchedShops, setSearchedShops] = useState();
  const { config, baseUrl, shopId, setShopId } = useGlobalContext();

  const crossHandler = () => {
    setFormText("");
    setFocused(false);
  };
  const openShopsHandler = () => {
    setFormText("");
    setOpenShops(!openShops);
  };
  const { t } = useTranslation();

  useEffect(() => {
    try {
      const response = axios
        .post(`${baseUrl}/shop/search`, { text: formText })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setSearchedShops(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
    if (formText) {
      setOpenShops(false);
    }
  }, [formText]);
  return (
    <HomeContainer>
      <HomeContent>
        <FlexColumn>
          <HomeHeader>
            <Word>MojBizNexus</Word>
            <br />
            <Word>{t("shops")}</Word>
          </HomeHeader>
          <div style={{ position: "relative" }}>
            <DropDownMenu openShops={openShops}>
              <DropDownUl>
                {searchedShops?.length < 5 ? (
                  <>
                    {searchedShops?.map((item) => {
                      return (
                        <>
                          <DropDownLi
                            to={`/shop/${item.id}`}
                            id="3"
                            style={{
                              textDecoration: "none",
                            }}
                          >
                            <DropDownLink
                              onClick={() =>
                                localStorage.setItem("shopId", item.id)
                              }
                            >
                              {item.name}
                            </DropDownLink>
                          </DropDownLi>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {searchedShops?.slice(1, 5).map((item, index) => {
                      return (
                        <DropDownLi
                          to={`/shop/${item.id}`}
                          key={index}
                          style={{ textDecoration: "none" }}
                        >
                          <DropDownLink>{item.name}</DropDownLink>
                        </DropDownLi>
                      );
                    })}
                  </>
                )}
              </DropDownUl>
            </DropDownMenu>
            <SearchBar focused={focused} id="3">
              <div style={{ display: "flex" }}>
                <SearchButton src={Search} alt="Search" />
                <InputWrap id="3">
                  <SearchInput
                    focused={focused}
                    placeholder={t("Search")}
                    onFocus={() => setFocused(true)}
                    onChange={(e) => setFormText(e.target.value)}
                    value={formText}
                  />
                  <Cross
                    focused={focused}
                    src={CrossBtn}
                    alt=""
                    onClick={crossHandler}
                  />
                </InputWrap>
              </div>
              <SearchBox id="search-box">
                <OurShops>{t("OurShops")}</OurShops>
              </SearchBox>
            </SearchBar>
            <div
              style={
                searchedShops?.length > 0 && formText
                  ? { display: "block" }
                  : { display: "none" }
              }
              className="home-dropdown-box"
            >
              <ul>
                {searchedShops?.map((item, index) => (
                  <Link
                    key={index}
                    style={{ textDecoration: "none" }}
                    to={`/shop/${item.id}`}
                  >
                    <li onClick={() => localStorage.setItem("shopId", item.id)}>
                      <span style={{ textDecoration: "none" }}>
                        {item.name}
                      </span>
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          </div>
          <a
            href="#recommend"
            style={
              formText.length || openShops
                ? {
                    opacity: "0",
                    textDecoration: "none",
                    transition: ".5s all ease",
                  }
                : {
                    textDecoration: "none",
                    opacity: "1",
                    transition: ".5s all ease",
                  }
            }
          >
            <ScrollAnimate
              id="3"
              onMouseOver={() => setScroll(true)}
              onMouseOut={() => setScroll(false)}
            >
              <ScrollDownText scroll={scroll}>
                {t("Scroll down")}
              </ScrollDownText>
              <DownArrow scroll={scroll} src={ArrowDown} alt="" />
            </ScrollAnimate>
          </a>
        </FlexColumn>
      </HomeContent>
    </HomeContainer>
  );
};

export default Home;
