import React from "react";
import { useState } from "react";
import {
  BlueCross,
  CrossBtn,
  EmptyImage,
  UploadImage,
} from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import {
  ButtonBlue,
  GrayTextMedium,
  GrayTextMediumBigger,
} from "../../../styles/GlobalStyles";
import { CustomerBoxHeader, CustomerBoxHeaderNoBorder } from "../styles";
import FileBase from "react-file-base64";
import "./shopStyles.scss";
import { useEffect } from "react";
import {
  InputWrap,
  PhoneNumberCross,
  PhoneNumberPlaceholder,
  PhoneNumberPlaceholderBOx,
} from "../../../pages/CheckoutPage/CheckoutInputs";
import PhoneInput from "react-phone-number-input";
import { useTranslation } from "react-i18next";

const CreateNewShop = ({ setOpenCreateShop, openCreateShop }) => {
  const [shopNameFocused, setShopNameFocused] = useState(false);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [descriptionFocused, setDescriptionFocused] = useState(false);
  const [shopImage, setShopImage] = useState();
  const [selectedPhone, setSelectedPhone] = useState();
  const [message, setMessage] = useState("");
  const [clicked, setClicked] = useState(false);
  const {
    createNewShop,
    setCreateNewShop,
    postCreateShop,
    setReload,
    loadShop,
    setLoadShop,
    setShopMessage,
    shopMessage,
  } = useGlobalContext();
  const SubmitShopHandler = async (e) => {
    e.preventDefault();
    if (
      createNewShop.image !== null &&
      createNewShop.image !== undefined &&
      createNewShop.image.length &&
      createNewShop.name.length &&
      createNewShop.description.length
    ) {
      setLoadShop(true);
      setClicked(true);
      postCreateShop();
    } else {
      setClicked(true);
      setShopMessage("Please fill all the fields correctly.");
    }
    setTimeout(() => {
      // setOpenCreateShop(false);
    }, 2000);
    setTimeout(() => {
      setShopMessage("");
      setClicked(false);
    }, 7000);
    setTimeout(() => {
      setLoadShop(false);
    }, 2000);
    setReload(true);
  };
  // cross handlers
  const shopNameCrossHandler = () => {
    setShopNameFocused(false);
    setCreateNewShop({ ...createNewShop, name: "" });
  };
  const phoneCrossHandler = (e) => {
    console.log(e.target);
    setPhoneFocused(false);
    setCreateNewShop({ ...createNewShop, phone_number: "" });
    setSelectedPhone("");
  };
  const descriptionCrossHandler = () => {
    setDescriptionFocused(false);
    setCreateNewShop({ ...createNewShop, description: "" });
  };

  useEffect(() => {
    setCreateNewShop({
      name: "",
      description: "",
      image: "",
      is_premium: false,
      phone_number: "",
    });
    if (shopImage) {
      setCreateNewShop({
        ...createNewShop,
        image: shopImage.split("base64,")[1],
      });
    }
  }, [shopImage]);
  const shopImageHandler = (file) => {
    setShopImage(file.base64);
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="content">
        <CustomerBoxHeaderNoBorder>{t("CreateShop")}</CustomerBoxHeaderNoBorder>
        <div className="box">
          {loadShop ? (
            <>
              <div className="shop-image-box-loading">
                <img src={shopImage ? shopImage : EmptyImage} alt="" />
                <div className="text">
                  <label>
                    <div className="loading-spinner-smaller"></div>
                  </label>
                </div>
                <h3 className="create-box-response">
                  {clicked ? (
                    <GrayTextMediumBigger>{shopMessage}</GrayTextMediumBigger>
                  ) : (
                    <></>
                  )}
                </h3>
              </div>
            </>
          ) : (
            <>
              <div className="shop-image-box">
                <img src={shopImage ? shopImage : EmptyImage} alt="" />
                <div className="text">
                  <label>
                    <FileBase
                      type="file"
                      onDone={shopImageHandler.bind(this)}
                    />
                    {t("ChangeImage")}
                  </label>
                </div>
                <h3 className="create-box-response">
                  {clicked ? (
                    <GrayTextMediumBigger>{shopMessage}</GrayTextMediumBigger>
                  ) : (
                    <></>
                  )}
                </h3>
              </div>
            </>
          )}
          {/* <div className="shop-image-box">
            <img src={shopImage ? shopImage : EmptyImage} alt="" />
            <div className="text">
              <label className="custom-file-upload">
                <FileBase type="file" onDone={shopImageHandler.bind(this)} />
                Upload image
              </label>
            </div>
            <h3 className="create-box-response">
              {clicked ? (
                <GrayTextMediumBigger>{message}</GrayTextMediumBigger>
              ) : (
                <></>
              )}
            </h3>
          </div> */}
          <form onSubmit={SubmitShopHandler}>
            <div className="no-shop-content">
              <div className="input-shop">
                <input
                  maxlength="50"
                  style={
                    shopNameFocused
                      ? { background: "rgba(114, 139, 166, .4)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onFocus={() => setShopNameFocused(true)}
                  onChange={(e) =>
                    setCreateNewShop({ ...createNewShop, name: e.target.value })
                  }
                  type="text"
                  value={createNewShop.name}
                />
                <img
                  style={shopNameFocused ? { opacity: "1" } : { opacity: "0" }}
                  src={BlueCross}
                  onClick={shopNameCrossHandler}
                  alt=""
                />
                <div
                  style={shopNameFocused ? { top: "-33px" } : { top: "3px" }}
                  className="placeholder-box"
                >
                  <div
                    className="placeholder"
                    style={
                      shopNameFocused
                        ? { fontSize: "11px" }
                        : { fontSize: "16px" }
                    }
                  >
                    {t("Shopname")}
                  </div>
                </div>
              </div>
              <InputWrap
                className="phone-input-box"
                style={
                  phoneFocused
                    ? {
                        backgroundColor: "rgba(114, 139, 166, .4)",
                        width: "100%",
                        borderRadius: "20px",
                      }
                    : { width: "100%", borderRadius: "20px" }
                }
              >
                <PhoneNumberCross
                  phoneFocused={phoneFocused}
                  src={BlueCross}
                  style={{ width: "24px", height: "24px" }}
                  alt=""
                  onClick={phoneCrossHandler}
                />
                <PhoneInput
                  type="phone"
                  name="phone"
                  international
                  onFocus={() => setPhoneFocused(true)}
                  value={createNewShop?.phone_number}
                  onChange={(number) =>
                    setCreateNewShop({ ...createNewShop, phone_number: number })
                  }
                />
                <PhoneNumberPlaceholderBOx phoneFocused={phoneFocused}>
                  <PhoneNumberPlaceholder phoneFocused={phoneFocused}>
                    {t("YourPhone")}
                  </PhoneNumberPlaceholder>
                </PhoneNumberPlaceholderBOx>
              </InputWrap>
              <div className="input-shop">
                <textarea
                  maxLength="2000"
                  className="create-shop-textarea"
                  style={
                    descriptionFocused
                      ? { background: "rgba(114, 139, 166, .4)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onFocus={() => setDescriptionFocused(true)}
                  onChange={(e) =>
                    setCreateNewShop({
                      ...createNewShop,
                      description: e.target.value,
                    })
                  }
                  value={createNewShop.description}
                />
                <img
                  style={
                    descriptionFocused ? { opacity: "1" } : { opacity: "0" }
                  }
                  src={BlueCross}
                  onClick={descriptionCrossHandler}
                  alt=""
                />
                <div
                  style={descriptionFocused ? { top: "-33px" } : { top: "3px" }}
                  className="placeholder-box"
                >
                  <div
                    className="placeholder"
                    style={
                      descriptionFocused
                        ? { fontSize: "11px" }
                        : { fontSize: "16px" }
                    }
                  >
                    {t("Description")}
                  </div>
                </div>
              </div>
              <div className="create-shop-button-box">
                <ButtonBlue className="create-shop-btn" type="submit">
                  {t("Save")}
                </ButtonBlue>
                <ButtonBlue
                  className="create-shop-btn"
                  onClick={() => setOpenCreateShop(false)}
                  type="button"
                >
                  {t("Close")}
                </ButtonBlue>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateNewShop;
