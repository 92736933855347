import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import {
  BlueCross,
  DemoProdavnica,
  PremiumStar,
} from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import {
  PhoneNumberPlaceholder,
  InputWrap,
  PhoneNumberCross,
  PhoneNumberPlaceholderBOx,
} from "../../../pages/CheckoutPage/CheckoutInputs";
import { ButtonBlue, ButtonYellow } from "../../../styles/GlobalStyles";
import { CustomerBoxHeader } from "../styles";

const shopData = {
  id: 1,
  name: "Demo prodavnica",
  phone: "+382 99 000-000",
  is_premium: false,
  image: DemoProdavnica,
};

const EditShop = ({ setCreateNewShop, edit, setEdit }) => {
  const [patchedShop, setPatchedShop] = useState({
    name: "",
    description: "",
    phone: "",
    image: "",
    is_premium: false,
  });
  const [shopNameFocused, setShopNameFocused] = useState(false);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [descriptionFocused, setDescriptionFocused] = useState(false);

  const SubmitShophandler = (e) => {
    e.preventDefault();
    setShopNameFocused(false);
    setDescriptionFocused(false);
    setPhoneFocused(false);
  };

  // cross handlers
  const shopNameCrossHandler = () => {
    setShopNameFocused(false);
    setPatchedShop({ ...patchedShop, name: "" });
  };
  const phoneCrossHandler = () => {
    setPhoneFocused(false);
    setPatchedShop({ ...patchedShop, phone: "" });
  };
  const descriptionCrossHandler = () => {
    setDescriptionFocused(false);
    setPatchedShop({ ...patchedShop, description: "" });
  };
  const handlePhoneChange = (value) => {
    // handle phone number change here
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="content">
        <CustomerBoxHeader className="header">
          {t("Editshop")}
        </CustomerBoxHeader>
        <div className="edit-box">
          <div className="shop-image-box">
            <img src={shopData.image} alt="" />
            <div className="text">
              <label>{t("ChangeImage")}</label>
            </div>
            {shopData.is_premium && (
              <img src={PremiumStar} alt="" className="star" />
            )}
          </div>
          <form onSubmit={SubmitShophandler}>
            <div className="no-shop-content">
              <div className="input-shop">
                <input
                  style={
                    shopNameFocused
                      ? { background: "rgba(114, 139, 166, .6)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onFocus={() => setShopNameFocused(true)}
                  onChange={(e) =>
                    setPatchedShop({ ...patchedShop, name: e.target.value })
                  }
                  value={patchedShop.name}
                  placeholder={shopNameFocused ? "" : shopData.name}
                />
                <img
                  style={shopNameFocused ? { opacity: "1" } : { opacity: "0" }}
                  src={BlueCross}
                  onClick={shopNameCrossHandler}
                  alt=""
                />
                <div style={{ top: "-33px" }} className="placeholder-box">
                  <div className="placeholder" style={{ fontSize: "11px" }}>
                    {t("Shopname")}
                  </div>
                </div>
              </div>
              <InputWrap
                className="phone-input-box"
                style={
                  phoneFocused
                    ? {
                        backgroundColor: "rgba(114, 139, 166, .4)",
                        width: "100%",
                        borderRadius: "20px",
                      }
                    : { width: "100%", borderRadius: "20px" }
                }
              >
                <PhoneNumberCross
                  phoneFocused={phoneFocused}
                  src={BlueCross}
                  style={{ width: "24px", height: "24px" }}
                  alt=""
                  onClick={phoneCrossHandler}
                />
                <PhoneInput
                  onFocus={() => setPhoneFocused(true)}
                  onChange={handlePhoneChange}
                  placeholder={shopData.phone}
                />
                <PhoneNumberPlaceholderBOx
                  style={{ top: "-33px", left: "22px" }}
                >
                  <PhoneNumberPlaceholder style={{ fontSize: "11px" }}>
                    {t("YourPhone")}
                  </PhoneNumberPlaceholder>
                </PhoneNumberPlaceholderBOx>
              </InputWrap>
              <div className="input-shop" style={{ height: "130px" }}>
                <textarea
                  className="edit-shop-textarea"
                  style={
                    descriptionFocused
                      ? { background: "rgba(114, 139, 166, .6)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onFocus={() => setDescriptionFocused(true)}
                  onChange={(e) =>
                    setPatchedShop({
                      ...patchedShop,
                      description: e.target.value,
                    })
                  }
                  value={patchedShop.description}
                  placeholder={t("PremiumDesc")}
                />
                <img
                  style={
                    descriptionFocused ? { opacity: "1" } : { opacity: "0" }
                  }
                  src={BlueCross}
                  onClick={descriptionCrossHandler}
                  alt=""
                />
                <div style={{ top: "-70px" }} className="placeholder-box">
                  <div className="placeholder" style={{ fontSize: "11px" }}>
                    {t("Description")}
                  </div>
                </div>
              </div>

              <div className="button-box">
                <div>
                  <ButtonBlue
                    className="create-shop-btn"
                    onSubmit={SubmitShophandler}
                  >
                    {t("Editshop")}
                  </ButtonBlue>
                  <ButtonBlue
                    className="create-shop-btn"
                    type="submit"
                    onClick={() => setEdit(false)}
                  >
                    {t("Close")}
                  </ButtonBlue>
                </div>
                <ButtonYellow className="premium-plan-btn" type="button">
                  {t("Changetopremiumplan")}
                </ButtonYellow>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditShop;
