import React, { useRef, useState } from "react";

import {
  FlexBetween,
  FlexCenter,
  FlexColumn,
  GrayTextMedium,
  YellowTextBold,
} from "../../styles/GlobalStyles";
import {
  LogInSection,
  LogInBox,
  LogInHeader,
  WelcomeBack,
  Forgot,
  LogInButton,
  Google,
  Facebook,
  NoAccount,
  LogInContainer,
  Label,
  Line,
  Break,
  GoogleText,
  FacebookText,
  Remember,
  FacebookIMG,
  FacebookIMGHover,
  PlaceholderBoxEmail,
  PlaceholderEmail,
  LogInInput,
  LogInInputBoxPassword,
  LogInInputBoxConfirmEmail,
  LogInInputBoxConfirmPassword,
  LogInCrossPassword,
  LogInCrossConfirmEmail,
  LogInCrossConfirmPassword,
  LogInCrossEmail,
  LogInInputBoxEmail,
  PlaceholderBoxPassword,
  PlaceholderPassword,
  PlaceholderBoxConfirmEmail,
  PlaceholderConfirmEmail,
  PlaceholderBoxConfirmPassword,
  PlaceholderConfirmPassword,
  GreenClick,
  RegisterPlaceholderBoxEmail,
  RegisterPlaceholderBoxPassword,
  RegisterPlaceholderEmail,
  RegisterPlaceholderPassword,
  LogInCrossRegisterEmail,
  LoginInputBoxRegisterEmail,
  LogInCrossRegisterPassword,
  LogInInputBoxRegisterPassword,
  LogInButtonClicked,
} from "./styles";
import {
  GoogleIMG,
  FacebookHover,
  FacebookOriginal,
  Iks,
  GreenCheckbox,
} from "../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../context/context";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LogIn = () => {
  // states
  const {
    formData,
    setFormData,
    baseUrl,
    handleLogIn,
    registerError,
    setRegisterError,
  } = useGlobalContext();
  const [isSignUp, setIsSignUp] = useState(true);
  const [emailFocus, setEmailFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [confirmEmailFocus, setConfirmEmailFocus] = useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
  const [registerEmailfocus, setRegisterEmailFocus] = useState(false);
  const [registerPasswordfocus, setRegisterPasswordFocus] = useState(false);

  const [checkbox, setCheckbox] = useState(false);
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();
  // refs
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const registerEmailRef = useRef(null);
  const registerPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const toTop = () => {
    window.scrollTo(0, 0);
  };
  const handleRegister = async (e) => {
    e.preventDefault();
    if (formData.RegisterPassword === formData.ConfirmPassword) {
      const registerUser = async () => {
        const { RegisterEmail, RegisterPassword } = formData;
        const newObj = { email: RegisterEmail, password: RegisterPassword };
        setClicked(true);
        try {
          const response = await axios
            .post(`${baseUrl}/user`, newObj)
            .then((response) => {
              if (response.status === 201) {
                setClicked(false);
                navigate("/verifyemail");
              }
            });
        } catch (error) {
          setRegisterError(error.response.data.message);
          setClicked(false);
          setTimeout(() => {
            setRegisterError("");
          }, 5000);
        }
      };
      registerUser();
    } else {
      setRegisterError("Passwords do not match.");
      setTimeout(() => {
        setRegisterError("");
      }, 5000);
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setPasswordFocus(true);
    setEmailFocus(true);
  };
  //cross handlers
  const emailCrossHandler = () => {
    setEmailFocus(false);
    emailRef.current.value = "";
  };
  const passwordCrossHandler = () => {
    setPasswordFocus(false);
    passwordRef.current.value = "";
  };
  const registerPasswordCrossHandler = () => {
    setRegisterPasswordFocus(false);
    registerPasswordRef.current.value = "";
  };
  const registerEmailCrossHandler = () => {
    setRegisterEmailFocus(false);
    registerEmailRef.current.value = "";
  };
  const confirmPasswordCrossHandler = () => {
    setConfirmPasswordFocus(false);
    confirmPasswordRef.current.value = "";
  };
  //is signed up handler
  const IsSignUpHandler = () => {
    toTop();
    setIsSignUp(!isSignUp);
    setEmailFocus(false);
    setPasswordFocus(false);
    setConfirmEmailFocus(false);
    setConfirmPasswordFocus(false);
    setRegisterEmailFocus(false);
    setRegisterPasswordFocus(false);
    setRegisterError("");
    setFormData({
      email: "",
      password: "",
      ConfirmPassword: "",
      ConfirmEmail: "",
      RegisterPassword: "",
      RegisterEmail: "",
    });
  };
  const { t } = useTranslation();
  return (
    <LogInSection>
      <LogInContainer>
        <FlexCenter>
          <FlexColumn>
            {isSignUp ? (
              <>
                <div className="sign-in-up-box">
                  <LogInBox onSubmit={handleLogIn}>
                    <LogInHeader>{t("LogIn")}</LogInHeader>
                    {/* <div style={{ textAlign: "center", marginBottom: "40px" }}> */}
                    <WelcomeBack>{t("WelcomeBack")}!</WelcomeBack>
                    <WelcomeBack style={{ marginBottom: "30px" }}>
                      {t("PleaseEnterDetails")}
                    </WelcomeBack>
                    {/* </div> */}
                    {/* Email */}
                    <LogInInputBoxEmail emailFocus={emailFocus}>
                      <LogInCrossEmail
                        onClick={emailCrossHandler}
                        src={Iks}
                        emailFocus={emailFocus}
                        alt=""
                      />
                      <LogInInput
                        ref={emailRef}
                        onChange={handleChange}
                        type="email"
                        name="email"
                        onFocus={() => setEmailFocus(true)}
                        emailFocus={emailFocus}
                      />
                      <PlaceholderBoxEmail emailFocus={emailFocus} id="3">
                        <PlaceholderEmail emailFocus={emailFocus}>
                          {t("EmailAddress")}
                        </PlaceholderEmail>
                      </PlaceholderBoxEmail>
                    </LogInInputBoxEmail>
                    {/* Password */}
                    <LogInInputBoxPassword
                      passwordFocus={passwordFocus}
                      style={{ marginBottom: "13px" }}
                    >
                      <LogInCrossPassword
                        passwordFocus={passwordFocus}
                        src={Iks}
                        onClick={passwordCrossHandler}
                      />
                      <LogInInput
                        ref={passwordRef}
                        onChange={handleChange}
                        type="password"
                        name="password"
                        onFocus={() => setPasswordFocus(true)}
                        passwordFocus={passwordFocus}
                      />
                      <PlaceholderBoxPassword passwordFocus={passwordFocus}>
                        <PlaceholderPassword passwordFocus={passwordFocus}>
                          {t("Password")}
                        </PlaceholderPassword>
                      </PlaceholderBoxPassword>
                    </LogInInputBoxPassword>
                    <div className="remember-me-box">
                      <div
                        id="rememberMe"
                        onClick={() => setCheckbox(!checkbox)}
                        style={{
                          cursor: "pointer",
                          alignItems: "center",
                          position: "relative",
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <Label htmlFor="checkbox">{t("RememberMe")}</Label>
                        <Remember
                          id="checkbox"
                          type="checkbox"
                          name="remember"
                        />
                        <GreenClick
                          src={GreenCheckbox}
                          alt=""
                          checkbox={checkbox}
                        />
                      </div>
                    </div>
                    <div className="login-message">
                      {registerError ? (
                        <p className="login-message-text">{registerError}</p>
                      ) : (
                        <>
                          <GrayTextMedium></GrayTextMedium>
                        </>
                      )}
                    </div>
                    <LogInButton type="submit">{t("LogIn")}</LogInButton>
                    <Break>
                      <Line />
                      <GrayTextMedium
                        style={{ fontSize: "14px", padding: "0 15px" }}
                      >
                        {t("or")}
                      </GrayTextMedium>
                      <Line />
                    </Break>
                  </LogInBox>
                  <div className="google-facebook-box">
                    <a
                      href="https://mbgc-me-staging.herokuapp.com/api/v1/auth/google"
                      target="_self"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Google>
                        <img
                          src={GoogleIMG}
                          alt=""
                          style={{ padding: "13px 30px 12.4px 16px" }}
                        />
                        <GoogleText>{t("Google")}</GoogleText>
                      </Google>
                    </a>
                    <a
                      href="https://mbgc-me-staging.herokuapp.com/api/v1/auth/facebook"
                      target="_self"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Facebook>
                        <FacebookIMGHover src={FacebookHover} alt="" />
                        <FacebookIMG src={FacebookOriginal} alt="" />
                        <FacebookText>{t("Facebook")}</FacebookText>
                      </Facebook>
                    </a>
                    <NoAccount>
                      {t("NoAccount")}
                      <YellowTextBold
                        onClick={IsSignUpHandler}
                        style={{ cursor: "pointer" }}
                      >
                        {t("Register")}
                      </YellowTextBold>
                    </NoAccount>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="sign-in-up-box">
                  <LogInBox>
                    <LogInHeader style={{ marginBottom: "5px" }}>
                      {t("CreateNewAccount")}
                    </LogInHeader>
                    <div style={{ textAlign: "center", marginBottom: "40px" }}>
                      <WelcomeBack>{t("PleaseEnterDetails")}</WelcomeBack>
                    </div>
                    {/* REGISTER EMAIL */}
                    <LoginInputBoxRegisterEmail
                      registerEmailfocus={registerEmailfocus}
                    >
                      <LogInCrossRegisterEmail
                        onClick={registerEmailCrossHandler}
                        src={Iks}
                        registerEmailfocus={registerEmailfocus}
                        alt=""
                      />
                      <LogInInput
                        ref={registerEmailRef}
                        onChange={handleChange}
                        type="email"
                        name="RegisterEmail"
                        onFocus={() => setRegisterEmailFocus(true)}
                        registerEmailfocus={registerEmailfocus}
                      />
                      <RegisterPlaceholderBoxEmail
                        registerEmailfocus={registerEmailfocus}
                        id="3"
                      >
                        <RegisterPlaceholderEmail
                          registerEmailfocus={registerEmailfocus}
                        >
                          {t("EmailAddress")}
                        </RegisterPlaceholderEmail>
                      </RegisterPlaceholderBoxEmail>
                    </LoginInputBoxRegisterEmail>
                    <LogInInputBoxRegisterPassword
                      registerPasswordfocus={registerPasswordfocus}
                    >
                      <LogInCrossRegisterPassword
                        registerPasswordfocus={registerPasswordfocus}
                        src={Iks}
                        onClick={registerPasswordCrossHandler}
                      />
                      <LogInInput
                        ref={registerPasswordRef}
                        onChange={handleChange}
                        type="password"
                        name="RegisterPassword"
                        onFocus={() => setRegisterPasswordFocus(true)}
                        registerPasswordfocus={registerPasswordfocus}
                      />
                      <RegisterPlaceholderBoxPassword
                        registerPasswordfocus={registerPasswordfocus}
                      >
                        <RegisterPlaceholderPassword
                          registerPasswordfocus={registerPasswordfocus}
                        >
                          {t("Password")}
                        </RegisterPlaceholderPassword>
                      </RegisterPlaceholderBoxPassword>
                    </LogInInputBoxRegisterPassword>
                    {/* CONFIRM PASSWORD */}
                    <LogInInputBoxConfirmPassword
                      style={{ marginBottom: "15px" }}
                      confirmPasswordFocus={confirmPasswordFocus}
                    >
                      <LogInCrossConfirmPassword
                        confirmPasswordFocus={confirmPasswordFocus}
                        src={Iks}
                        onClick={confirmPasswordCrossHandler}
                      />
                      <LogInInput
                        ref={confirmPasswordRef}
                        onChange={handleChange}
                        type="password"
                        name="ConfirmPassword"
                        onFocus={() => setConfirmPasswordFocus(true)}
                        confirmPasswordFocus={confirmPasswordFocus}
                      />
                      <PlaceholderBoxConfirmPassword
                        confirmPasswordFocus={confirmPasswordFocus}
                      >
                        <PlaceholderConfirmPassword
                          confirmPasswordFocus={confirmPasswordFocus}
                        >
                          {t("ConfirmPassword")}
                        </PlaceholderConfirmPassword>
                      </PlaceholderBoxConfirmPassword>
                    </LogInInputBoxConfirmPassword>
                    {/* error message */}
                    <div className="login-message">
                      {registerError ? (
                        <p className="login-message-text">{registerError}</p>
                      ) : (
                        <>
                          <GrayTextMedium></GrayTextMedium>
                        </>
                      )}
                    </div>
                    {clicked ? (
                      <LogInButtonClicked onClick={handleRegister}>
                        <div class="loading-spinner-smaller"></div>
                      </LogInButtonClicked>
                    ) : (
                      <LogInButton onClick={handleRegister}>
                        {t("Register")}
                      </LogInButton>
                    )}
                    {/* gooogle and facebook stuff */}
                    <Break>
                      <Line />
                      <GrayTextMedium
                        style={{ fontSize: "14px", padding: "0 15px" }}
                      >
                        or
                      </GrayTextMedium>
                      <Line />
                    </Break>
                  </LogInBox>
                  <div className="google-facebook-box">
                    <a
                      href="https://mbgc-me-staging.herokuapp.com/api/v1/auth/google"
                      target="_self"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Google>
                        <img
                          src={GoogleIMG}
                          alt=""
                          style={{ padding: "13px 30px 12.4px 16px" }}
                        />
                        <GoogleText>Continue with Google</GoogleText>
                      </Google>
                    </a>
                    <a
                      href="https://mbgc-me-staging.herokuapp.com/api/v1/auth/facebook"
                      target="_self"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Facebook>
                        <FacebookIMGHover src={FacebookHover} alt="" />
                        <FacebookIMG src={FacebookOriginal} alt="" />
                        <FacebookText>{t("Facebook")}</FacebookText>
                      </Facebook>
                    </a>
                    <NoAccount>
                      {t("AlreadyHaveAccount")}
                      <YellowTextBold
                        onClick={IsSignUpHandler}
                        style={{ cursor: "pointer" }}
                      >
                        {t("LogIn")}
                      </YellowTextBold>
                    </NoAccount>
                  </div>
                </div>
              </>
            )}
          </FlexColumn>
        </FlexCenter>
      </LogInContainer>
    </LogInSection>
  );
};

export default LogIn;
