import { useGlobalContext } from "../../context/context";
import { motion } from "framer-motion";
import {
  GrayTextMedium,
  WhiteTextBold,
  GrayTextLightMedium,
} from "../../styles/GlobalStyles";
import Backdrop from "./Backdrop";
import "./styles.scss";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const modalPopUp = {
  hidden: {
    opacity: "0",
    transition: ".3s all ease",
  },
  visible: {
    opacity: "1",
    transition: ".3s all ease",
  },
};

const Modal = ({ handleClose, acceptOrder, rejectOrder }) => {
  const [converter, setConverter] = useState();
  const {
    deleteCategory,
    setClosed,
    deleteProduct,
    removeString,
    modalRemoveId,
    deleteAttribute,
    accRej,
    baseUrl,
    config,
    orderId,
    closed,
    setAcceptRejectClicked,
    openPremiumModal,
    setOpenPremiumModal,
    shopId,
    deleteShop,
    setAccRej,
    setShopId,
    setOrderId,
    setSelected,
    setModalRemoveId,
    loadSingleOrder,
    setLoadSingleOrder,
  } = useGlobalContext();
  const { t } = useTranslation();
  const removeItem = () => {
    if (removeString === "category") {
      deleteCategory();
      setModalRemoveId(null);
    } else if (removeString === "products") {
      deleteProduct();
    } else if (removeString === "shop") {
      deleteShop();
    } else if (removeString === "attribute") {
      deleteAttribute();
    }
    handleClose();
    setClosed(true);
  };
  let obj = { shop_id: shopId, status: accRej };
  const handleSelect = async () => {
    setAcceptRejectClicked(true);
    setLoadSingleOrder(true);
    const changeOrderStatus = async () => {
      console.log(orderId, shopId, accRej, config);
      try {
        const response = await axios
          .patch(`${baseUrl}/order/${orderId}`, obj, config)
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data);
            }
            setLoadSingleOrder(false);
          })
          .catch((response) => {
            console.log(response);
          });
      } catch (error) {
        console.log(error);
      }
    };
    changeOrderStatus();
    const getDemoShopCategories = async () => {
      try {
        const response = axios
          .get(`${baseUrl}/category`, config)
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data);
            }
          });
      } catch (error) {
        console.log(error);
      }
    };
    getDemoShopCategories();
    handleClose();
    setClosed(true);
    setSelected(accRej);
  };

  const handlePremium = () => {
    handleClose(setOpenPremiumModal(false));
  };

  const convertHandler = (accRej, openPremiumModal) => {};

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal"
        initial="hidden"
        animate="visible"
        variants={modalPopUp}
      >
        <div className="text">
          {removeString === "accepted" ? (
            <>
              <WhiteTextBold
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  marginBottom: "10px",
                }}
              >
                {t("AcceptOrder")}
              </WhiteTextBold>
              <GrayTextLightMedium>{t("BeAware")}</GrayTextLightMedium>
            </>
          ) : removeString === "rejected" ? (
            <>
              <WhiteTextBold
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  marginBottom: "10px",
                }}
              >
                {t("RejectOrder")}
              </WhiteTextBold>
              <GrayTextLightMedium>{t("BeAware")}</GrayTextLightMedium>
            </>
          ) : removeString === "premium" ? (
            <>
              <WhiteTextBold
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  marginBottom: "10px",
                }}
              >
                {t("ComingSoon")}
              </WhiteTextBold>
              <GrayTextLightMedium>
                {t("Ifyouwantpurchase")}
              </GrayTextLightMedium>
            </>
          ) : removeString === "category" ? (
            <>
              <WhiteTextBold
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  marginBottom: "10px",
                }}
              >
                {t("RemoveCategory")}
              </WhiteTextBold>
              <GrayTextLightMedium>{t("LossOfCategory")}</GrayTextLightMedium>
            </>
          ) : removeString === "attribute" ? (
            <>
              <WhiteTextBold
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  marginBottom: "10px",
                }}
              >
                {t("RemoveAttribute")}
              </WhiteTextBold>
              <GrayTextLightMedium>{t("LossOfAttribute")}</GrayTextLightMedium>
            </>
          ) : removeString === "products" ? (
            <>
              <WhiteTextBold
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  marginBottom: "10px",
                }}
              >
                {t("RemoveProduct")}
              </WhiteTextBold>
              <GrayTextLightMedium>{t("LossOfProduct")} </GrayTextLightMedium>
            </>
          ) : removeString === "shop" ? (
            <>
              <WhiteTextBold
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  marginBottom: "10px",
                }}
              >
                {t("RemoveShopModal")}
              </WhiteTextBold>
              <GrayTextLightMedium>{t("LossOfShop")}</GrayTextLightMedium>
            </>
          ) : removeString === "limit" ? (
            <>
              <WhiteTextBold
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  marginBottom: "10px",
                }}
              >
                {t("FreeAccount")}
              </WhiteTextBold>
              <GrayTextLightMedium>{t("PremiumPackage")}</GrayTextLightMedium>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="button-box">
          {removeString === "premium" || removeString === "limit" ? (
            <button onClick={handlePremium}>Ok</button>
          ) : removeString === "rejected" ? (
            <>
              <button onClick={handleSelect}>{t("Reject")}</button>
              <button onClick={handleClose}>{t("Close")}</button>
            </>
          ) : removeString === "accepted" ? (
            <>
              <button onClick={handleSelect}>{t("Accept")}</button>
              <button onClick={handleClose}>{t("Close")}</button>
            </>
          ) : (
            <>
              <button onClick={removeItem}>{t("Remove")}</button>
              <button onClick={handleClose}>{t("Close")}</button>
            </>
          )}
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default Modal;
