import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Logo,
  DropdownArrow,
  UK,
  MNE,
  ShoppingCart,
  ShoppingCartBlue,
} from "../../../assets/landingPage/landingPageAssets";
import { FlexBetween, FlexCenter } from "../../../styles/GlobalStyles";
import {
  Ul,
  LiHome,
  HrefLink,
  SearchBox,
  NavbarContainer,
  SellersCorner,
  BlueLinks,
  Language,
  Dropdown,
  LogIn,
  Flag,
  Lang,
  FlagMenu,
  FlagUl,
  FlagLi,
  FlagSpan,
  ShoppingCartImageBox,
  ShoppingCartImage,
  CartNumber,
  LogOut,
  MenuWrapper,
  ResponsiveUl,
  ResponsiveCartImageBox,
  SearchBoxResponsive,
} from "./styles";
import "./navbar.scss";
import { useGlobalContext } from "../../../context/context";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import menuIcon from "../../../assets/landingPage/menu.svg";
import closeIcon from "../../../assets/landingPage/close.svg";
import { useEffect } from "react";
import i18next from "../../../i18next/configurei18next";

const Navbar = () => {
  const [user, setUser] = useState("profile");
  const [flag, setFlag] = useState("ME");
  const [url, setUrl] = useState("");
  const [userTest, setUserTest] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [flagOpen, setFlagOpen] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [cartHover, setCartHover] = useState(false);
  const { authKey, logUserOut, setLogInFired, cart, yourShop, setAuthKey } =
    useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();
  console.log(flag);

  const LogOutHandler = () => {
    logUserOut();
    setLogInFired(false);
    setToggleMenu(false);
    navigate("/");
  };

  const LogInHandler = () => {
    setToggleMenu(false);
    setUrl("");
  };

  useEffect(() => {
    const authKeyAuth = sessionStorage.getItem("authKey");
    i18next.changeLanguage("mne");

    if (!authKeyAuth || authKeyAuth === "home") {
      setAuthKey(false);
    }
  }, []);
  const UrlHandler = (e) => {
    setUrl(e.target.outerText);
  };

  function handleLanguageChange(newLanguage, flag) {
    i18next.changeLanguage(newLanguage);
    setFlag(flag);
    setFlagOpen(false);
  }

  function scrollSection() {
    const section = document.getElementById("recommend"); // replace with the ID of the section you want to scroll to
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition - 100;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
  return (
    <NavbarContainer id="home">
      <FlexBetween>
        <FlexCenter id="logo">
          <HrefLink to={"/"} id="logo1">
            <img className="logo" src={Logo} alt="logo" />
          </HrefLink>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              gap: 20,
            }}
          >
            <Link to="/cart" className="cart-link-responsive">
              <ResponsiveCartImageBox
                onMouseOver={() => setCartHover(true)}
                onMouseOut={() => setCartHover(false)}
                className="cart-box"
              >
                <ShoppingCartImage
                  id="cart"
                  src={cartHover ? ShoppingCartBlue : ShoppingCart}
                  alt=""
                />

                <CartNumber>{cart.length}</CartNumber>
              </ResponsiveCartImageBox>
            </Link>
            <MenuWrapper>
              {location.pathname === "/profile" ? (
                <>
                  <img className="nav-avatar" src={yourShop?.image} alt="" />
                </>
              ) : (
                <>
                  <img
                    onClick={() => setToggleMenu((prev) => !prev)}
                    className="menu_icon"
                    src={`${toggleMenu ? closeIcon : menuIcon}`}
                    alt="logo"
                  />
                </>
              )}
            </MenuWrapper>
          </div>

          <nav>
            <>
              <ResponsiveUl className={`${toggleMenu ? "show" : "hide"}`}>
                <li>
                  <a onClick={() => setToggleMenu(false)} href="/#home">
                    {t("home")}
                  </a>
                </li>
                <li>
                  <a onClick={() => setToggleMenu(false)} href="/#news">
                    {t("News")}
                  </a>
                </li>
                <li>
                  <a onClick={() => setToggleMenu(false)} href="/#recommend" className="mojBiz">
                    {t("MOJBIZ Shops")}
                  </a>
                </li>
                <hr />
                <SearchBoxResponsive id="searchBox" className="search-box">
                  <SellersCorner>{t("SellersCorner")}</SellersCorner>
                  {authKey ? (
                    <BlueLinks
                      onClick={() => {
                        setUrl("");
                        setToggleMenu(false);
                      }}
                      style={
                        location.pathname === "/profile"
                          ? {
                              background: "#3853A3",
                              borderRadius: "100px",
                              color: "#fff",
                              fontWeight: "400",
                            }
                          : null
                      }
                      to={"/profile"}
                    >
                      {t("AdminDashboard")}
                    </BlueLinks>
                  ) : (
                    <BlueLinks
                      onClick={() => {
                        setUrl("");
                        setToggleMenu(false);
                      }}
                      style={
                        location.pathname === "/demoshop"
                          ? {
                              background: "#3853A3",
                              borderRadius: "100px",
                              color: "#fff",
                              fontWeight: "400",
                            }
                          : null
                      }
                      to={"/demoshop"}
                    >
                      {t("Demoshop")}
                    </BlueLinks>
                  )}
                  <BlueLinks
                    onClick={LogInHandler}
                    style={
                      location.pathname === "/offer"
                        ? {
                            background: "#3853A3",
                            borderRadius: "100px",
                            color: "#fff",
                            fontWeight: "400",
                          }
                        : null
                    }
                    to={"/offer"}
                  >
                    {t("Offer")}
                  </BlueLinks>
                  <div id="log_res">
                    {authKey ? (
                      <>
                        <LogOut
                          className="log-out-component"
                          onClick={LogOutHandler}
                        >
                          {t("LogOut")}
                        </LogOut>
                      </>
                    ) : (
                      <LogIn
                        className="log-in-component"
                        onClick={LogInHandler}
                        style={
                          location.pathname === "/login"
                            ? {
                                background: "#3853A3",
                                border: "2px solid transparent",
                                color: "#fff",
                              }
                            : null
                        }
                        to={"/login"}
                      >
                        {t("LogIn")}
                      </LogIn>
                    )}
                    <Language
                      flagOpen={flagOpen}
                      onClick={() => setFlagOpen(!flagOpen)}
                      id="languageBox"
                    >
                      <Dropdown
                        flagOpen={flagOpen}
                        src={DropdownArrow}
                        alt=""
                        id="3"
                      />
                      <Lang>{flag}</Lang>
                      <Flag src={flag === "EN" ? UK : MNE} alt="" />
                    </Language>
                    <FlagMenu flagOpen={flagOpen}>
                      <FlagUl>
                        <FlagLi
                          style={
                            flag === "ME"
                              ? { background: "#E2E6EB" }
                              : { bacground: "transparent" }
                          }
                          onClick={() => {
                            handleLanguageChange("mne", "ME");
                          }}
                        >
                          <FlagSpan
                            style={
                              flag === "ME"
                                ? { fontWeight: "700" }
                                : { fontWeight: "400" }
                            }
                            flag={flag}
                          >
                            Crnogorski
                          </FlagSpan>
                        </FlagLi>
                        <FlagLi
                          style={
                            flag === "EN"
                              ? { background: "#E2E6EB" }
                              : { background: "transparent" }
                          }
                        >
                          <FlagSpan
                            style={
                              flag === "EN"
                                ? { fontWeight: "700" }
                                : { fontWeight: "400" }
                            }
                            flag={flag}
                            onClick={() => {
                              handleLanguageChange("en", "EN");
                            }}
                          >
                            English
                          </FlagSpan>
                        </FlagLi>
                      </FlagUl>
                    </FlagMenu>
                  </div>
                </SearchBoxResponsive>
              </ResponsiveUl>
              <div
                className={`overlay ${
                  toggleMenu ? "show_overlay" : "hide_overlay"
                }`}
              ></div>
            </>
            <Ul>
              <LiHome
                onClick={UrlHandler}
                style={
                  url === "Home" || url === "Početna"
                    ? {
                        border: "2px solid transparent",
                        background: "#2c4591",
                        borderRadius: "100px",
                      }
                    : null
                }
              >
                <HrefLink href="/" to={"/#home"}>
                  {t("home")}
                </HrefLink>
              </LiHome>
              <LiHome
                onClick={UrlHandler}
                style={
                  url === "News" || url === "Novosti"
                    ? {
                        border: "2px solid transparent",
                        background: "#2c4591",
                        borderRadius: "100px",
                      }
                    : null
                }
              >
                <HrefLink onClick={UrlHandler} href="#news" to={"/#news"}>
                  {t("News")}
                </HrefLink>
              </LiHome>
              <LiHome
                onClick={UrlHandler}
                style={
                  url === "MBGC Shops" || url === "Prodavnice"
                    ? {
                        border: "2px solid transparent",
                        background: "#2c4591",
                        borderRadius: "100px",
                      }
                    : null
                }
              >
                <HrefLink
                  onClick={UrlHandler}
                  to={"/#recommend"}
                  href="#recommend"
                >
                  {t("MOJBIZ Shops")}
                </HrefLink>
              </LiHome>
            </Ul>
          </nav>
        </FlexCenter>
        <FlexCenter id="register">
          <SearchBox id="searchBox" className="search-box">
            <Link to="/cart" className="cart-link">
              <ShoppingCartImageBox
                onMouseOver={() => setCartHover(true)}
                onMouseOut={() => setCartHover(false)}
                className="cart-box"
              >
                <ShoppingCartImage
                  id="cart"
                  src={cartHover ? ShoppingCartBlue : ShoppingCart}
                  alt=""
                />
                <CartNumber>{cart.length}</CartNumber>
              </ShoppingCartImageBox>
            </Link>
            <SellersCorner>{t("SellersCorner")}</SellersCorner>
            {authKey ? (
              <BlueLinks
                onClick={() => setUrl("")}
                style={
                  location.pathname === "/profile" ||
                  window.location.href.includes("app.mbgc.me/profile#")
                    ? {
                        background: "#3853A3",
                        borderRadius: "100px",
                        color: "#fff",
                        fontWeight: "400",
                      }
                    : null
                }
                to={"/profile"}
              >
                {t("AdminDashboard")}
              </BlueLinks>
            ) : (
              <BlueLinks
                onClick={() => setUrl("")}
                style={
                  location.pathname === "/demoshop"
                    ? {
                        background: "#3853A3",
                        borderRadius: "100px",
                        color: "#fff",
                        fontWeight: "400",
                      }
                    : null
                }
                to={"/demoshop"}
              >
                {t("Demoshop")}
              </BlueLinks>
            )}
            <BlueLinks
              onClick={LogInHandler}
              style={
                location.pathname === "/offer"
                  ? {
                      background: "#3853A3",
                      borderRadius: "100px",
                      color: "#fff",
                      fontWeight: "400",
                    }
                  : null
              }
              to={"/offer"}
            >
              {t("Offer")}
            </BlueLinks>
            <div style={{ display: "flex" }}>
              {authKey ? (
                <>
                  <LogOut className="log-out-component" onClick={LogOutHandler}>
                    {t("LogOut")}
                  </LogOut>
                </>
              ) : (
                <LogIn
                  className="log-in-component"
                  onClick={LogInHandler}
                  style={
                    location.pathname === "/login"
                      ? {
                          background: "#3853A3",
                          border: "2px solid transparent",
                          color: "#fff",
                        }
                      : null
                  }
                  to={"/login"}
                >
                  {t("LogIn")}
                </LogIn>
              )}
              <Language
                flagOpen={flagOpen}
                onClick={() => setFlagOpen(!flagOpen)}
                id="languageBox"
              >
                <Dropdown
                  flagOpen={flagOpen}
                  src={DropdownArrow}
                  alt=""
                  id="3"
                />
                <Lang>{flag}</Lang>
                <Flag src={flag === "EN" ? UK : MNE} alt="" />
              </Language>
              <FlagMenu flagOpen={flagOpen}>
                <FlagUl>
                  <FlagLi
                    style={
                      flag === "ME"
                        ? { background: "#E2E6EB" }
                        : { bacground: "transparent" }
                    }
                    onClick={() => {
                      handleLanguageChange("mne", "ME");
                    }}
                  >
                    <FlagSpan
                      style={
                        flag === "ME"
                          ? { fontWeight: "700" }
                          : { fontWeight: "400" }
                      }
                      flag={flag}
                    >
                      Crnogorski
                    </FlagSpan>
                  </FlagLi>
                  <FlagLi
                    style={
                      flag === "EN"
                        ? { background: "#E2E6EB" }
                        : { background: "transparent" }
                    }
                  >
                    <FlagSpan
                      style={
                        flag === "EN"
                          ? { fontWeight: "700" }
                          : { fontWeight: "400" }
                      }
                      flag={flag}
                      onClick={() => {
                        handleLanguageChange("en", "EN");
                      }}
                    >
                      English
                    </FlagSpan>
                  </FlagLi>
                </FlagUl>
              </FlagMenu>
            </div>
          </SearchBox>
        </FlexCenter>
      </FlexBetween>
    </NavbarContainer>
  );
};

export default Navbar;
