import React, { useRef } from "react";
import { useState } from "react";
import {
  AvatarBlank,
  EmptyImage,
  UploadImage,
} from "../../../assets/landingPage/landingPageAssets";
import {
  BlueTextBold,
  ButtonBlue,
  GrayTextMedium,
} from "../../../styles/GlobalStyles";
import {
  AllCustomersBox,
  Avatar,
  AvatarImg,
  AvatarText,
  BlueTextSmaller,
  CreateCustomer,
  CreateCustomerGray,
  CustomerBoxHeader,
  CustomerBoxHeaderNoBorder,
  CustomerContainer,
  CustomerHeader,
  CustomersBoxFlex,
  CustomerSection,
} from "../styles";
import "./shopStyles.scss";
import "../stylesAllPages.scss";
import CreateNewShop from "./CreateNewShop";
import { useGlobalContext } from "../../../context/context";
import { useEffect } from "react";
import YourShop from "./YourShop";
import EditShop from "./EditShop";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading";
import { useTranslation } from "react-i18next";

const ShopSection = ({ setSections, open, handleClose, sections }) => {
  const [edit, setEdit] = useState(false);
  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };
  const {
    reload,
    setReload,
    logUserOut,
    getDemoShopShop,
    yourShop,
    userDetails,
    getUserDetails,
    openCreateShop,
    setOpenCreateShop,
    loadShop,
    message,
    setLoadShop,
    loadYourShop,
  } = useGlobalContext();
  const navigate = useNavigate();
  useEffect(() => {
    getUserDetails();
    getDemoShopShop();
  }, [openCreateShop, sections, loadYourShop]);
  const LogOutHandler = () => {
    logUserOut();
    navigate("/");
  };
  const { t } = useTranslation();
  return (
    <>
      <CustomerSection>
        <Avatar id="avatar" className="avatar">
          <AvatarText className="avatar-text">
            <BlueTextBold style={{ fontSize: "18px" }}>
              {userDetails?.email}
            </BlueTextBold>
            <div className="flex">
              <GrayTextMedium className="settings" onClick={settingsHandler}>
                {t("Profilesettings")}
              </GrayTextMedium>
              <GrayTextMedium className="log-out" onClick={LogOutHandler}>
                {t("LogOut")}
              </GrayTextMedium>
            </div>
          </AvatarText>
          {yourShop?.image && <AvatarImg src={yourShop?.image} alt="" />}{" "}
        </Avatar>
        <CustomerContainer id="container">
          <CustomerHeader>
            <BlueTextSmaller>{t("Shop")}</BlueTextSmaller>
            {/* ustvari ako je yourshop objekat prazan ... */}
            {/* {yourShop === undefined &&
              <>
                {openCreateShop ?
                  <>
                    <CreateCustomerGray>Create new</CreateCustomerGray>
                  </>
                  :
                  (
                    <>
                      <CreateCustomer onClick={() => setOpenCreateShop(true)}>Create new</CreateCustomer>
                    </>
                  )}
              </>
            } */}
          </CustomerHeader>

          <AllCustomersBox>
            <CustomersBoxFlex id="customersBox">
              {/* ustvari ako je yourshop objekat prazan ... */}

              {yourShop?.id ? (
                <>
                  {edit ? (
                    <>
                      <EditShop edit={edit} setEdit={setEdit} open={open} />
                    </>
                  ) : (
                    <>
                      <YourShop
                        edit={edit}
                        setEdit={setEdit}
                        open={open}
                        handleClose={handleClose}
                        sections={sections}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {openCreateShop ? (
                    <>
                      <CreateNewShop
                        setOpenCreateShop={setOpenCreateShop}
                        sections={sections}
                        openCreateShop={openCreateShop}
                      />
                    </>
                  ) : (
                    <>
                      <div className="content">
                        <CustomerBoxHeaderNoBorder>
                          {t("Yourshop")}
                        </CustomerBoxHeaderNoBorder>
                        <div className="empty-image-box">
                          <img src={EmptyImage} alt="" />
                          <div className="no-shop">
                            <div className="no-shop-content">
                              <BlueTextBold>{t("OopsNoShop")}</BlueTextBold>
                              <ButtonBlue
                                className="create-shop-btn"
                                onClick={() => setOpenCreateShop(true)}
                              >
                                {t("Create")}
                              </ButtonBlue>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </CustomersBoxFlex>
          </AllCustomersBox>
        </CustomerContainer>
      </CustomerSection>
    </>
  );
};

export default ShopSection;
