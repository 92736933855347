import React from "react";
import Home from "../../components/LandingPage/Home/Home";
import RecommendedShops from "../../components/LandingPage/RecommendedShops/RecommendedShops";
import NewsLetter from "../../components/LandingPage/NewsLetter/NewsLetter";
import Footer from "../../components/LandingPage/Footer/Footer";
import News from "../../components/LandingPage/News/News";

const LandingPage = () => {
  return (
    <div id="home">
      <Home />
      <RecommendedShops />
      <NewsLetter />
      <News />
      <Footer />
    </div>
  );
};

export default LandingPage;
