import styled from "styled-components";
import {
  BlueTextMedium,
  BlueTextSemiBold,
  ButtonBlue,
  ButtonGray,
  ButtonTransparentYellow,
  Cross,
  GrayTextBold,
  GrayTextMedium,
  YellowTextBold,
} from "../../styles/GlobalStyles";

const DemoShopSection = styled.section`
  background: #ffa800;
  display: flex;
  justify-content: center;
  height: 100%;
  @media only screen and (max-width: 450px) {
    background: #f2f8ff;
  }
`;

const Container = styled.div`
  padding: ${(props) =>
    props.authKey ? "120px 0 235px 0" : "100px 0 120px 0"};
  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 0px 0 450px 0;
  }
`;
const Content = styled.div`
  background: #f2f8ff;
  padding-right: 40px;
  box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
  border-radius: 30px;
  @media only screen and (max-width: 1400px) {
    width: 100%;
    margin: 0 auto;
  }
  @media only screen and (max-width: 450px) {
    padding-right: 0;
    margin: 0 auto;
    border-radius: 0px;
    box-shadow: 0px 14px 30px rgb(56 83 163 / 24%);
  }
`;

const FlexDemoShop = styled.div`
  display: flex;
  @media only screen and (max-width: 1450px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 450px) {
    gap: 40px;
  }
`;

const BlueTextBigger = styled(BlueTextSemiBold)`
  line-height: 30px;
  font-size: 36px;
  @media (max-width: 1600px) {
    font-size: 30px;
  }
`;
const BlueTextSmaller = styled(BlueTextSemiBold)`
  line-height: 30px;
  font-size: 22px;
  @media (max-width: 1600px) {
    font-size: 19px;
  }
  @media (max-width: 1450px) {
    margin-inline: auto;
  }
`;
const BlueTextMini = styled(BlueTextSemiBold)`
  line-height: 30px;
  font-size: 16px;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;
const GrayText = styled(GrayTextMedium)`
  font-size: 14px;
  line-height: 30px;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;

const HomeIcons = styled.div`
  margin-top: 23px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  @media only screen and (max-width: 480px) {
    width: 200px;
    margin-inline: auto;
    @media only screen and (max-width: 480px) {
      margin-top: 20px;
      width: 200px;
      margin-inline: auto;
      transition: 0.5s all ease;
      display: ${(props) => (props.drop ? "block" : "none")};
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 350px;
    margin-inline: auto;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 500px;
    margin-inline: auto;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1450px) {
    width: 700px;
  }
`;

const SingleIcon = styled.img`
  display: block;
  margin: 5px 13px 5px 13px;
`;
const IconBox = styled.div`
  border-radius: 5px;
  margin-bottom: 14px;
  height: 41px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    transition: 0.5s all ease;
    background: #fff;
    box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
    border-radius: 10px;
  }
`;

const DescText = styled(YellowTextBold)`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
`;

const DescBox = styled.div`
  background: #ffffff;
  border-radius: 5px;
`;

const Header = styled.div`
  margin: 30px 0 20px 0;
  display: flex;
  gap: 25px;
  @media only screen and (max-width: 1450px) {
    flex-direction: column;
    margin: 0;
    text-align: center;
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 450px) {
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
  }
`;
const StatsBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
  border-radius: 20px;
  width: 330px;
  height: 165px;
  &:first-child {
    margin-right: 46px;
  }
  margin-bottom: 40px;
  @media only screen and (max-width: 480px) {
    width: 230px;
    &:first-child {
      margin-right: 20px;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    width: 35%;
    &:first-child {
      margin-right: 20px;
    }
  }
  @media only screen and (min-width: 1025px) and (max-width: 1450px) {
    width: 50%;
    margin-right: 30px;
  }
`;
const StatText = styled.div`
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    width: 50%;
  }
`;

const StatLeft = styled(StatsBox)`
  @media only screen and (max-width: 1024px) {
    width: 46%;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1450px) {
    width: 50%;
  }
`;
const StatRight = styled(StatsBox)`
  @media only screen and (max-width: 1024px) {
    width: 46%;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1450px) {
    width: 45%;
  }
`;

const FlexBetweenDemoShop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1450px) {
    width: 90%;
  }
`;

const StatImageBox = styled.div`
  background: #3853a3;
  width: 106px;
  height: 135px;
  box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
  border-radius: 15px;
  padding: 32px 18px 33px 18px;
  @media only screen and (max-width: 480px) {
    width: 50px;
    height: 50px;
    padding: 5px 10px;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    width: 80px;
    height: 80px;
    padding: 5px 10px;
  }
`;

const StatImage = styled.img`
  width: 70px;
  height: 70px;
  object-fit: contain;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }
`;

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1450px) {
    padding: 40px;
  }
`;

const OrdersReady = styled.div`
  background: #ffffff;
  box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
  border-radius: 20px;
  width: 866px;
  margin-bottom: 41px;
  @media (max-width: 1600px) {
    width: 747px;
  }
`;
const TopProducts = styled.div`
  width: 866px;
  height: 147px;
  background: #ffffff;
  box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
  border-radius: 20px;
  margin-bottom: 56px;
  @media (max-width: 1600px) {
    width: 747px;
  }
`;
const SingleOrder = styled.div`
  padding: 0 25.9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 49px;
  width: 100%;
  border-top: 1px solid rgba(126, 148, 171, 0.1);
  &:first-child {
    border-top: 1px solid transparent;
  }
  &:hover {
    transition: 0.3s all ease;
    background: #ffa800;
  }
  &:hover ${BlueTextBigger} {
    transition: 0.3s all ease;
    color: #fff;
    cursor: pointer;
  }
  &:hover ${BlueTextMedium} {
    transition: 0.3s all ease;
    color: #fff;
    cursor: pointer;
  }
`;
const Arrow = styled.img`
  width: 7.1px;
  height: 15.84px;
`;
const Activity = styled.div`
  background: #ffffff;
  box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
  border-radius: 20px;
  width: 265px;
  padding: 19px 0 0 20px;
  margin-bottom: 56px;
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 70%;
    text-align: center;
  }
`;
const OrderPlaced = styled.div`
  height: 90px;
  &:last-child {
    display: block;
  }
  &:not(:first-child) {
    margin-top: -4px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 100%;
    text-align: center;
  }
`;
const LogoBox = styled.img`
  width: 170px;
  @media only screen and (max-width: 480px) {
    margin-inline: auto;
    display: none;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 140px;
    margin-inline: auto;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 160px;
    margin-inline: auto;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1400px) {
    width: 180px;
    margin-inline: auto;
  }
`;

const HomeLeftSide = styled.div`
  margin: 43px 65px 56px 32px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1450px) {
    align-items: center;
    margin-inline: 0;
  }
  @media only screen and (max-width: 450px) {
    position: relative;
    margin-bottom: 0;
  }
`;
const IconText = styled(GrayTextBold)`
  font-size: 16px;
  line-height: 30px;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    margin-right: 20px;
  }
`;

const HomeSection = styled.div`
  @media only screen and (max-width: 1024px) {
    padding-inline: 10px;
  }
  @media only screen and (max-width: 450px) {
    margin-top: 10px;
  }
`;

const PagesSection = styled.div``;
const TranslationsSection = styled.div``;

const CustomerSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CustomerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 1100px;
  margin-top: 80px;
  @media screen and (max-width: 450px) {
    width: 100%;
    margin-top: 20px;
  }
  @media only screen and (min-width: 451px) {
    margin-top: 0;
  }
`;
const CustomerHeader = styled(Header)`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 0;

  @media only screen and (max-width: 1450px) {
    width: 100%;
    justify-content: center;
  }
`;
const CreateCustomer = styled(ButtonBlue)`
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  color: #fff;
  // margin-left: 30px;
  padding: 3px 30px;
  border-radius: 10px;
  transition: 0.3s all ease;
  &:hover {
    background: #fff;
    transition: 0.3s all ease;
    color: #ffa800;
  }
  @media only screen and (max-width: 1450px) {
    margin-left: 0px;
  }
`;
const CreateIfEmpty = styled(ButtonBlue)`
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  color: #fff;
  padding: 3px 45px;
  border-radius: 10px;
  transition: 0.3s all ease;
  &:hover {
    background: #fff;
    transition: 0.3s all ease;
    color: #ffa800;
  }
`;
const CreateCustomerGray = styled(ButtonGray)`
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  color: #fff;
  padding: 3px 30px;
  border-radius: 10px;
  margin-left: 20px;
  border: none;
  outline: none;
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 450px) {
    position: absolute;
    right: -45px;
    top: -60px;
    flex-direction: row-reverse;
    gap: 15px;
  }
`;

const AvatarText = styled.div``;

const AvatarImg = styled.img`
  border-radius: 10px;
  width: 52px;
  height: 52px;
  @media only screen and (max-width: 1024px) {
    width: 42px;
    height: 42px;
  }
  @media only screen and (max-width: 450px) {
    display: none;
  }
`;

const AllCustomersBox = styled.div`
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 56px;
  width: 1050px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  @media only screen and (max-width: 450px) {
    border-radius: 0;
  }
  @media only screen and (max-width: 1450px) {
    width: 100%;
  }
`;

const CustomersBoxFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: 1100px) {
    text-align: center;
  }
`;

const CustomerBoxHeader = styled(BlueTextSmaller)`
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  color: #3853a3;
  display: inline;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  padding: 20px 25px 20px 25px;
  width: 100%;
  border-bottom: 1px solid rgba(126, 148, 171, 0.1);
`;

const CustomerBoxHeaderNoBorder = styled(BlueTextSmaller)`
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  color: #3853a3;
  display: inline;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  padding: 20px 25px 20px 25px;
  width: 100%;
`;

const CustomerFiltersBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 12px;
  padding: 0 20px;
  width: 100%;
  @media only screen and (max-width: 1450px) {
    margin-block: 30px;
  }
`;

const CustomerFilterButton = styled(ButtonTransparentYellow)`
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  padding: 1px 40px;
  font-size: 15px;
  line-height: 30px;
  border-radius: 10px;
  margin-right: 10px;
  transition: 0.5s all ease;
  &:hover {
    transition: 0.5s all ease;
    background: #ffa800;
    color: #fff;
  }
`;

const CustomerInputWrap = styled.div`
  border-radius: 10px;
  position: relative;
  display: flex;
  height: 33px;
  width: 100%;
  z-index: 1;
`;

const CustomerInput = styled.input`
  outline: none;
  transition: 0.5s all ease;
  background: ${(props) => (props.customerFocus ? "#EBEBEB" : "#fff")};
  border: ${(props) =>
    props.customerFocus ? "1px solid transparent" : "1px solid #3853a3"};
  border-radius: 10px;
  width: 100%;
  padding: 2px 0 0 20px;
  position: relative;
  height: 100%;
  color: #3853a3;
  z-index: 2;
  &::placeholder {
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    transition: 0.5s all ease;
    color: ${(props) => (props.customerFocus ? "transparent" : "#3853a3")};
  }
  @media only screen and (max-width: 450px) {
    text-align: center;
    padding-left: 0;
  }
`;

const CustomerCross = styled(Cross)`
  opacity: ${(props) => (props.customerFocus ? "1" : "0")};
  width: 15px;
  height: 15px;
  top: 10px;
  z-index: 3;
`;

const CustomerTable = styled.table`
  display: flex;
  align-items: start;
  padding: 0 28px 0 43px;
  width: 100%;
  justify-content: space-between;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
`;

const CustomerSingleTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: 190px;
`;

const CustomerName = styled(CustomerSingleTable)``;
const CustomerEmail = styled(CustomerSingleTable)``;
const CustomerOrders = styled(CustomerSingleTable)`
  margin-right: 0;
  align-items: center;
`;

const CustomerTableHeader = styled.div`
  display: flex;
  align-items: center;
`;

const CustomerArrow = styled.img`
  width: 20px;
  height: 20px;
`;

const SingleCustomers = styled.div`
  display: flex;
  flex-direction: column;
`;
const SingleCustomersText = styled(GrayTextMedium)`
  margin: 10px 0 10px 0;
`;

const PaginationBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 30px 15px 33px;
  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
`;

const RowsNumber = styled(BlueTextMedium)``;
const PaginationArrowBox = styled.div``;
const PaginationArrows = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
`;

// PROFILE SETTINGS

const ProfileSettingsSection = styled(CustomerSection)``;

const PublishedButton = styled.button`
  background: #bfe795;
  border-radius: 10px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #386da5;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: none;
  border: none;
  padding: 4.5px 25px;
`;

export {
  CreateCustomerGray,
  ContentBox,
  PublishedButton,
  ProfileSettingsSection,
  AvatarText,
  AvatarImg,
  PaginationArrowBox,
  PaginationBox,
  RowsNumber,
  PaginationArrows,
  SingleCustomersText,
  SingleCustomers,
  CustomerName,
  CustomerEmail,
  CustomerOrders,
  CustomerTableHeader,
  CustomerArrow,
  CustomerTable,
  CustomerBoxHeader,
  CustomerFiltersBox,
  CustomerFilterButton,
  CustomerInputWrap,
  CustomerInput,
  CustomerCross,
  CreateCustomer,
  Avatar,
  AllCustomersBox,
  CustomersBoxFlex,
  CustomerHeader,
  CustomerContainer,
  CustomerSection,
  PagesSection,
  TranslationsSection,
  HomeSection,
  IconText,
  HomeLeftSide,
  LogoBox,
  DemoShopSection,
  DescText,
  DescBox,
  GrayText,
  IconBox,
  BlueTextMini,
  BlueTextBigger,
  BlueTextSmaller,
  SingleOrder,
  Arrow,
  Content,
  OrderPlaced,
  StatText,
  StatImage,
  Container,
  HomeIcons,
  StatsBox,
  Header,
  StatLeft,
  StatRight,
  OrdersReady,
  TopProducts,
  Activity,
  SingleIcon,
  StatImageBox,
  CustomerBoxHeaderNoBorder,
  FlexDemoShop,
  FlexBetweenDemoShop,
};
