import styled from "styled-components";
import {
  BlueTextBold,
  BlueTextMedium,
  ButtonBlue,
  ButtonYellow,
  GrayTextMedium,
  WhiteTextBold,
  WhiteTextMedium,
} from "../../../styles/GlobalStyles";

const ChooseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 41px 53px 152px 54px;
  @media only screen and (max-width: 768px) {
    padding-bottom: 30px;
  }
`;
const ChooseSection = styled.div``;

const ChooseHeader = styled(BlueTextMedium)`
  text-align: center;
  font-size: 3rem;
  margin-bottom: 80px;
  display: block;
  padding-top: 60px;
  @media only screen and (max-width: 768px) {
    padding-top: 60px;
  }
`;

const FlexResponsive = styled.div`
  display: flex;

  @media only screen and (max-width: 1200px) {
    diplay: flex;
    flex-direction: column;
  }
`;

const RegularPlan = styled.div`
  width: 450px;
  height: 620px;
  background: #ffffff;
  border: 2px solid #dadada;
  border-radius: 30px;
  margin-right: 50px;
  transition: 0.3s all ease;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: 0.3s all ease;
    cursor: pointer;
    transform: translateY(-10px);
  }

  @media only screen and (max-width: 480px) {
    width: 320px;
    margin-bottom: 30px;
    margin-right: 0;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 380px;
    margin-bottom: 30px;
    margin-right: 0;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 320px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 400px;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1480px) {
    width: 350px;
  }
`;

const PremiumPlan = styled.div`
  width: 450px;
  height: 620px;
  background: #ffa800;
  border-radius: 30px;
  transition: 0.3s all ease;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: 0.3s all ease;
    cursor: pointer;
    transform: translateY(-10px);
  }

  @media only screen and (max-width: 480px) {
    width: 320px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 380px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 320px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 400px;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1480px) {
    width: 350px;
  }
`;

const HeaderRegular = styled(BlueTextMedium)`
  text-align: center;
  font-size: 50px;
  display: block;
  line-height: 160px;
`;

const OfferPriceHeaderRegular = styled(GrayTextMedium)`
  font-size: 30px;
  line-height: 18px;
  margin-top: 20px;
`;

const OfferPriceRegular = styled(BlueTextBold)`
  font-size: 80px;
  line-height: 130px;

  @media only screen and (max-width: 480px) {
    font-size: 40px;
  }

  @media only screen and (min-width: 481px) and (max-width: 1200px) {
    font-size: 50px;
  }
`;
const CreateButtonRegular = styled.button`
  background: #ffa800;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  padding: 14.5px 149.5px;
  border: 2px solid transparent;
  &:hover {
    color: FFA800;
    border: 2px solid FFA800;
    transition: 0.5s all ease;
  }
`;

const PlanUl = styled.ul`
  text-align: center;
  text-decoration: none;
  list-style: none;
  padding-bottom: 28px;
`;
const HeaderPremium = styled(WhiteTextMedium)`
  text-align: center;
  font-size: 50px;
  display: block;
  line-height: 160px;
`;

const OfferPriceHeaderPremium = styled(WhiteTextMedium)`
  font-size: 30px;
  line-height: 0px;
  margin-top: 50px;
`;

const OfferPricePremium = styled(WhiteTextBold)`
  font-size: 80px;
  line-height: 140px;

  @media only screen and (max-width: 480px) {
    font-size: 40px;
  }

  @media only screen and (min-width: 481px) and (max-width: 1200px) {
    font-size: 50px;
  }
`;
const CreateButtonPremium = styled(ButtonBlue)`
  font-size: 16px;
  line-height: 23px;
  padding: 14.5px 149.5px;
`;

export {
  ChooseSection,
  HeaderPremium,
  HeaderRegular,
  OfferPriceHeaderPremium,
  CreateButtonPremium,
  OfferPricePremium,
  PlanUl,
  CreateButtonRegular,
  OfferPriceRegular,
  OfferPriceHeaderRegular,
  RegularPlan,
  PremiumPlan,
  ChooseContainer,
  ChooseHeader,
  FlexResponsive,
};
