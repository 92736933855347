import React from "react";
import {
  NewsSection,
  BackgroundImage,
  NewsText,
  Date,
  CategoryName,
  Title,
  Paragraph,
  NewsColumn,
} from "./styles";
import { FishingManBigger } from "../../assets/landingPage/landingPageAssets";
import MerProgram from "../../assets/news/MER-Program.png";
import Robotcode from "../../assets/news/Robotcode.png";
import Kotor from "../../assets/news/Kotor.png";
import { useTranslation } from "react-i18next";

const MiroiSonsNews = () => {
  const { t } = useTranslation();
  

  return (
    <>
      <NewsSection>
        <BackgroundImage src={Kotor} alt="news-image" />
        <NewsText>
          <NewsColumn>
            <div style={{ height: "41px" }}>
              <Date>4. avgust 2023.</Date>
            </div>
            <div style={{ height: "41px" }}>
              <CategoryName>Prodavnice</CategoryName>
            </div>
            <Title>Miro & Sons iz Kotora je prvi korisnik platforme mojbiz.me</Title>
            <Paragraph>
            Preduzeće Miro & Sons d.o.o. iz Kotora će biti prvi korisnik platforme mojbiz.me. Nakon prihvatanja ponude preduzeća Asenvicon d.o.o. iz Kotora za razvoj platforme za on-line kupovinu preduzeće Miro & Sons će razviti svoju digitalnu prodavnicu – prodajni izlog.
            </Paragraph>
            <p><br /></p>
            <p><br /></p>
            <Paragraph>
            Preduzeće Miro & Sons je porodična turistička agencija sa dugogodišnjim iskustvom i dobrom reputacijom u Crnoj Gori i regionu. Prodajni izlog na platformi mojbiz.me će im omogućiti da predstave raznovrsne ture koje su pripremili za posjetioce iz cijelog svijeta. Među njima se izvaja pješačka tura kroz Kotor. Ona turistima omogućava da se na 90-minutnom obilasku grada sa vodičem upoznaju sa više od 2.500 godina fascinantne istorije Kotora. Ta tura otkriva zadivljujuću arhitekturu koja okružuje trgove ovog utvrđenog grada na listi UNESCO-a.  
            </Paragraph>
            <p><br /></p>
            <p><br /></p>
          </NewsColumn>
        </NewsText>
      </NewsSection>
    </>
  );
};

export default MiroiSonsNews;
