import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DashboardTrash,
  OrangeTrash,
} from "../../../assets/landingPage/landingPageAssets";

const SingleAttribute = ({ item, removeHandler }) => {
  const { t } = useTranslation();
  const [trashHovered, setTrashHovered] = useState(false);
  return (
    <tr className="attributes-tr">
      <td className="attributes-td" width="60%">
        <div className="td-box">
          <h4 className="gray-text" style={{ textAlign: "left" }}>
            {item?.name}
          </h4>
        </div>
      </td>
      <td className="gray-text">{item?.number_of_occurrence}</td>
      <td>
        <div
          className="td-box"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            borderRight: "30px solid #fff",
          }}
        >
          {item?.number_of_occurrence > 0 ? (
            <button className="trash-btn-disabled">
              <div className="trash-btn-content">
                <img src={DashboardTrash} alt="" />
                <h4 className="trash-text">{t("Remove")}</h4>
              </div>
            </button>
          ) : (
            <button
              className="trash-btn"
              onClick={() => removeHandler("attribute", item?.id)}
              onMouseOver={() => setTrashHovered(true)}
              onMouseOut={() => setTrashHovered(false)}
            >
              <div className="trash-btn-content">
                <img
                  src={DashboardTrash}
                  alt=""
                  style={trashHovered ? { opacity: 0 } : { opacity: 1 }}
                />
                <img
                  src={OrangeTrash}
                  alt=""
                  className="orange-trash"
                  style={trashHovered ? { opacity: 1 } : { opacity: 0 }}
                />
                <h4 className="trash-text">{t("Remove")}</h4>
              </div>
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default SingleAttribute;
