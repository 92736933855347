import styled from "styled-components";
import { Li, BlueTextBold } from "../../../styles/GlobalStyles";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const NavbarContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: 100px;
  padding: 0px 40px;
  background: #3853a3;
  position: relative;
  border-bottom: 1px solid #4360bd;
  z-index: 50;
  top: 0;
  left: 0;
  @media only screen and (max-width: 480px) {
    padding: 10px 20px;
    max-width: 100%;
    height: 80px;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    padding: 20px 30px;
    max-width: 100%;
    height: 80px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 20px 40px;
    max-width: 100%;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1400px) {
    padding: 20px 45px;
    max-width: 100%;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1490px) {
    padding: 20px 25px;
    max-width: 100%;
  }
`;
const Ul = styled.ul`
  margin-left: 26px;
  display: flex;
  @media only screen and (max-width: 1400px) {
    display: none;
  }
`;

const ResponsiveUl = styled.ul`
  font-family: "Jost";
  font-style: normal;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3853a3;
  list-style-type: none;
  position: absolute;
  width: 100%;
  top: 80px;
  right: 0;
  padding: 40px 20px;
  gap: 10px;
  z-index: 40;
  a {
    color: white;
    text-decoration: none;
    @media only screen and (min-width: 481px) and (max-width: 768px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      font-size: 18px;
    }
    @media only screen and (min-width: 1025px) and (max-width: 1400px) {
      font-size: 20px;
    }
  }
  hr {
    width: 100%;
    border: none;
    height: 1px;
    background-color: white;
    opacity: 0.4;
    margin-block: 10px;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    top: 80px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    top: 90px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1400px) {
    top: 100px;
  }
  @media only screen and (min-width: 1400px) {
    display: none;
  }
`;

const LiHome = styled(Li)`
  width: 130px;
  text-align: center;
  border: 2px solid transparent;
  margin-right: 24px;
  cursor: pointer;
  &:hover {
    border: 2px solid #ffffff;
    border-radius: 100px;
  }

  @media only screen and (min-width: 1400px) and (max-width: 1465px) {
      margin-right: 0;
      :not(:last-child) {
      width: 125px;
      }
    }
`;

const HrefLink = styled(HashLink)`
  font-family: "Jost";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 41px;
  color: #fff;
  text-decoration: none;
  width: 100%;
  display: block;
  @media only screen and (max-width: 1400px) {
    line-height: 0;
  }
  .logo {
    @media only screen and (max-width: 480px) {
      width: 100px;
    }
    @media only screen and (min-width: 481px) and (max-width: 768px) {
      width: 120px;
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      width: 140px;
    }
    @media only screen and (min-width: 1025px) and (max-width: 1400px) {
      width: 160px;
    }
  }
`;

const MenuWrapper = styled.div`
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  @media only screen and (max-width: 1400px) {
    display: block;
  }
`;

const ShoppingCartImageBox = styled.div`
  border-radius: 50%;
  cursor: pointer;
`;

const ResponsiveCartImageBox = styled.div`
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  background: #4360bd;
  width: 40px;
  height: 40px;
  &:hover {
    background: #fff;
    transition: 0.3s all ease;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  @media only screen and (max-width: 350px) {
    margin-left: 60px;
  }

  @media only screen and (min-width: 351px) and (max-width: 380px) {
    margin-left: 100px;
  }

  @media only screen and (min-width: 381px) and (max-width: 500px) {
    margin-left: 130px;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    margin-left: 200px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1200px) {
    margin-left: 380px;
  }

  @media only screen and (min-width: 1201px) and (max-width: 1300px) {
    margin-left: 460px;
  }

  @media only screen and (min-width: 1301px) and (max-width: 1400px) {
    margin-left: 500px;
  }

  @media only screen and (min-width: 1401px) {
    display: none;
  }
`;

const ShoppingCartImage = styled.img`
  padding: 15px 18px 12px 18px;
  @media only screen and (max-width: 1400px) {
    padding: 9px 8px;
  }
`;

const CartNumber = styled.h3`
  position: absolute;
  padding: 2px 7px;
  border-radius: 50%;
  top: 34px;
  left: 36px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  background: #ffa800;
  @media only screen and (max-width: 1400px) {
    left: 25px;
    top: 25px;
    padding: 1px 6px;
    font-size: 12px;
  }
`;

const SearchBox = styled.div`
  position: relative;
  display: flex;
  background: #ffffff;
  border-radius: 30px;
  width: 660px;
  height: 54px;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1400px) {
    display: none;
  }
`;

const SearchBoxResponsive = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  @media only screen and (min-width: 1400px) {
    display: none;
  }
`;

const ShopsArrow = styled.img`
  width: 10px;
  height: 5px;
  margin-left: 18px;
`;

const RegisterBox = styled.div`
  display: flex;
  width: 100%;
  margin-left: 81px;
`;

const NavbarRegButtons = styled.button`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 8px 29px;
  outline: none;
  cursor: pointer;
`;

const SellersCorner = styled.span`
  font-family: "Jost";
  font-style: normal;
  color: #3853a3;
  width: 170px;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  display: block;
  position: relative;
  border-right: 1px solid rgba(126, 148, 171, 0.2);
  text-align: center;
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    font-size: 18px;
    line-height: 33px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 20px;
    line-height: 43px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1400px) {
    font-size: 24px;
    line-height: 50px;
  }
  @media only screen and (max-width: 1400px) {
    color: white;
    border-right: 0;
  }
`;
const BlueLinks = styled(Link)`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #3853a3;
  text-decoration: none;
  padding: 7px 14.5px;
  border: 2px solid transparent;
  border-radius: 100px;
  &:hover {
    border: 2px solid #3853a3;
    border-radius: 100px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 0;
    line-height: 30px;
    font-weight: 300;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1400px) {
    padding: 0;
    line-height: 50px;
    font-weight: 300;
  }
`;
const LogIn = styled(Link)`
    background: #FFA800;
    border-radius: 100px;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color:#fff;
    text-align-center;
    padding:6px 31.6px;
    margin-right:10px;
    text-decoration:none;
    border: 2px solid #FFA800;
    border-radius: 100px;
    &:hover {
        background: transparent;
        border: 2px solid #FFA800;
        border-radius: 100px;
        color:#FFA800;
    }
`;
const LogOut = styled.button`
    background: #FFA800;
    border-radius: 100px;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color:#fff;
    text-align-center;
    padding:6px 31.6px;
    margin-right:10px;
    text-decoration:none;
    border: 2px solid #FFA800;
    border-radius: 100px;
    cursor:pointer;
    &:hover {
        background: transparent;
        border: 2px solid #FFA800;
        border-radius: 100px;
        color:#FFA800;
    }
`;

const Language = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.flagOpen ? "#f0f5fb" : "rgba(126, 148, 171, 0.2)"};
  border-radius: 30px;
  height: 37px;
  padding: 0 20.23px 0 16.5px;
  transition: 0.3s all ease;
  position: relative;
  z-index: 8;
  &:hover {
    background: #f0f5fb;
    box-shadow: 0px 4px 4px rgba(126, 148, 171, 0.3);
    border-radius: 30px;
    transition: 0.3s all ease;
    cursor: pointer;
  }
  @media only screen and (max-width: 1400px) {
    background: #f0f5fb;
    height: 40px;
    font-size: 16px;
    line-height: 23px;
  }
`;
const FlagMenu = styled.div`
  position: absolute;
  z-index: 1;
  right: 3px;
  top: 19px;
  width: 85px;
  height: 95px;
  background: #ccd5df;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
  transition: 0.5s all ease;
  opacity: ${(props) => (props.flagOpen ? "1" : "0")};
  @media only screen and (max-width: 600px) {
    border-radius: 0px 0px 10px 10px;
    top: 90%;
    left: 57.5%;
  }
  @media only screen and (min-width: 601px) and (max-width: 800px) {
    border-radius: 0px 0px 10px 10px;
    top: 100%;
    left: 54.2%;
  }
  @media only screen and (min-width: 801px) and (max-width: 1024px) {
    border-radius: 0px 0px 10px 10px;
    top: 100%;
    left: 53.2%;
  }
`;

const FlagUl = styled.ul`
  list-style: none;
  padding-top: 31px;
`;
const FlagSpan = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #3853a3;
  display: block;
  margin-left: 6px;
`;
const FlagLi = styled.li`
  width: 100%;
  height: 15px;
  transition: 0.3s all ease;
  cursor: pointer;
  &:first-child {
    margin-bottom: 8px;
  }
  transition: 0.3s all ease;
`;

const Flag = styled.img`
  width: 21.57px;
  height: 11.76px;
`;
const Lang = styled(BlueTextBold)`
  font-size: 9px;
  line-height: 13px;
  margin-right: 5px;
  text-transform: uppercase;
`;

const Dropdown = styled.img`
  width: 8.7px;
  height: 4.78px;
  margin-right: 5px;
  transform: ${(props) => (props.flagOpen ? "rotate(180deg)" : "none")};
  transition: 0.3s all ease;
`;

export {
  LogOut,
  CartNumber,
  ShoppingCartImage,
  ShoppingCartImageBox,
  FlagUl,
  FlagLi,
  FlagSpan,
  FlagMenu,
  Lang,
  Flag,
  LogIn,
  Dropdown,
  Language,
  BlueLinks,
  SellersCorner,
  NavbarContainer,
  Ul,
  LiHome,
  HrefLink,
  SearchBox,
  ShopsArrow,
  RegisterBox,
  NavbarRegButtons,
  MenuWrapper,
  ResponsiveUl,
  ResponsiveCartImageBox,
  SearchBoxResponsive,
};
