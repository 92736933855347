import React, { useRef } from "react";
import { useState } from "react";
import {
  AvatarBlank,
  CustomerArrowUp,
  CustomerRealArrow,
  PaginationArrow,
  DashboardTrash,
  DashboardEdit,
  GrayArrow,
  PaginationArrowLeft,
  GrayArrowRight,
  BlueCross,
} from "../../../assets/landingPage/landingPageAssets";
import {
  BlueTextBold,
  BlueTextMedium,
  GrayTextMedium,
} from "../../../styles/GlobalStyles";
import {
  AllCustomersBox,
  Avatar,
  AvatarImg,
  AvatarText,
  BlueTextSmaller,
  CreateCustomer,
  CreateCustomerGray,
  CustomerArrow,
  CustomerBoxHeader,
  CustomerContainer,
  CustomerCross,
  CustomerFilterButton,
  CustomerFiltersBox,
  CustomerHeader,
  CustomerInput,
  CustomerInputWrap,
  CustomersBoxFlex,
  CustomerSection,
  PaginationArrows,
  PaginationBox,
} from "../styles";
import "../../DemoShop copy/stylesAllPages.scss";
import CreateNewProduct from "./CreateNewProduct";
import { useGlobalContext } from "../../../context/context";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import EditProduct from "./EditProduct";
import axios from "axios";
import SingleProduct from "./SingleProduct";
import { useTranslation } from "react-i18next";

const productsData = [
  {
    id: 3,
    name: "Ruzmarin usitnjen malo pakovanje",
    description:
      "Ruzmarin (Rosmarinus officinalis L.) je žbunasta višegodišnja biljka koja potiče iz mediteranskih krajeva. Mitologija govori o tome da je ruzmarin stvoren od pjene nastale pri Afroditinom izlasku iz mora pa se naziva i Afroditin cvijet. U antičko doba bio je kultna biljka, kojoj je pripisivan i stimulativni efekat na moždanu aktivnost pa su njegove grančice uplitane u krune kojima su studenti i intelektualci opasivali glave. Ruzmarin je biljka božanstvenog ukusa i sadrži supstance koje su korisne za stimulaciju imunog sistema, bolju cirkulaciju, dobru probavu i neophodna je kao dodatak jelima u svakoj a posebno mediteranskoj kuhinji. Nadzemni dio ruzmarina sadrži brojne aktivne sastojke od kojih je najznačajnije etarsko ulje, bogato kamforom, cineolom i pinenom. Ostali aktivni sastojci ruzmarina su flavonoidi (luteolin i diosmin), tanini, diterpeni, triterpeni, steroidi, fitoestrogeni, holin, fenolne kiseline (hlorogenska i ruzmaricinska), gorke supstance i lipidi. Ruzmarin poboljšava pamćenje, sprječava kancer, poboljšava cirkulaciju.",
    out_of_stock: false,
    price: 5.99,
    category: {
      id: 1,
      name: "Začini",
      description: "Accessories description",
    },
    attributes: ["100 gr"],
    images: [
      {
        id: 3,
        image_url: "https://i.ibb.co/hK2bKkq/Ruzmarin-usitnjen.jpg",
      },
      {
        id: 3,
        image_url: "https://i.ibb.co/7CBSDxQ/Ruzmarin-biljka.png",
      },
    ],
  },
  {
    id: 3,
    name: "Ruzmarin usitnjen veliko pakovanje",
    description:
      "Ruzmarin (Rosmarinus officinalis L.) je žbunasta višegodišnja biljka koja potiče iz mediteranskih krajeva. Mitologija govori o tome da je ruzmarin stvoren od pjene nastale pri Afroditinom izlasku iz mora pa se naziva i Afroditin cvijet. U antičko doba bio je kultna biljka, kojoj je pripisivan i stimulativni efekat na moždanu aktivnost pa su njegove grančice uplitane u krune kojima su studenti i intelektualci opasivali glave. Ruzmarin je biljka božanstvenog ukusa i sadrži supstance koje su korisne za stimulaciju imunog sistema, bolju cirkulaciju, dobru probavu i neophodna je kao dodatak jelima u svakoj a posebno mediteranskoj kuhinji. Nadzemni dio ruzmarina sadrži brojne aktivne sastojke od kojih je najznačajnije etarsko ulje, bogato kamforom, cineolom i pinenom. Ostali aktivni sastojci ruzmarina su flavonoidi (luteolin i diosmin), tanini, diterpeni, triterpeni, steroidi, fitoestrogeni, holin, fenolne kiseline (hlorogenska i ruzmaricinska), gorke supstance i lipidi. Ruzmarin poboljšava pamćenje, sprječava kancer, poboljšava cirkulaciju.",
    out_of_stock: false,
    price: 0.01,
    category: {
      id: 1,
      name: "Začini",
      description: "Accessories description",
    },
    attributes: ["1,000 gr"],
    images: [
      {
        id: 3,
        image_url: "https://i.ibb.co/hK2bKkq/Ruzmarin-usitnjen.jpg",
      },
      {
        id: 3,
        image_url: "https://i.ibb.co/7CBSDxQ/Ruzmarin-biljka.png",
      },
    ],
  },
  {
    id: 3,
    name: "Lovorov list",
    description:
      "Lovorov list se bere sa lovorovog drveta (Laurus nobilis L.), koje raste u Sredozemlju. Listovi su debeli, mesnati i oštri pri vrhu, tamnozelene boje. U antičko doba je lovorov list bio svjeta biljka, simbol slave, vojevanja i pobjede. Rimljani su pobjednike kitili lovorovim vijencima kako u ratnim pohodima, tako i u gladijatorskim arenama. U današnje doba lovor se kao začin najčešće koristi kao sušeni list. Često se stavlja u supe, čorbe, paprikaš, pikatna jela, turšiju, kao i u marinade za meso, živinu i ribu. Kuvari lovorov list često prije korišćenja presaviju ili polome kako bi se aroma pojačala. Ukoliko se ne osjeća nikakav miris prilikom savijanja i lomljenja lista, on je izgubio svoja svojstva, pa ga možete baciti. Lovorov list dobro utiče na rad sistema za varenje, otklanja nadutost, potpomaže rad jetre i ojačava imunitet. Pomaže kod začeljivanja rana, kod grčeva, uspostavlja normalan rad želuca, a pozitivno utiče i kod kamenja u bubrezima kao i dijareje i suvog kašlja. U pomenute svrhe se pije čaj od lovorovog lista.",
    out_of_stock: false,
    price: 5.99,
    category: {
      id: 1,
      name: "Začini",
      description: "Accessories description",
    },
    attributes: ["10 gr"],
    images: [
      {
        id: 3,
        image_url:
          "https://i.ibb.co/r0NCdMk/lovor-list-osusen.jpg",
      },
      {
        id: 3,
        image_url:
          "https://i.ibb.co/8BS0rFX/Lovor-list.png",
      },
    ],
  },
  {
    id: 3,
    name: "Lovorov list veliko pakovanje",
    description: "Lovorov list se bere sa lovorovog drveta (Laurus nobilis L.), koje raste u Sredozemlju. Listovi su debeli, mesnati i oštri pri vrhu, tamnozelene boje. U antičko doba je lovorov list bio svjeta biljka, simbol slave, vojevanja i pobjede. Rimljani su pobjednike kitili lovorovim vijencima kako u ratnim pohodima, tako i u gladijatorskim arenama. U današnje doba lovor se kao začin najčešće koristi kao sušeni list. Često se stavlja u supe, čorbe, paprikaš, pikatna jela, turšiju, kao i u marinade za meso, živinu i ribu. Kuvari lovorov list često prije korišćenja presaviju ili polome kako bi se aroma pojačala. Ukoliko se ne osjeća nikakav miris prilikom savijanja i lomljenja lista, on je izgubio svoja svojstva, pa ga možete baciti. Lovorov list dobro utiče na rad sistema za varenje, otklanja nadutost, potpomaže rad jetre i ojačava imunitet. Pomaže kod začeljivanja rana, kod grčeva, uspostavlja normalan rad želuca, a pozitivno utiče i kod kamenja u bubrezima kao i dijareje i suvog kašlja. U pomenute svrhe se pije čaj od lovorovog lista.",
    out_of_stock: false,
    price: 0.01,
    category: {
      id: 1,
      name: "Začini",
      description: "Accessories description",
    },
    attributes: ["1,000 gr"],
    images: [
      {
        id: 3,
        image_url:
          "https://i.ibb.co/r0NCdMk/lovor-list-osusen.jpg",
      },
      {
        id: 3,
        image_url:
          "https://i.ibb.co/8BS0rFX/Lovor-list.png",
      },
    ],
  },
  {
    id: 3,
    name: "Orada 400-600 gr",
    description: "Orada (lat. Sparus aurata L.) je riba belog, nežnog i suvog mesa. Ona je tipična mediteranska riba, koja se najviše sreće u Sredozemnom moru, a nešto rjeđe u Crnom moru. Ova riba živi na mjestima obraslim algama, gdje ima pjeskovitog dna, na dubini oko 30 metara. Hrani se malim beskičmenjacima.Tijelo orade je široko, ovalnog oblika i sa strane spljošteno. Može dostići dužinu do 70 cm i težinu do 16 kg, ali takvi primjerci su prava rijetkost. Ima male oči, a stomak joj je skoro ravan. Lovi se mrežama potegačama, plivaricom, vršom, parangalom, štapom, ostima i puškom za podvodni ribolov. Samo prije dvije decenije orada je bila veoma skupa zbog ograničenog lova i prisustva na tržištu. Krajem 80 - tih godina prošlog vijeka se razvija tehnologija gajenja orade na specijalizovanim farmama, pa to utiče da se cijene sniže. U našoj ponudi se nalazi orada sa farme u Bokokotorskom zalivu. Miris orade nije mnogo jak, što dozvoljava korišćenje raznih začina. Naravno, prirodno se nameću biber i limun, ali se mogu koristiti i lovorov list, svež ruzmarin i ruj. Cijena je izražena po kilogramu.",
    out_of_stock: false,
    price: 9.99,
    category: {
      id: 1,
      name: "Riba",
      description: "Accessories description",
    },
    attributes: ['400-600 gr'],
    images: [
      {
        id: 3,
        image_url: "https://i.ibb.co/cxcbfD2/Orada-na-ledu.jpg",
      },
      {
        id: 3,
        image_url: "https://i.ibb.co/D9WfSwq/Orada.jpg",
      },
      {
        id: 3,
        image_url: "https://i.ibb.co/qm4J62v/Orada-spremljena.png",
      },
    ],
  },
  {
    id: 3,
    name: "Orada 600-800 gr",
    description: "Orada (lat. Sparus aurata L.) je riba belog, nežnog i suvog mesa. Ona je tipična mediteranska riba, koja se najviše sreće u Sredozemnom moru, a nešto rjeđe u Crnom moru. Ova riba živi na mjestima obraslim algama, gdje ima pjeskovitog dna, na dubini oko 30 metara. Hrani se malim beskičmenjacima.Tijelo orade je široko, ovalnog oblika i sa strane spljošteno. Može dostići dužinu do 70 cm i težinu do 16 kg, ali takvi primjerci su prava rijetkost. Ima male oči, a stomak joj je skoro ravan. Lovi se mrežama potegačama, plivaricom, vršom, parangalom, štapom, ostima i puškom za podvodni ribolov. Samo prije dvije decenije orada je bila veoma skupa zbog ograničenog lova i prisustva na tržištu. Krajem 80 - tih godina prošlog vijeka se razvija tehnologija gajenja orade na specijalizovanim farmama, pa to utiče da se cijene sniže. U našoj ponudi se nalazi orada sa farme u Bokokotorskom zalivu. Miris orade nije mnogo jak, što dozvoljava korišćenje raznih začina. Naravno, prirodno se nameću biber i limun, ali se mogu koristiti i lovorov list, svež ruzmarin i ruj. Cijena je izražena po kilogramu.",
    out_of_stock: false,
    price: 12.99,
    category: {
      id: 1,
      name: "Riba",
      description: "Accessories description",
    },
    attributes: ['600-800 gr'],
    images: [
      {
        id: 3,
        image_url: "https://i.ibb.co/cxcbfD2/Orada-na-ledu.jpg",
      },
      {
        id: 3,
        image_url: "https://i.ibb.co/D9WfSwq/Orada.jpg",
      },
      {
        id: 3,
        image_url: "https://i.ibb.co/qm4J62v/Orada-spremljena.png",
      },
    ],
  },
  {
    id: 3,
    name: "Brancin 400-600 gr",
    description: "Brancin (lat. Dicentrarchus labrax) je poznata mediteranska vrsta ribe koja uz obalu ima tridesetak sličnih i različitih imena. Najrasprostranjeniji su brancin i lubin, ali se često koriste i imena agač, levrek, dut, luben, smudut, morski grgeč i dr. Brancin može da naraste do jednog metra dužine, i da dostigne težinu od 14 kg. Po svom izgledu brancin je tipičan grabežljivac. Čvrste je građe, robustan, ali istovremeno izdužene i elegantne linije tijela. Lovi se lakim mamcem, prutom s koturom, laganim parangalom, a veći primjerci se ubijaju harpunom. Brancin je bio jedna od prvih riba u Evropi koja se komercijalno uzgajala. Istorijski gledano, uzgajana je u obalskim lagunama i plimnim rezervoarima prije nego što su razvijene tehnike masovne proizvodnje tokom kasnih 1960-ih. To je najvažnija komercijalna riba koja se široko uzgaja u Mediteranu. U našoj ponudi se nalazi brancin sa farme u Bokokotorskom zalivu. Cijena je izražena po kilogramu.",
    out_of_stock: false,
    price: 9.99,
    category: {
      id: 1,
      name: "Riba",
      description: "Accessories description",
    },
    attributes: ['400-600 gr'],
    images: [
      {
        id: 3,
        image_url:
          "https://i.ibb.co/7k2HZnz/Brancin-600.jpg",
      },
      {
        id: 3,
        image_url:
          "https://i.ibb.co/TbHvH2n/Brancin.jpg",
      },
      {
        id: 3,
        image_url:
          "https://i.ibb.co/4g84Yjw/Brancin-jato.jpg",
      },
    ],
  },
  {
    id: 3,
    name: "Brancin 600-800 gr",
    description: "Brancin (lat. Dicentrarchus labrax) je poznata mediteranska vrsta ribe koja uz obalu ima tridesetak sličnih i različitih imena. Najrasprostranjeniji su brancin i lubin, ali se često koriste i imena agač, levrek, dut, luben, smudut, morski grgeč i dr. Brancin može da naraste do jednog metra dužine, i da dostigne težinu od 14 kg. Po svom izgledu brancin je tipičan grabežljivac. Čvrste je građe, robustan, ali istovremeno izdužene i elegantne linije tijela. Lovi se lakim mamcem, prutom s koturom, laganim parangalom, a veći primjerci se ubijaju harpunom. Brancin je bio jedna od prvih riba u Evropi koja se komercijalno uzgajala. Istorijski gledano, uzgajana je u obalskim lagunama i plimnim rezervoarima prije nego što su razvijene tehnike masovne proizvodnje tokom kasnih 1960-ih. To je najvažnija komercijalna riba koja se široko uzgaja u Mediteranu. U našoj ponudi se nalazi brancin sa farme u Bokokotorskom zalivu. Cijena je izražena po kilogramu.",
    out_of_stock: true,
    price: 12.99,
    category: {
      id: 1,
      name: "Riba",
      description: "Accessories description",
    },
    attributes: ['600-800 gr'],
    images: [
      {
        id: 3,
        image_url:
          "https://i.ibb.co/7k2HZnz/Brancin-600.jpg",
      },
      {
        id: 3,
        image_url:
          "https://i.ibb.co/TbHvH2n/Brancin.jpg",
      },
      {
        id: 3,
        image_url:
          "https://i.ibb.co/4g84Yjw/Brancin-jato.jpg",
      },
    ],
  },
  {
    id: 3,
    name: "Mušlja",
    description: "Mušlja ili dagnja (lat. Mytilus sp.) je rod školjki koje žive na stijenama, molovima, podvodnim stubovima i brodovima.Za vrijeme oseke ljuštura se tako čvrsto zatvori da nekoliko sati, a ponekad i nekoliko dana mogu opstati na suvom. Stopalo kod dagnje izlazi na zadnji kraj tijela i koristi ga da se oslobodi niti bisusa koji izlazi na prednjem kraju tela. Kreće se puzeći po podlozi. Različite vrste su jestive, pa se danas uzgajaju na mnogim mjestima na primorju. Te vrste naseljavaju atlantske i sredozemne vode. U našoj ponudi se nalaze mušlje sa farme u Bokokotorskom zalivu. Cijena je izražena po kilogramu.",
    out_of_stock: true,
    price: 3.99,
    category: {
      id: 1,
      name: "Školjke",
      description: "Accessories description",
    },
    attributes: ['Svježa'],
    images: [
      {
        id: 3,
        image_url:
          "https://i.ibb.co/SydMdSx/Dagnja.jpg",
      },
      {
        id: 3,
        image_url:
          "https://i.ibb.co/D11ymCL/Dagnja-2.jpg",
      },
      {
        id: 3,
        image_url:
          "https://i.ibb.co/54DwBsS/Dagnja-gajenje.jpg",
      },
      {
        id: 3,
        image_url:
          "https://i.ibb.co/rmyTnYs/Dagnja-otvorena",
      },
    ],
  },
  {
    id: 3,
    name: "Kamenice",
    description: "Kamenica ili Evropska ostriga (lat. Ostrea edulis) nastanjuje morske i okeanske obale Evrope i sjeverne Afrike, od Norveške na sjeveru do Maroka i obala Sredozemlja na jugu. Tipično stanište su joj pliće vode, gdje živi pričvršćena za čvrstu podlogu. Kamenica se koristi u ishrani kao veoma cijenjeni plod mora. Upotreba kamenice u ishrani dokumentovana je još od vremena starog Rima. Meki djelovi tijela kamenice sadrže visoke količine proteina, ugljenih hidrata i vitamina A, B1, B2, C i D. Danas se kamenica uzgaja u akvakulturi. U našoj ponudi se nalaze kamenice sa farme u Bokokotorskom zalivu. Cijena je izražena po komadu.",
    out_of_stock: true,
    price: 2.5,
    category: {
      id: 1,
      name: "Školjke",
      description: "Best for saltwater",
    },
    attributes: ['Svježa'],
    images: [
      {
        id: 3,
        image_url:
          "https://i.ibb.co/HN9XDDM/Kamenica.jpg",
      },
      {
        id: 3,
        image_url:
        "https://i.ibb.co/TYSj31j/Kamenica-ljustura.jpg",
      },
      {
        id: 3,
        image_url:
          "https://i.ibb.co/0XYgCWm/Kamenica-gajenje.jpg",
      },
      {
        id: 3,
        image_url:
          "https://i.ibb.co/zXzYSns/Kamenica-servirana.jpg",
      },
    ],
  },
];

const ProductPage = ({ setSections, modalOpen }) => {
  const [ascending, setAscending] = useState({
    title: false,
    category: false,
    availability: false,
    price: false,
  });

  const [customerFocus, setCustomerFocus] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [createNew, setCreateNew] = useState(false);
  const [editProduct, setEditProduct] = useState(false);
  const [productFilter, setProductFilter] = useState("");
  const [singleProduct, setSingleProduct] = useState();
  const customerRef = useRef(null);
  const {
    logUserOut,
    getDemoShopProducts,
    products,
    setProducts,
    productId,
    setProductId,
    baseUrl,
    config,
  } = useGlobalContext();
  const customerCrossHandler = () => {
    setCustomerFocus(false);
    setProductFilter("");
    customerRef.current.value = "";
  };

  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };
  const productsListMaker = () => {
    let list = [];
    productsData.filter((item) => {
      if (item.name.toLowerCase().includes(productFilter.toLowerCase())) {
        list.push(item);
      }
      return list;
    });
    console.log(list);
    return list;
  };
  let itemsPerPage = 7;
  let pageCount = Math.ceil(productsListMaker().length / itemsPerPage);
  let itemsVisited = currentPage * itemsPerPage;
  const pageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    if (productFilter) {
      setCurrentPage(0);
    }
  }, [productFilter]);

  const editProductHandler = (item) => {
    setEditProduct(true);
    setSingleProduct(item);
  };

  const ascendingHandler = (asc) => {
    let data = productsData;
    if (asc === "title") {
      setAscending({ ...ascending, title: !ascending.title });
    } else if (asc === "category") {
      setAscending({ ...ascending, category: !ascending.category });
    } else if (asc === "availability") {
      setAscending({ ...ascending, availability: !ascending.availability });
    } else if (asc === "price") {
      setAscending({ ...ascending, price: !ascending.price });
    }

    if (asc === "title") {
      if (ascending.title) {
        data.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        data.sort((a, b) => b.name.localeCompare(a.name));
      }
    } else if (asc === "price") {
      if (ascending.price) {
        data.sort((a, b) => a.price - b.price);
      } else {
        data.sort((a, b) => b.price - a.price);
      }
    } else if (asc === "category") {
      if (ascending.category) {
        data.sort((a, b) => a.category.name.localeCompare(b.category.name));
      } else {
        data.sort((a, b) => b.category.name.localeCompare(a.category.name));
      }
    } else if (asc === "availability") {
      if (ascending.availability) {
        data.sort((a, b) => a.out_of_stock - b.out_of_stock);
      } else {
        data.sort((a, b) => b.out_of_stock - a.out_of_stock);
      }
    }
  };

  const nextPage = () => {
    if (currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const { t } = useTranslation();

  return (
    <>
      <CustomerSection>
        <CustomerContainer id="container">
          <CustomerHeader>
            <BlueTextSmaller>{t("Products")}</BlueTextSmaller>
            {createNew == true || editProduct == true ? (
              <></>
            ) : (
              <>
                <CreateCustomer onClick={() => setCreateNew(true)}>
                  {t("CreateNew")}{" "}
                  <div
                    style={{
                      fontFamily: "Jost",
                      fontSize: "20px",
                      display: "inline-block",
                      transform: "translate(2px, 1px)",
                    }}
                  >
                    +
                  </div>
                </CreateCustomer>
              </>
            )}
          </CustomerHeader>

          <AllCustomersBox>
            <CustomersBoxFlex id="customersBox">
              {createNew ? (
                <>
                  <CustomerBoxHeader>{t("CreateProduct")}</CustomerBoxHeader>
                  <CreateNewProduct
                    setCreateNew={setCreateNew}
                    createNew={createNew}
                  />
                </>
              ) : editProduct ? (
                <>
                  <CustomerBoxHeader>{t("EditProduct")}</CustomerBoxHeader>
                  <EditProduct
                    setEditProduct={setEditProduct}
                    editProduct={editProduct}
                    singleProduct={singleProduct}
                  />
                </>
              ) : (
                <>
                  <CustomerBoxHeader
                    style={{
                      width: "100%",
                      borderBottom: "1px solid rgba(126, 148, 171, 0.1)",
                    }}
                  >
                    {t("AllProducts")}
                  </CustomerBoxHeader>
                  <CustomerFiltersBox>
                    <CustomerInputWrap customerFocus={customerFocus}>
                      <CustomerCross
                        onClick={customerCrossHandler}
                        customerFocus={customerFocus}
                        src={BlueCross}
                        alt=""
                      />
                      <CustomerInput
                        ref={customerRef}
                        placeholder={t("SearchProductByName")}
                        onClick={() => setCustomerFocus(true)}
                        onChange={(e) => setProductFilter(e.target.value)}
                        customerFocus={customerFocus}
                      />
                    </CustomerInputWrap>
                  </CustomerFiltersBox>
                  <div className="table_wrapper">
                    <table id="table" className="table">
                      {/* headers */}
                      <tbody>
                        <tr className="header">
                          <td width="25%">
                            <div className="td-box-border-left">
                              <h4
                                className="gray-text"
                                style={{ marginRight: "10px" }}
                              >
                                {t("Title")}
                              </h4>
                              <div
                                className="arrowBox"
                                onClick={() => ascendingHandler("title")}
                              >
                                <CustomerArrow src={CustomerArrowUp} alt="" />
                                <img
                                  src={CustomerRealArrow}
                                  alt=""
                                  className={
                                    ascending.title ? "arrow-drop" : "arrow"
                                  }
                                />
                              </div>
                            </div>
                          </td>
                          <th width="15%" className="gray-text">
                            <div className="table-center">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <h4
                                  className="gray-text"
                                  style={{ marginRight: "10px" }}
                                >
                                  {t("CategoryName")}
                                </h4>
                                <div
                                  className="arrowBox"
                                  onClick={() => ascendingHandler("category")}
                                >
                                  <CustomerArrow src={CustomerArrowUp} alt="" />
                                  <img
                                    src={CustomerRealArrow}
                                    alt=""
                                    className={
                                      ascending.category
                                        ? "arrow-drop"
                                        : "arrow"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th width="15%" className="gray-text">
                            <div className="table-center">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <h4
                                  className="gray-text"
                                  style={{ marginRight: "10px" }}
                                >
                                  {t("Availability")}
                                </h4>
                                <div
                                  className="arrowBox"
                                  onClick={() =>
                                    ascendingHandler("availability")
                                  }
                                >
                                  <CustomerArrow src={CustomerArrowUp} alt="" />
                                  <img
                                    src={CustomerRealArrow}
                                    alt=""
                                    className={
                                      ascending.availability
                                        ? "arrow-drop"
                                        : "arrow"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th width="15%" className="gray-text">
                            <div className="table-center">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <h4
                                  className="gray-text"
                                  style={{ marginRight: "10px" }}
                                >
                                  {t("Price")}
                                </h4>
                                <div
                                  className="arrowBox"
                                  onClick={() => ascendingHandler("price")}
                                >
                                  <CustomerArrow src={CustomerArrowUp} alt="" />
                                  <img
                                    src={CustomerRealArrow}
                                    alt=""
                                    className={
                                      ascending.price ? "arrow-drop" : "arrow"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th width="30%"></th>
                        </tr>

                        {/* table */}
                        {productsListMaker()
                          .slice(itemsVisited, itemsVisited + itemsPerPage)
                          .map((item, index) => {
                            return (
                              <SingleProduct
                                item={item}
                                key={index}
                                editProductHandler={editProductHandler}
                              />
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <PaginationBox>
                    <div className="pagination-elements">
                      <div className="pagination-arrow">
                        <img
                          alt=""
                          src={
                            currentPage === 0 ? GrayArrow : PaginationArrowLeft
                          }
                          onClick={previousPage}
                        />
                      </div>
                      <BlueTextMedium className="current-page">
                      {pageCount == 0 ? `${currentPage+1} / ${pageCount+1}` : `${currentPage+1} / ${pageCount}`}
                      </BlueTextMedium>
                      <div className="pagination-arrow">
                        <img
                          alt=""
                          src={
                            productsListMaker().length - itemsVisited <= 7
                              ? GrayArrowRight
                              : PaginationArrow
                          }
                          onClick={nextPage}
                        />
                      </div>
                    </div>
                  </PaginationBox>
                </>
              )}
            </CustomersBoxFlex>
          </AllCustomersBox>
        </CustomerContainer>
      </CustomerSection>
    </>
  );
};

export default ProductPage;
