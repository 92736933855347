import React, { useRef, useState, useEffect } from "react";
import {
  CustomerArrowUp,
  CustomerRealArrow,
  PaginationArrow,
  BlueCross,
  GrayArrow,
  PaginationArrowLeft,
  GrayArrowRight,
} from "../../../assets/landingPage/landingPageAssets";
import { BlueTextMedium } from "../../../styles/GlobalStyles";
import ReactPaginate from "react-paginate";
import {
  AllCustomersBox,
  BlueTextSmaller,
  CreateCustomer,
  CustomerArrow,
  CustomerBoxHeader,
  CustomerContainer,
  CustomerCross,
  CustomerFiltersBox,
  CustomerHeader,
  CustomerInput,
  CustomerInputWrap,
  CustomersBoxFlex,
  CustomerSection,
  PaginationBox,
} from "../styles";
import "../../DemoShop copy/stylesAllPages.scss";
import { useGlobalContext } from "../../../context/context";
import CreateNewAttribute from "./CreateNewAttribute";
import SingleAttribute from "./SingleAttribute";
import { useTranslation } from "react-i18next";

const attributeData = [
  { id: 3, name: "Svježa", number_of_occurrence: 2 },
  { id: 3, name: "Zamrznuta", number_of_occurrence: 0 },
  { id: 3, name: "5 gr", number_of_occurrence: 0 },
  { id: 3, name: "10 gr", number_of_occurrence: 1 },
  { id: 3, name: "100 gr", number_of_occurrence: 0 },
  { id: 3, name: "1,000 gr", number_of_occurrence: 2 },
  { id: 3, name: "400-600 gr", number_of_occurrence: 2 },
  { id: 3, name: "600-800 gr", number_of_occurrence: 2 },
];

const AttributesSection = ({ setSections, modalOpen, open }) => {
  const [ascending, setAscending] = useState(false);
  const { attribute, setAttribute, getAllAttributes, setModalRemoveId } =
    useGlobalContext();
  const [customerFocus, setCustomerFocus] = useState(false);
  const [createNewAttribute, setCreateNewAttribute] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [attributeFilter, setAttributeFilter] = useState("");
  const customerRef = useRef(null);
  const customerCrossHandler = () => {
    setCustomerFocus(false);
    setAttributeFilter("");
    customerRef.current.value = "";
  };

  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };
  const attributeListMaker = () => {
    let list = [];
    attributeData.filter((item) => {
      if (item.name.toLowerCase().includes(attributeFilter.toLowerCase())) {
        list.push(item);
      }
      return list;
    });
    return list;
  };
  let itemsPerPage = 7;
  let pageCount = Math.ceil(attributeListMaker().length / itemsPerPage);
  let itemsVisited = currentPage * itemsPerPage;
  const pageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const ascendingHandler = () => {
    setAscending(!ascending);
    let data = attributeData;
    if (ascending) {
      data.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      data.sort((a, b) => b.name.localeCompare(a.name));
    }
  };

  useEffect(() => {
    if (attributeFilter) {
      setCurrentPage(0);
    }
  }, [attributeFilter]);
  const nextPage = () => {
    if (currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <CustomerSection>
        <CustomerContainer id="container">
          <CustomerHeader>
            <BlueTextSmaller>{t("Attributes")}</BlueTextSmaller>
            {createNewAttribute ? (
              <></>
            ) : (
              <>
                <CreateCustomer onClick={() => setCreateNewAttribute(true)}>
                  {t("CreateNew")}{" "}
                  <div
                    style={{
                      fontFamily: "Jost",
                      fontSize: "20px",
                      display: "inline-block",
                      transform: "translate(2px, 1px)",
                    }}
                  >
                    +
                  </div>
                </CreateCustomer>
              </>
            )}
          </CustomerHeader>

          <AllCustomersBox>
            <CustomersBoxFlex id="customersBox">
              {createNewAttribute ? (
                <>
                  <CustomerBoxHeader>{t("CreateAttribute")}</CustomerBoxHeader>
                  <CreateNewAttribute
                    setCreateNewAttribute={setCreateNewAttribute}
                  />
                </>
              ) : (
                <>
                  <CustomerBoxHeader>{t("AllAttributes")}</CustomerBoxHeader>
                  <CustomerFiltersBox>
                    <CustomerInputWrap customerFocus={customerFocus}>
                      <CustomerCross
                        onClick={customerCrossHandler}
                        customerFocus={customerFocus}
                        src={BlueCross}
                        alt=""
                      />
                      <CustomerInput
                        ref={customerRef}
                        placeholder={t("SearchAttributes")}
                        onClick={() => setCustomerFocus(true)}
                        onChange={(e) => setAttributeFilter(e.target.value)}
                        customerFocus={customerFocus}
                      />
                    </CustomerInputWrap>
                  </CustomerFiltersBox>
                  <table id="table" className="table">
                    <tbody>
                      <tr className="categories-tr">
                        <td className="categories-td">
                          <div
                            className="categories-td-box"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h4
                              className="gray-text"
                              style={{ marginRight: "10px" }}
                            >
                              {t("AttributeName")}
                            </h4>
                            <div
                              className="arrowBox"
                              onClick={() => ascendingHandler("name")}
                            >
                              <CustomerArrow src={CustomerArrowUp} alt="" />
                              <img
                                src={CustomerRealArrow}
                                alt=""
                                className={
                                  ascending.name ? "arrow-drop" : "arrow"
                                }
                              />
                            </div>
                          </div>
                        </td>
                        <th>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h4
                              className="gray-text"
                              style={{ marginRight: "10px" }}
                            >
                              {t("NumberOfProducts")}
                            </h4>
                            <div
                              className="arrowBox"
                              onClick={() => ascendingHandler("products")}
                            >
                              <CustomerArrow src={CustomerArrowUp} alt="" />
                              <img
                                src={CustomerRealArrow}
                                alt=""
                                className={
                                  ascending.products ? "arrow-drop" : "arrow"
                                }
                              />
                            </div>
                          </div>
                        </th>
                        <th></th>
                      </tr>

                      {attributeListMaker()
                        .slice(itemsVisited, itemsVisited + itemsPerPage)
                        .map((item, index) => {
                          return <SingleAttribute item={item} key={index} />;
                        })}
                    </tbody>
                  </table>
                  <PaginationBox>
                    <div className="pagination-elements">
                      <div className="pagination-arrow">
                        <img
                          alt=""
                          src={
                            currentPage === 0 ? GrayArrow : PaginationArrowLeft
                          }
                          onClick={previousPage}
                        />
                      </div>
                      <BlueTextMedium className="current-page">
                      {pageCount == 0 ? `${currentPage+1} / ${pageCount+1}` : `${currentPage+1} / ${pageCount}`}
                      </BlueTextMedium>
                      <div className="pagination-arrow">
                        <img
                          alt=""
                          src={
                            attributeListMaker().length - itemsVisited <= 7
                              ? GrayArrowRight
                              : PaginationArrow
                          }
                          onClick={nextPage}
                        />
                      </div>
                    </div>
                  </PaginationBox>
                </>
              )}
            </CustomersBoxFlex>
          </AllCustomersBox>
        </CustomerContainer>
      </CustomerSection>
    </>
  );
};

export default AttributesSection;
