import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BlueCross } from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import { ButtonBlue } from "../../../styles/GlobalStyles";
import "../../DemoShop copy/stylesAllPages.scss";

const CreateNewAttribute = ({ setCreateNewAttribute }) => {
  const [attributeFocused, setAttributeFocused] = useState(false);
  const { newAttribute, setNewAttribute } = useGlobalContext();
  const attributeCrossHandler = () => {
    setAttributeFocused(false);
    setNewAttribute({ name: "" });
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const createNewAttributeHandler = () => {
    setCreateNewAttribute(false);
    setNewAttribute({ name: "" });
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="content">
        <form onSubmit={submitHandler} className="attribute-form">
          <div className="input-shop">
            <input
              style={
                attributeFocused
                  ? { background: "rgba(114, 139, 166, .6)" }
                  : { background: "rgba(114, 139, 166, 0.2)" }
              }
              onClick={() => setAttributeFocused(true)}
              onChange={(e) => setNewAttribute({ name: e.target.value })}
              value={newAttribute.name}
            />
            <img
              style={attributeFocused ? { opacity: "1" } : { opacity: "0" }}
              src={BlueCross}
              onClick={attributeCrossHandler}
              alt=""
            />
            <div
              style={attributeFocused ? { top: "-33px" } : { top: "3px" }}
              className="placeholder-box"
            >
              <div
                className="placeholder"
                style={
                  attributeFocused ? { fontSize: "11px" } : { fontSize: "16px" }
                }
              >
                {t("AttributeName")}
              </div>
            </div>
          </div>
          <div className="attribute-button-box">
            <ButtonBlue type="submit">{t("Save")}</ButtonBlue>
            <ButtonBlue onClick={createNewAttributeHandler}>
              {t("Close")}
            </ButtonBlue>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateNewAttribute;
