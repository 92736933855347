import React, { useState, useEffect } from "react";
import {
  BlueTextBold,
  BlueTextMedium,
  ButtonBlue,
  GrayTextMedium,
  GrayTextMediumBigger,
} from "../../../styles/GlobalStyles";
import {
  BlueCross,
  UploadImage,
  OutOfStockTick,
  PasswordCross,
} from "../../../assets/landingPage/landingPageAssets";
import "./Productstyles.scss";
import { useGlobalContext } from "../../../context/context";
import Select from "react-select";
import { useRef } from "react";
import FileBase from "react-file-base64";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const CreateNewProduct = ({ createNew, setCreateNew, open, products }) => {
  const [formText, setFormText] = useState({
    name: "",
    description: "",
    price: "",
    out_of_stock: false,
    category_id: null,
    attribute_ids: [],
    images: [],
  });
  const [nameFocused, setNameFocused] = useState(false);
  const [descriptionFocused, setDescriptionFocused] = useState(false);
  const [priceFocused, setPriceFocused] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [image4, setImage4] = useState();
  const [image5, setImage5] = useState();
  const [allImages, setAllImages] = useState([]);
  const [clicked, setClicked] = useState(false);
  const numberInputRef = useRef(null);
  const [productLoaded, setProductLoaded] = useState(false);
  const [priceRequest, setPriceRequest] = useState(false);
  const {
    getDemoShopCategories,
    categories,
    baseUrl,
    config,
    setClosed,
    createProductMessage,
    setCreateProductMessage,
    setLoadProduct,
    getDemoShopProducts,
    setRemoveString,
    getCreateProductAttributes,
    unassignedAtt,
    yourShop,
    loadProduct,
  } = useGlobalContext();

  const formSubmit = async (e) => {
    e.preventDefault();

    const createDemoShopProduct = async () => {
      try {
        const response = await axios
          .post(`${baseUrl}/product`, formText, config)
          .then((response) => {
            if (response.status === 201) {
              setLoadProduct(false);
              setProductLoaded(false);
              setCreateProductMessage("You have created a product.");
              setTimeout(() => {
                setCreateNew(false);
              }, 2000);
            }
          })
          .catch((error) => {
            if (error.response) {
              setLoadProduct(false);
              setProductLoaded(false);
              setCreateProductMessage(error.response.data.message);
            }
          });
      } catch (error) {
        setLoadProduct(false);
        setProductLoaded(false);
      }
    };

    const prodArray = products.data.data.map((e) => e.name.toLowerCase());
    const formName = formText.name.toLowerCase();

    if (prodArray.includes(formName)) {
      setCreateProductMessage("Product with that name already exist.");
      setClicked(true);
    } else if (
      formText.category_id &&
      formText.description &&
      formText.name &&
      formText.price &&
      formText.images.length &&
      !yourShop?.is_premium < 4
    ) {
      setProductLoaded(true);
      createDemoShopProduct();
      setClicked(true);
      setFormText({
        name: "",
        description: "",
        price: "",
        category_id: null,
        attribute_ids: [],
      });
      setPriceFocused(false);
      setDescriptionFocused(false);
      setNameFocused(false);
      setSelectedAttributes(null);
      setSelectedCategories(null);
      setTimeout(() => {
        setImage1(null);
        setImage2(null);
        setImage3(null);
        setImage4(null);
        setImage5(null);
      }, 0);
    } else if (
      formText.attribute_ids.length &&
      formText.category_id &&
      formText.description &&
      formText.name &&
      formText.price &&
      !formText.images.length
    ) {
      setCreateProductMessage(
        "Please upload at least one image of the product."
      );
      setClicked(true);
    } else if (
      !formText.category_id ||
      !formText.description ||
      !formText.name ||
      !formText.price ||
      !formText.images.length
    ) {
      setCreateProductMessage("Please fill all the fields.");
      setClicked(true);
    } else if (products?.data.data.length > 3) {
      open();
      setClicked(true);
    }
    setTimeout(() => {
      setClicked(false);
      setCreateProductMessage("");
    }, 5000);
    setClosed(true);
  };

  const nameCrossHandler = () => {
    setNameFocused(false);
    setFormText({ ...formText, name: "" });
  };
  const DescriptionCrossHandler = () => {
    setDescriptionFocused(false);
    setFormText({ ...formText, description: "" });
  };
  const PriceCrossHandler = () => {
    setPriceFocused(false);
    setFormText({ ...formText, price: "" });
  };
  const cancelHandler = () => {
    setCreateNew(false);
  };
  useEffect(() => {
    console.log(products.data.data, "ja");
    setRemoveString("limit");
    getDemoShopProducts();
    getDemoShopCategories();
    getCreateProductAttributes();
    let arr = [image1, image2, image3, image4, image5];
    const newArr = arr.filter((item) => {
      if (item !== undefined || item !== null) {
        return item;
      }
    });
    const finalAll = newArr.map((item) => {
      let bla = item.split("base64,");
      return bla[1];
    });
    setFormText({
      ...formText,
      out_of_stock: false,
      images: finalAll,
    });
  }, [image1, image2, image3, image4, image5, formText.price]);

  let categoriesArr = [];
  categories?.data.forEach((item) => {
    let itemobj = { value: item.id, label: item.name };
    categoriesArr.push(itemobj);
    console.log(categoriesArr);
  });
  let attributesArr = [];
  unassignedAtt?.data.forEach((item) => {
    let itemobj = { value: item.id, label: item.name };
    attributesArr.push(itemobj);
  });

  const handleAttributesChange = (selectedAttributes) => {
    setSelectedAttributes(selectedAttributes);
    let arr = [];
    selectedAttributes.forEach((item) => arr.push(item.value));
    setFormText({ ...formText, attribute_ids: arr });
  };
  const handleCategoriesChange = (selectedCategories) => {
    setSelectedCategories(selectedCategories);
    setFormText({ ...formText, category_id: selectedCategories.value });
  };

  const selectStyles = {
    control: (provided, state) => {
      const height = selectedAttributes?.length > 2 ? "100px" : "54px";
      return {
        ...provided,
        height: height,
        whiteSpace: "nowrap",
        overflow: "auto",
        width: "100%",
        borderRadius: "20px",
        border: state.isFocused
          ? "2px solid rgba(114, 139, 166, 0.5)"
          : "2px solid transparent",
        fontFamily: "Jost",
        fontWeight: "400",
        color: "#3853a3",
        background: state.isFocused
          ? "rgba(114, 139, 166, 0.4)"
          : "rgba(114, 139, 166, 0.2)",
        padding: "0 0 0 10px",
        "&:hover": {
          borderColor: "rgba(114, 139, 166, 0.5)",
        },
      };
    },
    placeholder: (provider) => ({
      ...provider,
      color: "#3853a3",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      borderRadius: "5px",
    }),
  };

  const selectStylesCategory = {
    control: (provided, state) => ({
      ...provided,
      height: "54px",
      width: "100%",
      // border: '2px solid rgba(114, 139, 166, 0.5)',
      borderRadius: "20px",
      border: state.isSelected
        ? "2px solid rgba(114, 139, 166, 0.5)"
        : "2px solid transparent",
      fontFamily: "Jost",
      fontWeight: "400",
      color: "#3853a3",
      background: state.isFocused
        ? "rgba(114, 139, 166, 0.4)"
        : "rgba(114, 139, 166, 0.2)",
      padding: "0 0 0 10px",
      "&:hover": {
        borderColor: "rgba(114, 139, 166, 0.5)",
      },
    }),
    placeholder: (provider) => ({
      ...provider,
      color: "#3853a3",
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "5px",
      background: "#fff",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#3853a3",
    }),
  };

  const deleteImage1 = () => {
    setImage1(null);
  };
  const deleteImage2 = () => {
    setImage2(null);
  };
  const deleteImage3 = () => {
    setImage3(null);
  };
  const deleteImage4 = () => {
    setImage4(null);
  };
  const deleteImage5 = () => {
    setImage5(null);
  };

  const stockHandler = (e) => {
    let price = formText.price;
    setFormText({ ...formText, price: 0.01 });
    setPriceRequest(!priceRequest);
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="create-new-product">
        <form onSubmit={formSubmit}>
          <div className="input-box">
            <div className="left-side">
              <div className="product-input-wrap">
                <input
                  maxLength="60"
                  style={
                    nameFocused
                      ? { background: "rgba(114, 139, 166, .4)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onFocus={() => setNameFocused(true)}
                  onChange={(e) =>
                    setFormText({ ...formText, name: e.target.value })
                  }
                  value={formText.name}
                />
                <img
                  style={nameFocused ? { opacity: "1" } : { opacity: "0" }}
                  src={BlueCross}
                  onClick={nameCrossHandler}
                  alt=""
                />
                <div
                  style={nameFocused ? { top: "-33px" } : { top: "3px" }}
                  className="placeholder-box"
                >
                  <div
                    className="placeholder"
                    style={
                      nameFocused ? { fontSize: "11px" } : { fontSize: "16px" }
                    }
                  >
                    {t("ProductName")}
                  </div>
                </div>
              </div>
              {/* description */}
              <div className="input-wrap" style={{ height: "130px" }}>
                <textarea
                  maxLength="2000"
                  className="create-shop-textarea"
                  style={
                    descriptionFocused
                      ? { background: "rgba(114, 139, 166, .4)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onFocus={() => setDescriptionFocused(true)}
                  onChange={(e) =>
                    setFormText({ ...formText, description: e.target.value })
                  }
                  value={formText.description}
                />
                <img
                  style={
                    descriptionFocused ? { opacity: "1" } : { opacity: "0" }
                  }
                  src={BlueCross}
                  onClick={DescriptionCrossHandler}
                  alt=""
                />
                <div
                  style={
                    descriptionFocused ? { top: "-70px" } : { top: "-35px" }
                  }
                  className="placeholder-box"
                >
                  <div
                    className="placeholder"
                    style={
                      descriptionFocused
                        ? { fontSize: "11px" }
                        : { fontSize: "16px" }
                    }
                  >
                    {t("Description")}
                  </div>
                </div>
              </div>
              {/* price */}

              <div className="product-input-wrap">
                <input
                  disabled={priceRequest}
                  className="no-number-arrows"
                  type="number"
                  ref={numberInputRef}
                  style={
                    priceFocused
                      ? { background: "rgba(114, 139, 166, .4)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onFocus={() => setPriceFocused(true)}
                  onChange={(e) =>
                    setFormText({
                      ...formText,
                      price: parseFloat(e.target.value),
                    })
                  }
                  value={formText.price}
                />
                <img
                  style={priceFocused ? { opacity: "1" } : { opacity: "0" }}
                  src={BlueCross}
                  onClick={PriceCrossHandler}
                  alt=""
                />
                <div
                  style={priceFocused ? { top: "-33px" } : { top: "3px" }}
                  className="placeholder-box"
                >
                  <div
                    className="placeholder"
                    style={
                      priceFocused ? { fontSize: "11px" } : { fontSize: "16px" }
                    }
                  >
                    {t("Price")} (€)
                  </div>
                </div>
              </div>
            </div>
            {/* category */}
            <div className="right-side">
              <Select
                styles={selectStylesCategory}
                defaultValue={"Rose"}
                name="colors"
                options={categoriesArr.sort((a, b) =>
                  a.label.toLowerCase() < b.label.toLowerCase()
                    ? -1
                    : a.label.toLowerCase() > b.label.toLowerCase()
                    ? 1
                    : 0
                )}
                className="basic-single react-select-styling"
                classNamePrefix="select"
                placeholder={t("SelectCategories")}
                onChange={handleCategoriesChange}
                isClearable
                value={selectedCategories}
              />
              <Select
                styles={selectStyles}
                isMulti
                name="colors"
                options={attributesArr.sort((a, b) =>
                  a.label.toLowerCase() < b.label.toLowerCase()
                    ? -1
                    : a.label.toLowerCase() > b.label.toLowerCase()
                    ? 1
                    : 0
                )}
                className="basic-multi-select react-select-styling"
                placeholder={t("SelectAttributes")}
                onChange={handleAttributesChange}
                value={selectedAttributes}
              />

              <div className="out-of-stock">
                <h3 className="out-of-stock-header">{t("PriceRequest")}:</h3>
                {/* <input
                  type="checkbox"
                  name="stock"
                  id="stock"
                  onClick={stockHandler}
                /> */}
                <div
                  className="stock-box"
                  style={priceRequest ? { background: "#249924" } : {}}
                  onClick={stockHandler}
                >
                  <img className="stock-tick" src={OutOfStockTick} alt="" />
                </div>
              </div>

              <BlueTextBold className="upload-header">
                {t("UploadImagesOfYourProduct(max.5)")}
              </BlueTextBold>
              <BlueTextMedium className="upload-recom">
                {t("RecommendedSize")}
              </BlueTextMedium>
              <div className="create-new-product-upload-image">
                <div className="create-new-product-image-box">
                  <img src={image1 ? image1 : UploadImage} alt="" />
                  {image1 && (
                    <img
                      className="upload-cross"
                      src={PasswordCross}
                      alt=""
                      onClick={deleteImage1}
                    />
                  )}
                  <div className="text">
                    <label className="custom-file-upload">
                      <FileBase
                        type="file"
                        accept="image/*"
                        onDone={({ base64 }) => setImage1(base64)}
                      />
                      {t("UploadImage")}
                    </label>
                  </div>
                </div>
                <div className="create-new-product-image-box">
                  <img src={image2 ? image2 : UploadImage} alt="" />
                  {image2 && (
                    <img
                      className="upload-cross"
                      src={PasswordCross}
                      alt=""
                      onClick={deleteImage2}
                    />
                  )}
                  <div className="text">
                    <label className="custom-file-upload">
                      <FileBase
                        type="file"
                        accept="image/*"
                        onDone={({ base64 }) => setImage2(base64)}
                      />
                      {t("UploadImage")}
                    </label>
                  </div>
                </div>
                <div className="create-new-product-image-box">
                  <img src={image3 ? image3 : UploadImage} alt="" />
                  {image3 && (
                    <img
                      className="upload-cross"
                      src={PasswordCross}
                      alt=""
                      onClick={deleteImage3}
                    />
                  )}
                  <div className="text">
                    <label className="custom-file-upload">
                      <FileBase
                        type="file"
                        accept="image/*"
                        onDone={({ base64 }) => setImage3(base64)}
                      />
                      {t("UploadImage")}
                    </label>
                  </div>
                </div>
                <div className="create-new-product-image-box">
                  <img src={image4 ? image4 : UploadImage} alt="" />
                  {image4 && (
                    <img
                      className="upload-cross"
                      src={PasswordCross}
                      alt=""
                      onClick={deleteImage4}
                    />
                  )}
                  <div className="text">
                    <label className="custom-file-upload">
                      <FileBase
                        type="file"
                        accept="image/*"
                        onDone={({ base64 }) => setImage4(base64)}
                      />
                      {t("UploadImage")}
                    </label>
                  </div>
                </div>
                <div className="create-new-product-image-box">
                  <img src={image5 ? image5 : UploadImage} alt="" />
                  {image5 && (
                    <img
                      className="upload-cross"
                      src={PasswordCross}
                      alt=""
                      onClick={deleteImage5}
                    />
                  )}
                  <div className="text">
                    <label className="custom-file-upload">
                      <FileBase
                        type="file"
                        accept="image/*"
                        onDone={({ base64 }) => setImage5(base64)}
                      />
                      {t("UploadImage")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="created-product" style={{ height: "10px" }}>
            {clicked && (
              <>
                {productLoaded ? (
                  <>
                    <div className="loading-spinner-gray"></div>
                  </>
                ) : (
                  <>
                    <GrayTextMediumBigger>
                      {createProductMessage}
                    </GrayTextMediumBigger>
                  </>
                )}
              </>
            )}
          </div>
          <div className="button-box-edit-product">
            <ButtonBlue type="submit">{t("Save")}</ButtonBlue>
            <ButtonBlue onClick={cancelHandler}>{t("Close")}</ButtonBlue>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateNewProduct;
