import React from "react";

import Footer from "../../components/LandingPage/Footer/Footer";
import {
  BlueTextBold,
  ButtonBlue,
  GrayTextBold,
  YellowTextBold,
} from "../../styles/GlobalStyles";
import "./styles.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OrderPlacedPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="order-placed-section">
        <div className="order-container">
          <GrayTextBold className="header">{t("Hooray")}</GrayTextBold>
          <div className="order-placed">
            <BlueTextBold className="order-number">
              {t("YourOrderHasBeenPlaced")}
            </BlueTextBold>
            <YellowTextBold className="thanks">{t("ThankYou")}</YellowTextBold>
          </div>
          <Link to={`/shop/${localStorage.getItem("shopId")}`}>
            <ButtonBlue className="proceed">{t("BackToShop")}</ButtonBlue>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderPlacedPage;
