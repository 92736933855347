import axios from "axios";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BlueCross,
  PasswordCross,
} from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import {
  BlueTextBold,
  ButtonYellow,
  GrayTextMedium,
} from "../../../styles/GlobalStyles";
import "../stylesAllPages.scss";

const ChangePassword = ({ setCreateNewAttribute, setChangePasswordPage }) => {
  const [formText, setFormText] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [oldPasswordFocus, setOldPasswordFocus] = useState(false);
  const [newPasswordFocus, setNewPasswordFocus] = useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
  const [showError, setShowError] = useState(false);
  const { baseUrl, config } = useGlobalContext();

  const oldPasswordCrossHandler = () => {
    setOldPasswordFocus(false);
    setFormText({ ...formText, oldPassword: "" });
  };
  const newPasswordCrossHandler = () => {
    setNewPasswordFocus(false);
    setFormText({ ...formText, newPassword: "" });
  };
  const confirmPasswordCrossHandler = () => {
    setConfirmPasswordFocus(false);
    setFormText({ ...formText, confirmPassword: "" });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const { oldPassword, newPassword, confirmPassword } = formText;
    console.log(newPassword, confirmPassword);
    if (formText.newPassword !== formText.confirmPassword) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    } else {
      try {
        const response = axios
          .patch(
            `${baseUrl}/user/password`,
            { old_password: oldPassword, new_password: newPassword, confirm_password: newPassword },
            config
          )
          .then((response) => {
            if (response.status === 200) {
              console.log(response);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="change-password-box">
        <img
          src={PasswordCross}
          alt=""
          className="password-cross"
          onClick={() => setChangePasswordPage(false)}
        />
        <BlueTextBold className="password-header">
          {t("UpdatePassword")}
        </BlueTextBold>
        <div className="change-password-content">
          <form onSubmit={submitHandler} className="attribute-form">
            <div className="input-shop">
              <input
                type="password"
                style={
                  oldPasswordFocus
                    ? { background: "rgba(114, 139, 166, .4)" }
                    : { background: "rgba(114, 139, 166, 0.2)" }
                }
                onFocus={() => setOldPasswordFocus(true)}
                onChange={(e) =>
                  setFormText({ ...formText, oldPassword: e.target.value })
                }
                value={formText.oldPassword}
              />
              <img
                style={oldPasswordFocus ? { opacity: "1" } : { opacity: "0" }}
                src={BlueCross}
                onClick={oldPasswordCrossHandler}
                alt=""
              />
              <div
                style={oldPasswordFocus ? { top: "-33px" } : { top: "3px" }}
                className="placeholder-box"
              >
                <div
                  className="placeholder"
                  style={
                    oldPasswordFocus
                      ? { fontSize: "11px" }
                      : { fontSize: "16px" }
                  }
                >
                  {t("OldPassword")}
                </div>
              </div>
            </div>
            <div className="input-shop">
              <input
                type="password"
                style={
                  newPasswordFocus
                    ? { background: "rgba(114, 139, 166, .4)" }
                    : { background: "rgba(114, 139, 166, 0.2)" }
                }
                onFocus={() => setNewPasswordFocus(true)}
                onChange={(e) =>
                  setFormText({ ...formText, newPassword: e.target.value })
                }
                value={formText.newPassword}
              />
              <img
                style={newPasswordFocus ? { opacity: "1" } : { opacity: "0" }}
                src={BlueCross}
                onClick={newPasswordCrossHandler}
                alt=""
              />
              <div
                style={newPasswordFocus ? { top: "-33px" } : { top: "3px" }}
                className="placeholder-box"
              >
                <div
                  className="placeholder"
                  style={
                    newPasswordFocus
                      ? { fontSize: "11px" }
                      : { fontSize: "16px" }
                  }
                >
                  {t("NewPassword")}
                </div>
              </div>
            </div>
            <div className="input-shop">
              <input
                type="password"
                style={
                  confirmPasswordFocus
                    ? { background: "rgba(114, 139, 166, .4)" }
                    : { background: "rgba(114, 139, 166, 0.2)" }
                }
                onFocus={() => setConfirmPasswordFocus(true)}
                onChange={(e) =>
                  setFormText({ ...formText, confirmPassword: e.target.value })
                }
                value={formText.confirmPassword}
              />
              <img
                style={
                  confirmPasswordFocus ? { opacity: "1" } : { opacity: "0" }
                }
                src={BlueCross}
                onClick={confirmPasswordCrossHandler}
                alt=""
              />
              <div
                style={confirmPasswordFocus ? { top: "-33px" } : { top: "3px" }}
                className="placeholder-box"
              >
                <div
                  className="placeholder"
                  style={
                    confirmPasswordFocus
                      ? { fontSize: "11px" }
                      : { fontSize: "16px" }
                  }
                >
                  {t("ConfirmPassword")}
                </div>
              </div>
            </div>
            <div style={{ height: "20px" }}>
              <GrayTextMedium
                style={showError ? { display: "block" } : { display: "none" }}
              >
                {t("IncorrectCredentials")}
              </GrayTextMedium>
            </div>
            <ButtonYellow type="submit" className="password-btn">
              {t("ChangePasswordMessage")}
            </ButtonYellow>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
