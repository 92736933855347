import React, { useRef } from "react";
import { useState } from "react";
import {
  AvatarBlank,
  CustomerArrowUp,
  CustomerRealArrow,
  PaginationArrow,
  GrayArrow,
  PaginationArrowLeft,
  GrayArrowRight,
  BlueCross,
} from "../../../assets/landingPage/landingPageAssets";
import {
  BlueTextBold,
  BlueTextMedium,
  GrayTextMedium,
  GrayTextMediumBigger,
} from "../../../styles/GlobalStyles";
import {
  AllCustomersBox,
  Avatar,
  AvatarImg,
  AvatarText,
  BlueTextSmaller,
  CreateCustomer,
  CustomerArrow,
  CustomerBoxHeader,
  CustomerContainer,
  CustomerHeader,
  CustomersBoxFlex,
  CustomerSection,
  PaginationArrows,
  PaginationBox,
  CreateCustomerGray,
  CustomerFiltersBox,
  CustomerInputWrap,
  CustomerCross,
  CustomerInput,
} from "../styles";
import "../stylesAllPages.scss";
import CreateNew from "./CreateNew";
import { useEffect } from "react";
import { useGlobalContext } from "../../../context/context";
import ReactPaginate from "react-paginate";
import SingleCategory from "./SingleCategory";
import Loading from "../../Loading";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const CustomersPage = ({ sections, setSections, open }) => {
  const [ascending, setAscending] = useState({
    name: false,
    products: false,
  });
  const [createNew, setCreateNew] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const customerRef = useRef(null);
  const [customerFocus, setCustomerFocus] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [categories, setCategories] = useState([]);
  const [newArr, setNewArr] = useState([]);
  const [loadCategory, setLoadCategory] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const {
    config,
    baseUrl,
    setModalRemoveId,
    closed,
    removeString,
    setRemoveString,
    setClosed,
    logUserOut,
    getUserDetails,
    userDetails,
    newCategoryResponse,
    yourShop,
    getDemoShopShop,
    setCategoryId,
  } = useGlobalContext();
  const childrenNumberRef = useRef(null);
  const navigate = useNavigate();

  const customerCrossHandler = () => {
    setCustomerFocus(false);
    setCategoryFilter("");
    customerRef.current.value = "";
  };

  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };
  const goToShopHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: true,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: false,
    });
  };

  useEffect(() => {
    if (categoryFilter) {
      setCurrentPage(0);
    }
    const getDemoShopCategories = async () => {
      try {
        const response = axios
          .get(`${baseUrl}/category`, config)
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data);
              setCategories(response.data);
              setLoadCategory(false);
            }
          });
      } catch (error) {
        console.log(error);
      }
      setCurrentPage(0);
    };
    getDemoShopCategories()
    setClosed(false);
    getUserDetails();
    getDemoShopShop();
  }, [createNew, closed, newCategoryResponse, loadCategory, categoryFilter, categories.length]);

  const removeHandler = (string, id) => {
    open();
    setModalRemoveId(id);
    setRemoveString(string);
  };

  const CategoriesListMaker = () => {
    let list = [];
    categories?.data?.filter((item) => {
      if (item.name.toLowerCase().includes(categoryFilter.toLowerCase())) {
        list.push(item);
      }
      return list.sort((a,b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0);
    });
    return list;
  };
  let itemsPerPage = 7;
  let pageCount = Math.ceil(CategoriesListMaker().length / itemsPerPage);
  let itemsVisited = currentPage * itemsPerPage;
  const pageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const ascendingHandler = (asc) => {
    let data = categories?.data;
    if (asc === "name") {
      setAscending({ ...ascending, name: !ascending.name });
      if (ascending.name) {
        data.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        data.sort((a, b) => b.name.localeCompare(a.name));
      }
    } else if (asc === "products") {
      setAscending({ ...ascending, products: !ascending.products });
      if (ascending.products) {
        data.sort((a, b) => a.number_of_occurrence - b.number_of_occurrence);
      } else {
        data.sort((a, b) => b.number_of_occurrence - a.number_of_occurrence);
      }
    }
  };

  const LogOutHandler = () => {
    logUserOut();
    navigate("/");
  };
  const nextPage = () => {
    if (currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const editCategoryHandler = (id) => {
    setOpenEdit(true);
    setCategoryId(id);
  };

  const { t } = useTranslation();
  return (
    <>
      <CustomerSection>
        <Avatar id="avatar" className="avatar">
          <AvatarText className="avatar-text">
            <BlueTextBold style={{ fontSize: "18px" }}>
              {userDetails?.email}
            </BlueTextBold>
            <div className="flex">
              <GrayTextMedium className="settings" onClick={settingsHandler}>
                {t("Profilesettings")}
              </GrayTextMedium>
              <GrayTextMedium className="log-out" onClick={LogOutHandler}>
                {t("LogOut")}
              </GrayTextMedium>
            </div>
          </AvatarText>
          {yourShop?.image && <AvatarImg src={yourShop?.image} alt="" />}{" "}
        </Avatar>
        <CustomerContainer id="container">
          <CustomerHeader>
            <BlueTextSmaller>{t("Categories")}</BlueTextSmaller>
            {createNew ? (
              <></>
            ) : (
              <>
                <CreateCustomer onClick={() => setCreateNew(true)}>
                  {t("CreateNew")}{" "}
                  <div
                    style={{
                      fontFamily: "Jost",
                      fontSize: "20px",
                      display: "inline-block",
                      transform: "translate(2px, 1px)",
                    }}
                  >
                    +
                  </div>
                </CreateCustomer>
              </>
            )}
          </CustomerHeader>

          <AllCustomersBox>
            <CustomersBoxFlex id="customersBox">
              {createNew ? (
                <>
                  <CustomerBoxHeader style={{ marginBottom: "15px" }}>
                    {t("CreateNewCategory")}
                  </CustomerBoxHeader>
                  <CreateNew
                    setCreateNew={setCreateNew}
                    createNew={createNew}
                    categories={categories}
                  />
                </>
              ) : (
                <>
                  <CustomerBoxHeader
                    style={{
                      width: "100%",
                      borderBottom: "1px solid rgba(126, 148, 171, 0.1)",
                    }}
                  >
                    {t("AllCategories")}
                  </CustomerBoxHeader>
                  {categories?.data?.length > 0 ? (
                    <>
                      <CustomerFiltersBox>
                        <CustomerInputWrap customerFocus={customerFocus}>
                          <CustomerCross
                            onClick={customerCrossHandler}
                            customerFocus={customerFocus}
                            src={BlueCross}
                            alt=""
                          />
                          <CustomerInput
                            onClick={() => setCustomerFocus(true)}
                            ref={customerRef}
                            placeholder={t("SearchCategoryByName")}
                            onChange={(e) => setCategoryFilter(e.target.value)}
                            customerFocus={customerFocus}
                          />
                        </CustomerInputWrap>
                      </CustomerFiltersBox>
                      <table id="table" className="table">
                        <tbody ref={childrenNumberRef}>
                          <tr className="categories-tr">
                            <td width="60%" className="categories-td">
                              <div
                                className="categories-td-box"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <h4
                                  className="gray-text"
                                  style={{ marginRight: "10px" }}
                                >
                                  {t("CategoryName")}
                                </h4>
                                <div
                                  className="arrowBox"
                                  onClick={() => ascendingHandler("name")}
                                >
                                  <CustomerArrow src={CustomerArrowUp} alt="" />
                                  <img
                                    src={CustomerRealArrow}
                                    alt=""
                                    className={
                                      ascending.name ? "arrow-drop" : "arrow"
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <th>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <h4
                                  className="gray-text"
                                  style={{ marginRight: "10px" }}
                                >
                                  {t("NumberOfProducts")}
                                </h4>
                                <div
                                  className="arrowBox"
                                  onClick={() => ascendingHandler("products")}
                                >
                                  <CustomerArrow src={CustomerArrowUp} alt="" />
                                  <img
                                    src={CustomerRealArrow}
                                    alt=""
                                    className={
                                      ascending.products
                                        ? "arrow-drop"
                                        : "arrow"
                                    }
                                  />
                                </div>
                              </div>
                            </th>
                            <th></th>
                          </tr>

                          {CategoriesListMaker()
                            .slice(itemsVisited, itemsVisited + itemsPerPage)
                            .map((item, index) => {
                              return (
                                <SingleCategory
                                  item={item}
                                  removeHandler={removeHandler}
                                  editCategoryHandler={editCategoryHandler}
                                  key={index}
                                />
                              );
                            })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <></>
                  )}
                  {loadCategory ? (
                    <div style={{ padding: "20px 0 0 30px" }}>
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {!categories?.data?.length && (
                        <div className="table-box-empty">
                          <GrayTextMediumBigger>
                            {t("NoCategories")}
                          </GrayTextMediumBigger>
                          <CreateCustomer onClick={() => setCreateNew(true)}>
                            {t("CreateFirstCategory")}
                          </CreateCustomer>
                        </div>
                      )}
                    </>
                  )}

                  <PaginationBox>
                    <div className="pagination-elements">
                      <div className="pagination-arrow">
                        <img
                          alt=""
                          src={
                            currentPage === 0 ? GrayArrow : PaginationArrowLeft
                          }
                          onClick={previousPage}
                        />
                      </div>
                      <BlueTextMedium className="current-page">
                      {pageCount == 0 ? `${currentPage+1} / ${pageCount+1}` : `${currentPage+1} / ${pageCount}`}
                      </BlueTextMedium>
                      <div className="pagination-arrow">
                        <img
                          alt=""
                          src={
                            CategoriesListMaker().length - itemsVisited <= 7
                              ? GrayArrowRight
                              : PaginationArrow
                          }
                          onClick={nextPage}
                        />
                      </div>
                    </div>
                  </PaginationBox>
                </>
              )}
            </CustomersBoxFlex>
          </AllCustomersBox>
        </CustomerContainer>
      </CustomerSection>
    </>
  );
};

export default CustomersPage;
