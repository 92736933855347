import React from "react";
import {
  EmptyImage,
  ShopFoolishFly,
} from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import {
  BlueTextBold,
  ButtonBlue,
  GrayTextMedium,
} from "../../../styles/GlobalStyles";
import { CustomerBoxHeader } from "../styles";
import { DemoProdavnica } from "../../../assets/landingPage/landingPageAssets";
import "../../DemoShop copy/ShopPage/shopStyles.scss";
import { useTranslation } from "react-i18next";

const shopData = {
  id: 1,
  name: "Demo prodavnica",
  description:
    "Ovo je demonstrativni prikaz prodavnice kakvu možete da kreirate nakon prijave na platformu i dobijanja pristupa administratorskoj tabli. U lijevom dijelu administratorske table na početku korišćenja treba da kliknete na opciju „Prodavnica“. Nakon toga Vam se otvara prozor za pravljenje Vaše prodavnice u kome treba da unesete tražene podatke i nakon toga kliknete na dugme „Sačuvaj“. U administratorskom panelu, izborom opcije „Kreiraj proizvod“ kreirate svoj novi proizvod. Preduslov za kreiranje proizvoda je kreiranje kategorije. Besplatna korisnička licenca Vam omogućava kreiranje najviše 4 proizvoda. Za kreiranje većeg broja proizvoda morate kupiti jedan od ponuđenih korisničkih paketa. U ovoj demonstrativnoj prodavnici možete vidjeti kako izgledaju kreirani proizvodi i izvještaji koje možete dobiti ukoliko posjedujete Odličan paket. Pogledajte sekciju „Ponuda“!",
  is_premium: false,
  image: DemoProdavnica,
};

const YourShop = ({ edit, setEdit }) => {
  const { yourShop, deleteShop } = useGlobalContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="your-shop-content">
        <CustomerBoxHeader className="your-shop-header">
          {t("Yourshop")}
        </CustomerBoxHeader>
        <div className="your-shop-box">
          <img src={shopData.image} alt="" style={{ borderRadius: "10px", maxWidth: '280px', maxHeight: '280px' }} />
          <div className="shop-info">
            <div className="shop-info-content">
              <div className="your-shop-text">
                <GrayTextMedium
                  style={{ fontSize: "16px", marginBottom: "5px" }}
                >
                  {t("Shopname")}
                </GrayTextMedium>
                <BlueTextBold
                  style={{ fontSize: "25px", marginBottom: "12px" }}
                >
                  {shopData.name}
                </BlueTextBold>
                <GrayTextMedium
                  style={{ fontSize: "16px", marginBottom: "5px" }}
                >
                  {t("Description")}
                </GrayTextMedium>
                <div className="your-shop-description-box">
                  <BlueTextBold>{shopData.description}</BlueTextBold>
                  <h3>&nbsp;</h3>
                </div>
              </div>
              <div className="buttons">
                <div className="button-box">
                  <ButtonBlue onClick={() => setEdit(true)}>
                    {t("Editshop")}
                  </ButtonBlue>
                </div>
                <div className="button-box">
                  <ButtonBlue onClick={deleteShop}>
                    {t("RemoveShop")}
                  </ButtonBlue>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourShop;
