import React from "react";
import LogIn from "../../components/LogInPage/LogIn";
import Footer from "../../components/LandingPage/Footer/Footer";

const LogInPage = () => {
  return (
    <>
      <LogIn />
      <Footer />
    </>
  );
};

export default LogInPage;
