import React from "react";
import Footer from "../../components/LandingPage/Footer/Footer";
import {
  ShopHook,
  CartPlustYellow,
  CartMinusYellow,
  AddToCartWhite,
  RemoveNoCircle,
} from "../../assets/landingPage/landingPageAssets";
import {
  BlueTextBold,
  ButtonBlue,
  GrayTextBold,
  GrayTextMedium,
  WhiteTextBold,
  YellowTextBold,
} from "../../styles/GlobalStyles";
import "./styles.scss";
import { useGlobalContext } from "../../context/context";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { HrefLink } from "../../components/LandingPage/Navbar/styles";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Cart = () => {
  // const { state, dispatch, shopId } = useGlobalContext();
  // const [hoveredMinus, setHoveredMinus] = useState(false);
  // const [hoveredPlus, setHoveredPlus] = useState(false);
  // const loc = useLocation();
  // useEffect(() => {}, [state.cart]);
  // let data = sessionStorage.getItem("cart");
  // let parsed = JSON.parse(data);
  // let total = sessionStorage.getItem("total");
  const {
    cart,
    total,
    handleIncrease,
    handleDecrease,
    handleAddProduct,
    handleRemoveProduct,
    shopId,
  } = useGlobalContext();
  const [hoveredMinus, setHoveredMinus] = useState(false);
  const [hoveredPlus, setHoveredPlus] = useState(false);
  
  const { t } = useTranslation();
  return (
    <>
      <div className="cart-section">
        {cart.length < 1 ? (
          <div id="emptyCart">
            <BlueTextBold className="header">{t("EmptyCart")}</BlueTextBold>
          </div>
        ) : (
          <>
            <BlueTextBold className="header">
              {t("YourShoppingCart")}
            </BlueTextBold>
          </>
        )}
        <div className="cart-container">
          {cart.map((item, index) => {
            return (
              <>
                <div key={index} className="single-item">
                  <div className="single-cart-item-content">
                    <div className="left">
                      <div className="picture-box">
                        <img src={item?.images[0]?.image_url} alt="" />
                      </div>
                      <div className="text">
                        <BlueTextBold className="text-header">
                          {item.name}
                        </BlueTextBold>
                        <GrayTextMedium className="category">
                        {t("Category")}: {item.category.name}
                        </GrayTextMedium>
                        <GrayTextBold className="shop">
                        {t("Shop")}: {item.shop.name}
                        </GrayTextBold>
                      </div>
                    </div>
                    <div className="price-box">
                      <YellowTextBold className="price">
                        {item.price === 0.01 || item.price === 0.02 || item.price === 0.03 ? `${t("PriceRequest")}` : `${item.price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}€`}
                      </YellowTextBold>
                      <div className="number">
                        <div
                          className="cart-box"
                          onClick={() => handleDecrease(item)}
                          onMouseOver={() => setHoveredMinus(true)}
                          onMouseOut={() => setHoveredMinus(false)}
                        >
                          <div className="minus number-img"></div>
                        </div>
                        <BlueTextBold style={{ fontSize: "20px" }}>
                          {item.quantity}
                        </BlueTextBold>
                        <div
                          onClick={() => handleIncrease(item)}
                          className="cart-box"
                          onMouseOver={() => setHoveredPlus(true)}
                          onMouseOut={() => setHoveredPlus(false)}
                        >
                          <div className="minus number-img"></div>
                          <div className="plus number-img"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <div className="total">
            <div className="cart-total-content">
              {cart.length < 1 ? (
                <div className="total-button-box">
                  <HrefLink to={`/#recommend`}>
                    <ButtonBlue className="all-shops-btn">
                      {t("AllShops")}
                    </ButtonBlue>
                  </HrefLink>
                </div>
              ) : (
                <>
                  <div className="total-button-box-cart">
                    <Link to={`/shop/${localStorage.getItem("shopId")}`}>
                      <ButtonBlue className="proceed">
                        {t("BackToShops")}
                      </ButtonBlue>
                    </Link>
                    <Link to="/checkout">
                      <ButtonBlue className="proceed">
                        {t("ProceedToCheckout")}
                      </ButtonBlue>
                    </Link>
                  </div>
                </>
              )}
              <div className="price-box">
                <BlueTextBold className="price-header">
                  {t("TotalPrice")}
                </BlueTextBold>
                <WhiteTextBold className="total-price">
                  {total === 0.01 || total === 0.02 || total === 0.03 || total === 0.04 ? 0 : total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}€
                </WhiteTextBold>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
