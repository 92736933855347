import React, { useState } from "react";
import {
  ButtonBlue,
  Cross,
  GrayTextMedium,
} from "../../../styles/GlobalStyles";
import "../stylesAllPages.scss";
import {
  NameInput,
  InputWrap,
  NamePlaceholderBox,
  NamePlaceholder,
  NameCross,
  Textarea,
  TextareaCross,
  TextareaPlaceholderBox,
  TextareaPlaceholder,
} from "./CategoryInputs";
import { BlueCross } from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import { useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const CreateNew = ({ createNew, setCreateNew, categories }) => {
  const [categoryFocused, setCategoryFocused] = useState(false);
  const [createdMessage, setCreatedMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState(false);
  // const [descriptionFocused, setDescriptionFocused] = useState(false);
  // const [descriptionCount, setDescriptionCount] = useState(0);
  const {
    authKey,
    logUserOut,
    createDemoShopCategory,
    createNewCategory,
    setCreateNewCategory,
    newCategoryResponse,
    setNewCategoryResponse,
    setClosed,
    closed,
    baseUrl,
    config,
    setLoadCategory,
  } = useGlobalContext();

  const formSubmit = (e) => {
    e.preventDefault();

    const catArray = categories.data.map((e) => e.name.toLowerCase());
    const newCat = createNewCategory.name;
    const found = catArray.some(item => item === newCat)
    if (found) {
      setNewCategoryResponse("Category with that name already exist.");
      setCreatedMessage(true);
    }
    if (createNewCategory.name) {
      console.log(found)
      const createDemoShopCategory = async () => {
        setLoadCategory(true);
        try {
          const response = axios
            .post(`${baseUrl}/category`, createNewCategory, config)
            .then((response) => {
              if (response.status === 201) {
                setNewCategoryResponse(response.data.message);
                setLoadCategory(false);
                setTimeout(() => {
                  setCreateNew(false);
                }, 2000);
              }
            })
            .catch((error) => {
              setTimeout(() => {
                setNewCategoryResponse(error.response.data.message);
              }, 1000);
              setLoadCategory(false);
            });
        } catch (error) {
          setLoadCategory(false);
        }
      };
      createDemoShopCategory();
      setCreatedMessage(true);
    } else {
      setNewCategoryResponse("Please fill all the fields.");
      setCreatedMessage(true);
    }
    setCreateNewCategory({ name: "", description: "" });
    setCategoryFocused(false);
    // setDescriptionFocused(false);
    setTimeout(() => {
      setCreatedMessage(false);
    }, 7000);
  };
  const nameCrossHandler = () => {
    setCategoryFocused(false);
    setCreateNewCategory({ ...createNewCategory, name: "" });
  };
  const TextareaCrossHandler = () => {
    // setDescriptionFocused(false);
    setCreateNewCategory({ ...createNewCategory, description: "" });
  };
  const cancelHandler = () => {
    setCreateNew(false);
  };

  useEffect(() => {
    setCreateNewCategory({ name: "", description: "" });
    console.log(
      categories.data.map((e) => e.name),
      "ja"
    );
  }, [createNew]);

  const { t } = useTranslation();
  return (
    <>
      <div className="create-new-section">
        <form onSubmit={formSubmit}>
          <InputWrap>
            <NameInput
              maxLength="50"
              onClick={() => setCategoryFocused(true)}
              categoryFocused={categoryFocused}
              onChange={(e) =>
                setCreateNewCategory({
                  ...createNewCategory,
                  name: e.target.value,
                })
              }
              value={createNewCategory.name}
            />
            <NameCross
              categoryFocused={categoryFocused}
              src={BlueCross}
              onClick={nameCrossHandler}
            />
            <NamePlaceholderBox categoryFocused={categoryFocused}>
              <NamePlaceholder categoryFocused={categoryFocused}>
                {t("CategoryName")}
              </NamePlaceholder>
            </NamePlaceholderBox>
          </InputWrap>
          {/* <InputWrap style={{ height: "230px" }}>
            <Textarea
              maxLength="450"
              descriptionFocused={descriptionFocused}
              onFocus={() => setDescriptionFocused(true)}
              onChange={(e) => {
                setCreateNewCategory({
                  ...createNewCategory,
                  description: e.target.value,
                });
                setDescriptionCount(e.target.value.length);
              }}
              value={createNewCategory.description}
            />
            <div
              style={{
                top: "40%",
                left: "-8%",
                position: "relative",
                zIndex: "1",
              }}
              className="placeholder-box"
            >
              <p>{descriptionCount}</p>
            </div>
            <TextareaCross
              descriptionFocused={descriptionFocused}
              src={BlueCross}
              onClick={TextareaCrossHandler}
            />
            <TextareaPlaceholderBox descriptionFocused={descriptionFocused}>
              <TextareaPlaceholder descriptionFocused={descriptionFocused}>
                {t("Description")}
              </TextareaPlaceholder>
            </TextareaPlaceholderBox>
          </InputWrap> */}
          <div className="new-attribute-created">
            {createdMessage ? (
              <>
                <GrayTextMedium>{newCategoryResponse}</GrayTextMedium>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="category-button-box">
            <ButtonBlue type="submit">{t("Save")}</ButtonBlue>
            <ButtonBlue onClick={cancelHandler} type="button">
              {t("Close")}
            </ButtonBlue>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateNew;
