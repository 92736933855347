import axios from "axios";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import {
  AvatarBlank,
  BlueCross,
  CustomerArrowUp,
  CustomerRealArrow,
  GrayArrow,
  GrayArrowRight,
  PaginationArrow,
  PaginationArrowLeft,
} from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import {
  BlueTextBold,
  BlueTextMedium,
  GrayTextMedium,
  GrayTextMediumBigger,
} from "../../../styles/GlobalStyles";
import Loading from "../../Loading";
import {
  AllCustomersBox,
  Avatar,
  AvatarImg,
  AvatarText,
  BlueTextSmaller,
  CustomerArrow,
  CustomerBoxHeader,
  CustomerContainer,
  CustomerCross,
  CustomerEmail,
  CustomerFilterButton,
  CustomerFiltersBox,
  CustomerHeader,
  CustomerInput,
  CustomerInputWrap,
  CustomerName,
  CustomerOrders,
  CustomersBoxFlex,
  CustomerSection,
  CustomerTable,
  CustomerTableHeader,
  PaginationArrowBox,
  PaginationArrows,
  PaginationBox,
  RowsNumber,
  SingleCustomers,
  SingleCustomersText,
  PublishedButton,
  CreateCustomer,
} from "../styles";
import "./styles.scss";

const CustomersPage = ({ sections, setSections }) => {
  const [ascending, setAscending] = useState({
    name: false,
    lastName: false,
    email: false,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [customerFocus, setCustomerFocus] = useState(false);
  const [customersFilter, setCustomersFilter] = useState("");
  const [customers, setCustomers] = useState([]);
  const [loadCustomers, setLoadCustomers] = useState(true);
  const customerRef = useRef(null);
  const customerCrossHandler = () => {
    setCustomerFocus(false);
    setCustomersFilter("");
    customerRef.current.value = "";
  };
  const navigate = useNavigate();

  const {
    logUserOut,
    getUserDetails,
    userDetails,
    getDemoShopShop,
    yourShop,
    baseUrl,
    config,
  } = useGlobalContext();
  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };

  const goToShopHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: true,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: false,
    });
  };
  const getDemoShopCustomers = async () => {
    try {
      const response = axios
        .get(`${baseUrl}/order/customers`, config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setLoadCustomers(false);
            setCustomers(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (customersFilter) {
      setCurrentPage(0);
    }
    getDemoShopShop();
    getDemoShopCustomers();
    getUserDetails();
  }, [customersFilter]);
  const customersListMaker = () => {
    let list = [];
    customers?.data?.filter((item) => {
      if (
        item.customer_email
          .toLowerCase()
          .includes(customersFilter.toLowerCase())
      ) {
        list.push(item);
      }
      return list;
    });
    return list;
  };
  let itemsPerPage = 7;
  let pageCount = Math.ceil(customersListMaker().length / itemsPerPage);
  let itemsVisited = currentPage * itemsPerPage;
  const pageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const ascendingHandler = (asc) => {
    let data = customers?.data;
    if (asc === "firstName") {
      setAscending({ ...ascending, name: !ascending.name });
      if (ascending.name) {
        data.sort((a, b) => a.customer_name.localeCompare(b.customer_name));
      } else {
        data.sort((a, b) => b.customer_name.localeCompare(a.customer_name));
      }
    } else if (asc === "lastName") {
      setAscending({ ...ascending, lastName: !ascending.lastName });
      if (ascending.lastName) {
        data.sort((a, b) =>
          a.customer_last_name.localeCompare(b.customer_last_name)
        );
      } else {
        data.sort((a, b) =>
          b.customer_last_name.localeCompare(a.customer_last_name)
        );
      }
    } else if (asc === "email") {
      setAscending({ ...ascending, email: !ascending.email });
      if (ascending.email) {
        data.sort((a, b) => a.customer_email.localeCompare(b.customer_email));
      } else {
        data.sort((a, b) => b.customer_email.localeCompare(a.customer_email));
      }
    }
  };

  const LogOutHandler = () => {
    logUserOut();
    navigate("/");
  };
  const nextPage = () => {
    if (currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <CustomerSection>
        <Avatar id="avatar" className="avatar">
          <AvatarText className="avatar-text">
            <BlueTextBold style={{ fontSize: "18px" }}>
              {userDetails?.email}
            </BlueTextBold>
            <div className="flex">
              <GrayTextMedium className="settings" onClick={settingsHandler}>
                {t("Profilesettings")}
              </GrayTextMedium>
              <GrayTextMedium className="log-out" onClick={LogOutHandler}>
                {t("LogOut")}
              </GrayTextMedium>
            </div>
          </AvatarText>
          {yourShop?.image && <AvatarImg src={yourShop?.image} alt="" />}{" "}
        </Avatar>
        <CustomerContainer id="container">
          <CustomerHeader>
            <BlueTextSmaller>{t("Customers")}</BlueTextSmaller>
          </CustomerHeader>
          <AllCustomersBox>
            <CustomersBoxFlex id="customersBox">
              <CustomerBoxHeader>{t("AllCustomers")}</CustomerBoxHeader>
              {customers?.data?.length > 0 && (
                <>
                  <CustomerFiltersBox>
                    <CustomerInputWrap customerFocus={customerFocus}>
                      <CustomerCross
                        onClick={customerCrossHandler}
                        customerFocus={customerFocus}
                        src={BlueCross}
                        alt=""
                      />
                      <CustomerInput
                        onClick={() => setCustomerFocus(true)}
                        ref={customerRef}
                        placeholder={t("SearchCustomerbyemail")}
                        onChange={(e) => setCustomersFilter(e.target.value)}
                        customerFocus={customerFocus}
                      />
                    </CustomerInputWrap>
                  </CustomerFiltersBox>
                  <div className="table_wrapper">
                    <table className="orders-table-customers">
                      <tbody>
                        <tr>
                          <td width="20%">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <h4
                                className="gray-text"
                                style={{ marginRight: "10px" }}
                              >
                                {t("Firstname")}
                              </h4>
                              <div
                                className="arrowBox"
                                onClick={() => ascendingHandler("firstName")}
                              >
                                <CustomerArrow src={CustomerArrowUp} alt="" />
                                <img
                                  src={CustomerRealArrow}
                                  alt=""
                                  className={
                                    ascending.name ? "arrow-drop" : "arrow"
                                  }
                                />
                              </div>
                            </div>
                          </td>
                          <td width="20%">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <h4
                                className="gray-text"
                                style={{ marginRight: "10px" }}
                              >
                                {t("LastName")}
                              </h4>
                              <div
                                className="arrowBox"
                                onClick={() => ascendingHandler("lastName")}
                              >
                                <CustomerArrow src={CustomerArrowUp} alt="" />
                                <img
                                  src={CustomerRealArrow}
                                  alt=""
                                  className={
                                    ascending.lastName ? "arrow-drop" : "arrow"
                                  }
                                />
                              </div>
                            </div>
                          </td>
                          <td width="40%">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <h4
                                className="gray-text"
                                style={{ marginRight: "10px" }}
                              >
                                {t("Customeremail")}
                              </h4>
                              <div
                                className="arrowBox"
                                onClick={() => ascendingHandler("email")}
                              >
                                <CustomerArrow src={CustomerArrowUp} alt="" />
                                <img
                                  src={CustomerRealArrow}
                                  alt=""
                                  className={
                                    ascending.email ? "arrow-drop" : "arrow"
                                  }
                                />
                              </div>
                            </div>
                          </td>
                          <td width="20%">
                            <div
                              className="orders-td-box-center"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="gray-text ">
                                {t("PhoneNumber")}
                              </div>
                            </div>
                          </td>
                        </tr>

                        {/* table */}
                        {customersListMaker().length ? (
                          <>
                            {customersListMaker()
                              .slice(itemsVisited, itemsVisited + itemsPerPage)
                              .map((item) => {
                                return (
                                  <tr>
                                    <td width="20%">
                                      <div className="image-flex">
                                        <h4 className="gray-text orders-td-box">
                                          {item.customer_name}
                                        </h4>
                                      </div>
                                    </td>
                                    <td width="20%" className="gray-text">
                                      <div className="orders-td-box">
                                        {item.customer_last_name}
                                      </div>
                                    </td>
                                    <td width="40%">
                                      <div
                                        className="orders-td-box gray-text"
                                        style={{ textAlign: "left" }}
                                      >
                                        {item.customer_email}
                                      </div>
                                    </td>
                                    <td width="20%" className="gray-text">
                                      <div className="orders-td-box-center">
                                        {item.customer_phone}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {!yourShop?.id ? (
                <>
                  <div className="table-box-empty">
                    <GrayTextMediumBigger>
                      {t("WithoutAShop")}
                    </GrayTextMediumBigger>
                    <CreateCustomer onClick={goToShopHandler}>
                      {t("CreateShopHere")}
                    </CreateCustomer>
                  </div>
                </>
              ) : (
                <>
                  {loadCustomers ? (
                    <div style={{ padding: "20px 0 0 30px" }}>
                      <Loading />
                    </div>
                  ) : (
                    !customers?.data?.length && (
                      <div className="table-box-empty">
                        <GrayTextMediumBigger>
                          {t("NoCustomers")}
                        </GrayTextMediumBigger>
                      </div>
                    )
                  )}
                </>
              )}
              <PaginationBox>
                <div className="pagination-elements">
                  <div className="pagination-arrow">
                    <img
                      alt=""
                      src={currentPage === 0 ? GrayArrow : PaginationArrowLeft}
                      onClick={previousPage}
                    />
                  </div>
                  <BlueTextMedium className="current-page">
                  {pageCount == 0 ? `${currentPage+1} / ${pageCount+1}` : `${currentPage+1} / ${pageCount}`}
                  </BlueTextMedium>
                  <div className="pagination-arrow">
                    <img
                      alt=""
                      src={
                        customersListMaker().length - itemsVisited <= 7
                          ? GrayArrowRight
                          : PaginationArrow
                      }
                      onClick={nextPage}
                    />
                  </div>
                </div>
              </PaginationBox>
            </CustomersBoxFlex>
          </AllCustomersBox>
        </CustomerContainer>
      </CustomerSection>
    </>
  );
};

export default CustomersPage;
