import React from "react";
import MojBizNews from "../../components/News/MojBizNews";
import { Footer } from "../../imports/landingPageContent";


const MojBizNewsPage = () => {
  
  return (
    <>
      <MojBizNews />
      <Footer />
    </>
  );
};

export default MojBizNewsPage;
