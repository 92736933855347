import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../../context/context";
import {
  BlueTextBold,
  ButtonBlue,
  GrayTextMedium,
} from "../../../styles/GlobalStyles";
import Loading from "../../Loading";
import { CustomerBoxHeaderNoBorder } from "../styles";
import "./shopStyles.scss";

const YourShop = ({ edit, setEdit, handleClose, open, sections }) => {
  const {
    yourShop,
    getDemoShopShop,
    closed,
    setClosed,
    loadShop,
    reload,
    setReload,
    setRemoveString,
  } = useGlobalContext();

  useEffect(() => {
    setReload(false);
    setEdit(false);
    setClosed(false);
    getDemoShopShop();
  }, [closed, reload, sections]);

  const deleteHandler = (e) => {
    e.preventDefault();
    setRemoveString("shop");
    open();
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="your-shop-content">
        <CustomerBoxHeaderNoBorder className="your-shop-header">
          {t("Yourshop")}
        </CustomerBoxHeaderNoBorder>
        <div className="your-shop-box">
          <img src={yourShop?.image} alt="" className="your-shop-box-img" />
          <div className="shop-info">
            <div className="shop-info-content">
              <div className="your-shop-text">
                {loadShop ? (
                  <div style={{ padding: "20px 0 0 30px" }}>
                    <Loading />
                  </div>
                ) : (
                  <>
                    <GrayTextMedium
                      style={{ fontSize: "16px", marginBottom: "5px" }}
                    >
                      {t("Shopname")}
                    </GrayTextMedium>
                    <BlueTextBold
                      style={{ fontSize: "25px", marginBottom: "12px" }}
                    >
                      {yourShop?.name}
                    </BlueTextBold>
                    <GrayTextMedium
                      style={{ fontSize: "16px", marginBottom: "5px" }}
                    >
                      {t("Description")}
                    </GrayTextMedium>
                    <div className="your-shop-description-box">
                      <BlueTextBold>{yourShop?.description}</BlueTextBold>
                      <h3>&nbsp;</h3>
                    </div>
                  </>
                )}
              </div>
              <div className="your-shop-buttons">
                <div className="button-box">
                  <ButtonBlue onClick={() => setEdit(true)}>
                    {t("Editshop")}
                  </ButtonBlue>
                </div>
                <div className="button-box">
                  <ButtonBlue onClick={deleteHandler} type="button">
                    {t("RemoveShop")}
                  </ButtonBlue>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourShop;
