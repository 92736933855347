import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import {
  FaqContainer,
  FaqHeader,
  FaqList,
  FaqBox,
  FaqLiParagraph,
  FaqSection,
  FaqBlueText,
  After,
  FaqText,
} from "./styles";

const Faq = () => {
  // const { hash } = useLocation();
  // useEffect(() => {
  //   if (hash) {
  //     const element = document.querySelector(hash);
  //     console.log(element);
  //     if (element) {
  //       element.scrollIntoView();
  //       window.scrollBy(0, -150);
  //     }
  //   }
  // }, [hash]);
  // console.log(hash);
  const { t } = useTranslation();
  return (
    <>
      <FaqSection id="faq">
        <FaqHeader>{t("Faq")}</FaqHeader>
        <FaqContainer>
          {/* <div style={{height:'700px', marginBottom:'50px'}}> */}
          <After id="123">
            {" "}
            {/*samo after efekat*/}
            <FaqList>
              <FaqBox>
                <FaqText>
                  <FaqBlueText>Šta je "mojbiz.me" platforma?</FaqBlueText>
                  <FaqLiParagraph>
                    "mojbiz.me" je online platforma koja omogućava kreiranje
                    digitalnih prodavnica - prodajnih izloga, namijenjenih
                    prvenstveno malim i srednjim preduzećima koja ne posjeduju
                    svoj web sajt ili digitalno prodajno mjesto – izlog. Pomaže
                    vam da pratite svoje poslovanje, upravljate proizvodima i
                    komunicirate s potencijalnim kupcima.
                  </FaqLiParagraph>
                </FaqText>
              </FaqBox>
              <FaqBox>
                <FaqText>
                  <FaqBlueText>
                    Kako se prijavljujem na platformu "mojbiz.me"?
                  </FaqBlueText>
                  <FaqLiParagraph>
                    Da biste se prijavili, kliknite na dugme "Prijavi se" na
                    početnoj stranici platforme. Možete se prijaviti putem
                    Google ili Facebook naloga, ili izabrati opciju "Registrujte
                    se" da biste kreirali novi nalog.
                  </FaqLiParagraph>
                </FaqText>
              </FaqBox>
              <FaqBox>
                <FaqText>
                  <FaqBlueText>
                    Kako da kreiram svoju digitalnu prodavnicu na "mojbiz.me"?
                  </FaqBlueText>
                  <FaqLiParagraph>
                    Nakon prijave, idite na administratorsku tablu i kliknite na
                    opciju "Prodavnica". Zatim, kliknite na dugme "Napravi" da
                    biste kreirali svoju prodavnicu i unijeli relevantne
                    informacije.
                  </FaqLiParagraph>
                </FaqText>
              </FaqBox>
              <FaqBox>
                <FaqText>
                  <FaqBlueText>
                    Kako mogu dodati nove proizvode u svoju prodavnicu?
                  </FaqBlueText>
                  <FaqLiParagraph>
                    Koristite opciju "Kreiraj proizvod" u
                    administratorskom panelu. Unesite informacije o proizvodu,
                    uključujući naziv, opis, kategoriju, atribute, cijenu i
                    slike. Prije nego što kreirate proizvode, neophodno je da
                    kreirate liste očekivanih kategorija u administratorskom
                    panelu.
                  </FaqLiParagraph>
                </FaqText>
              </FaqBox>
              <FaqBox>
                <FaqText>
                  <FaqBlueText>
                    Šta su kategorije i kako da ih kreiram?
                  </FaqBlueText>
                  <FaqLiParagraph>
                    Kategorije služe za grupisanje proizvoda. Kreiraju se u
                    administratorskom panelu. Prije nego što kreirate proizvode,
                    neophodno je da kreirate liste očekivanih kategorija.
                  </FaqLiParagraph>
                </FaqText>
              </FaqBox>
              <FaqBox>
                <FaqText>
                  <FaqBlueText>
                    Šta su atributi i kako da ih kreiram?
                  </FaqBlueText>
                  <FaqLiParagraph>
                    Atributi su karakteristike proizvoda. Kreiraju se u
                    administratorskom panelu. Preporučujemo da prije kreiranja
                    proizvoda kreirajte listu očekivanih atributa.
                  </FaqLiParagraph>
                </FaqText>
              </FaqBox>
              <FaqBox>
                <FaqText>
                  <FaqBlueText>
                    Koliko košta korišćenje "mojbiz.me" platforme?
                  </FaqBlueText>
                  <FaqLiParagraph>
                    "mojbiz.me" pruža besplatan osnovni nalog (paket) sa
                    osnovnim funkcijama. Plaćeni paketi nude napredne funkcije
                    platforme. Za ponudu paketa pogledajte stranicu „Ponuda“ na
                    platformi.
                  </FaqLiParagraph>
                </FaqText>
              </FaqBox>
              <FaqBox>
                <FaqText>
                  <FaqBlueText>
                    Kako funkcionišu korisnički paketi na "mojbiz.me"?
                  </FaqBlueText>
                  <FaqLiParagraph>
                    Pri registraciji dobijate besplatnu korisničku licencu za
                    Običan paket koja vam omogućava kreiranje do 4 proizvoda. Za
                    više proizvoda, možete kupiti jedan od ponuđenih korisničkih
                    paketa. Za ponudu paketa pogledajte stranicu „Ponuda“ na
                    platformi.
                  </FaqLiParagraph>
                </FaqText>
              </FaqBox>
              <FaqBox>
                <FaqText>
                  <FaqBlueText>
                    Kako korisnici vrše kupovinu na digitalnoj prodavnici?
                  </FaqBlueText>
                  <FaqLiParagraph>
                    Potencijalni kupci dodaju proizvode u korpu za kupovinu i
                    popunjavaju obaveznu formu s informacijama kao što su ime,
                    prezime, e-pošta, telefon, adresa i druge. Nakon toga,
                    potencijalni kupci dobijaju potvrdu narudžbine, a vlasnici
                    prodavnice dobijaju informacije o narudžbini. Sama kupovina
                    se odvija u direktnoj komunikaciji između kupca i prodavca i
                    nije podržana od strane platforme.
                  </FaqLiParagraph>
                </FaqText>
              </FaqBox>
              <FaqBox>
                <FaqText>
                  <FaqBlueText>
                    Da li "mojbiz.me" nudi podršku za online plaćanja?
                  </FaqBlueText>
                  <FaqLiParagraph>
                    "mojbiz.me" omogućava samo online plaćanja korisničkih
                    paketa. Integraciju sa različitim online platnim sistemima
                    za pojedine digitalne prodavnice – prodajne izloge se radi
                    isključivo po narudžbini - zasebnom dogovoru sa
                    zainteresovanim prodavcima.
                  </FaqLiParagraph>
                </FaqText>
              </FaqBox>
              <FaqBox>
                <FaqText>
                  <FaqBlueText>
                    Kako mogu pristupiti izvještajima na "mojbiz.me"?
                  </FaqBlueText>
                  <FaqLiParagraph>
                    Platforma omogućava pristup izvještajima o porudžbinama i
                    kupcima za vlasnike prodavnica. Ove informacije su dostupne
                    u administratorskom panelu.
                  </FaqLiParagraph>
                </FaqText>
              </FaqBox>
              <FaqBox>
                <FaqText>
                  <FaqBlueText>
                    Kako mogu da kontaktiram podršku za tehničke probleme?
                  </FaqBlueText>
                  <FaqLiParagraph>
                    Za tehničku podršku, možete nas kontaktirati putem e-pošte
                    na podrska@mojbiz.me.
                  </FaqLiParagraph>
                </FaqText>
              </FaqBox>
            </FaqList>
          </After>
          {/* </div> */}
        </FaqContainer>
      </FaqSection>
    </>
  );
};

export default Faq;
