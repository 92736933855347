import React, { useEffect, useState } from "react";
import {
  BlueTextBold,
  GrayTextMedium,
  YellowTextBold,
} from "../../styles/GlobalStyles";
import {
  AddToCart,
  AddToCartWhite,
  RemoveNoCircle,
} from "../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../context/context";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SingleShopProduct = ({ item }) => {
  const [addedToCart, setAddedToCart] = useState(false);
  const { handleAddProduct, handleRemoveProduct, cart } = useGlobalContext();

  const cartHandler = () => {
    handleAddProduct(item);
    setAddedToCart(true);
  };
  const cartRemoveHandler = () => {
    handleRemoveProduct(item);
    setAddedToCart(false);
  };
  const attributesString = () => {
    let allAtts = item?.attributes;
    let arr = [];
    allAtts.forEach((att) => {
      arr.push(att.name);
      return arr;
    });
    return arr.map((item) => item).join(",");
  };

  const isItAdded = cart.find((cartItem) => {
    return cartItem.id === item.id;
  });
  const { t } = useTranslation();
  return (
    <>
      <>
        <div className="single-item">
          <div className="shop-content">
            <Link to={`/product/${item.id}`}>
              <div className="image-box">
                <img
                  src={item?.images[0]?.image_url}
                  alt=""
                  onClick={() => console.log(item?.id)}
                />
              </div>
            </Link>
            <div className="text">
              <Link
                to={`/product/${item.id}`}
                style={{ textDecoration: "none" }}
              >
                <BlueTextBold className="single-item-header">
                  {item?.name}
                </BlueTextBold>
              </Link>
              <GrayTextMedium className="single-item-desc">
                {item?.description > 200 ? (
                  <>
                    {item?.description.slice(0, 200)}...
                    <Link
                      to={`/product/${item.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <YellowTextBold className="view">
                        {t("ViewMore")}
                      </YellowTextBold>
                    </Link>
                  </>
                ) : (
                  <>{item?.description}</>
                )}
              </GrayTextMedium>
              <GrayTextMedium className="category">
                {t("Category")}:&nbsp;
                <BlueTextBold>{item?.category.name}</BlueTextBold>
              </GrayTextMedium>
              <GrayTextMedium className="attributes">
                {t("Attribute")}:&nbsp;
                <BlueTextBold>{attributesString()}</BlueTextBold>
              </GrayTextMedium>
              <YellowTextBold className="price">
                {item?.price === 0.01 ? `${t("PriceRequest")}` : `${item?.price.toLocaleString()}€`}
              </YellowTextBold>
              {isItAdded && !item?.out_of_stock ? (
                <button className="added-btn" onClick={cartRemoveHandler}>
                  <img src={RemoveNoCircle} alt="" />
                  <YellowTextBold className="added-btn-text">
                    {t("RemoveFromCart")}
                  </YellowTextBold>
                </button>
              ) : !item?.out_of_stock ? (
                <button className="add-btn" onClick={cartHandler}>
                  <img src={AddToCart} alt="" />
                  <img
                    src={AddToCartWhite}
                    alt=""
                    className="add-to-cart-white"
                  />
                  <YellowTextBold 
                    className="add-btn-text"
                    >
                    {t("AddToCart")}
                  </YellowTextBold>
                </button>
              ) : (
                <button
                  className="add-btn outofstock"
                  onClick={cartHandler}
                  disabled
                >
                  <img src={AddToCart} alt="" />
                  <img
                    src={AddToCartWhite}
                    alt=""
                    className="add-to-cart-white"
                  />
                  <YellowTextBold className="add-btn-text">
                    Out of Stock
                  </YellowTextBold>
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SingleShopProduct;
