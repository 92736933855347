import React, { useState, useRef } from "react";
import { useEffect } from "react";
import {
  AvatarBlank,
  BlueCross,
  CustomerArrowUp,
  CustomerRealArrow,
  DashboardEdit,
  DashboardTrash,
  DemoPageCross,
  GrayArrow,
  GrayArrowRight,
  PaginationArrow,
  PaginationArrowLeft,
} from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import {
  BlueTextBold,
  BlueTextMedium,
  ButtonBlue,
  GrayTextMedium,
  GrayTextMediumBigger,
} from "../../../styles/GlobalStyles";
import {
  AllCustomersBox,
  Avatar,
  AvatarImg,
  AvatarText,
  BlueTextSmaller,
  CreateCustomer,
  CustomerArrow,
  CustomerBoxHeader,
  CustomerContainer,
  CustomerCross,
  CustomerFilterButton,
  CustomerFiltersBox,
  CustomerHeader,
  CustomerInput,
  CustomerInputWrap,
  CustomersBoxFlex,
  CustomerSection,
  PaginationArrows,
  PaginationBox,
} from "../styles";
import "../stylesAllPages.scss";
import ReactPaginate from "react-paginate";
import SingleOrderCheck from "./SingleOrderCheck";
import { ItemContent } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading";
import axios from "axios";
import { useTranslation } from "react-i18next";

const OrdersSection = ({ setSections, sections, modalOpen }) => {
  const [ascending, setAscending] = useState({
    id: false,
    date: false,
  });
  const {
    logUserOut,
    orders,
    setOrderId,
    baseUrl,
    config,
    closed,
    setClosed,
    getUserDetails,
    userDetails,
    setOrders,
    yourShop,
    getDemoShopShop,
  } = useGlobalContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [ordersHeader, setOrdersHeader] = useState("allOrders");
  const [checkOrder, setCheckOrder] = useState(false);
  const [customerFocus, setCustomerFocus] = useState(false);
  const [headersClicked, setHeadersClicked] = useState(false);
  const [ordersFilter, setOrdersFilter] = useState("");
  const [loadOrders, setLoadOrders] = useState(true);
  const customerRef = useRef(null);
  const customerCrossHandler = () => {
    setCustomerFocus(false);
    customerRef.current.value = "";
    setOrdersFilter("");
  };
  const navigate = useNavigate();
  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };
  const getDemoShopOrders = async () => {
    try {
      const response = axios
        .get(`${baseUrl}/order`, config)
        .then((response) => {
          if (response.status === 200) {
            setOrders(response.data);
            setLoadOrders(false);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const OrdersListMaker = () => {
    let list = [];
    orders?.data.filter((item) => {
      if (ordersFilter) {
        if (
          item.buyer_email.toLowerCase().includes(ordersFilter.toLowerCase())
        ) {
          list.push(item);
        }
        return list;
      } else {
        if (item.status === ordersHeader) {
          list.push(item);
        } else if (ordersHeader === "allOrders") {
          list.push(item);
        }
      }
    });
    return list;
  };
  let itemsPerPage = 7;
  let pageCount = Math.ceil(OrdersListMaker().length / itemsPerPage);
  let itemsVisited = currentPage * itemsPerPage;
  const handleSingleOrder = (id) => {
    setOrderId(id);
    setCheckOrder(true);
  };
  useEffect(() => {
    if (filterHandler) {
      setCurrentPage(0);
    }
    console.log(pageCount + " this is page count");
    console.log(currentPage + " this is current page");
    setClosed(false);
    getDemoShopOrders();
    getDemoShopShop();
    getUserDetails();
  }, [checkOrder, headersClicked, ordersHeader, closed, ordersFilter]);

  const filterHandler = (selecter) => {
    setOrdersHeader(selecter);
    let data = orders?.data;
    if (selecter === "accepted") {
      let newArr = data.filter((item) => item.status === "accepted");
      console.log(newArr);
    } else if (selecter === "rejected") {
      let newArr = data.filter((item) => item.status === "rejected");
      // data.filter((item) => item.status === "rejected");
    } else if (selecter === "pending") {
      data.filter((item) => item.status === "pending");
    } else {
      return data;
    }
  };

  const ascendingHandler = (acc) => {
    let data = orders?.data;
    if (acc === "id") {
      setAscending({ ...ascending, id: !ascending.id });
      if (ascending.id) {
        data.sort((a, b) => a.id - b.id);
      } else {
        data.sort((a, b) => b.id - a.id);
      }
    } else if (acc === "date") {
      setAscending({ ...ascending, date: !ascending.date });
      if (ascending.date) {
        data.sort(
          (a, b) =>
            parseInt(b.order_date.split(" ")[1].split(":").join("")) -
            parseInt(a.order_date.split(" ")[1].split(":").join(""))
        );
      } else {
        data.sort(
          (a, b) =>
            parseInt(a.order_date.split(" ")[1].split(":").join("")) -
            parseInt(b.order_date.split(" ")[1].split(":").join(""))
        );
      }
    }
  };

  const LogOutHandler = () => {
    logUserOut();
    navigate("/");
  };
  const nextPage = () => {
    if (currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const { t } = useTranslation();
  return (
    <CustomerSection>
      <Avatar id="avatar" className="avatar">
        <AvatarText className="avatar-text">
          <BlueTextBold style={{ fontSize: "18px" }}>
            {userDetails?.email}
          </BlueTextBold>
          <div className="flex">
            <GrayTextMedium className="settings" onClick={settingsHandler}>
              {t("Profilesettings")}
            </GrayTextMedium>
            <GrayTextMedium className="log-out" onClick={LogOutHandler}>
              {t("LogOut")}
            </GrayTextMedium>
          </div>
        </AvatarText>
        {yourShop?.image && <AvatarImg src={yourShop?.image} alt="" />}{" "}
      </Avatar>
      <CustomerContainer id="container">
        <CustomerHeader>
          <BlueTextSmaller>{t("Orders")}</BlueTextSmaller>
          {checkOrder && (
            <CreateCustomer onClick={() => setCheckOrder(false)}>
              {t("Allorders")}
            </CreateCustomer>
          )}
        </CustomerHeader>
        <AllCustomersBox>
          <CustomersBoxFlex id="customersBox">
            {checkOrder ? (
              <>
                <div style={{ display: "flex", margin: "auto" }}>
                  <CustomerBoxHeader style={{ paddingRight: "20px" }}>
                    {t("SingleOrder")}
                  </CustomerBoxHeader>
                </div>
                <SingleOrderCheck
                  checkOrder={checkOrder}
                  modalOpen={modalOpen}
                />
              </>
            ) : (
              <>
                <div className="orders-accepted-box">
                  <h3
                    style={{ fontSize: "14px" }}
                    className={
                      ordersHeader === "allOrders"
                        ? "orders-accepted-active"
                        : "orders-accepted-header"
                    }
                    onClick={() => filterHandler("allOrders")}
                  >
                    {t("Allorders")}
                  </h3>
                  <h3
                    style={{ fontSize: "14px" }}
                    className={
                      ordersHeader === "accepted"
                        ? "orders-accepted-active"
                        : "orders-accepted-header"
                    }
                    onClick={() => filterHandler("accepted")}
                  >
                    {t("Accepted")}
                  </h3>
                  <h3
                    style={{ fontSize: "14px" }}
                    className={
                      ordersHeader === "rejected"
                        ? "orders-accepted-active"
                        : "orders-accepted-header"
                    }
                    onClick={() => filterHandler("rejected")}
                  >
                    {t("Declined")}
                  </h3>
                  <h3
                    style={{ fontSize: "14px" }}
                    className={
                      ordersHeader === "pending"
                        ? "orders-accepted-active"
                        : "orders-accepted-header"
                    }
                    onClick={() => filterHandler("pending")}
                  >
                    {t("Pending")}
                  </h3>
                </div>
                {orders?.data.length > 0 && (
                  <>
                    <CustomerFiltersBox>
                      <CustomerInputWrap customerFocus={customerFocus}>
                        <CustomerCross
                          onClick={customerCrossHandler}
                          customerFocus={customerFocus}
                          src={BlueCross}
                          alt=""
                        />
                        <CustomerInput
                          ref={customerRef}
                          placeholder={t("SearchOrderbyemail")}
                          onClick={() => setCustomerFocus(true)}
                          onChange={(e) => setOrdersFilter(e.target.value)}
                          customerFocus={customerFocus}
                        />
                      </CustomerInputWrap>
                    </CustomerFiltersBox>
                    <div className="table_wrapper">
                      <table className="orders-table">
                        {/* header */}
                        <tbody>
                          <tr>
                            <td width="15%">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <h4
                                  className="gray-text"
                                  style={{ marginRight: "10px" }}
                                >
                                  {t("Idnumber")}
                                </h4>
                                <div
                                  className="arrowBox"
                                  onClick={() => ascendingHandler("id")}
                                >
                                  <CustomerArrow src={CustomerArrowUp} alt="" />
                                  <img
                                    src={CustomerRealArrow}
                                    alt=""
                                    className={
                                      ascending.id ? "arrow-drop" : "arrow"
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td width="10%">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <h4
                                  className="gray-text"
                                  style={{ marginRight: "10px" }}
                                >
                                  {t("Date")}
                                </h4>
                                <div
                                  className="arrowBox"
                                  onClick={() => ascendingHandler("date")}
                                >
                                  <CustomerArrow src={CustomerArrowUp} alt="" />
                                  <img
                                    src={CustomerRealArrow}
                                    alt=""
                                    className={
                                      ascending.date ? "arrow-drop" : "arrow"
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td width="20%">
                              <div className="orders-td-box gray-text">
                                {t("Email")}
                              </div>
                            </td>
                            <td width="20%">
                              <div className="orders-td-box gray-text">
                                {t("Customer")}
                              </div>
                            </td>
                            <td width="10%">
                              <div className="orders-td-box gray-text">
                                {t("Checkorderstatus")}
                              </div>
                            </td>
                            <td width="15%">
                              <div className="orders-td-box-center gray-text">
                                {t("Orderstatus")}
                              </div>
                            </td>
                            <td width="10%">
                              <div className="orders-td-box-center gray-text">
                                {t("Price")}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        {/* table */}

                        {OrdersListMaker()
                          .slice(itemsVisited, itemsVisited + itemsPerPage)
                          .map((item, index) => {
                            return (
                              <>
                                <tbody key={index}>
                                  <tr>
                                    <td width="15%">
                                      <div className="image-flex">
                                        <h4 className="gray-text orders-td-box">
                                          #{item.id}
                                        </h4>
                                      </div>
                                    </td>
                                    <td width="10%" className="gray-text">
                                      <div className="orders-td-box">
                                        {item.order_date.split(" ")[0]}
                                      </div>
                                    </td>
                                    <td width="20%">
                                      <div className="orders-td-box gray-text">
                                        {item.buyer_email}
                                      </div>
                                    </td>
                                    <td width="20%">
                                      <div className="orders-td-box">
                                        {ordersHeader ? <></> : <></>}
                                        <div className="gray-text">
                                          {item.buyer_name}{" "}
                                          {item.buyer_last_name}
                                        </div>
                                      </div>
                                    </td>
                                    <td width="10%">
                                      <div className="orders-td-box">
                                        <div className="gray-text">
                                          <button
                                            className="trash-btn"
                                            onClick={() =>
                                              handleSingleOrder(item.id)
                                            }
                                          >
                                            <div className="trash-btn-content">
                                              <h4 className="check-text">
                                                {t("Check")}
                                              </h4>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </td>
                                    <td width="15%" className="gray-text">
                                      <div className="orders-td-box-center">
                                        <button
                                          className={
                                            item.status === "accepted"
                                              ? "availability green"
                                              : item.status === "pending"
                                              ? "availability yellow"
                                              : "availability red"
                                          }
                                        >
                                          {item.status}
                                        </button>
                                      </div>
                                    </td>
                                    <td width="10%">
                                      <div
                                        className="orders-td-box"
                                        style={{ textAlign: "right" }}
                                      >
                                        <GrayTextMedium>
                                          <span style={{ fontSize: "10px" }}>
                                            EUR
                                          </span>{" "}
                                          {item.order_total}.00
                                        </GrayTextMedium>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            );
                          })}
                      </table>
                    </div>
                  </>
                )}
              </>
            )}

            {loadOrders ? (
              <div style={{ padding: "20px 0 0 30px" }}>
                <Loading />
              </div>
            ) : (
              <>
                {!orders?.data.length && (
                  <div className="table-box-empty">
                    <GrayTextMediumBigger>{t("NoOrders")}</GrayTextMediumBigger>
                  </div>
                )}
              </>
            )}
            {!checkOrder && (
              <PaginationBox>
                <div className="pagination-elements">
                  <div className="pagination-arrow">
                    <img
                      alt=""
                      src={currentPage === 0 ? GrayArrow : PaginationArrowLeft}
                      onClick={previousPage}
                    />
                  </div>
                  <BlueTextMedium className="current-page">
                  {pageCount == 0 ? `${currentPage+1} / ${pageCount+1}` : `${currentPage+1} / ${pageCount}`}
                  </BlueTextMedium>
                  <div className="pagination-arrow">
                    <img
                      alt=""
                      src={
                        OrdersListMaker().length - itemsVisited <= 7
                          ? GrayArrowRight
                          : PaginationArrow
                      }
                      onClick={nextPage}
                    />
                  </div>
                </div>
              </PaginationBox>
            )}
          </CustomersBoxFlex>
        </AllCustomersBox>
      </CustomerContainer>
    </CustomerSection>
  );
};

export default OrdersSection;
