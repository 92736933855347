import styled from "styled-components";

export const YellowTextBold = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  color: #ff9900;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;
export const GrayTextLightMedium = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: #c2c9d0;
  font-size: 14px;
`;
export const GrayTextMedium = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: #687b90;
  font-size: 14px;
`;
export const GrayTextMediumBigger = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: #687b90;
  font-size: 16px;
`;
export const GrayTextBold = styled.p`
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  color: #687b90;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;
export const WhiteTextBold = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;
export const WhiteTextMedium = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  color: #ffffff;
  font-size: 14px;
`;

export const BlueTextMedium = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: #3853a3;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;
export const BlueTextBold = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  color: #3853a3;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;
export const BlueTextSemiBold = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  color: #3853a3;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;

export const ButtonBlue = styled.button`
  background: #3853a3;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
  font-family: "Jost";
  font-style: normal;
  border: 1px solid transparent;
  transition: 0.3s all ease;
  &:hover {
    background-color: #fff;
    transition: 0.3s all ease;
    color: #ffa800;
    border: 1px solid #ffa800;
  }
  @media screen and (min-width: 768px) {
    margin-left: 0;
  }
`;

export const ButtonBluetoYellow = styled.button`
  background: #3853a3;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
  font-family: "Jost";
  font-style: normal;
  border: 1px solid transparent;
  transition: 0.3s all ease;
  &:hover {
    background-color: #ffa800;
    transition: 0.3s all ease;
    color: #fff;
  }
`;

export const ButtonGray = styled.button`
  background: rgba(126, 148, 171, 0.5);
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  outline: none;
  font-family: "Jost";
  font-style: normal;
`;
export const ButtonYellow = styled.button`
  background: #ffa800;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  outline: none;
  border: 1px solid #ffa800;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  transition: 0.3s all ease;
  &:hover {
    color: #ffa800;
    background: transparent;
    border: 1px solid #ffa800;
    transition: 0.3s all ease;
  }
  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;
export const ButtonTransparentYellow = styled.button`
  color: #ffa800;
  background: transparent;
  cursor: pointer;
  border: 1px solid #ffa800;
  border-radius: 100px;
  padding: 11px 41px;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;
export const ButtonTransparentWhite = styled.button`
  border-radius: 100px;
  color: #fff;
  background: transparent;
  cursor: pointer;
  border: 1px solid #d8d6db;
  outline: none;
`;
export const Flex = styled.div`
  display: flex;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const FlexBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Li = styled.li`
  list-style: none;
  text-decoration: none;
`;

export const AlignItems = styled.div`
  display: flex;
  align-items: center;
`;

export const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 450px) {
    position: relative;
  }
`;
export const SliderBox = styled.div`
  padding-bottom: ${(props) => (props.news ? "0" : "100px")};
  overflow: hidden;
  width: 1500px;
  padding-top: 20px;
  @media only screen and (max-width: 450px) {
    padding-top: 0px;
    padding-bottom: 0;
    width: 260px;
  }
`;
export const Slider = styled.div`
  display: flex;
  width: 100%;
`;

const Arrow = styled.img`
  cursor: pointer;
  transition: 0.5s all ease;
  width: 100%;
  @media only screen and (max-width: 450px) {
  }
`;

export const ArrowLeft = styled(Arrow)``;
export const ArrowRight = styled(Arrow)``;

export const Cross = styled.img`
  width: 28px;
  height: 28px;
  position: absolute;
  right: 10px;
  top: 13px;
  z-index: 5;
  cursor: pointer;
  opacity: 0;
  transition: 0.5s all ease;
`;

export const Input = styled.input`
  height: 50px;
  font-size: 15px;
  line-height: 22px;
  padding: 0 0 0 23px;
  color: #3853a3;
  outline: none;
  border: none;
  background: ${(props) => props.emailfocus};
  // &:focus {
  //   background: #ebebeb;
  //   transition: 0.3s all ease;
  //   border: 1px solid transparent;
  // }
`;

export const InputBox = styled.div`
  position: relative;
  margin-bottom: 24px;
  border: 1px solid #3853a3;
  border-radius: 100px;
  color: #3853a3;
`;
