import React from "react";
import { useEffect } from "react";
import {
  AvatarBlank,
  Diagram,
  Note,
} from "../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../context/context";
import {
  BlueTextBold,
  ButtonBluetoYellow,
  FlexBetween,
  GrayTextMedium,
  GrayTextMediumBigger,
} from "../../styles/GlobalStyles";
import moment from "moment/moment";
import {
  Activity,
  BlueTextMini,
  BlueTextSmaller,
  GrayText,
  Header,
  HomeSection,
  OrderPlaced,
  StatImage,
  StatImageBox,
  StatLeft,
  StatRight,
  StatText,
  ContentBox,
  FlexBetweenDemoShop,
  Avatar,
  AvatarText,
  AvatarImg,
} from "./styles";
import "./stylesAllPages.scss";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DemoShopHome = ({ sections, setSections }) => {
  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };
  const navigate = useNavigate();
  const {
    authKey,
    getUserDetails,
    logUserOut,
    getDemoShopHome,
    home,
    yourShop,
    getDemoShopShop,
    userDetails,
    getDemoShopOrders,
    orders,
    loadHome,
    shopId,
  } = useGlobalContext();

  useEffect(() => {
    getUserDetails();
    getDemoShopHome();
    getDemoShopShop();
    getDemoShopOrders();
  }, [authKey]);
  const dateHandler = (dateString) => {
    const time = moment(dateString).fromNow();
    return time;
  };
  console.log(localStorage.getItem("authKey"));
  const LogOutHandler = () => {
    logUserOut();
    navigate("/");
  };
  const { t } = useTranslation();
  console.log(yourShop);
  return (
    <>
      <HomeSection>
        <div className="home-header-box">
          <Avatar id="avatar" className="avatar">
            <AvatarText className="avatar-text">
              <BlueTextBold style={{ fontSize: "18px" }}>
                {userDetails?.email}
              </BlueTextBold>
              <div className="flex">
                <GrayTextMedium className="settings" onClick={settingsHandler}>
                  {t("Profilesettings")}
                </GrayTextMedium>
                <GrayTextMedium className="log-out" onClick={LogOutHandler}>
                  {t("LogOut")}
                </GrayTextMedium>
              </div>
            </AvatarText>
            {yourShop?.image && <AvatarImg src={yourShop?.image} alt="" />}
          </Avatar>
        </div>
        {/* stats */}
        <Header>
          <div className="hello-there">
            <BlueTextSmaller style={{ display: "block" }}>
              {t("Hellothere")}, {userDetails?.email}
            </BlueTextSmaller>
            <GrayTextMedium style={{ lineHeight: "30px", height: "60px" }}>
              {t("HereIsSomeInformation")}
            </GrayTextMedium>
          </div>
          <div className="hello-there-small">
            <h2 className="hello">{t("Hellothere")}</h2>
            <GrayTextMedium style={{ lineHeight: "30px", height: "60px" }}>
              {t("HereIsSomeInformation")}
            </GrayTextMedium>
          </div>
          {yourShop?.id !== null ? (
            <Link to={`/shop/${yourShop?.id}`}>
              <ButtonBluetoYellow className="home-single-product">
                {t("SeeyourShop")}
              </ButtonBluetoYellow>
            </Link>
          ) : (
            <></>
          )}
        </Header>
        <ContentBox>
          <div className="stats-box-and-products">
            <FlexBetweenDemoShop>
              <StatLeft id="stat">
                <FlexBetween
                  style={{
                    padding: "15px 15px 15px 20px",
                    alignItems: "start",
                  }}
                >
                  <StatText>
                    <div
                      style={{
                        height: "90px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <BlueTextSmaller style={{ marginLeft: -3 }}>
                        {t("Sales")}
                      </BlueTextSmaller>
                      <GrayText>{t("Today")}</GrayText>
                    </div>
                    <div
                      style={{
                        lineHeight: "30px",
                        marginTop: "-8px",
                      }}
                    >
                      <BlueTextMini>EUR</BlueTextMini>&nbsp;
                      {loadHome ? (
                        <GrayTextMediumBigger>-- --</GrayTextMediumBigger>
                      ) : (
                        <>
                          {home?.total_sum !== null ? (
                            <BlueTextSmaller>
                              {parseInt(home?.total_sum).toLocaleString()}.00
                            </BlueTextSmaller>
                          ) : (
                            <BlueTextSmaller>0</BlueTextSmaller>
                          )}
                        </>
                      )}
                    </div>
                  </StatText>
                  <StatImageBox>
                    <StatImage src={Diagram} alt="" />
                  </StatImageBox>
                </FlexBetween>
              </StatLeft>
              <StatRight>
                <FlexBetween
                  style={{
                    padding: "15px 15px 15px 20px",
                    alignItems: "start",
                  }}
                >
                  <StatText>
                    <div
                      style={{
                        height: "90px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <BlueTextSmaller style={{ marginLeft: -3 }}>
                        {t("Orders")}
                      </BlueTextSmaller>
                      <GrayText>{t("Today")}</GrayText>
                    </div>
                    <div
                      style={{
                        lineHeight: "30px",
                        marginTop: "-8px",
                      }}
                    >
                      {loadHome ? (
                        <GrayTextMediumBigger>-- --</GrayTextMediumBigger>
                      ) : (
                        <>
                          {home?.order_count !== null ? (
                            <BlueTextSmaller>
                              {home?.order_count}
                            </BlueTextSmaller>
                          ) : (
                            <BlueTextSmaller>0</BlueTextSmaller>
                          )}
                        </>
                      )}
                    </div>
                  </StatText>
                  <StatImageBox>
                    <StatImage src={Note} alt="" />
                  </StatImageBox>
                </FlexBetween>
              </StatRight>
            </FlexBetweenDemoShop>
            {/* orders */}
            <div className="home-top-products">
              {/* <div className="home-products-content"> */}
              <h4 className="home-top-products-header">
                {t("Bestsellingproducts")}
              </h4>
              <table className="home-products-table">
                <tbody>
                  {home !== undefined && home?.top_products?.length > 1 ? (
                    <>
                      {home?.top_products.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <GrayTextMedium>{item?.name}</GrayTextMedium>
                            </td>
                            <td>
                              <GrayTextMedium>
                                EUR {parseInt(item?.price).toLocaleString()}
                                .00
                              </GrayTextMedium>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td>
                        <GrayTextMedium>{t("NoProducts")}</GrayTextMedium>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* </div> */}
            </div>
          </div>
          <Activity id="activity">
            <div style={{ height: "60px" }}>
              <BlueTextSmaller
                style={{ textAlign: "left", display: "inline-block" }}
              >
                {t("Activity")}
              </BlueTextSmaller>
            </div>
            <div className="activity-box">
              {home !== undefined && home?.user_orders?.length > 1 ? (
                <>
                  {home?.user_orders?.map((item) => {
                    return (
                      <OrderPlaced>
                        <BlueTextMini>
                          #{item?.id} {t("Orderwasplaced")}
                        </BlueTextMini>
                        <div className="activity-order-gray-text">
                          {dateHandler(item?.order_date)}
                        </div>
                      </OrderPlaced>
                    );
                  })}
                </>
              ) : (
                <OrderPlaced>
                  <BlueTextMini>{t("LatestOrder")}</BlueTextMini>
                </OrderPlaced>
              )}
            </div>
          </Activity>
        </ContentBox>
      </HomeSection>
    </>
  );
};

export default DemoShopHome;
