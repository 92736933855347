import { motion } from 'framer-motion'
import { useGlobalContext } from '../../context/context'
import './styles.scss'

const Backdrop = ({ onClick, children }) => {
    return (
        <motion.div onClick={onClick} className='backdrop' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            {children}
        </motion.div>
    )
}

export default Backdrop