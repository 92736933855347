import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FlexCenter,
  FlexColumn,
  GrayTextMedium,
  WhiteTextMedium,
} from "../../../styles/GlobalStyles";
import {
  ChooseContainer,
  ChooseHeader,
  CreateButtonPremium,
  CreateButtonRegular,
  HeaderPremium,
  HeaderRegular,
  OfferPriceHeaderPremium,
  OfferPriceHeaderRegular,
  OfferPricePremium,
  OfferPriceRegular,
  ChooseSection,
  PlanUl,
  PremiumPlan,
  RegularPlan,
  FlexResponsive,
} from "./styles";
import axios from "axios";
import { useGlobalContext } from "../../../context/context";
import uuid from 'uuid';
import { format } from 'date-fns';

const ChoosePlan = () => {
  const { t } = useTranslation();
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [amount, setAmount] = useState(' ');
  const apiUrl = `https://mbgc-me-staging.herokuapp.com/api/v1/auth/nlb`;
  const [merchantTransactionId, setMerchantTransactionId] = useState('')
  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const {
    getUserDetails,
    userDetails,
    paymentCounter,
    setPaymentCounter,
    incrementPaymentCounter
  } = useGlobalContext();
  
  const requestBodyMonth = {
    merchantTransactionId: `${currentDate} - ${String(paymentCounter).padStart(3, '0')}`,
    amount: '9.99',
    currency: "EUR",
    "successUrl": "https://mojbiz.me/success",
    "cancelUrl": "https://mojbiz.me/cancel",
    "errorUrl": "https://mojbiz.me/error",
    "callbackUrl": "https://mbgc-me-staging.herokuapp.com/api/v1/auth/nlb/callback",
    "withRegister": false,
    "transactionIndicator": "SINGLE",
    "customer": {
        "billingAddress1": "Ulica",
        "billingCity": "City",
        "billingPostcode": "1000",
        "billingCountry": "ME",
        "email": userDetails?.email,
    },
    "language": "en"
  };
  
  const requestBodyYear = {
    merchantTransactionId: `${currentDate} - ${String(paymentCounter).padStart(3, '0')}`,
    amount: '99.00',
    currency: "EUR",
    "successUrl": "https://mojbiz.me/success",
    "cancelUrl": "https://mojbiz.me/cancel",
    "errorUrl": "https://mojbiz.me/error",
    "callbackUrl": "https://mbgc-me-staging.herokuapp.com/api/v1/auth/nlb/callback",
    "withRegister": false,
    "transactionIndicator": "SINGLE",
    "customer": {
        "billingAddress1": "Ulica",
        "billingCity": "City",
        "billingPostcode": "1000",
        "billingCountry": "ME",
        "email": userDetails?.email,
    },
    "language": "en"
  };

  const initiatePaymentMonth = async () => {
    getUserDetails();
    if (userDetails?.email) {
      try {
        console.log(amount)
        const headers = {
          "Content-Type": "application/json",
        };

        const response = await axios.post(apiUrl, requestBodyMonth, { headers });
        const responseData = response.data;
        setPaymentResponse(responseData);
        incrementPaymentCounter();

        if (responseData.returnType === "REDIRECT") {
          window.location.href = responseData.redirectUrl;
        } else if (responseData.returnType === "ERROR") {
        }
      } catch (error) {
        console.error("Error initiating payment:", error);
      }
    } else {
    console.log ("Please log in!")
    }
  };
  
  const initiatePaymentYear = async () => {
    getUserDetails();
    if (userDetails?.email) {
      try {
        console.log(amount)
        const headers = {
          "Content-Type": "application/json",
        };

        const response = await axios.post(apiUrl, requestBodyYear, { headers });
        const responseData = response.data;
        setPaymentResponse(responseData);
        setPaymentCounter(paymentCounter + 1);

        if (responseData.returnType === "REDIRECT") {
          window.location.href = responseData.redirectUrl;
        } else if (responseData.returnType === "ERROR") {
        }
      } catch (error) {
        console.error("Error initiating payment:", error);
      }
    } else {
      console.log ("Please log in!")
    }
  };

  return (
    <>
      <ChooseSection>
        <ChooseContainer>
          <ChooseHeader>{t("ChooseYourPlan")}</ChooseHeader>
          <FlexResponsive>
            <RegularPlan >
              <FlexColumn>
                <HeaderRegular>{t("RegularPlan")}</HeaderRegular>
                <PlanUl>
                  <li>
                    <GrayTextMedium style={{ lineHeight: "40px", fontSize: "20px" }}>
                      {t("Limitednumberofproducts")}
                    </GrayTextMedium>
                  </li>
                  <li>
                    <GrayTextMedium style={{ fontSize: "20px", color: "#3853a3" }}>
                      4
                    </GrayTextMedium>
                  </li>
                  <li>
                    <GrayTextMedium style={{ lineHeight: "40px", fontSize: "20px", marginTop: "0px" }}>
                      {t("Analytics")}
                    </GrayTextMedium>
                  </li>
                  <li>
                    <GrayTextMedium style={{ fontSize: "20px", color: "#3853a3" }}>
                    {t("No")}
                    </GrayTextMedium>
                  </li>
                  <li>
                    <GrayTextMedium style={{ lineHeight: "40px", fontSize: "20px", marginTop: "0px" }}>
                      {t("Advertising")}
                    </GrayTextMedium>
                  </li>
                  <li>
                    <GrayTextMedium style={{ fontSize: "20px", color: "#3853a3" }}>
                    {t("No")}
                    </GrayTextMedium>
                  </li>
                </PlanUl>
                <OfferPriceHeaderRegular style={{ marginTop: "70px" }}>
                  {t("Offerprice")}
                </OfferPriceHeaderRegular>
                <OfferPriceRegular>0,00€</OfferPriceRegular>
              </FlexColumn>
            </RegularPlan >
            <RegularPlan onClick={() => initiatePaymentMonth()}>
              <FlexColumn>
              <HeaderRegular style={{ lineHeight: '0', marginTop: '80px'}}>{t("PremiumPlan")}</HeaderRegular>
                <HeaderRegular style={{ lineHeight: '0', marginBottom: '60px', marginTop: '60px'}}>({t("Month")})</HeaderRegular>
                <PlanUl>
                <li>
                    <GrayTextMedium style={{ lineHeight: "40px", fontSize: "20px" }}>
                      {t("Unlimitednumberofproducts")}
                    </GrayTextMedium>
                  </li>
                  <li>
                    <GrayTextMedium style={{ lineHeight: "40px", fontSize: "20px", marginTop: "0px" }}>
                      {t("Analytics")}
                    </GrayTextMedium>
                  </li>
                  <li>
                    <GrayTextMedium style={{ fontSize: "20px", color: "#3853a3" }}>
                    {t("Yes")}
                    </GrayTextMedium>
                  </li>
                  <li>
                    <GrayTextMedium style={{ lineHeight: "40px", fontSize: "20px", marginTop: "0px" }}>
                      {t("Advertising")}
                    </GrayTextMedium>
                  </li>
                  <li>
                    <GrayTextMedium style={{ fontSize: "20px", color: "#3853a3" }}>
                    {t("Yes")}
                    </GrayTextMedium>
                  </li>
                </PlanUl>
                <OfferPriceHeaderRegular style={{ marginTop: "60px" }}>
                  {t("Offerprice")}
                </OfferPriceHeaderRegular>
                <OfferPriceRegular>9,99€</OfferPriceRegular>
              </FlexColumn>
            </RegularPlan>
            <PremiumPlan onClick={() => initiatePaymentYear()}>
              <FlexColumn>
                <HeaderPremium style={{ lineHeight: '0', marginTop: '80px'}}>{t("PremiumPlan")}</HeaderPremium>
                <HeaderPremium style={{ lineHeight: '0', marginBottom: '60px', marginTop: '60px'}}>({t("Year")})</HeaderPremium>
                <PlanUl style={{ color: "#fff" }}>
                <li>
                    <WhiteTextMedium style={{ lineHeight: "40px", fontSize: "20px" }}>
                      {t("Unlimitednumberofproducts")}
                    </WhiteTextMedium>
                  </li>
                  <li>
                    <WhiteTextMedium style={{ lineHeight: "40px", fontSize: "20px", marginTop: "0px" }}>
                      {t("Analytics")}
                    </WhiteTextMedium>
                  </li>
                  <li>
                    <WhiteTextMedium style={{ fontSize: "20px" }}>
                    {t("Yes")}
                    </WhiteTextMedium>
                  </li>
                  <li>
                    <WhiteTextMedium style={{ lineHeight: "40px", fontSize: "20px", marginTop: "0px" }}>
                      {t("Advertising")}
                    </WhiteTextMedium>
                  </li>
                  <li>
                    <WhiteTextMedium style={{ fontSize: "20px" }}>
                    {t("Yes")}
                    </WhiteTextMedium>
                  </li>
                </PlanUl>
                <OfferPriceHeaderPremium>
                  {t("Offerprice")}
                </OfferPriceHeaderPremium>
                <OfferPricePremium>99,00€</OfferPricePremium>
              </FlexColumn>
            </PremiumPlan>
          </FlexResponsive>
        </ChooseContainer>
      </ChooseSection>
    </>
  );
};

export default ChoosePlan;
