import React from "react";
import ChoosePlan from "../../components/CreateShopPage/ChoosePlan/ChoosePlan";
import Faq from "../../components/CreateShopPage/Faq/Faq";
import { Navbar } from "../../imports/landingPageContent";
import Footer from "../../components/LandingPage/Footer/Footer";
import styled from "styled-components";
import  Pattern3  from "../../assets/landingPage/lightblueWeb_Business1.png";

const Container = styled.div`
  background: linear-gradient(0deg, #ffffff 7.44%, rgba(255, 255, 255, 0.3) 100%);
`;
const Section = styled.div`
  background-image: url(${Pattern3});
  background-size: 40%;
  @media only screen and (max-width: 730px) {
    background-size: 100%;}
`;

const CreateShopPage = () => {
  return (
    <>
      {/* <Navbar /> */}
      <Section>
        <Container>
          <ChoosePlan />
          <Faq />
        </Container>
      </Section>
      <Footer />
    </>
  );
};

export default CreateShopPage;
