import React, { useState, useEffect } from "react";
import { BlueTextBold, ButtonBlue } from "../../../styles/GlobalStyles";
import {
  BlueCross,
  OutOfStockTick,
  PasswordCross,
  UploadImage,
} from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const EditProduct = ({ editProduct, setEditProduct, singleProduct }) => {
  const [formText, setFormText] = useState({
    name: "",
    description: "",
    price: "",
    out_of_stock: false,
    category_id: null,
    attribute_ids: [],
    images: [],
  });
  const [nameFocused, setNameFocused] = useState(false);
  const [descriptionFocused, setDescriptionFocused] = useState(false);
  const [priceFocused, setPriceFocused] = useState(false);
  const [file, setFile] = useState();
  const [selectedAttributes, setSelectedAttributes] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [editProductContent, setEditProductContent] = useState();
  const [stock, setStock] = useState(false);
  const [images, setImages] = useState({
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  });
  const formSubmit = (e) => {
    e.preventDefault();
  };
  const nameCrossHandler = () => {
    setNameFocused(false);
    setFormText({ ...formText, name: "" });
  };
  const DescriptionCrossHandler = () => {
    setDescriptionFocused(false);
    setFormText({ ...formText, description: "" });
  };
  const PriceCrossHandler = () => {
    setPriceFocused(false);
    setFormText({ ...formText, price: "" });
  };
  const cancelHandler = () => {
    setEditProduct(false);
  };

  //   categories dropdown data
  let categoriesArr = [
    { value: "Lure Kit", label: "Lure Kit" },
    { value: "Fishing Rod", label: "Fishing Rod" },
    { value: "Fishing Reel", label: "Fishing Reel" },
    { value: "Rod", label: "Rod" },
    { value: "Spinning Reel", label: "Spinning Reel" },
  ];
  let attributesArr = [
    { value: "blue", label: "Blue" },
    { value: "green", label: "Green" },
    { value: "strong", label: "strong" },
    { value: "smooth", label: "smooth" },
    { value: "sharp", label: "sharp" },
    { value: "yellow", label: "yellow" },
    { value: "big", label: "big" },
    { value: "premium", label: "premium" },
  ];
  //   select component functionality
  const handleAttributesChange = (selectedAttributes) => {
    setSelectedAttributes(selectedAttributes);
  };
  const handleCategoriesChange = (selectedCategories) => {
    setSelectedCategories(selectedCategories);
  };
  const selectStyles = {
    control: (provided, state) => {
      const height = selectedAttributes?.length > 2 ? "100px" : "54px";
      return {
        ...provided,
        height: height,
        whiteSpace: "nowrap",
        overflow: "auto",
        borderRadius: "20px",
        border: state.isSelected
          ? "2px solid rgba(114, 139, 166, 0.5)"
          : "2px solid transparent",
        fontFamily: "Jost",
        fontWeight: "400",
        color: "#3853a3",
        background: "rgba(114, 139, 166, 0.2)",
        padding: "0 0 0 10px",
        "&:hover": {
          borderColor: "rgba(114, 139, 166, 0.5)",
        },
      };
    },
    placeholder: (provider) => ({
      ...provider,
      color: "#3853a3",
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "5px",
      background: "#fff",
    }),
  };
  const stockHandler = () => {
    setStock(!stock);
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="create-new-product">
        <form>
          <div className="input-box">
            <div className="left-side">
              <div className="input-wrap">
                <input
                  style={
                    nameFocused
                      ? { background: "rgba(114, 139, 166, .6)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onClick={() => setNameFocused(true)}
                  onChange={(e) =>
                    setFormText({ ...formText, name: e.target.value })
                  }
                  value={formText.nameFocused}
                  placeholder={nameFocused ? "" : singleProduct?.name}
                />
                <img
                  style={nameFocused ? { opacity: "1" } : { opacity: "0" }}
                  src={BlueCross}
                  onClick={nameCrossHandler}
                  alt=""
                />
                <div style={{ top: "-33px" }} className="placeholder-box">
                  <div className="placeholder" style={{ fontSize: "11px" }}>
                    {t("ProductName")}
                  </div>
                </div>
              </div>
              {/* description */}
              <div className="input-wrap" style={{ height: "130px" }}>
                <textarea
                  className="create-shop-textarea"
                  style={
                    descriptionFocused
                      ? { background: "rgba(114, 139, 166, .6)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onClick={() => setDescriptionFocused(true)}
                  onChange={(e) =>
                    setFormText({ ...formText, description: e.target.value })
                  }
                  value={formText.description}
                  placeholder={
                    descriptionFocused ? "" : singleProduct?.description
                  }
                />
                <img
                  style={
                    descriptionFocused ? { opacity: "1" } : { opacity: "0" }
                  }
                  src={BlueCross}
                  onClick={DescriptionCrossHandler}
                  alt=""
                />
                <div style={{ top: "-70px" }} className="placeholder-box">
                  <div className="placeholder" style={{ fontSize: "11px" }}>
                    {t("Description")}
                  </div>
                </div>
              </div>
              {/* price */}
              <div className="input-wrap">
                <input
                  style={
                    priceFocused
                      ? { background: "rgba(114, 139, 166, .6)" }
                      : { background: "rgba(114, 139, 166, 0.2)" }
                  }
                  onClick={() => setPriceFocused(true)}
                  onChange={(e) =>
                    setFormText({ ...formText, price: e.target.value })
                  }
                  value={formText.price}
                  placeholder={priceFocused ? "" : `${singleProduct?.price}`}
                />
                <img
                  style={priceFocused ? { opacity: "1" } : { opacity: "0" }}
                  src={BlueCross}
                  onClick={PriceCrossHandler}
                  alt=""
                />
                <div style={{ top: "-33px" }} className="placeholder-box">
                  <div className="placeholder" style={{ fontSize: "11px" }}>
                    {t("Price")} (€)
                  </div>
                </div>
              </div>
            </div>
            {/* category */}
            <div className="right-side">
              <Select
                styles={selectStyles}
                name="colors"
                options={categoriesArr}
                className="basic-single react-select-styling"
                classNamePrefix="select"
                placeholder={t("SelectCategories")}
                onChange={handleCategoriesChange}
                isClearable
                value={selectedCategories}
              />
              <Select
                styles={selectStyles}
                isMulti
                name="colors"
                options={attributesArr}
                className="basic-multi-select react-select-styling"
                placeholder={t("SelectAttributes")}
                onChange={handleAttributesChange}
                value={selectedAttributes}
              />
              <div
                className="out-of-stock"
                style={
                  selectedAttributes?.length > 2 ? { paddingTop: "50px" } : {}
                }
              >
                <h3 className="out-of-stock-header">{t("OutOfStock")}:</h3>
                <div
                  className="stock-box"
                  style={stock ? { background: "#249924" } : {}}
                  onClick={stockHandler}
                >
                  <img className="stock-tick" src={OutOfStockTick} alt="" />
                </div>
              </div>
              <BlueTextBold className="upload-header">
                {t("UploadImagesOfYourProduct(max.5)")}
              </BlueTextBold>
              <div className="create-new-product-upload-image">
                {/* image 1 */}
                {singleProduct.images.map((item) => {
                  return (
                    <div className="create-new-product-image-box">
                      <img src={item.image_url} alt="" />
                      <img
                        className="upload-cross"
                        src={PasswordCross}
                        alt=""
                      />
                      <div className="text">
                        <label className="custom-file-upload">
                          <input type="file" />
                          {t("ChangeImage")}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="button-box">
            <ButtonBlue type="submit" onClick={formSubmit}>
              {t("Save")}
            </ButtonBlue>
            <ButtonBlue onClick={cancelHandler}>{t("Close")}</ButtonBlue>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditProduct;
