import React, { useRef, useState, useEffect } from "react";
import {
  AvatarBlank,
  CustomerArrowUp,
  CustomerRealArrow,
  PaginationArrow,
  DashboardTrash,
  BlueCross,
  GrayArrow,
  PaginationArrowLeft,
  GrayArrowRight,
} from "../../../assets/landingPage/landingPageAssets";
import {
  BlueTextBold,
  BlueTextMedium,
  GrayTextMedium,
  GrayTextMediumBigger,
} from "../../../styles/GlobalStyles";
import ReactPaginate from "react-paginate";
import {
  AllCustomersBox,
  Avatar,
  AvatarImg,
  AvatarText,
  BlueTextSmaller,
  CreateCustomer,
  CreateCustomerGray,
  CustomerArrow,
  CustomerBoxHeader,
  CustomerContainer,
  CustomerCross,
  CustomerFiltersBox,
  CustomerHeader,
  CustomerInput,
  CustomerInputWrap,
  CustomersBoxFlex,
  CustomerSection,
  PaginationArrows,
  PaginationBox,
} from "../styles";
import "../stylesAllPages.scss";
import { useGlobalContext } from "../../../context/context";
import CreateNewAttribute from "./CreateNewAttribute";
import SingleAttribute from "./SingleAttribute";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading";
import axios from "axios";
import { useTranslation } from "react-i18next";

const AttributesSection = ({ setSections, modalOpen, open }) => {
  const [ascending, setAscending] = useState({
    name: false,
    products: false,
  });
  const {
    setModalRemoveId,
    setRemoveString,
    setClosed,
    getUserDetails,
    attributeMessage,
    logUserOut,
    userDetails,
    closed,
    baseUrl,
    config,
    yourShop,
    getDemoShopShop,
  } = useGlobalContext();
  const [customerFocus, setCustomerFocus] = useState(false);
  const [createNewAttribute, setCreateNewAttribute] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [attributeFilter, setAttributeFilter] = useState("");
  const [attribute, setAttribute] = useState("");
  const [loadAttribute, setLoadAttribute] = useState(true);
  const customerRef = useRef(null);
  const customerCrossHandler = () => {
    setCustomerFocus(false);
    setAttributeFilter("");
    customerRef.current.value = "";
  };
  const navigate = useNavigate();

  const settingsHandler = () => {
    setSections({
      homeSection: false,
      ordersSection: false,
      customerSection: false,
      shopSection: false,
      categoriesSection: false,
      productsSection: false,
      attributesSection: false,
      settingsSection: true,
    });
  };
  const getAllAttributes = async () => {
    try {
      const response = axios
        .get(`${baseUrl}/attribute`, config)
        .then((response) => {
          if (response.status === 200) {
            setAttribute(response.data);
            setLoadAttribute(false);
          }
        });
    } catch (error) {
      setLoadAttribute(false);
    }
    setCurrentPage(0);
  };
  useEffect(() => {
    if (attributeFilter) {
      setCurrentPage(0);
    }
    setClosed(false);
    getUserDetails();
    getAllAttributes();
    getDemoShopShop();
  }, [
    attributeMessage,
    createNewAttribute,
    closed,
    attributeFilter,
    loadAttribute,
    attribute.length
  ]);

  const removeHandler = (string, id) => {
    open();
    setModalRemoveId(id);
    setRemoveString(string);
  };
  const attributeListMaker = () => {
    let list = [];
    attribute?.data?.filter((item) => {
      if (item.name.toLowerCase().includes(attributeFilter.toLowerCase())) {
        list.push(item);
      }
      return list.sort((a,b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0);
    });
    return list;
  };
  let itemsPerPage = 7;
  let pageCount = Math.ceil(attributeListMaker().length / itemsPerPage);
  let itemsVisited = currentPage * itemsPerPage;
  const pageChange = ({ selected }) => {
    setCurrentPage(selected);
  };


  const ascendingHandler = (asc) => {
    let data = attribute?.data;
    if (asc === "name") {
      setAscending({ ...ascending, name: !ascending.name });
      if (ascending.name) {
        data.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        data.sort((a, b) => b.name.localeCompare(a.name));
      }
    } else if (asc === "products") {
      setAscending({ ...ascending, products: !ascending.products });
      if (ascending.products) {
        data.sort((a, b) => a.number_of_occurrence - b.number_of_occurrence);
      } else {
        data.sort((a, b) => b.number_of_occurrence - a.number_of_occurrence);
      }
    }
  };

  const LogOutHandler = () => {
    logUserOut();
    navigate("/");
  };

  const nextPage = () => {
    if (currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <CustomerSection>
        <Avatar id="avatar" className="avatar">
          <AvatarText className="avatar-text">
            <BlueTextBold style={{ fontSize: "18px" }}>
              {userDetails?.email}
            </BlueTextBold>
            <div className="flex">
              <GrayTextMedium className="settings" onClick={settingsHandler}>
                {t("Profilesettings")}
              </GrayTextMedium>
              <GrayTextMedium className="log-out" onClick={LogOutHandler}>
                {t("LogOut")}
              </GrayTextMedium>
            </div>
          </AvatarText>
          {yourShop?.image && <AvatarImg src={yourShop?.image} alt="" />}{" "}
        </Avatar>
        <CustomerContainer id="container">
          <CustomerHeader>
            <BlueTextSmaller>{t("Attributes")}</BlueTextSmaller>
            {createNewAttribute ? (
              <></>
            ) : (
              <>
                <CreateCustomer onClick={() => setCreateNewAttribute(true)}>
                  {t("CreateNew")}{" "}
                  <div
                    style={{
                      fontFamily: "Jost",
                      fontSize: "20px",
                      display: "inline-block",
                      transform: "translate(2px, 1px)",
                    }}
                  >
                    +
                  </div>
                </CreateCustomer>
              </>
            )}
          </CustomerHeader>

          <AllCustomersBox>
            <CustomersBoxFlex id="customersBox">
              {createNewAttribute ? (
                <>
                  <CustomerBoxHeader>
                    {t("CreateNewAttribute")}
                  </CustomerBoxHeader>
                  <CreateNewAttribute
                    setCreateNewAttribute={setCreateNewAttribute}
                    attribute={attribute}
                  />
                </>
              ) : (
                <>
                  <CustomerBoxHeader>{t("AllAtributes")}</CustomerBoxHeader>
                  {attribute?.data?.length > 0 && (
                    <CustomerFiltersBox>
                      <CustomerInputWrap customerFocus={customerFocus}>
                        <CustomerCross
                          onClick={customerCrossHandler}
                          customerFocus={customerFocus}
                          src={BlueCross}
                          alt=""
                        />
                        <CustomerInput
                          ref={customerRef}
                          placeholder={t("SearchAttributes")}
                          onClick={() => setCustomerFocus(true)}
                          onChange={(e) => setAttributeFilter(e.target.value)}
                          customerFocus={customerFocus}
                        />
                      </CustomerInputWrap>
                    </CustomerFiltersBox>
                  )}
                  {attribute?.data?.length > 0 && (
                    <table id="table" className="table">
                      {/* headers */}
                      <tbody>
                        <tr className="header">
                          <td width="60%">
                            <div className="td-box-border-left">
                              <h4
                                className="gray-text"
                                style={{ marginRight: "10px" }}
                              >
                                {t("AttributeName")}
                              </h4>
                              <div
                                className="arrowBox"
                                onClick={() => ascendingHandler("name")}
                              >
                                <CustomerArrow src={CustomerArrowUp} alt="" />
                                <img
                                  src={CustomerRealArrow}
                                  alt=""
                                  className={
                                    ascending.name ? "arrow-drop" : "arrow"
                                  }
                                />
                              </div>
                            </div>
                          </td>
                          <th>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <h4
                                className="gray-text"
                                style={{ marginRight: "10px" }}
                              >
                                {t("NumberOfProducts")}
                              </h4>
                              <div
                                className="arrowBox"
                                onClick={() => ascendingHandler("products")}
                              >
                                <CustomerArrow src={CustomerArrowUp} alt="" />
                                <img
                                  src={CustomerRealArrow}
                                  alt=""
                                  className={
                                    ascending.products ? "arrow-drop" : "arrow"
                                  }
                                />
                              </div>
                            </div>
                          </th>
                          <th></th>
                        </tr>
                        {attributeListMaker()
                          .slice(itemsVisited, itemsVisited + itemsPerPage)
                          .map((item, index) => {
                            return (
                              <SingleAttribute
                                key={index}
                                item={item}
                                removeHandler={removeHandler}
                              />
                            );
                          })}
                      </tbody>

                      {/* table */}
                    </table>
                  )}
                  {loadAttribute ? (
                    <div style={{ padding: "20px 0 0 30px" }}>
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {!attribute?.data?.length && (
                        <>
                          <div className="table-box-empty">
                            <GrayTextMediumBigger>
                              {t("NoAttributes")}
                            </GrayTextMediumBigger>
                            <CreateCustomer
                              onClick={() => setCreateNewAttribute(true)}
                            >
                              {t("CreateFirstAttribute")}
                            </CreateCustomer>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  <PaginationBox style={{ margin: "auto" }}>
                    <div className="pagination-elements">
                      <div className="pagination-arrow">
                        <img
                          alt=""
                          src={
                            currentPage === 0 ? GrayArrow : PaginationArrowLeft
                          }
                          onClick={previousPage}
                        />
                      </div>
                      <BlueTextMedium className="current-page">
                      {pageCount == 0 ? `${currentPage+1} / ${pageCount+1}` : `${currentPage+1} / ${pageCount}`}
                      </BlueTextMedium>
                      <div className="pagination-arrow">
                        <img
                          alt=""
                          src={
                            attributeListMaker().length - itemsVisited <= 7
                              ? GrayArrowRight
                              : PaginationArrow
                          }
                          onClick={nextPage}
                        />
                      </div>
                    </div>
                  </PaginationBox>
                </>
              )}
            </CustomersBoxFlex>
          </AllCustomersBox>
        </CustomerContainer>
      </CustomerSection>
    </>
  );
};

export default AttributesSection;
