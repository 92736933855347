import React, { useEffect, useState } from "react";
import Footer from "../../components/LandingPage/Footer/Footer";
import {
  ArrowLeft,
  ArrowRight,
  BlueTextBold,
  BlueTextMedium,
  Cross,
  YellowTextBold,
} from "../../styles/GlobalStyles";
import "./shopStyles.scss";
import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { useParams } from "react-router";
import SingleShopProduct from "./SingleShopProduct";
import { BlueTextSmaller } from "../../components/DemoShop copy/styles";
import {
  ArrowYellowLeft,
  ArrowYellowRight,
  CrossBtn,
  DropdownArrow,
  Search,
} from "../../assets/landingPage/landingPageAssets";
import { LoadingBigger } from "../../components/LoadingBigger";
import { useTranslation } from "react-i18next";
import {
  InputWrap,
  OurShops,
  SearchBarShop,
  SearchBox,
  SearchButton,
  SearchInput,
  ShopsArrow,
} from "../../components/LandingPage/Home/styles";

const Shop = () => {
  const [shopInfo, setShopInfo] = useState();
  const [allProducts, setAllProducts] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [openShops, setOpenShops] = useState(false);
  const [searchType, setSearchType] = useState(true);
  const [productQuery, setProductQuery] = useState("");
  const [loadAllProducts, setLoadAllProducts] = useState(true);
  const [focused, setFocused] = useState(false);
  const { dispatch, baseUrl, shopId, setShopId, config } = useGlobalContext();
  const { id } = useParams();
  const openShopsHandler = () => {
    setOpenShops(!openShops);
  };
  const crossHandler = () => {
    setFocused(false);
  };
  useEffect(() => {
    const getSingleShop = () => {
      try {
        const response = axios.get(`${baseUrl}/shop/${id}`).then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setShopInfo(response.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    const getSingleShopProducts = () => {
      try {
        const response = axios
          .get(`${baseUrl}/shop/products/${id}`)
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data);
              setAllProducts(response.data);
              setLoadAllProducts(false);
            }
          });
      } catch (error) {
        setLoadAllProducts(false);
        console.log(error);
      }
    };
    getSingleShop();
    getSingleShopProducts();
  }, []);

  useEffect(() => {
    if (productQuery) {
      setCurrentPage(0);
    }
  }, [productQuery]);

  let itemsPerPage = 6;
  let pageCount = Math.ceil(allProducts?.length / itemsPerPage);
  let itemsVisited = currentPage * itemsPerPage;
  const pageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const { t } = useTranslation();
  const searchHandler = (type) => {
    if (type === "cat") {
      setSearchType(true);
    } else {
      setSearchType(false);
    }
    setOpenShops(false);
  };
  const shopListMaker = () => {
    let list = [];
    allProducts.filter((item) => {
      if (!searchType) {
        if (item.name.toLowerCase().includes(productQuery.toLowerCase())) {
          list.push(item);
        }
        return list;
      } else {
        if (
          item.category.name.toLowerCase().includes(productQuery.toLowerCase())
        ) {
          list.push(item);
        }
        return list;
      }
    });
    return list;
  };

  console.log(allProducts?.length);
  console.log(searchType);

  const nextPage = () => {
    if (currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <>
      {/* with data */}
      <div className="shop-section">
        <div className="yellow" id="yellow">
          <div className="header-image">
            <img alt="" src={shopInfo?.image} />
          </div>
        </div>
        <div className="header-text">
          <div className="text-content">
            <BlueTextBold className="header">{shopInfo?.name}</BlueTextBold>
            <p className="description">{shopInfo?.description}</p>
          </div>
        </div>
        <div className="shop-search">
          <div
            className="shop-dropdown"
            style={openShops ? { display: "block" } : { display: "none" }}
          >
            <ul>
              <li onClick={() => searchHandler("cat")}>
                <span>{t("CategoryProducts")}</span>
              </li>
              <li onClick={searchHandler}>
                <span>{t("ProductsCategory")}</span>
              </li>
            </ul>
          </div>
          {allProducts?.length > 0 && (
            <>
              <SearchBarShop id="3">
                <div style={{ display: "flex" }}>
                  <SearchButton src={Search} alt="Search" />
                  <InputWrap id="3">
                    <SearchInput
                      id="search-Input"
                      placeholder={t("Search")}
                      onChange={(e) => setProductQuery(e.target.value)}
                    />
                    <Cross src={CrossBtn} alt="" onClick={crossHandler} />
                  </InputWrap>
                </div>
                <SearchBox id="search-box" onClick={openShopsHandler}>
                  <OurShops>
                    {searchType ? (
                      <>{t("CategoryProducts")}</>
                    ) : (
                      <>{t("ProductsCategory")}</>
                    )}
                  </OurShops>
                  <ShopsArrow src={DropdownArrow} alt="" />
                </SearchBox>
              </SearchBarShop>
            </>
          )}
        </div>
        <div
          className="shop-items-container"
          style={
            allProducts?.length === 0 || allProducts?.length === 1
              ? { justifyContent: "center" }
              : {}
          }
        >
          {loadAllProducts ? (
            <>
              <LoadingBigger />
            </>
          ) : (
            <>
              {allProducts?.length > 0 ? (
                <>
                  {shopListMaker()?.length > 0 ? (
                    <>
                      {shopListMaker()
                        ?.slice(itemsVisited, itemsVisited + itemsPerPage)
                        .map((item, index) => {
                          return (
                            <>
                              <SingleShopProduct key={index} item={item} />
                            </>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      <div style={{ margin: "auto" }}>
                        <div className="empty-shop-box">
                          <BlueTextSmaller>{t("NoResults")}</BlueTextSmaller>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div>
                    <div className="empty-shop-box">
                      <BlueTextSmaller>{t("NoProductsYet")}</BlueTextSmaller>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {allProducts?.length > 0 ? (
        <>
          <div className="shop-pagination">
            <div className="shop-pagination-elements">
              <div className="pagination-arrow">
                <img
                  alt=""
                  src={ArrowYellowLeft}
                  style={
                    currentPage === 0
                      ? { opacity: ".2", transition: ".7 all ease" }
                      : { opacity: "1", transition: ".7 all ease" }
                  }
                  onClick={previousPage}
                />
              </div>
              <YellowTextBold className="current-page">
              {pageCount == 0 ? `${currentPage+1} / ${pageCount+1}` : `${currentPage+1} / ${pageCount}`}
              </YellowTextBold>
              <div className="pagination-arrow">
                <img
                  alt=""
                  src={ArrowYellowRight}
                  style={
                    shopListMaker()?.length - itemsVisited <= 6
                      ? { opacity: ".2", transition: ".7 all ease" }
                      : { opacity: "1", transition: ".7 all ease" }
                  }
                  onClick={nextPage}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <Footer />
    </>
  );
};

export default Shop;
