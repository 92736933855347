import React from "react";
import MojBizNews from "../../components/News/MojBizNews";
import { Footer } from "../../imports/landingPageContent";
import RobotCodeNews from "../../components/News/RobotCodeNews";


const RobotCodeNewsPage = () => {
  
  return (
    <>
      <RobotCodeNews />
      <Footer />
    </>
  );
};

export default RobotCodeNewsPage;
