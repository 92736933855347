import styled from "styled-components";
import {
  ButtonYellow,
  FlexColumn,
  WhiteTextBold,
  WhiteTextMedium,
  YellowTextBold,
} from "../../../styles/GlobalStyles";

const NewsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;
const NewsHeader = styled.h1`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 180px;
  text-align: center;
  color: #3853a3;

  @media only screen and (max-width: 768px) {
    font-size: 3rem;
  }
`;
const SingleNews = styled.div`
  height: 460px;
  background: #7e94ab;
  border-radius: 24px;
  margin: 0 23px;
  width: 456px;
  transition: 0.3s all ease;
  -webkit-box-shadow: 3px 3px 42px -3px rgba(201,197,201,1);
-moz-box-shadow: 3px 3px 42px -3px rgba(201,197,201,1);
box-shadow: 3px 3px 42px -3px rgba(201,197,201,1);
  &:hover {
    transition: 0.3s all ease;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  @media only screen and (max-width: 555px) {
    margin: 2rem auto;
    width: 80%;
    height: 100%;
    padding-bottom: 10px;
  }

  @media only screen and (min-width: 556px) and (max-width: 1024px) {
    margin: 2rem auto;
  }

  @media only screen and (min-width: 1025px) {
    margin: 2rem auto;
    width: 90%;
    height: 100%;
  }
`;

const NewsImage = styled.img`
  width: 100%;
  height: 201px;
  border-radius: 24px 24px 0 0;
  object-fit: cover;

  @media only screen and (max-width: 768px) {
    object-fit: fill;
  }
`;
const NewsDate = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #d8d6db;
  text-transform: capitalize;
  padding-bottom: 10px;

  @media only screen and (max-width: 768px) {
    padding-bottom: 0;
  }
`;
const NewsInfo = styled(FlexColumn)`
  align-items: start;
  padding: 13px 30px 0 38px;

  @media only screen and (max-width: 768px) {
    padding: 5px 15px 10px 15px;
  }
`;
const NewsCategoryName = styled(YellowTextBold)`
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  padding-bottom: 10px;
`;
const NewsTitle = styled(WhiteTextBold)`
  font-size: 18px;
  line-height: 32px;
  padding-bottom: 10px;
`;
const NewsDesc = styled(WhiteTextMedium)`
  font-size: 14px;
  line-height: 20px;
`;
const NewsButton = styled(ButtonYellow)`
  padding: 19px 72px;
  font-size: 25px;
  line-height: 36px;
  font-weight: 400;
  border: 2px solid transparent;
  margin-bottom: 70px;
  &:hover {
    background: transparent;
    color: #ffa800;
    border: 2px solid #ffa800;
  }

  @media only screen and (max-width: 768px) {
    padding: 5px 20px;
    font-size: 1rem;
  }
  @media only screen and (min-width: 769px) and (max-width: 1200px) {
    padding: 10px 30px;
    font-size: 1.2rem;
  }
`;
const NewsBox = styled.div`
  display: flex;
  margin-bottom: 100px;
  padding: 0 50px;
  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    padding: 0;
  }
`;

export {
  NewsBox,
  NewsSection,
  SingleNews,
  NewsButton,
  NewsImage,
  NewsDate,
  NewsInfo,
  NewsCategoryName,
  NewsTitle,
  NewsDesc,
  NewsHeader,
};
