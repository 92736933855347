import React from "react";
import "../styles/app.css";

const LoadingBigger = () => {
  return <div className="loading-empty"></div>;
};

const LittleLoading = () => {
  return <div className="loading-empty-little"></div>;
};

export { LoadingBigger, LittleLoading };
