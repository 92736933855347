import React from "react";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import Footer from "../../components/LandingPage/Footer/Footer";
import "./styles.scss";
import { BlueTextBold, ButtonYellow } from "../../styles/GlobalStyles";
import { useTranslation } from "react-i18next";

const RedirectPage = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <Navbar /> */}
      <section className="redirect-section">
        <div className="container">
          <div className="header">
            <BlueTextBold>
              {t("RedirectMessage")}
            </BlueTextBold>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default RedirectPage;
