import React, { useRef, useEffect, useState } from "react";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import Footer from "../../components/LandingPage/Footer/Footer";
import {
  BlueTextBold,
  ButtonBlue,
  ButtonBluetoYellow,
  GrayTextMedium,
  YellowTextBold,
} from "../../styles/GlobalStyles";
import {
  AddToCart,
  RemoveNoCircle,
  AddToCartWhite,
  LoadingGif,
} from "../../assets/landingPage/landingPageAssets";
import "./styles.scss";
import { ProductImageBox, ProductImageBoxSmaller } from "./styles";
import axios from "axios";
import { useGlobalContext } from "../../context/context";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductPage = () => {
  const [image, setImage] = useState();
  const [productContent, setProductContent] = useState();
  const [addedToCart, setAddedToCart] = useState();
  const [renderWidth, setRenderWidth] = useState(null);
  const [renderHeight, setRenderHeight] = useState(null);
  const [imageShape, setImageShape] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [screenW, setScreeenW] = useState(window.screen.width);
  const { baseUrl, cart, handleAddProduct, handleRemoveProduct } =
    useGlobalContext();
  const { id } = useParams();
  const imgRef = useRef(null);

  function handleImageLoad() {
    const image = imgRef.current;
    setRenderWidth(image.naturalWidth);
    setRenderHeight(image.naturalHeight);
    if (image.naturalWidth > image.naturalHeight) {
      setImageShape(true);
    } else {
      setImageShape(false);
    }
  }
  useEffect(() => {
    console.log(imageShape);
    const product = async () => {
      try {
        const response = axios
          .get(`${baseUrl}/product/${id}`)
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data);
              setProductContent(response.data);
              setImage(response.data.images[0].image_url);
            }
          });
      } catch (error) {
        console.log(error);
      }
    };
    product();
    setHasLoaded(true);
    setScreeenW(window.screen.width);
  }, [screenW]);

  const cartHandler = () => {
    handleAddProduct(productContent);
    setAddedToCart(true);
  };

  const cartRemoveHandler = () => {
    handleRemoveProduct(productContent);
    setAddedToCart(false);
  };

  const isItAdded = cart.find((cartItem) => {
    return cartItem.id === productContent?.id;
  });

  const { t } = useTranslation();
  
  return (
    <>
      {/* <Navbar /> */}
      <div className="product-section">
        <div className="gray-matter">
          <div className="text">
            <GrayTextMedium>MBGC Shops {">"}</GrayTextMedium>
            <GrayTextMedium className="shop-name-destination">
              &nbsp;{productContent?.shop?.name}
            </GrayTextMedium>
            <Link to={`/shop/${localStorage.getItem("shopId")}`}>
              <ButtonBlue className="back-to-shop">
                {t("BackToShop")}
              </ButtonBlue>
            </Link>
          </div>
        </div>
        <div className="shop-items-container">
          <div className="single-item">
            <div className="single-shop-item-content">
              <div className="pic-and-image-box">
                {productContent ? (
                  <>
                    <div className="pictures">
                      {productContent?.images.map((item) => {
                        return (
                          <div className="picture-box">
                            <img
                              src={item.image_url}
                              onClick={(e) => setImage(e.target.src)}
                              alt=""
                            />
                          </div>
                        );
                      })}
                    </div>
                    {screenW > 450 ? (
                      <ProductImageBox
                        imageShape={imageShape}
                        className="image-box"
                      >
                        <img
                          onLoad={handleImageLoad}
                          ref={imgRef}
                          src={image}
                          alt=""
                        />
                      </ProductImageBox>
                    ) : (
                      <ProductImageBoxSmaller
                        id="smaller"
                        imageShape={imageShape}
                        className="image-box-smaller"
                        style={
                          imageShape
                            ? { width: "300px", height: "230px" }
                            : { width: "230px", height: "300px" }
                        }
                      >
                        <img
                          onLoad={handleImageLoad}
                          ref={imgRef}
                          src={image}
                          alt=""
                        />
                      </ProductImageBoxSmaller>
                    )}
                  </>
                ) : (
                  <>
                    <div className="loading-spinner-picture"></div>
                  </>
                )}
              </div>

              <div className="text">
                <BlueTextBold className="single-item-header">
                  {productContent?.name}
                </BlueTextBold>
                <GrayTextMedium className="single-item-desc">
                  {productContent?.description}
                </GrayTextMedium>
                <YellowTextBold className="price">
                  {productContent?.price === 0.01 ? `${t("PriceRequest")}` : `${productContent?.price.toLocaleString()}€`}
                </YellowTextBold>
                {isItAdded && !productContent?.out_of_stock ? (
                  <button className="added-btn" onClick={cartRemoveHandler}>
                    <img src={RemoveNoCircle} alt="" />
                    <YellowTextBold className="added-btn-text">
                      {t("RemoveFromCart")}
                    </YellowTextBold>
                  </button>
                ) : !productContent?.out_of_stock ? (
                  <button className="add-btn" onClick={cartHandler}>
                    <img src={AddToCart} alt="" />
                    <img
                      src={AddToCartWhite}
                      alt=""
                      className="add-to-cart-white"
                    />
                    <YellowTextBold className="add-btn-text">
                      {t("AddToCart")}
                    </YellowTextBold>
                  </button>
                ) : (
                  <button className="add-btn outofstock" onClick={cartHandler} disabled>
                    <img src={AddToCart} alt="" />
                    <img
                      src={AddToCartWhite}
                      alt=""
                      className="add-to-cart-white"
                    />
                    <YellowTextBold className="add-btn-text">
                      Out of Stock
                    </YellowTextBold>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductPage;
