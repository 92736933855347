import styled from "styled-components";

const ProductImageBox = styled.div`
  width: ${(props) => (props.imageShape ? "600px" : "450px")};
  height: ${(props) => (props.imageShape ? "450px" : "600px")};
`;

const ProductImageBoxSmaller = styled.div`
  width: ${(props) => (props.imageShape ? "300px" : "200px")};
  height: ${(props) => (props.imageShape ? "200px" : "300px")};
`;

export { ProductImageBox, ProductImageBoxSmaller };
