import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BlueCross } from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import { ButtonBlue, GrayTextMedium } from "../../../styles/GlobalStyles";
import "../stylesAllPages.scss";

const CreateNewAttribute = ({ setCreateNewAttribute, attribute }) => {
  const [attributeFocused, setAttributeFocused] = useState(false);
  const [attributeMessage, setAttributeMessage] = useState(false);
  const [attResponse, setAttResponse] = useState("")
  const [clicked, setClicked] = useState(false);
  const { newAttribute, setClosed, setNewAttribute, createAttribute } =
    useGlobalContext();
  const attributeCrossHandler = () => {
    setAttributeFocused(false);
    setNewAttribute({ name: "" });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const attArray = attribute?.data?.map(e => e.name.toLowerCase());
    const newAtt = newAttribute.name.toLowerCase();
    console.log(attArray, newAtt)
    
    if(attArray.includes(newAtt)) {
      setAttResponse("Attribute with that name already exist!");
      setAttributeMessage(true);
    }

    if (newAttribute.name.length && !attArray.includes(newAtt)) {
      createAttribute();
      setAttResponse(`${t("NewAttributeCreated")}`)
      setAttributeMessage(true);
      setAttributeFocused(false);
      setClosed(true);
      setTimeout(() => {
        setAttributeMessage(false);
        setClicked(false);
        setCreateNewAttribute(false);
      }, 2000);
    }
    setNewAttribute({ name: "" });
    setClicked(true);
    
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="content">
        <form onSubmit={submitHandler} className="attribute-form">
          <div className="input-shop" style={{ marginBottom: "0" }}>
            <input
              maxLength="50"
              style={
                attributeFocused
                  ? { background: "rgba(114, 139, 166, .4)" }
                  : { background: "rgba(114, 139, 166, 0.2)" }
              }
              onClick={() => setAttributeFocused(true)}
              onChange={(e) => setNewAttribute({ name: e.target.value })}
              value={newAttribute.name}
            />
            <img
              style={attributeFocused ? { opacity: "1" } : { opacity: "0" }}
              src={BlueCross}
              onClick={attributeCrossHandler}
              alt=""
            />
            <div
              style={attributeFocused ? { top: "-33px" } : { top: "3px" }}
              className="placeholder-box"
            >
              <div
                className="placeholder"
                style={
                  attributeFocused ? { fontSize: "11px" } : { fontSize: "16px" }
                }
              >
                {t("AttributeName")}
              </div>
            </div>
          </div>
          <div className="new-attribute-created">
            {attributeMessage && clicked ? (
              <>
                <GrayTextMedium>{attResponse}</GrayTextMedium>
              </>
            ) : clicked ? (
              <GrayTextMedium>{t("TypeInAttribute")}</GrayTextMedium>
            ) : (
              <></>
            )}
          </div>
          <div className="attribute-button-box">
            <ButtonBlue type="submit">{t("Save")}</ButtonBlue>
            <ButtonBlue onClick={() => setCreateNewAttribute(false)}>
              {t("Close")}
            </ButtonBlue>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateNewAttribute;
