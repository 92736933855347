import React from "react";
import { useTranslation } from "react-i18next";
import { FishingMan } from "../../assets/landingPage/landingPageAssets";
import { Footer, Navbar } from "../../imports/landingPageContent";
import {
  BlueTextBold,
  GrayTextMedium,
  WhiteTextBold,
  YellowTextBold,
} from "../../styles/GlobalStyles";
import "./styles.scss";
import { Link } from "react-router-dom";
import MerProgram from "../../assets/news/MER-Program.png";
import Robotcode from "../../assets/news/Robotcode.png";
import Kotor from "../../assets/news/Kotor.png";

const MoreNewsPage = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <Navbar /> */}
      <div className="more-news-page-section">
        <BlueTextBold className="header">{t("AllNews")}</BlueTextBold>
        <div className="content">
          <Link
            to="/mojbiznews"
            className="news-box"
            style={{ textDecoration: "none" }}
          >
            <img src={MerProgram} alt="" style={{ objectFit: "fill" }} />
            <div className="news-box-text">
              <div className="text-content">
                <h3 className="date">03. avgust 2023.</h3>
                <h3 className="category">Projekti</h3>
                <WhiteTextBold className="text-header">
                  Mojbiz.me podržan od strane Ministarstva ekonomskog razvoja
                  Crne Gore
                </WhiteTextBold>
                <p>
                  Preduzeće Asenvirocon d.o.o. Kotor je danas potpisalo Ugovor o
                  sufinansiranju za realizaciju digitalne platforme mojbiz.me sa
                  Ministarstvom ekonomskog razvoja Crne Gore. Sredstva za
                  sufinansiranje su obezbjeđena iz Programske linije za podršku
                  digitalizaciji u okviru Programa za unapređenje konkurentnosti
                  privrede za 2023.godinu. Obezbjeđena sredstva predstavljaju
                  značajan doprinos za ostvarenje poslovnog plana Asenvirocon da
                  postane jedan od lidera u oblasti digitalne podrške poslovanju
                  mikro, malih i srednjih preduzeća u Crnog Gori sa fokusom na
                  preduzeća u sektoru plavog rasta...
                </p>
              </div>
            </div>
          </Link>
          <Link
            to="/robot_code_news"
            className="news-box"
            style={{ textDecoration: "none" }}
          >
            <img src={Robotcode} alt="" style={{ objectFit: "fill" }} />
            <div className="news-box-text">
              <div className="text-content">
                <h3 className="date">4. avgust 2023.</h3>
                <h3 className="category">Projekti</h3>
                <WhiteTextBold className="text-header">
                Robot Code će razvijati platformu mojbiz.me
                </WhiteTextBold>
                <p>
                Pereduzeće Robot code d.o.o. iz Podgorice je danas potpisalo
                  Ugovor o pružanju usluga iz oblasti informatike sa preduzećem
                  Asenvirocon d.o.o. Kotor. Predmet ovog Ugovora su usluge
                  razvoja platforme za on-line kupovinu koja je osmišljena kao
                  digitalni trgovački centar. Preduzeće Asenvirocon d.o.o. Kotor
                  je sredstva za realizaciju Ugovora...
                </p>
              </div>
            </div>
          </Link>
          <Link
            to="/miro&sons_news"
            className="news-box"
            style={{ textDecoration: "none" }}
          >
            <img src={Kotor} alt="" />
            <div className="news-box-text">
              <div className="text-content">
                <h3 className="date">4. avgust 2023.</h3>
                <h3 className="category">Prodavnice</h3>
                <WhiteTextBold className="text-header">
                Miro & Sons iz Kotora je prvi korisnik platforme mojbiz.me
                </WhiteTextBold>
                <p>
                Preduzeće Miro & Sons d.o.o. iz Kotora će biti prvi korisnik
                  platforme mojbiz.me. Nakon prihvatanja ponude preduzeća
                  Asenvicon d.o.o. iz Kotora za razvoj platforme za on-line
                  kupovinu preduzeće Miro & Sons će razviti svoju digitalnu
                  prodavnicu – prodajni izlog...
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MoreNewsPage;
