import React, { useState } from "react";
import { useEffect } from "react";
import { ButtonBlue } from "../../../styles/GlobalStyles";
import {
  NameInput,
  InputWrap,
  NamePlaceholderBox,
  NamePlaceholder,
  NameCross,
  Textarea,
  TextareaCross,
  TextareaPlaceholderBox,
  TextareaPlaceholder,
} from "./CategoryInputs";
import { BlueCross } from "../../../assets/landingPage/landingPageAssets";
import { useGlobalContext } from "../../../context/context";
import "../../DemoShop copy/stylesAllPages.scss";
import { useTranslation } from "react-i18next";

const CreateNew = ({ createNew, setCreateNew }) => {
  const [categoryFocused, setCategoryFocused] = useState(false);
  const [descriptionFocused, setDescriptionFocused] = useState(false);
  const { createNewCategory, setCreateNewCategory, newCategoryResponse } =
    useGlobalContext();
  const formSubmit = (e) => {
    e.preventDefault();
  };
  const nameCrossHandler = () => {
    setCategoryFocused(false);
    setCreateNewCategory({ ...createNewCategory, name: "" });
  };
  const TextareaCrossHandler = () => {
    setDescriptionFocused(false);
    setCreateNewCategory({ ...createNewCategory, description: "" });
  };
  const cancelHandler = () => {
    setCreateNew(false);
  };

  useEffect(() => {
    setCreateNewCategory({ name: "", description: "" });
  }, [createNew]);

  console.log(newCategoryResponse);

  const { t } = useTranslation();

  return (
    <>
      <div className="create-new-section">
        <form onSubmit={formSubmit}>
          <InputWrap>
            <NameInput
              maxLength="150"
              onClick={() => setCategoryFocused(true)}
              categoryFocused={categoryFocused}
              onChange={(e) =>
                setCreateNewCategory({
                  ...createNewCategory,
                  name: e.target.value,
                })
              }
              value={createNewCategory.name}
            />
            <NameCross
              categoryFocused={categoryFocused}
              src={BlueCross}
              onClick={nameCrossHandler}
            />
            <NamePlaceholderBox categoryFocused={categoryFocused}>
              <NamePlaceholder categoryFocused={categoryFocused}>
                {t("CategoryName")}
              </NamePlaceholder>
            </NamePlaceholderBox>
          </InputWrap>
          <InputWrap style={{ height: "230px" }}>
            <Textarea
              descriptionFocused={descriptionFocused}
              onFocus={() => setDescriptionFocused(true)}
              onChange={(e) =>
                setCreateNewCategory({
                  ...createNewCategory,
                  description: e.target.value,
                })
              }
              value={createNewCategory.description}
            />
            <TextareaCross
              descriptionFocused={descriptionFocused}
              src={BlueCross}
              onClick={TextareaCrossHandler}
            />
            <TextareaPlaceholderBox descriptionFocused={descriptionFocused}>
              <TextareaPlaceholder descriptionFocused={descriptionFocused}>
                {t("Description")}
              </TextareaPlaceholder>
            </TextareaPlaceholderBox>
          </InputWrap>
          <div className="button-box">
            <ButtonBlue type="submit">{t("Save")}</ButtonBlue>
            <ButtonBlue onClick={cancelHandler} type="button">
              {t("Close")}
            </ButtonBlue>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateNew;
