import React, { useState, useRef } from "react";
import {
  AvatarBlank,
  BlueCross,
  CrossBtn,
} from "../../../assets/landingPage/landingPageAssets";
import {
  BlueTextBold,
  ButtonBlue,
  ButtonYellow,
  GrayTextMedium,
} from "../../../styles/GlobalStyles";
import {
  ProfileSettingsSection,
  AvatarImg,
  Avatar,
  AvatarText,
} from "../styles";
import {
  EmailPlaceholderBox,
  PhoneNumberPlaceholderBOx,
  EmailPlaceholder,
  PhoneNumberPlaceholder,
  EmailCross,
  PhoneNumberCross,
  InputWrap,
  PhoneNumberInput,
  EmailInput,
} from "../SettingsInputs";
import ChangePassword from "./ChangePassword";
import { useEffect } from "react";
import axios from "axios";
import { useGlobalContext } from "../../../context/context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";

const ProfileSettings = () => {
  const [formInfo, setFormInfo] = useState({
    email: "",
    phoneNumber: "",
  });
  const [info, setInfo] = useState();
  const [emailFocused, setEmailFocused] = useState(false);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [changePasswordPage, setChangePasswordPage] = useState(false);
  const {
    baseUrl,
    config,
    logUserOut,
    userDetails,
    getUserDetails,
    getDemoShopShop,
    yourShop,
    patchedShop,
    setPatchedShop,
    postPatchedShop,
    setYourShop,
    setEditShopResponse,
    editShopResponse,
    setReload,
    reload,
    setLoadPatch,
  } = useGlobalContext();
  const navigate = useNavigate();
  // refs
  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const SubmitProfilehandler = (e) => {
    setEditShopResponse("");
    postPatchedShop();

    console.log("submited");

    setPhoneFocused(false);
  };

  const emailCrossHandler = () => {
    setEmailFocused(false);
    emailRef.current.value = "";
  };

  const phoneCrossHandler = () => {
    setPhoneFocused(false);
    phoneNumberRef.current.value = "";
  };

  useEffect(() => {
    getUserDetails();
    getDemoShopShop();
    try {
      const response = axios
        .get(`${baseUrl}/user/profile`, config)
        .then((response) => {
          if (response.status === 200) {
            setInfo(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  const LogOutHandler = () => {
    logUserOut();
    navigate("/");
  };
  const { t } = useTranslation();
  return (
    <>
      <ProfileSettingsSection className="profile-section">
        <Avatar id="avatar" className="avatar">
          <AvatarText className="avatar-text">
            <BlueTextBold style={{ fontSize: "18px" }}>
              {userDetails?.email}
            </BlueTextBold>
            <div className="flex">
              <GrayTextMedium className="settings">
                {t("Profilesettings")}
              </GrayTextMedium>
              <GrayTextMedium className="log-out" onClick={LogOutHandler}>
                {t("LogOut")}
              </GrayTextMedium>
            </div>
          </AvatarText>
          {yourShop?.image && <AvatarImg src={yourShop?.image} alt="" />}{" "}
        </Avatar>
        <div className="user-info">
          {!changePasswordPage ? (
            <>
              <div className="user-info-content">
                <BlueTextBold className="header">
                  {t("UserInformation")}
                </BlueTextBold>
                <form onSubmit={handleSubmit} className="all-inputs">
                  <InputWrap emailFocused={emailFocused}>
                    <EmailCross
                      emailFocused={emailFocused}
                      src={CrossBtn}
                      alt=""
                      onClick={emailCrossHandler}
                    />
                    <EmailInput
                      disabled="true"
                      ref={emailRef}
                      emailFocused={emailFocused}
                      type="email"
                      name="email"
                      onClick={() => setEmailFocused(true)}
                      onChange={(e) =>
                        setFormInfo({ ...formInfo, email: e.target.value })
                      }
                      style={{ cursor: "pointer" }}
                      placeholder={emailFocused ? "" : info?.email}
                    />

                    <EmailPlaceholderBox emailFocused={emailFocused}>
                      <EmailPlaceholder emailFocused={emailFocused}>
                        {t("EmailCantBeChanged")}
                      </EmailPlaceholder>
                    </EmailPlaceholderBox>
                  </InputWrap>

                  {/* phone number input */}

                  <InputWrap
                    className="phone-input-box"
                    style={
                      phoneFocused
                        ? {
                            backgroundColor: "rgba(114, 139, 166, .4)",

                            borderRadius: "20px",
                          }
                        : { borderRadius: "20px" }
                    }
                  >
                    <PhoneNumberCross
                      phoneFocused={phoneFocused}
                      src={BlueCross}
                      style={{ width: "24px", height: "24px" }}
                      alt=""
                      onClick={phoneCrossHandler}
                    />
                    <PhoneInput
                      onFocus={() => setPhoneFocused(true)}
                      value={yourShop?.phone_number}
                      onChange={(number) => {
                        setYourShop({ ...yourShop, phone_number: number });
                        setPatchedShop({
                          ...patchedShop,
                          phone_number: number,
                        });
                        console.log(patchedShop, yourShop);
                      }}
                    />
                    <PhoneNumberPlaceholderBOx
                      style={{ top: "-33px", left: "22px" }}
                    >
                      <PhoneNumberPlaceholder style={{ fontSize: "11px" }}>
                        {t("Phonenumber")}
                      </PhoneNumberPlaceholder>
                    </PhoneNumberPlaceholderBOx>
                  </InputWrap>
                  {phoneFocused && (
                  <div className="btn_wrapper">
                    <ButtonBlue
                      className="profile_settings_btn"
                      type="submit"
                      onClick={SubmitProfilehandler}
                    >
                      {t("Editshop")}
                    </ButtonBlue>
                  </div>
                  )}
                </form>
              </div>
              <div className="password-box">
                <div className="password-box-content">
                  <div className="text">
                    <div className="flex-column">
                      <BlueTextBold className="password">
                        {t("Password")}
                      </BlueTextBold>
                      <GrayTextMedium className="change">
                        {t("SecurityIssues")}
                      </GrayTextMedium>
                    </div>
                  </div>
                  <ButtonYellow
                    className="password-btn"
                    onClick={() => setChangePasswordPage(true)}
                  >
                    {t("ChangePassword")}
                  </ButtonYellow>
                </div>
              </div>
            </>
          ) : (
            <ChangePassword setChangePasswordPage={setChangePasswordPage} />
          )}
        </div>
      </ProfileSettingsSection>
    </>
  );
};

export default ProfileSettings;
