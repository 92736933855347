import React, { useEffect } from "react";
import { useState } from "react";
import { useGlobalContext } from "../../../context/context";
import {
  DropdownArrow,
  WhiteCross,
  WhiteTick,
} from "../../../assets/landingPage/landingPageAssets";
import axios from "axios";
import { GrayTextMedium } from "../../../styles/GlobalStyles";
import { SingleOrder } from "../styles";
import { useTranslation } from "react-i18next";

const SingleOrderCheck = ({ modalOpen }) => {
  const [arrowUp, setArrowUp] = useState(false);
  const [dropProducts, setDropProducts] = useState(false);
  const [singleOrder, setSingleOrder] = useState();
  const {
    orderId,
    baseUrl,
    acceptRejectClicked,
    accRej,
    setAccRej,
    selected,
    setAcceptRejectClicked,
    setShopId,
    config,
    setRemoveString,
    getDemoShopShop,
    yourShop,
    loadSingleOrder,
  } = useGlobalContext();
  useEffect(() => {
    setAcceptRejectClicked(false);
    const demoShopSingleOrder = () => {
      try {
        const response = axios
          .get(`${baseUrl}/order/${orderId}`, config)
          .then((response) => {
            if (response.status === 200) {
              setSingleOrder(response.data);
              setShopId(response.data);
            }
          });
      } catch (error) {
        console.log(error);
      }
    };
    demoShopSingleOrder();
    getDemoShopShop();
  }, [selected]);
  console.log(singleOrder);

  const acceptRejectHandler = (string) => {
    setAccRej(string);
    //modal
    modalOpen();
    setRemoveString(string);
    setShopId(yourShop?.id);
  };
  const handleDrop = () => {
    setArrowUp(!arrowUp);
    setDropProducts(!dropProducts);
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="single-order-box">
        <div className="left-column">
          <p className="order-info-type">{t("Idnumber")}</p>
          <p className="order-info-type">{t("Name")}</p>
          <p className="order-info-type">{t("Email")}</p>
          <p className="order-info-type">{t("Phonenumber")}</p>
          <p className="order-info-type">{t("Country")}</p>
          <p className="order-info-type">{t("Products")}</p>
          <p className="order-info-type">{t("TotalPrice")} (€)</p>
          <p className="order-info-type">{t("Status")}</p>
        </div>
        <div className="right-column">
          <p className="order-info">{orderId}</p>
          <p className="order-info" style={{ fontSize: "14px" }}>
            {singleOrder?.buyer_name} {singleOrder?.buyer_last_name}
          </p>
          <p className="order-info">{singleOrder?.buyer_email}</p>
          <p className="order-info">{singleOrder?.buyer_phone}</p>
          {singleOrder?.buyer_country.length > 20 ? (
            <>
              <p className="order-info" style={{ fontSize: "14px" }}>
                {singleOrder?.buyer_country}
              </p>{" "}
            </>
          ) : (
            <>
              <p className="order-info" style={{ fontSize: "14px" }}>
                {singleOrder?.buyer_country}
              </p>
            </>
          )}
          <div className="order-info">
            <div className="single-order-all-products" onClick={handleDrop}>
              <button>
                <GrayTextMedium style={{ fontWeight: "700" }}>
                  {t("AllProducts")}
                </GrayTextMedium>
              </button>
              <img
                src={DropdownArrow}
                alt=""
                style={arrowUp ? { transform: "rotate(180deg)" } : {}}
              />

              <div
                className="single-order-all-products-box"
                style={
                  dropProducts ? { display: "block" } : { display: "none" }
                }
              >
                <ul className="single-order-all-products-ul">
                  {singleOrder?.products.map((item) => (
                    <li>
                      <span>
                        <span className="item-quantity">x{item.quantity}</span>
                        {item.name}{" "}
                      </span>
                      <span>{item.price}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <p className="order-info">{singleOrder?.order_total}</p>
          <div className="order-info status-info">
            {singleOrder?.status === "pending" ? (
              <>
                {acceptRejectClicked ? (
                  <>
                    <div
                      className={
                        accRej === "accepted"
                          ? "orders-dropdown green"
                          : "orders-dropdown red"
                      }
                    >
                      <span className="availability active-selected">
                        {accRej}
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="accept-reject-box">
                    <button
                      className="accept-reject-image-box image-box-accept"
                      onClick={() => acceptRejectHandler("accepted")}
                    >
                      <img src={WhiteTick} alt="" />
                    </button>
                    <button
                      className="accept-reject-image-box image-box-reject"
                      onClick={() => acceptRejectHandler("rejected")}
                    >
                      <img src={WhiteCross} alt="" />
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                {loadSingleOrder ? (
                  <>
                    <div className="loading-spinner-gray"></div>
                  </>
                ) : (
                  <>
                    <div
                      className={
                        singleOrder?.status === "accepted"
                          ? "orders-dropdown green"
                          : "orders-dropdown red"
                      }
                    >
                      <span className="availability active-selected">
                        {singleOrder?.status}
                      </span>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleOrderCheck;
