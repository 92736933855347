import React, {
  useState,
  useContext,
  createContext,
  useReducer,
  useEffect,
} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const cartFromLocalStorage = JSON.parse(sessionStorage.getItem("cart") || "[]");

const AppContext = createContext();
const baseUrl = "https://mbgc-me-staging.herokuapp.com/api/v1";
const AppProvider = ({ children }) => {
  const [authKey, setAuthKey] = useState("");
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [accRej, setAccRej] = useState();
  const [selected, setSelected] = useState(false);
  const [acceptRejectClicked, setAcceptRejectClicked] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [closed, setClosed] = useState(false);
  const [newsLetterEmail, setNewsLetterEmail] = useState("");
  const [newsLetterResponse, setNewsLetterResponse] = useState("");
  const [shops, setShops] = useState();
  const [shopId, setShopId] = useState();
  const [registerError, setRegisterError] = useState();
  // cart states
  const [amount, setAmount] = useState(0);
  const [cartItems, setCartItems] = useState();
  const [logInError, setLogInError] = useState(false);
  const [logInFired, setLogInFired] = useState(false);
  //home,  customers
  const [home, setHome] = useState();
  const [loadHome, setLoadHome] = useState(true);
  // shop
  const [yourShop, setYourShop] = useState();
  const [loadPatch, setLoadPatch] = useState(false);
  const [loadCreateShop, setLoadCreateShop] = useState(false);
  const [patchedShop, setPatchedShop] = useState({
    name: "",
    description: "",
    phone_number: "",
    image: "",
    is_premium: false,
  });
  const [createNewShop, setCreateNewShop] = useState({
    name: "",
    description: "",
    image: "",
    phone_number: "+38164321462",
    is_premium: false,
  });
  const [editShopResponse, setEditShopResponse] = useState();
  const [loadShop, setLoadShop] = useState(false);
  const [openCreateShop, setOpenCreateShop] = useState(false);

  // categories
  const [categories, setCategories] = useState();
  const [categoryId, setCategoryId] = useState();
  const [createNewCategory, setCreateNewCategory] = useState({
    name: "",
    description: "",
  });
  const [newCategoryResponse, setNewCategoryResponse] = useState();
  const [filteredCategory, setFilteredCategory] = useState("");
  const [loadCategory, setLoadCategory] = useState(true);
  // products
  const [products, setProducts] = useState();
  const [productId, setProductId] = useState();
  const [createProductMessage, setCreateProductMessage] = useState();
  const [productLoaded, setProductLoaded] = useState(false);

  // attributes
  const [attribute, setAttribute] = useState();
  const [newAttribute, setNewAttribute] = useState({ name: "" });
  const [loadAttribute, setLoadAttribute] = useState(true);
  const [unassignedAtt, setUnassignedAtt] = useState();
  // orders
  const [orders, setOrders] = useState();
  const [orderId, setOrderId] = useState();
  const [singleOrder, setSingleOrder] = useState();
  const [updateOrder, setUpdateOrder] = useState({ shop_id: null, status: "" });
  const [loadOrders, setLoadOrders] = useState(true);
  const [loadSingleOrder, setLoadSingleOrder] = useState(false);
  // customers
  const [customers, setCustomers] = useState();
  const [loadCustomers, setLoadCustomers] = useState(true);
  const [reload, setReload] = useState();
  //overall
  const [modalRemoveId, setModalRemoveId] = useState();
  const [removeString, setRemoveString] = useState();
  const [openPremiumModal, setOpenPremiumModal] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [cart, setCart] = useState(cartFromLocalStorage);
  const [total, setTotal] = useState(0);
  const [reloadCounter, setReloadCounter] = useState(0);
  const [message, setMessage] = useState("");
  const [shopCreated, setShopCreated] = useState(false);
  const [loadYourShop, setLoadYourShop] = useState(false);
  const [loadShopHome, setLoadShopeHome] = useState(true);
  const [loadProduct, setLoadProduct] = useState(false);
  const [shopMessage, setShopMessage] = useState(false);
  // loginHandle
  const [loginClicked, setLoginClicked] = useState(false);
  // premium plan order
  const [paymentCounter, setPaymentCounter] = useState(1);
  const [lastResetDate, setLastResetDate] = useState(new Date());

  const incrementPaymentCounter = () => {
    setPaymentCounter((prevCounter) => prevCounter + 1);
  };

  useEffect(() => {
    const currentDate = new Date();
    if (
      currentDate.getDate() !== lastResetDate.getDate() ||
      currentDate.getMonth() !== lastResetDate.getMonth() ||
      currentDate.getFullYear() !== lastResetDate.getFullYear()
    ) {
      setPaymentCounter(1);
      setLastResetDate(currentDate);
    }
  }, [lastResetDate]);


  const navigate = useNavigate();
  const pathname = window.location.href;
  useEffect(() => {
    if (cart) {
      sessionStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);
  useEffect(() => {
    if (!pathname.includes("#")) {
      setAuthKey(sessionStorage.getItem("authKey"));
    } else {
      const hash = window.location.hash.split("#")[1];
      sessionStorage.setItem("authKey", hash);
      setAuthKey(hash);
      setTimeout(() => {
        sessionStorage.removeItem("authKey");
        setAuthKey(null);
      }, 2 * 60 * 60 * 1000);
    }
  }, []);

  const handleIncrease = (item) => {
    const updatedCart = cart.map((i) => {
      if (i.id === item.id) {
        return {
          ...i,
          quantity: i.quantity + 1,
          amount: i.price * (i.quantity + 1),
        };
      }
      return i;
    });
    setCart(updatedCart);
  };

  const handleDecrease = (item) => {
    const updatedCart = cart
      .map((cartItem) => {
        if (cartItem.id === item.id) {
          if (cartItem.quantity <= 0) {
            return { ...cartItem, quantity: (cartItem.quantity = 0) };
          } else {
            return {
              ...cartItem,
              quantity: cartItem.quantity - 1,
              amount: cartItem.price * (cartItem.quantity - 1),
            };
          }
        }
        return cartItem;
      })
      .filter((item) => item.quantity !== 0);
    setCart(updatedCart);
  };

  const handleAddProduct = (product) => {
    const newItem = { ...product, quantity: 1, amount: product.price === 0.01 ? 0 : product.price };
    setCart([...cart, newItem]);
  };

  const handleRemoveProduct = (item) => {
    const updatedCart = cart.filter((i) => i.id !== item.id);
    setCart(updatedCart);
  };

  const handleClearCart = () => {
    setCart([]);
  };

  useEffect(() => {
    setTotal(
      cart.reduce((acc, currentValue) => {
        return acc + currentValue.amount;
      }, 0)
    );
  }, [cart]);
  //  -------- API CALLS ------------------------------------------------------
  const config = {
    headers: {
      Authorization: `${authKey}`,
    },
  };

  // user details
  const getUserDetails = async () => {
    try {
      const response = axios
        .get(`${baseUrl}/user/profile`, config)
        .then((response) => {
          if (response.status === 200) {
            setUserDetails(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // log in
  const handleLogIn = (e) => {
    e.preventDefault();
    const fetchUserData = async () => {
      try {
        const response = await axios
          .post(`${baseUrl}/auth/login`, formData)
          .then((response) => {
            if (response.status === 200) {
              console.log(response);
              sessionStorage.setItem("authKey", response.data.Authorization);
              setAuthKey(sessionStorage.getItem("authKey"));
              navigate("/profile");
              setLoginClicked(true);
            }
          });
      } catch (error) {
        setRegisterError(error.response.data.message);
        setTimeout(() => {
          setRegisterError("");
        }, 5000);
      }
    };
    fetchUserData();
    // setClicked(true);
  };

  // register user
  const registerUser = async () => {
    const { RegisterEmail, RegisterPassword } = formData;
    const newUser = { email: RegisterEmail, password: RegisterPassword };
    try {
      const response = await axios.post(`${baseUrl}/user`, newUser);
    } catch (error) {
      console.log(error);
    }
  };

  // login User

  // log out
  const logUserOut = async () => {
    try {
      const response = await axios
        .post(`${baseUrl}/auth/logout`, {}, config)
        .then((response) => {
          if (response.status === 201) {
            console.log(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
    sessionStorage.clear();
    setAuthKey("");
    setShopId("");
  };

  //facebook
  const facebookLogIn = async () => {
    try {
      const response = await axios.get(
        `https://mbgc-me-staging.herokuapp.com/api/v1/auth/facebook`
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  //google
  const googleLogIn = async () => {
    try {
      const response = await axios.get(`${baseUrl}/auth/google`);
      // console.log(response)
    } catch (error) {
      console.log(error);
    }
  };

  // all shops
  const fetchAllShops = async () => {
    try {
      const response = await axios.get(`${baseUrl}/shop/promoted`);
      if (response) {
        setShops(response.data.data);
      }
    } catch (error) {
      console.log(error);
      setShops(false);
    }
  };

  // newsletter
  const newsLetter = async () => {
    try {
      const response = await axios
        .post(
          `${baseUrl}/subscribe`,
          { email: newsLetterEmail },
          { headers: { "Content-type": "application/json" } }
        )
        .then((response) => {
          setNewsLetterResponse(response.data.message);
        });
    } catch (error) {
      setNewsLetterResponse(error.response.data.message);
    }
  };

  // single shop content

  //demo shop home

  const getDemoShopHome = async () => {
    try {
      const response = axios
        .get(`${baseUrl}/user/dashboard`, config)
        .then((response) => {
          if (response.status === 200) {
            setHome(response.data);
            setLoadHome(false);
          }
        });
    } catch (error) {
      console.log(error.status);
    }
  };
  //demo shop orders
  const getDemoShopOrders = async () => {
    try {
      const response = axios
        .get(`${baseUrl}/order`, config)
        .then((response) => {
          if (response.status === 200) {
            setOrders(response.data);
            setLoadOrders(false);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // demo shop single order
  const demoShopSingleOrder = () => {
    try {
      const response = axios
        .get(`${baseUrl}/order/${orderId}`, config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setSingleOrder(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // update single order

  const updateSingleOrder = () => {
    console.log(updateOrder);
    try {
      const response = axios
        .patch(`${baseUrl}/order/${orderId}`, updateOrder, config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  //  demo shop customers
  const getDemoShopCustomers = async () => {
    try {
      const response = axios
        .get(`${baseUrl}/order/customers`, config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setLoadCustomers(false);
            setCustomers(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  //  demo shop shop

  const getDemoShopShop = async () => {
    try {
      const response = axios.get(`${baseUrl}/shop`, config).then((response) => {
        if (response.status === 200) {
          setLoadShop(false);
          setLoadYourShop(false);
          setLoadShopeHome(false);
          setYourShop(response.data);
          sessionStorage.setItem("shopId", response.data.id);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadShop(false);
      setLoadYourShop(false);
      setLoadShopeHome(false);
    }
  };

  // demo shop patch

  const postPatchedShop = async () => {
    try {
      const response = axios
        .patch(`${baseUrl}/shop`, patchedShop, config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setEditShopResponse(response.data.message);
            setLoadPatch(false);
          }
        });
    } catch (error) {
      setLoadPatch(false);
      console.log(error);
    }
  };

  // demo shop create shop

  const postCreateShop = async () => {
    try {
      const response = await axios
        .post(`${baseUrl}/shop`, createNewShop, config)
        .then((response) => {
          if (response.status === 201) {
            setLoadShop(false);
            setShopMessage(response.data.message);
            setShopCreated(true);
            setLoadYourShop();
          }
        })
        .catch((error) => {
          setShopMessage(error.response.data.message);
        });
    } catch (error) {
      console.log(error);
      setLoadShop(false);
      setLoadYourShop(false);
    }
  };

  // demo shop delete shop

  const deleteShop = async () => {
    try {
      const response = await axios
        .delete(`${baseUrl}/shop`, config)
        .then((response) => {
          if (response.status === 200) {
            setClosed(true);
            console.log(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // demo shop categories
  const getDemoShopCategories = async () => {
    try {
      const response = axios
        .get(`${baseUrl}/category`, config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setCategories(response.data);
            setLoadCategory(false);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // demo shop create category
  const createDemoShopCategory = async () => {
    try {
      const response = axios
        .post(`${baseUrl}/category`, createNewCategory, config)
        .then((response) => {
          if (response.status === 200) {
            setNewCategoryResponse(response.data);
          }
        });
    } catch (error) {
      setNewCategoryResponse(error);
    }
  };

  // demo shop delete category

  const deleteCategory = async () => {
    try {
      const response = axios
        .delete(`${baseUrl}/category/${modalRemoveId}`, config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // demo shop products
  const getDemoShopProducts = async () => {
    try {
      const response = axios
        .get(`${baseUrl}/product`, config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setProducts(response);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  //delete product

  const deleteProduct = async () => {
    try {
      const response = axios
        .delete(`${baseUrl}/product/${modalRemoveId}`, config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // demo shop attribute
  const getAllAttributes = async () => {
    try {
      const response = axios
        .get(`${baseUrl}/attribute`, config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data.message);
            setAttribute(response.data);
            setLoadAttribute(false);
          }
        });
    } catch (error) {}
  };

  const getCreateProductAttributes = async () => {
    try {
      const response = axios
        .get(`${baseUrl}/attribute/unassigned`, config)
        .then((response) => {
          if (response.status === 200) {
            setUnassignedAtt(response.data);
          }
        });
    } catch (error) {}
  };

  const createAttribute = async () => {
    try {
      const response = axios
        .post(`${baseUrl}/attribute`, newAttribute, config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setNewAttribute(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAttribute = async () => {
    try {
      const response = axios
        .delete(`${baseUrl}/attribute/${modalRemoveId}`, config)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AppContext.Provider
      value={{
        setLoadAttribute,
        formData,
        setFormData,
        registerUser,
        fetchAllShops,
        newsLetter,
        setNewsLetterEmail,
        newsLetterEmail,
        newsLetterResponse,
        setNewsLetterResponse,
        setShops,
        shops,
        shopId,
        setShopId,
        amount,
        setAmount,
        cartItems,
        setCartItems,
        authKey,
        logUserOut,
        baseUrl,
        facebookLogIn,
        logInError,
        setLogInError,
        logInFired,
        setLogInFired,
        googleLogIn,
        getDemoShopHome,
        getDemoShopOrders,
        getDemoShopCustomers,
        getDemoShopShop,
        createDemoShopCategory,
        getDemoShopCategories,
        getDemoShopProducts,
        home,
        orders,
        customers,
        yourShop,
        patchedShop,
        postPatchedShop,
        setPatchedShop,
        postCreateShop,
        setCreateNewShop,
        createNewShop,
        deleteShop,
        categories,
        setCategories,
        createNewCategory,
        setCreateNewCategory,
        modalRemoveId,
        setModalRemoveId,
        deleteCategory,
        filteredCategory,
        setFilteredCategory,
        setProducts,
        products,
        getAllAttributes,
        setAttribute,
        attribute,
        newAttribute,
        setNewAttribute,
        createAttribute,
        orderId,
        setOrderId,
        demoShopSingleOrder,
        singleOrder,
        updateOrder,
        setUpdateOrder,
        updateSingleOrder,
        config,
        setCustomers,
        categoryId,
        setCategoryId,
        newCategoryResponse,
        setNewCategoryResponse,
        productId,
        setProductId,
        // fetchUserData,
        loggedIn,
        setLoggedIn,
        closed,
        setClosed,
        deleteProduct,
        removeString,
        setRemoveString,
        deleteAttribute,
        setAuthKey,
        userDetails,
        getUserDetails,
        setYourShop,
        accRej,
        setAccRej,
        selected,
        setSelected,
        setAcceptRejectClicked,
        acceptRejectClicked,
        openPremiumModal,
        setOpenPremiumModal,
        loadCategory,
        setLoadCategory,
        loadAttribute,
        loadOrders,
        setLoadOrders,
        loadCustomers,
        setLoadCustomers,
        loadShop,
        setLoadShop,
        loadHome,
        setLoadHome,
        setEditShopResponse,
        editShopResponse,
        reload,
        setReload,
        openCreateShop,
        setOpenCreateShop,
        setLoadPatch,
        loadPatch,
        registerError,
        setRegisterError,
        handleLogIn,
        cart,
        total,
        handleIncrease,
        handleDecrease,
        handleAddProduct,
        handleRemoveProduct,
        setReloadCounter,
        reloadCounter,
        createProductMessage,
        setCreateProductMessage,
        handleClearCart,
        message,
        setMessage,
        shopCreated,
        setShopCreated,
        loadShopHome,
        setLoadShopeHome,
        loadYourShop,
        setLoadYourShop,
        loadProduct,
        setLoadProduct,
        getCreateProductAttributes,
        setUnassignedAtt,
        unassignedAtt,
        productLoaded,
        setProductLoaded,
        loadSingleOrder,
        setLoadSingleOrder,
        shopMessage,
        setShopMessage,
        setLoadCustomers,
        setOrders,
        paymentCounter,
        setPaymentCounter,
        incrementPaymentCounter
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
