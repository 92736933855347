import React, { useState, useRef } from "react";
import {
  Flex,
  BlueTextSemiBold,
  GrayTextMedium,
} from "../../styles/GlobalStyles";
import {
  Logo,
  DashboardHome,
  DashboardOrders,
  DashboardCustomers,
  DashboardShop,
  DashboardCategories,
  DashboradProducts,
  DashboardAttributes,
  OrdersWhite,
  HomeWhite,
  CustomersWhite,
  ShopWhite,
  CategoriesWhite,
  ProductsWhite,
  AttributesWhite,
  Menu,
} from "../../assets/landingPage/landingPageAssets";
import {
  IconText,
  HomeLeftSide,
  LogoBox,
  DemoShopSection,
  Content,
  Container,
  HomeIcons,
  SingleIcon,
  FlexDemoShop,
  MenuDropdown,
} from "./styles";
import { useGlobalContext } from "../../context/context";
import Navbar from "../LandingPage/Navbar/Navbar";
import CustomersPage from "./CustomersPage/CustomersPage";
import DemoShopHome from "./DemoShopHome";
import AttributesSection from "./AttributesPage/AttributesSection";
import CategoriesSection from "./CategoriesPage/CategoriesSection";
import OrdersSection from "./OrdersPage/OrdersSection";
import ProductsSection from "./ProductPage/ProductsSection";
import ShopSection from "./ShopPage/ShopSection";
import Modal from "../Modal/Modal";
import "../DemoShop copy/stylesAllPages.scss";
import { useTranslation } from "react-i18next";

const DemoShopOriginal = () => {
  const [drop, setDrop] = useState(false);
  const [sections, setSections] = useState({
    homeSection: true,
    ordersSection: false,
    customerSection: false,
    shopSection: false,
    categoriesSection: false,
    productsSection: false,
    attributesSection: false,
    settingsSection: false,
  });
  const [customerFocus, setCustomerFocus] = useState(false);
  const [customerAscend, setCustomerAscend] = useState({
    name: false,
    email: false,
    orders: false,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const { authKey, setAuthKey } = useGlobalContext();
  const customerRef = useRef(null);
  const customerCrossHandler = () => {
    setCustomerFocus(false);
    customerRef.current.value = "";
  };
  const { modalRemoveId } = useGlobalContext();

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);
  const { t } = useTranslation();

  const menuHandler = (clickedSection) => {
    setDrop(false);
    if (clickedSection === "home") {
      setSections({
        homeSection: true,
        ordersSection: false,
        customerSection: false,
        shopSection: false,
        categoriesSection: false,
        productsSection: false,
        attributesSection: false,
        settingsSection: false,
      });
    } else if (clickedSection === "orders") {
      setSections({
        homeSection: false,
        ordersSection: true,
        customerSection: false,
        shopSection: false,
        categoriesSection: false,
        productsSection: false,
        attributesSection: false,
        settingsSection: false,
      });
    } else if (clickedSection === "customers") {
      setSections({
        homeSection: false,
        ordersSection: false,
        customerSection: true,
        shopSection: false,
        categoriesSection: false,
        productsSection: false,
        attributesSection: false,
        settingsSection: false,
      });
    } else if (clickedSection === "shop") {
      setSections({
        homeSection: false,
        ordersSection: false,
        customerSection: false,
        shopSection: true,
        categoriesSection: false,
        productsSection: false,
        attributesSection: false,
        settingsSection: false,
      });
    } else if (clickedSection === "categories") {
      setSections({
        homeSection: false,
        ordersSection: false,
        customerSection: false,
        shopSection: false,
        categoriesSection: true,
        productsSection: false,
        attributesSection: false,
        settingsSection: false,
      });
    } else if (clickedSection === "products") {
      setSections({
        homeSection: false,
        ordersSection: false,
        customerSection: false,
        shopSection: false,
        categoriesSection: false,
        productsSection: true,
        attributesSection: false,
        settingsSection: false,
      });
    } else if (clickedSection === "attributes") {
      setSections({
        homeSection: false,
        ordersSection: false,
        customerSection: false,
        shopSection: false,
        categoriesSection: false,
        productsSection: false,
        attributesSection: true,
        settingsSection: false,
      });
    }
  };

  return (
    <>
      <DemoShopSection>
        <Container>
          <Content>
            <FlexDemoShop>
              {/* left Side */}
              <HomeLeftSide id="left-side">
                <LogoBox src={Logo} alt="" />
                <p className="demo-shop-header">{t("Demoshop")}</p>
                <MenuDropdown
                  src={Menu}
                  alt=""
                  onClick={() => setDrop(!drop)}
                />
                <HomeIcons id="home-icons" drop={drop}>
                  {/* home section */}
                  <div
                    className={sections.homeSection ? "icon-hovered" : "icon"}
                    id="homeSection"
                    onClick={(e) => menuHandler("home")}
                  >
                    <SingleIcon
                      src={sections.homeSection ? HomeWhite : DashboardHome}
                      alt=""
                    />
                    <IconText
                      className={sections.homeSection ? "icon-text" : null}
                    >
                      {t("home")}
                    </IconText>
                  </div>
                  {/* orders section */}
                  <div
                    id="ordersSection"
                    className={sections.ordersSection ? "icon-hovered" : "icon"}
                    onClick={(e) => menuHandler("orders")}
                  >
                    <SingleIcon
                      src={
                        sections.ordersSection ? OrdersWhite : DashboardOrders
                      }
                      alt=""
                    />
                    <IconText
                      className={sections.ordersSection ? "icon-text" : null}
                    >
                      {t("Orders")}
                    </IconText>
                  </div>
                  {/* customers section */}
                  <div
                    id="customerSection"
                    className={
                      sections.customerSection ? "icon-hovered" : "icon"
                    }
                    onClick={(e) => menuHandler("customers")}
                  >
                    <SingleIcon
                      src={
                        sections.customerSection
                          ? CustomersWhite
                          : DashboardCustomers
                      }
                      alt=""
                    />
                    <IconText
                      className={sections.customerSection ? "icon-text" : null}
                    >
                      {t("Customers")}
                    </IconText>
                  </div>
                  {/* shop section */}
                  <div
                    id="shopSection"
                    className={sections.shopSection ? "icon-hovered" : "icon"}
                    onClick={(e) => menuHandler("shop")}
                  >
                    <SingleIcon
                      src={sections.shopSection ? ShopWhite : DashboardShop}
                      alt=""
                    />
                    <IconText
                      className={sections.shopSection ? "icon-text" : null}
                    >
                      {t("Shops")}
                    </IconText>
                  </div>
                  {/* categories section */}
                  <div
                    id="categoriesSection"
                    className={
                      sections.categoriesSection ? "icon-hovered" : "icon"
                    }
                    onClick={(e) => menuHandler("categories")}
                  >
                    <SingleIcon
                      src={
                        sections.categoriesSection
                          ? CategoriesWhite
                          : DashboardCategories
                      }
                      alt=""
                    />
                    <IconText
                      className={
                        sections.categoriesSection ? "icon-text" : null
                      }
                    >
                      {t("Categories")}
                    </IconText>
                  </div>
                  {/* producst section */}
                  <div
                    id="productsSection"
                    className={
                      sections.productsSection ? "icon-hovered" : "icon"
                    }
                    onClick={(e) => menuHandler("products")}
                  >
                    <SingleIcon
                      src={
                        sections.productsSection
                          ? ProductsWhite
                          : DashboradProducts
                      }
                      alt=""
                    />
                    <IconText
                      className={sections.productsSection ? "icon-text" : null}
                    >
                      {t("Products")}
                    </IconText>
                  </div>
                  {/* attributes section */}
                  <div
                    id="attributesSection"
                    className={
                      sections.attributesSection ? "icon-hovered" : "icon"
                    }
                    onClick={(e) => menuHandler("attributes")}
                  >
                    <SingleIcon
                      src={
                        sections.attributesSection
                          ? AttributesWhite
                          : DashboardAttributes
                      }
                      alt=""
                    />
                    <IconText
                      className={
                        sections.attributesSection ? "icon-text" : null
                      }
                    >
                      {t("Attributes")}
                    </IconText>
                  </div>
                </HomeIcons>
              </HomeLeftSide>
              {/* header */}

              {sections.homeSection === true &&
              sections.ordersSection === false &&
              sections.customerSection === false &&
              sections.shopSection === false &&
              sections.categoriesSection === false &&
              sections.productsSection === false &&
              sections.attributesSection === false &&
              sections.settingsSection === false ? (
                // Home Section
                <DemoShopHome sections={sections} setSections={setSections} />
              ) : sections.homeSection === false &&
                sections.ordersSection === false &&
                sections.customerSection === true &&
                sections.shopSection === false &&
                sections.categoriesSection === false &&
                sections.productsSection === false &&
                sections.attributesSection === false &&
                sections.settingsSection === false ? (
                <>
                  <CustomersPage
                    sections={sections}
                    setSections={setSections}
                  />
                </>
              ) : sections.homeSection === false &&
                sections.ordersSection === false &&
                sections.customerSection === false &&
                sections.shopSection === false &&
                sections.categoriesSection === false &&
                sections.productsSection === false &&
                sections.attributesSection === true &&
                sections.settingsSection === false ? (
                <AttributesSection
                  sections={sections}
                  setSections={setSections}
                  open={open}
                  handleClose={close}
                  modalOpen={modalOpen}
                />
              ) : sections.homeSection === false &&
                sections.ordersSection === false &&
                sections.customerSection === false &&
                sections.shopSection === false &&
                sections.categoriesSection === true &&
                sections.productsSection === false &&
                sections.attributesSection === false &&
                sections.settingsSection === false ? (
                <CategoriesSection
                  sections={sections}
                  setSections={setSections}
                  open={open}
                  modalOpen={modalOpen}
                  handleClose={close}
                />
              ) : sections.homeSection === false &&
                sections.ordersSection === true &&
                sections.customerSection === false &&
                sections.shopSection === false &&
                sections.categoriesSection === false &&
                sections.productsSection === false &&
                sections.attributesSection === false &&
                sections.settingsSection === false ? (
                <OrdersSection sections={sections} setSections={setSections} />
              ) : sections.homeSection === false &&
                sections.ordersSection === false &&
                sections.customerSection === false &&
                sections.shopSection === false &&
                sections.categoriesSection === false &&
                sections.productsSection === true &&
                sections.attributesSection === false &&
                sections.settingsSection === false ? (
                <ProductsSection
                  sections={sections}
                  setSections={setSections}
                  modalOpen={open}
                  handleClose={close}
                />
              ) : sections.homeSection === false &&
                sections.ordersSection === false &&
                sections.customerSection === false &&
                sections.shopSection === true &&
                sections.categoriesSection === false &&
                sections.productsSection === false &&
                sections.attributesSection === false &&
                sections.settingsSection === false ? (
                <ShopSection sections={sections} setSections={setSections} />
              ) : (
                <></>
              )}
              {/* activity */}
            </FlexDemoShop>
          </Content>
        </Container>
      </DemoShopSection>
      {modalOpen && (
        <>
          <Modal
            modalOpen={open}
            handleClose={close}
            modalRemoveId={modalRemoveId}
          />
        </>
      )}
    </>
  );
};

export default DemoShopOriginal;
