import React from "react";
import MojBizNews from "../../components/News/MojBizNews";
import { Footer } from "../../imports/landingPageContent";
import MiroiSonsNews from "../../components/News/MiroiSonsNews";


const MiroiSonsNewsPage = () => {
  
  return (
    <>
      <MiroiSonsNews />
      <Footer />
    </>
  );
};

export default MiroiSonsNewsPage;
