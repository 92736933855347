import styled from "styled-components";
import {
  ButtonBlue,
  Cross,
  WhiteTextMedium,
} from "../../../styles/GlobalStyles";

const ContactContainer = styled.div`
  background: #ffa800;
`;

const ContactHeader = styled(WhiteTextMedium)`
  font-size: 50px;
  line-height: 180px;
  text-align: center;
  display: block;
  @media only screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const InputWrap = styled.div`
  width: 562px;
  position: relative;
  display: flex;
  margin-bottom: 24px;
  height: 54px;

  @media only screen and (max-width: 650px) {
    width: 350px;
  }
`;

const PlaceHolderBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 22px;
  transition: 1s all ease;
  z-index: 1;
`;
const Placeholder = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: #fff;
  transition: 1s all ease;
  height: 30px;
`;

const NameCross = styled(Cross)`
  opacity: ${(props) => (props.nameFocused ? "1" : "0")};
`;

const EmailCross = styled(Cross)`
  opacity: ${(props) => (props.yourEmailFocused ? "1" : "0")};
`;

const NamePlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.nameFocused ? "-33px" : "2px")};
`;

const EmailPlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.yourEmailFocused ? "-33px" : "2px")};
`;

const NamePlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.nameFocused ? "11px" : "16px")};
  @media only screen and (max-width: 768px) {
    font-size: ${(props) => (props.nameFocused ? "11px" : "14px")};
  }
`;

const EmailPlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.yourEmailFocused ? "11px" : "16px")};
  @media only screen and (max-width: 768px) {
    font-size: ${(props) => (props.yourEmailFocused ? "11px" : "14px")};
  }
`;

const Input = styled.input`
  transition: 0.5s all ease;
  width: 100%;
  border-radius: 50px;
  height: 55px;
  border: none;
  outline: none;
  padding: 0 0 0 20px;
  margin-bottom: 27px;
  color: #fff;
  position: relative;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  &::placeholder {
    color: #fff;
  }
`;

const NameInput = styled(Input)`
  background: ${(props) => (props.nameFocused ? "#E98C00" : "#FF9900")};
`;

const EmailInput = styled(Input)`
  background: ${(props) => (props.yourEmailFocused ? "#E98C00" : "#FF9900")};
`;

const ContactButton = styled(ButtonBlue)`
  font-size: 16px;
  line-height: 23px;
  padding: 14.5px 231.5px;
  margin-bottom: 85px;

  @media only screen and (max-width: 768px) {
    width: 60%;
    padding: 10px 0px;
    white-space: nowrap;
  }
`;
const ContactTextArea = styled.textarea`
  width: 562px;
  border: none;
  outline: none;
  resize: none;
  // padding: 20px 0 0 20px;
  padding: 20px 0 0 20px;
  border-radius: 30px;
  color: #fff;
  font-size: 15px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  margin-bottom: 27px;
  background: ${(props) => (props.textFocused ? "#E98C00" : "#FF9900")};
  &::placeholder {
    color: #fff;
  }
  @media only screen and (max-width: 650px) {
    width: 350px;
  }
`;
const MessageBox = styled.div`
  display: flex;
  position: relative;
  resize: none;
`;
const MessagePlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.textFocused ? "-118px" : "-80px")};
`;
const MessagePlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.textFocused ? "11px" : "16px")};
  @media only screen and (max-width: 768px) {
    font-size: ${(props) => (props.textFocused ? "11px" : "14px")};
  }
`;
const MessageCross = styled(Cross)`
  top: 15px;
  opacity: ${(props) => (props.textFocused ? "1" : "0")};
`;

export {
  MessageCross,
  MessagePlaceholder,
  MessagePlaceholderBox,
  MessageBox,
  EmailInput,
  NameInput,
  EmailCross,
  NameCross,
  EmailPlaceholderBox,
  NamePlaceholder,
  EmailPlaceholder,
  NamePlaceholderBox,
  Placeholder,
  InputWrap,
  ContactTextArea,
  ContactButton,
  ContactContainer,
  ContactHeader,
  Input,
};
