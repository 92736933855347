import styled from "styled-components";
import { Cross } from "../../styles/GlobalStyles";

const InputWrap = styled.div`
  width: 494px;
  position: relative;
  display: flex;
  margin-bottom: 24px;
  height: 54px;
  &:nth-child(odd) {
    margin-right: 21px;

    @media only screen and (max-width: 768px) {
      margin-right: 0px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 769px) and (max-width: 1500px) {
    width: 45%;
  }
`;

const Input = styled.input`
  transition: 0.5s all ease;
  width: 100%;
  border-radius: 50px;
  height: 55px;
  border: none;
  outline: none;
  padding: 0 0 0 20px;
  margin-bottom: 27px;
  color: #374366;
  position: relative;
  z-index: 3;
`;
const PlaceHolderBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 22px;
  transition: 0.5s all ease;
  z-index: 1;
`;
const Placeholder = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: #3853a3;
  transition: 0.5s all ease;
  height: 30px;
`;

// inputs

const NameInput = styled(Input)`
  background: ${(props) =>
    props.nameFocused ? "rgba(114, 139, 166, .4)" : "rgba(114, 139, 166, 0.2)"};
`;
const LastNameInput = styled(Input)`
  background: ${(props) =>
    props.lastNameFocused
      ? "rgba(114, 139, 166, .4)"
      : "rgba(114, 139, 166, 0.2)"};
`;
const PhoneNumberInput = styled(Input)`
  background: ${(props) =>
    props.phoneFocused
      ? "rgba(114, 139, 166, .4)"
      : "rgba(114, 139, 166, 0.2)"};
`;

const EmailInput = styled(Input)`
  background: ${(props) =>
    props.emailFocused
      ? "rgba(114, 139, 166, .4)"
      : "rgba(114, 139, 166, 0.2)"};
`;
const AddressInput = styled(Input)`
  background: ${(props) =>
    props.addressFocused
      ? "rgba(114, 139, 166, .4)"
      : "rgba(114, 139, 166, 0.2)"};
`;

const CityInput = styled(Input)`
  background: ${(props) =>
    props.cityFocused ? "rgba(114, 139, 166, .4)" : "rgba(114, 139, 166, 0.2)"};
`;

const CountryInput = styled(Input)`
  background: ${(props) =>
    props.countryFocused
      ? "rgba(114, 139, 166, .4)"
      : "rgba(114, 139, 166, 0.2)"};
`;

const PostalInput = styled(Input)`
  background: ${(props) =>
    props.postalFocused
      ? "rgba(114, 139, 166, .4)"
      : "rgba(114, 139, 166, 0.2)"};
`;

// crosses

const NameCross = styled(Cross)`
  opacity: ${(props) => (props.nameFocused ? "1" : "0")};
`;
const LastNameCross = styled(Cross)`
  opacity: ${(props) => (props.lastNameFocused ? "1" : "0")};
`;
const EmailCross = styled(Cross)`
  opacity: ${(props) => (props.emailFocused ? "1" : "0")};
`;
const PhoneNumberCross = styled(Cross)`
  opacity: ${(props) => (props.phoneFocused ? "1" : "0")};
`;

const AddressCross = styled(Cross)`
  opacity: ${(props) => (props.addressFocused ? "1" : "0")};
`;
const CityCross = styled(Cross)`
  opacity: ${(props) => (props.cityFocused ? "1" : "0")};
`;
const CountryCross = styled(Cross)`
  opacity: ${(props) => (props.countryFocused ? "1" : "0")};
`;
const PostalCross = styled(Cross)`
  opacity: ${(props) => (props.postalFocused ? "1" : "0")};
`;

// placeholderBox

const NamePlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.nameFocused ? "-33px" : "2px")};
`;
const LastNamePlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.lastNameFocused ? "-33px" : "2px")};
`;
const EmailPlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.emailFocused ? "-33px" : "2px")};
`;
const PhoneNumberPlaceholderBOx = styled(PlaceHolderBox)`
  left: ${(props) => (props.phoneFocused ? "22px" : "70px")};
  top: ${(props) => (props.phoneFocused ? "-33px" : "4px")};
`;

const AddressPlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.addressFocused ? "-33px" : "2px")};
`;
const CityPlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.cityFocused ? "-33px" : "2px")};
`;
const CountryPlaceholderBox = styled(PlaceHolderBox)`
  left: ${(props) => (props.countryFocused ? "22px" : "70px")};
  top: ${(props) => (props.countryFocused ? "-33px" : "4px")};
`;
const PostalPlaceholderBox = styled(PlaceHolderBox)`
  top: ${(props) => (props.postalFocused ? "-33px" : "2px")};
`;

// placeholders

const NamePlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.nameFocused ? "11px" : "16px")};
`;
const LastNamePlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.lastNameFocused ? "11px" : "16px")};
`;

const EmailPlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.emailFocused ? "11px" : "16px")};
`;
const PhoneNumberPlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.phoneFocused ? "11px" : "16px")};
`;

const AddressPlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.addressFocused ? "11px" : "16px")};
`;
const CityPlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.cityFocused ? "11px" : "16px")};
`;

const CountryPlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.countryFocused ? "11px" : "16px")};
`;
const PostalPlaceholder = styled(Placeholder)`
  font-size: ${(props) => (props.postalFocused ? "11px" : "16px")};
`;

export {
  AddressInput,
  CityInput,
  CountryInput,
  PostalInput,
  AddressCross,
  CityCross,
  CountryCross,
  PostalCross,
  AddressPlaceholderBox,
  CityPlaceholderBox,
  CountryPlaceholderBox,
  PostalPlaceholderBox,
  AddressPlaceholder,
  CityPlaceholder,
  CountryPlaceholder,
  PostalPlaceholder,
  NamePlaceholderBox,
  LastNamePlaceholderBox,
  EmailPlaceholderBox,
  PhoneNumberPlaceholderBOx,
  NamePlaceholder,
  LastNamePlaceholder,
  EmailPlaceholder,
  PhoneNumberPlaceholder,
  NameCross,
  LastNameCross,
  EmailCross,
  PhoneNumberCross,
  InputWrap,
  Input,
  NameInput,
  LastNameInput,
  PhoneNumberInput,
  EmailInput,
};
